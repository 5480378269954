import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewCollaboratorDealComponent } from '../new-collaborator-deal/new-collaborator-deal.component';
import { MatTableDataSource } from '@angular/material/table';

export interface PeriodicElement {
  select: boolean;
  queue: number;
  status: boolean;
  name: string;
  email: string;
  acting: string;
  idiomas: Array<string>;
  dealsToday: number;
  totalDeals: number;
  menu: object;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    select: false,
    queue: 2,
    status: true,
    name: 'Gustavo Lopes',
    email: 'lopesalmeidagustavo@gmail.com',
    acting: 'audit',
    idiomas: ['EN', 'ES', 'PT'],
    dealsToday: 23514,
    totalDeals: 321,
    menu: {},
  },
  {
    select: true,
    queue: 1,
    status: false,
    name: 'Ana Silva',
    email: 'anasilva@example.com',
    acting: 'support',
    idiomas: ['EN', 'PT'],
    dealsToday: 124,
    totalDeals: 15,
    menu: {},
  },
  {
    select: false,
    queue: 3,
    status: true,
    name: 'Lucas Oliveira',
    email: 'lucas.oliveira@example.com',
    acting: 'bug',
    idiomas: ['PT'],
    dealsToday: 87,
    totalDeals: 20,
    menu: {},
  },
  {
    select: true,
    queue: 2,
    status: true,
    name: 'Mariana Santos',
    email: 'mariana.santos@example.com',
    acting: 'feature',
    idiomas: ['EN', 'PT'],
    dealsToday: 150,
    totalDeals: 30,
    menu: {},
  },
  {
    select: false,
    queue: 1,
    status: true,
    name: 'Juliana Lima',
    email: 'juliana.lima@example.com',
    acting: 'support',
    idiomas: ['EN', 'ES'],
    dealsToday: 75,
    totalDeals: 10,
    menu: {},
  },
  {
    select: true,
    queue: 2,
    status: false,
    name: 'Pedro Santos',
    email: 'pedro.santos@example.com',
    acting: 'bug',
    idiomas: ['PT'],
    dealsToday: 120,
    totalDeals: 25,
    menu: {},
  },
  {
    select: true,
    queue: 3,
    status: true,
    name: 'Carolina Oliveira',
    email: 'carolina.oliveira@example.com',
    acting: 'feature',
    idiomas: ['EN'],
    dealsToday: 90,
    totalDeals: 15,
    menu: {},
  },
  {
    select: false,
    queue: 1,
    status: false,
    name: 'Rafael Costa',
    email: 'rafael.costa@example.com',
    acting: 'audit',
    idiomas: ['PT'],
    dealsToday: 110,
    totalDeals: 20,
    menu: {},
  },
  {
    select: true,
    queue: 2,
    status: true,
    name: 'Fernanda Almeida',
    email: 'fernanda.almeida@example.com',
    acting: 'bug',
    idiomas: ['EN', 'PT'],
    dealsToday: 150,
    totalDeals: 30,
    menu: {},
  },
];

@Component({
  selector: 'app-endorsement',
  templateUrl: './endorsement.component.html',
  styleUrls: ['./endorsement.component.scss']
})
export class EndorsementComponent implements OnInit {
  displayedColumns: string[] = [
    'select',
    'queue',
    'status',
    'name',
    'email',
    'acting',
    'idiomas',
    'dealsToday',
    'totalDeals',
    'menu'
  ];

  constructor(public dialog: MatDialog) {}
  ngOnInit(): void {
    // this.openDialog();
  }

  openDialog() {
    this.dialog.open(NewCollaboratorDealComponent);
  }

  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
}
