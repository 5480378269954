import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-quote-detail-dialog',
  templateUrl: './quote-detail-dialog.component.html',
  styleUrls: ['../quote-detail.component.scss']
})
export class QuoteDetailDialogComponent implements OnInit {

  leadId: number;
  images: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<QuoteDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: number
  ) { }

  ngOnInit(): void {

    this.leadId = this.data;

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
