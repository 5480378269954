import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, pluck, tap } from 'rxjs/operators';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { PusherService } from 'src/app/shared/services/pusher.service';
import { environment } from 'src/environments/environment';
import { NewNotification } from '../../model/newNotification';
import { NotificationData, NotificationResult } from '../../model/notificationData';
import { NotificationDataService } from '../../services/notification-data.service';

@Component({
  selector: 'app-nav-notifications',
  templateUrl: './nav-notifications.component.html',
  styleUrls: ['./nav-notifications.component.scss']
})
export class NavNotificationsComponent implements OnInit {

  notifications$: Observable<NotificationResult[]>;
  notifications: NotificationResult[] = [];
  idNotifications: number[] = [];
  caps: string[] = [];
  loading: boolean = false;
  unread: boolean = false;
  read: boolean = false;
  soundOn: boolean;
  totalUnreadNotification: number;

  private _unread: BehaviorSubject<number> = new BehaviorSubject(null);

  public get Unread(): Observable<number> {
    return this._unread.asObservable();
  }

  setUnread(unread: number) {
    if(unread === 0) this._unread.next(null);
    else this._unread.next(unread);

  }

  @Output('get-unread') getUnread: EventEmitter<number> = new EventEmitter<number>();

  constructor(private notificationDataService: NotificationDataService,
    private notificationService: NotificationService,
    private pusherService: PusherService,
    private cookieService: CookieService) { }

  ngOnInit(): void {
    this.getNotification();
    this.pusher();
    this.verifySound();

  }

  verifySound() {
    let sound = this.cookieService.get('sound');

    if (sound === 'sound-on') this.soundOn = true;
    else if (sound === 'sound-off') this.soundOn = false;
    else {
      this.soundOn = true;
      this.cookieService.set('sound', 'sound-on');
    };
  }

  setSoundOff() {
    this.soundOn = false;
    this.cookieService.set('sound', 'sound-off');
  }

  setSoundOn() {
    this.soundOn = true;
    this.cookieService.set('sound', 'sound-on');
  }

  pusher() {

    let pusherClient = this.pusherService.getPusher();
    let roles = JSON.parse(window.localStorage.getItem('userRole')) as any[];
    let userId = JSON.parse(window.localStorage.getItem('userId'));
    let firstIndex: number;

    for (var index in roles) {
      firstIndex = parseInt(index);
      break;
    }
    const channelGlobal = pusherClient.subscribe(`${environment.channelSlug}global`);
    const channel = pusherClient.subscribe(`${environment.channelSlug}role-${roles[firstIndex]}`);
    const channelUserId = pusherClient.subscribe(`${environment.channelSlug}${userId}`);

    let leadsource = window.localStorage.getItem('leadsource');

    if (leadsource) {
      const leadsourceChannel = pusherClient.subscribe(`${environment.channelSlug}leadsource-${leadsource}`);

      leadsourceChannel.bind('read-notification', (read) => {

        read.ids.forEach(e => {
          let message = this.notifications.find(x => x.id === e.toString());
          if (message) message.is_read = '1';

        });

      });

      leadsourceChannel.bind('archive-notification', (archive) => {

        archive.ids.forEach(e => {
          let i = this.notifications.findIndex(x => x.id === e.toString());
          this.notifications.splice(i, 1);
        });

      });
    }

    channelGlobal.bind(
      'pusher:subscription_succeeded', () => {
        console.log(`channel: ${environment.channelSlug}global`);
      });

    channelUserId.bind('read-notification', (read) => {

      read.notifications_id.forEach(e => {
        let message = this.notifications.find(x => x.id === e.toString());
        if (message) message.is_read = '1';

      });

    });

    channelUserId.bind('archive-notification', (archive) => {

      archive.notifications_id.forEach(e => {
        let i = this.notifications.findIndex(x => x.id === e.toString());
        this.notifications.splice(i, 1);
      });

    });

    channelGlobal.bind('read-notification', (read) => {

      read.notifications_id.forEach(e => {
        let message = this.notifications.find(x => x.id === e.toString());
        if (message) message.is_read = '1';

      });

    });

    channelGlobal.bind('archive-notification', (archive) => {

      archive.notifications_id.forEach(e => {
        let i = this.notifications.findIndex(x => x.id === e.toString());
        this.notifications.splice(i, 1);
      });

    });

    channel.bind('read-notification', (read) => {

      read.ids.forEach(e => {
        let message = this.notifications.find(x => x.id === e.toString());
        if (message) message.is_read = read.read.toString();

      });


    });

    channel.bind('archive-notification', (archive) => {

      archive.ids.forEach(e => {
        let i = this.notifications.findIndex(x => x.id === e.toString());
        this.notifications.splice(i, 1);
      });

    });

  }

  getNotification() {
    this.loading = true;

    this.notificationDataService.getNotifications().pipe(pluck('results'), finalize(() => {
      this.loading = false;
    })).subscribe(notifications => {
      if (notifications) {
        this.notifications = notifications;
        this.totalUnreadNotification = notifications.filter(x => x.is_read === '0').length;
        this.setUnread(this.totalUnreadNotification);
      }
    });
  }

  markAllAsRead() {

    this.notifications.forEach((e) => {
      this.idNotifications.push(e.id);
    });

    this.notificationDataService.markAsRead(this.idNotifications).subscribe(res => {

      if (res.status === 'FAILED') this.notificationService.error(res['error'][0].type);
      else {
        this.notifications.map(x => x.is_read = '1');
        this.totalUnreadNotification = 0;
        this.setUnread(null);
      }

    });
  }

  archiveAll() {

    this.notifications.forEach((e) => {
      this.idNotifications.push(e.id);
    });

    this.notificationDataService.archiveNotification(this.idNotifications).subscribe(res => {

      if (res.status === 'FAILED') this.notificationService.error(res['error'][0].type);
      else {
        res.params.notifications_id.forEach(e => {
          let i = this.notifications.findIndex(x => x.id === e);
          this.notifications.splice(i, 1);
        });

        this.totalUnreadNotification = 0;
        this.setUnread(null);

        //this.notifications.map(x => x.is_read = '1');
      }

    });
  }

  markAsRead(idNotification: number) {

    let notifications: number[] = [];
    notifications.push(idNotification);

    this.notificationDataService.markAsRead(notifications).subscribe(res => {

      if (res.status === 'FAILED') this.notificationService.error(res['error'][0].type);
      else {
        this.notifications.find(x => x.id === res.params.notifications_id[0]).is_read = '1';
        this.totalUnreadNotification = this.totalUnreadNotification - 1;
        this.setUnread(this.totalUnreadNotification);
      };

    });
  }

  markAsUnread(idNotification: number) {

    let notifications: number[] = [];
    notifications.push(idNotification);

    this.notificationDataService.markAsUnread(notifications).subscribe(res => {

      if (res.status === 'FAILED') this.notificationService.error(res['error'][0].type);
      else {
        this.notifications.find(x => x.id === res.params.notifications_id[0]).is_read = '0';
        this.totalUnreadNotification = this.totalUnreadNotification + 1;
        this.setUnread(this.totalUnreadNotification);
      };

    });
  }

  archiveNotification(idNotification: number) {

    let notifications: number[] = [];
    notifications.push(idNotification);

    if(this.notifications.find(x => x.id === idNotification).is_read === '0') {
      this.totalUnreadNotification = this.totalUnreadNotification - 1;
      this.setUnread(this.totalUnreadNotification);
    }

    this.notificationDataService.archiveNotification(notifications).subscribe(res => {

      if (res.status === 'FAILED') this.notificationService.error(res['error'][0].type);

    });
  }

  notificationEM($event: NewNotification) {
  }

}
