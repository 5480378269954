<mat-card [ngClass]="{'shadowNone': !boxShadow}">

  <mat-card-header>
    <h1>DEC PAGE - Quote ID {{idQuote}}
    </h1>
    <mat-card-subtitle>
    </mat-card-subtitle>
  </mat-card-header>

  <div *ngIf="decpage$ | async as dec">

    <mat-card style="box-shadow: none;" *ngIf="dec.total > 0; else elseBlock">
      <mat-card-actions align="end" style="margin-top: -80px;">
        <button mat-button color="warn" style="margin-left: 20px; font-weight: bold;"
          (click)="DeleteFile(dec.data[0].file_id)">Delete File</button>
        <button mat-button color="primary" style="font-weight: bold;"
          (click)="DownloadDecPage(dec.data[0].file_id)">Download File
          <mat-icon>get_app</mat-icon>
        </button>
      </mat-card-actions>
      <mat-card-content class="pdf-container">
        <iframe [src]="dec.data[0].data | safeHtml"></iframe>
      </mat-card-content>

    </mat-card>

    <ng-template #elseBlock>
      <app-file-upload></app-file-upload>
    </ng-template>

  </div>

  <app-quote-detail></app-quote-detail>
</mat-card>
