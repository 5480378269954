import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-card-filter-view-quote',
  templateUrl: './card-filter-view-quote.component.html',
  styleUrls: ['./card-filter-view-quote.component.scss']
})
export class CardFilterViewQuoteComponent implements OnInit {

  fgFilter: FormGroup;

  @Output('change-filter') changeFilter: EventEmitter<any> = new EventEmitter<any>();

  constructor(private _formBuilder: FormBuilder, private cookieService: CookieService) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {

    let from: Date;
    let to: Date;
    

    if (this.cookieService.get('dtFromViewQuotes')) {
      
      from = new Date(this.htmlDecode(this.cookieService.get('dtFromViewQuotes')));
    }

    if (this.cookieService.get('dtToViewQuotes')) {
      to = new Date(this.htmlDecode(this.cookieService.get('dtToViewQuotes')));
    }

    this.fgFilter = this._formBuilder.group({
      // ctrlLimit: [''],
      fcDtFrom: [from, ''],
      fcDtTo: [to, ''],
      fcSearch: [''],
      fcStatus: [this.cookieService.get('statusViewQuote') || ''],
    });

  }

  htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  }

  onSubmit() {

    var datePipe = new DatePipe('en-US');
    let from = null;
    let to = null;

    from = datePipe.transform(this.fgFilter.get('fcDtFrom').value, 'MM/dd/yyyy');
    to = datePipe.transform(this.fgFilter.get('fcDtTo').value, 'MM/dd/yyyy');
    this.cookieService.set('statusViewQuote', this.fgFilter.get('fcStatus').value || '');
    this.cookieService.set('dtFromViewQuotes', from);
    this.cookieService.set('dtToViewQuotes', to);
    this.changeFilter.emit(this.fgFilter);
  }

}
