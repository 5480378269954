import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollaboratorsRoutingModule } from './collaborators-routing.module';
import { TicketsComponent } from './tickets/tickets.component';
import {MatTabsModule} from '@angular/material/tabs';
import { AuditTicketComponent } from './tickets/audit-ticket/audit-ticket.component';
import {MatTableModule} from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NewCollaboratorComponent } from './tickets/new-collaborator/new-collaborator.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import { CoiComponent } from './tickets/coi/coi.component';
import { ClaimComponent } from './tickets/claim/claim.component';
import { AvoidComponent } from './tickets/avoid/avoid.component';
import { DealsComponent } from './deals/deals.component';
import { AuditDealsComponent } from './deals/audit-deals/audit-deals.component';
import { RetentionComponent } from './deals/retention/retention.component';
import { EndorsementComponent } from './deals/endorsement/endorsement.component';
import { SalesComponent } from './deals/sales/sales.component';
import { NewCollaboratorDealComponent } from './deals/new-collaborator-deal/new-collaborator-deal.component';
import { BlipComponent } from './blip/blip.component';
import { ClaimBlipComponent } from './blip/claim-blip/claim-blip.component';
import { SdrBlipComponent } from './blip/sdr-blip/sdr-blip.component';
import { CollaboratorsService } from './collaborators.service';
import { SlideComponent } from './components/slide/slide.component';
import {MatMenuModule} from '@angular/material/menu';
import { NewCollaboratorsBlipComponent } from './blip/new-collaborators-blip/new-collaborators-blip.component';
import { RemoveCollaboratorComponent } from './blip/remove-collaborator/remove-collaborator.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { CxComponent } from './blip/cx/cx.component';
import { MenuLaguageComponent } from './components/menu-laguage/menu-laguage.component'
import { FormsModule } from '@angular/forms'; 
import { ModalLogsComponent } from './components/modal-logs/modal-logs.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatButtonModule} from '@angular/material/button';
@NgModule({
  declarations: [
    TicketsComponent,
    AuditTicketComponent,
    NewCollaboratorComponent,
    CoiComponent,
    ClaimComponent,
    AvoidComponent,
    DealsComponent,
    AuditDealsComponent,
    ModalLogsComponent,
    RetentionComponent,
    EndorsementComponent,
    SalesComponent,
    NewCollaboratorDealComponent,
    BlipComponent,
    ClaimBlipComponent,
    SdrBlipComponent,
    SlideComponent,
    NewCollaboratorsBlipComponent,
    RemoveCollaboratorComponent,
    ConfirmComponent,
    CxComponent,
    MenuLaguageComponent
  ],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatSelectModule,
    MatFormFieldModule,
    FormsModule,
    MatDialogModule,
    MatDatepickerModule,
    CollaboratorsRoutingModule,
    MatTabsModule,
    MatTableModule,
    MatButtonModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatIconModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    MatMenuModule
  ],
  providers:[CollaboratorsService]
})
export class CollaboratorsModule { }
