import { NgModule } from '@angular/core';
import { SafeHtmlPipe } from './safe-html.pipe';
import { PhonePipe } from './phone.pipe';

@NgModule({
  declarations: [
    SafeHtmlPipe,
    PhonePipe
  ],
  exports: [
    SafeHtmlPipe,
    PhonePipe
  ]
})
export class PipeModule {

  static forRoot() {
    return {
      ngModule: PipeModule,
      providers: [],
    };
  }

}
