<mat-card-actions align="end">
  <div class="two-fields two-fields-mt">
    <button mat-stroked-button color="primary" class="btn-export" (click)="exportExcel()">
      Export
    </button>
  </div>
</mat-card-actions>

<div class="export-mobile-container">
  <button mat-stroked-button color="primary" class="btn-export-mobile" (click)="exportExcel()">
    Export
  </button>
</div>

<div class="shadow-export" *ngIf="loaderExport">
  <div class="shadow-export-loader shadow-export-loader-message">
    <div class="spinner">
      <mat-spinner [diameter]="60"></mat-spinner>
    </div>
    <span class="shadow-export-loader-text">Wait, we are generating the CSV</span>
  </div>
</div>

<div class="table-container" style="display: flex; justify-content: center;">
  <div class="example-container">
    <div class="example-loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
      <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
      <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
        API rate limit has been reached. It will be reset in one minute.
      </div>
    </div>

  <div class="example-table-container">

    <div class="example-table-container" #TABLEEXPORT>

      <table mat-table [dataSource]="data" class="table" matSort matSortActive="daily_cash_product_id" matSortDisableClear
          matSortDirection="desc">

          <ng-container matColumnDef="daily_cash_product_id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Product# </th>
            <td mat-cell *matCellDef="let element">
              <span>
                {{ element.daily_cash_product_id }}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="daily_cash_id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> DC# </th>
            <td mat-cell *matCellDef="let element">
              <span *ngIf="canEdit()" matTooltip="Edit" matTooltipPosition="right" class="badge-outline-primary badge" (click)="editDailyCash(element.daily_cash_id)">
                {{ element.daily_cash_id }}
              </span>

              <span *ngIf="!canEdit()">
                {{ element.daily_cash_id }}
              </span>
            </td>

          </ng-container>

          <ng-container matColumnDef="daily_cash_type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> DC Type </th>
            <td mat-cell *matCellDef="let element">
              {{ element.daily_cash_type }} </td>
          </ng-container>

          <ng-container matColumnDef="daily_cash_product_effective_date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Effective Date </th>
            <td mat-cell *matCellDef="let element">
              {{ element.daily_cash_product_effective_date }} </td>
          </ng-container>

          <ng-container matColumnDef="foreign_location_id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Location </th>
            <td mat-cell *matCellDef="let element">
              <div [ngSwitch]="element.foreign_location_id">

                <span *ngSwitchCase="1" style="    font-family: Lato !important;
                font-size: 11px !important;
                color: #666 !important;
            ">
                  Framingham
                </span>

                <span *ngSwitchCase="2" style="    font-family: Lato !important;
                font-size: 11px !important;
                color: #666 !important;
            ">
                  Malden
                </span>

                <span *ngSwitchCase="3" style="    font-family: Lato !important;
                font-size: 11px !important;
                color: #666 !important;
            ">
                  Br
                </span>

              </div>
          </ng-container>

          <ng-container matColumnDef="daily_cash_display_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let element">{{ element.daily_cash_display_name }}</td>
          </ng-container>

          <ng-container matColumnDef="user_email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> CSR/Agent </th>
            <td mat-cell *matCellDef="let element">
                {{ element.user_email }}
            </td>
          </ng-container>

          <ng-container matColumnDef="foreign_lob_id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Lob# </th>
            <td mat-cell *matCellDef="let element">
              {{ element.foreign_lob_id  }} </td>
          </ng-container>

          <ng-container matColumnDef="foreign_carrier_id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Carrier Id </th>
            <td mat-cell *matCellDef="let element">
              {{ element.foreign_carrier_id  }} </td>
          </ng-container>

          <ng-container matColumnDef="foreign_policy_id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Policy# </th>
            <td mat-cell *matCellDef="let element">
              {{ element.foreign_policy_id  }} </td>
          </ng-container>

          <ng-container matColumnDef="daily_cash_product_premium">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Premium </th>
            <td mat-cell *matCellDef="let element">
              {{ element.daily_cash_product_premium | currency }} </td>
          </ng-container>

          <ng-container matColumnDef="daily_cash_product_brz">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Brz </th>
            <td mat-cell *matCellDef="let element">
              {{ element.daily_cash_product_brz | currency }} </td>
          </ng-container>

          <ng-container matColumnDef="daily_cash_product_monthly">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Monthly </th>
            <td mat-cell *matCellDef="let element">
              {{ element.daily_cash_product_monthly | currency }} </td>
          </ng-container>

          <ng-container matColumnDef="daily_cash_product_carrier">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Carrier </th>
            <td mat-cell *matCellDef="let element">
              {{ element.daily_cash_product_carrier | currency }} </td>
          </ng-container>

          <ng-container matColumnDef="daily_cash_product_monthly_to_be_paid">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Monthly Paid </th>
            <td mat-cell *matCellDef="let element">
              {{ element.daily_cash_product_monthly_to_be_paid | currency }} </td>
          </ng-container>

          <ng-container matColumnDef="daily_cash_product_carrier_to_be_paid">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Carrier Paid </th>
            <td mat-cell *matCellDef="let element">
              {{ element.daily_cash_product_carrier_to_be_paid | currency }} </td>
          </ng-container>

          <ng-container matColumnDef="daily_cash_product_is_rmv">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Rmv </th>
            <td mat-cell *matCellDef="let element">
              {{ element.daily_cash_product_is_rmv | currency }} </td>
          </ng-container>

          <ng-container matColumnDef="daily_cash_product_runner">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Runner </th>
            <td mat-cell *matCellDef="let element">
              {{ element.daily_cash_product_runner | currency }} </td>
          </ng-container>

          <ng-container matColumnDef="daily_cash_product_runner_id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Runner Id </th>
            <td mat-cell *matCellDef="let element">
              {{ element.daily_cash_product_runner_id | currency }} </td>
          </ng-container>

          <ng-container matColumnDef="daily_cash_product_translation">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Translation </th>
            <td mat-cell *matCellDef="let element">
              {{ element.daily_cash_product_translation | currency }} </td>
          </ng-container>

          <ng-container matColumnDef="daily_cash_product_cccharge">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> CC Charge </th>
            <td mat-cell *matCellDef="let element">
              {{ element.daily_cash_product_cccharge | currency }} </td>
          </ng-container>

          <ng-container matColumnDef="daily_cash_file">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Pdf </th>
            <td mat-cell *matCellDef="let element">
              <i *ngIf="element.daily_cash_file" class="far fa-file-pdf pdf"></i>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>

  <mat-paginator [length]="resultsLength" [pageSizeOptions]="pageLimit"></mat-paginator>
</div>
</div>
