import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { DataDriver } from '../../../model/driver';
import { DataVehicle } from '../../../model/vehicles';
import { QuotesService } from '../../../service/quote.service';
import { DataQuote } from '../../../model/quote';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { UserService } from 'src/app/core/user/user.service';

interface Image {
  data: string;
  id: number;
}

@Component({
  selector: 'app-quote-detail',
  templateUrl: './quote-detail.component.html',
  styleUrls: ['./quote-detail.component.scss']
})
export class QuoteDetailComponent implements OnInit {

  vehicles$: Observable<DataVehicle[]>;
  drivers$: Observable<DataDriver[]>;
  basics$: Observable<DataQuote>;

  @Input() ismodal: boolean;
  @Input() leadId: number;

  images: Image[] = [];
  idQuote: number;

  constructor(private quoteService: QuotesService, private userService: UserService,
    private activatedRoute: ActivatedRoute, private notificationService: NotificationService) { }

  ngOnInit(): void {

    if (!this.ismodal) {
      this.activatedRoute.params.subscribe(params => {

        this.idQuote = params.id;

        this.listenBasics(params.id);
        this.listenVehicles(params.id);
        this.listenDrivers(params.id);
        this.listenFiles(params.id);
      });
    } else {
      this.listenBasics(this.leadId);
      this.listenVehicles(this.leadId);
      this.listenDrivers(this.leadId);
      this.listenFiles(this.leadId);
    }

  }

  listenVehicles(id) {
    this.vehicles$ = this.quoteService.getVehicle(id).pipe(
      pluck('data')
    );
  }
  listenDrivers(id) {
    this.drivers$ = this.quoteService.getDriver(id).pipe(
      pluck('data')
    );
  }

  listenBasics(id) {
    this.basics$ = this.quoteService.getBasicsById(id);
  }

  listenFiles(id) {

    this.quoteService.getFiles(id).subscribe(file => {

      if (file.data.length > 0) {
        file.data.forEach((e) => {

          this.images.push({
            data: e.data,
            id: e.file_id
          });

        });
      }

    });
  }

  teste(id: number) {
    this.downloadDecPage(id);
  }

  downloadDecPage(id: number) {
    if (this.userService.cap.hasOwnProperty('brzinsurance_lead_download_file')) {
      this.quoteService.downloadDecPage(id, this.idQuote).subscribe();
    }
    else {
      this.notificationService.alert('You have no permission to download dec page.')
    }

  }

}
