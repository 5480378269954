<mat-expansion-panel class="expansion-panel">
  <mat-expansion-panel-header>
    <mat-panel-title style="color: #ffffff">
      <div style="margin-top: 5px">
        <mat-icon inline="true">forum</mat-icon>
        <span style="margin-left: 10px">Communication</span>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <div>
    <a
      *ngIf="checkQuoteCap()"
      mat-list-item
      routerLink="/home/communication/"
      class="item-desc"
    >
      Broadcast Message
    </a>
  </div>
</mat-expansion-panel>
