import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { DashboardLeadService } from '../../service/dashboard-lead.service';

import * as Highcharts from "highcharts/highcharts.src";
import HighchartsMore from "highcharts/highcharts-more.src.js";
import SolidGauge from "highcharts//modules/solid-gauge.js";

HighchartsMore(Highcharts);
SolidGauge(Highcharts);

@Component({
  selector: 'app-conversion-rate',
  templateUrl: './conversion-rate.component.html',
  styleUrls: ['./conversion-rate.component.scss']
})
export class ConversionRateComponent implements OnInit {

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};
  updateFlag = false;

  teste: boolean = false;

  @Input() hasCapPermission: boolean;

  constructor(private dashService: DashboardLeadService, private notification: NotificationService) { }

  ngOnInit(): void {
    if (this.hasCapPermission) {
      this.chartIt();
    }
  }

  chartIt() {

    this.dashService.getConversionRate().subscribe(data => {

      this.chartOptions.series = [];

      this.chartOptions.chart = {
        type: 'solidgauge',
        backgroundColor: '#fafafa',
      }

      this.chartOptions.credits = {
        enabled: false
      }

      this.chartOptions.title = {
        text: null
      }

      this.chartOptions.tooltip = {
        enabled: false,
        borderWidth: 0,
        backgroundColor: 'none',
        shadow: false,
        style: {
          fontSize: '22px',
          fontFamily: 'Lato'
        },
        valueSuffix: '%',
        pointFormat: '<br><span style="font-size:2em; color: {point.color}; font-weight: bold">{point.y}</span>',
        positioner: function (labelWidth) {
          return {
            x: (this.chart.chartWidth - labelWidth) / 2,
            y: (this.chart.plotHeight / 2)
          };
        }
      }

      this.chartOptions.pane = {
        startAngle: 0,
        endAngle: 360,
        background: [{ // Track for Exercise
          outerRadius: '47%',
          innerRadius: '33%',
          backgroundColor: Highcharts.color('#fc636b')
            .setOpacity(0.3)
            .get(),
          borderWidth: 0
        }]
      }

      this.chartOptions.yAxis = {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: []
      };

      this.chartOptions.plotOptions = {
        solidgauge: {
          dataLabels: {
            enabled: true,
            borderColor: '#ffffff',
            align: 'center',
            verticalAlign: 'center',
            position: 'center',
            format:
              '<div style="">' +
              '<span style="font: 2.6em Lato; font-weight: bold">{y}%</span><br/>' +
              '</div>'
          },

          linecap: 'round',
          stickyTracking: false,
          rounded: true
        }
      }

      this.chartOptions.series.push({
        name: 'Exercise',
        type: 'solidgauge',
        data: [{
          color: '#fc636b',
          radius: '47%',
          innerRadius: '33%',
          y: data.value * 100,

        }]
      });

      this.teste = true;

    }, (error: HttpErrorResponse) => {

      this.notification.error(error.error);

    });

  }

}
