import { Injectable } from '@angular/core';

const KEY = 'authToken';
const KEY_EXPIRE = 'expiresIn';

@Injectable({ providedIn: 'root' })
export class TokenService {

  hasToken() {
    return !!this.getToken();
  }

  setToken(token) {
    window.localStorage.setItem(KEY, token);
  }

  getToken() {
    return window.localStorage.getItem(KEY);
  }

  isTokenExpired(token?: string): boolean {
    if (!token) token = this.getToken();
    if (!token) return true;

    const date = this.getTokenExpirationDate();
    if (date === undefined) return false;
    return !(date.valueOf() > new Date().valueOf());
  }

  private decode(token: string) {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      console.log(`Nao foi possivel decodificar o token`)
    }
  }

  // getTokenExpirationDate(token: string): Date {
  //   const decoded = this.decode(token);

  //   if (decoded.exp === undefined) return null;

  //   const date = new Date(0);
  //   date.setUTCSeconds(decoded.exp);
  //   return date;
  // }

  setExpirationDate(expiresIn: string) {
    window.localStorage.setItem(KEY_EXPIRE, expiresIn);
  }

  killToken() {
    window.localStorage.removeItem(KEY);
  }

  getTokenExpirationDate() {
    const date = window.localStorage.getItem(KEY_EXPIRE);

    const year = parseInt(date.substr(0, 4));
    const month = parseInt(date.substr(5, 2)) - 1;
    const day = parseInt(date.substr(8, 2));
    const hour = parseInt(date.substr(11, 2));
    const minute = parseInt(date.substr(14, 2));
    const second = parseInt(date.substr(17, 2));

    const expires_in = new Date(year, month, day, hour, minute, second);

    return expires_in;
  }

  removeToken() {
    window.localStorage.removeItem(KEY);
  }

}
