<!-- <highcharts-chart
  [Highcharts]="Highcharts"
  [options]="chartOptions"
  style="
    height: 30em;
    display: flex;
    justify-content: center;
    align-items: center;
  "
  
  class="chart"
></highcharts-chart> -->

<div id="carrierChart">
</div>
