import { DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { TableViewPoliciesComponent } from 'src/app/modules/daily-cash/modules/view-policies/components/table-view-policies/table-view-policies.component';
import { DataViewPolicy } from 'src/app/modules/daily-cash/modules/view-policies/model/dataViewPolicy';
import { ViewPoliciesService } from 'src/app/modules/daily-cash/modules/view-policies/services/view-policies.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { Dashboard } from '../../model/dashboard';
import { DashboardLeadService } from '../../service/dashboard-lead.service';

@Component({
  selector: 'app-dashboard-brz-carrier-target',
  templateUrl: './dashboard-brz-carrier-target.component.html',
  styleUrls: ['./dashboard-brz-carrier-target.component.scss']
})
export class DashboardBrzCarrierTargetComponent {


  policies$: Observable<DataViewPolicy[]>;
  loading: boolean = false;
  dados:any;
  @ViewChild(TableViewPoliciesComponent) TableViewPoliciesComponent: TableViewPoliciesComponent;

  constructor(private viewDailyCashService: ViewPoliciesService,
    private dialog: MatDialog, private notificationService: NotificationService) { }

  ngOnInit(): void {}

  changeFilter($event) {

    this.dados = $event;

    // console.log($event)
  }


}
