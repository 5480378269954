import { Component, Input, OnInit } from '@angular/core';
import { Cap } from 'src/app/core/user/model/caps';

@Component({
  selector: 'app-nav-item-daily-cash',
  templateUrl: './nav-item-daily-cash.component.html',
  styleUrls: ['./nav-item-daily-cash.component.scss']
})
export class NavItemDailyCashComponent implements OnInit {

  @Input() cap: Cap;

  constructor() { }

  ngOnInit(): void {
  }

  checkCreateDc(): boolean {      
    return this.cap.hasOwnProperty('brz_menu_dc_create');
  }

  checkViewDc(): boolean {
    return this.cap.hasOwnProperty('brz_menu_dc_view');
  }

  checkViewPolicies(): boolean {
    
    return this.cap.hasOwnProperty('brz_menu_dc_view_policies');
  }

}
