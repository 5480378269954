<mat-expansion-panel class="expansion-panel" *ngIf="checkCreateDc() || checkViewDc() || checkViewPolicies()">
  <mat-expansion-panel-header>
    <mat-panel-title style="color: #ffffff;">
      <div style="margin-top: 5px;">
        <i class="far fa-money-bill-alt"></i>
        <span style="margin-left: 10px;">Daily Cash</span>
      </div>
    </mat-panel-title>

  </mat-expansion-panel-header>

  <div>
    <a mat-list-item routerLink="/home/daily-cash/create-daily-cash" class="item-desc" *ngIf="checkCreateDc()">
      Create Daily Cash
    </a>
    <a mat-list-item routerLink="/home/daily-cash/view-daily-cash" class="item-desc" *ngIf="checkViewDc()">
      View Daily Cash
    </a>
    <a mat-list-item routerLink="/home/daily-cash/view-policies" class="item-desc" *ngIf="checkViewPolicies()">
      View Policies
    </a>

  </div>

</mat-expansion-panel>
