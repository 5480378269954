import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MDeal } from 'src/app/modules/dashboard-lead/model/mDeal';
import { DashboardLeadService } from 'src/app/modules/dashboard-lead/service/dashboard-lead.service';

interface DataPie {
  y: number,
  name: string,
  color?: string
}
@Component({
  selector: 'app-chart-marketing-deals',
  templateUrl: './chart-marketing-deals.component.html',
  styleUrls: ['./chart-marketing-deals.component.scss']
})
export class ChartMarketingDealsComponent implements OnInit {

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};
  teste: boolean = false;
  deals$: Observable<MDeal>;
  title: string;
  subtitle: string;

  @Input() range: string;
  @Input() from: string;
  @Input() to: string;

  constructor(private dashboardLeadService: DashboardLeadService) { }

  ngOnInit(): void {
    this.getMarketingDeals(this.range, this.from, this.to);
  }

  getMarketingDeals(period: string, from: string, to: string) {
    this.deals$ = this.dashboardLeadService.getMarketingDeals(period, from, to)
      .pipe(tap((deals) => {
        this.title = deals.title;
        this.subtitle = deals.description;
        this.chartIt(deals);
      }));
  }

  chartIt(deals: MDeal) {

    this.teste = false;

    var dataPie: DataPie[] = [];

    this.chartOptions.series = [];

    this.chartOptions.chart = {
      type: 'pie',
      backgroundColor: '#fafafa',
      height: '300px'
    }

    this.chartOptions.credits = {
      enabled: false
    }

    this.chartOptions.title = {
      text: null
    }

    this.chartOptions.legend = {
      itemStyle: {
        fontFamily: 'Lato',
        fontWeight: '300'
      }
    }

    this.chartOptions.yAxis = {
      title: {
        text: null
      },
    }

    this.chartOptions.tooltip = {
      shared: true
    }

    this.chartOptions.plotOptions = {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        shadow: false
      }
    }

    this.chartOptions.tooltip = {
      formatter: function () {
        return '<b>' + this.point.name + '</b>: ' + this.y + ' | ' + this.point.percentage.toFixed(2) + '%';
      },
      style: {
        fontFamily: 'Lato'
      }
    }

    deals.data.forEach((e) => {

      dataPie.push({
        y: parseInt(e.total.toString()),
        name: e.label,
        //color: '#ff8800'
      });

    });

    this.chartOptions.series.push({
      colorByPoint: true,
      type: 'pie',
      data: dataPie,
      size: '50%',
      borderWidth: 0,
      innerSize: '75%',
      showInLegend: true,
      dataLabels: {
        enabled: false
      }
    });

    this.teste = true;
  }

}
