import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { DashboardLeadService } from '../../service/dashboard-lead.service';

interface DataPie {
  y: number,
  name: string,
  color: string
}

@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.scss']
})
export class QuotesComponent implements OnInit {

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};
  updateFlag = false;

  teste: boolean = false;

  @Input() hasCapPermission: boolean;

  constructor(private dashService: DashboardLeadService, private notification: NotificationService) { }

  ngOnInit(): void {
    if (this.hasCapPermission) {
      this.chartIt();
    }
  }

  chartIt() {

    this.dashService.getQuotes().subscribe(data => {

      var dataPie: DataPie[] = [];

      this.chartOptions.series = [];

      this.chartOptions.chart = {
        type: 'pie',
        backgroundColor: '#fafafa',
      }

      this.chartOptions.credits = {
        enabled: false
      }

      this.chartOptions.title = {
        text: null
      }

      this.chartOptions.legend = {
        itemStyle: {
          fontFamily: 'Lato',
          fontWeight: '300'
        }
      }

      this.chartOptions.yAxis = {
        title: {
          text: null
        },
      }

      this.chartOptions.tooltip = {
        shared: true
      }

      this.chartOptions.plotOptions = {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          shadow: false
        }
      }

      this.chartOptions.tooltip = {
        formatter: function () {
          return '<b>' + this.point.name + '</b>: ' + this.y;
        },
        style: {
          fontFamily: 'Lato'
        }
      }

      data.forEach((e) => {

        switch (e.label) {
          case 'P':
            dataPie.push({
              y: parseInt(e.value.toString()),
              name: 'Pending',
              color: '#ff8800'
            });
            break;
          case 'W':
            dataPie.push({
              y: parseInt(e.value.toString()),
              name: 'Won',
              color: '#00bb10'
            });
            break;
          case 'L':
            dataPie.push({
              y: parseInt(e.value.toString()),
              name: 'Lost',
              color: '#bb0000'
            });
            break;
          case 'A':
            dataPie.push({
              y: parseInt(e.value.toString()),
              name: 'Active',
              color: 'black'
            });
            break;
          default:
            break;
        }


      })

      this.chartOptions.series.push({
        colorByPoint: true,
        type: 'pie',
        data: dataPie,
        size: '40%',
        borderWidth: 0,
        innerSize: '70%',
        showInLegend: true,
        dataLabels: {
          enabled: false
        }
      });

      this.teste = true;

    }, (error: HttpErrorResponse) => {

      this.notification.error(error.message);

    });

  }

}
