import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginGuard } from '../auth/login.guard';
import { NavBarComponent } from './nav-bar.component';

const routes: Routes = [
  {
    path: '', component: NavBarComponent, children: [
      // { path: 'quote', component: QuoteComponent },
      {
        path: 'quote',
        loadChildren: () =>
          import('../../modules/quote/quote.module').then((m) => m.QuoteModule),
        canActivate: [LoginGuard],
      },
      {
        path: 'dashboard-lead',
        loadChildren: () =>
          import('../../modules/dashboard-lead/dashboard-lead.module').then((m) => m.DashboardLeadModule),
        canActivate: [LoginGuard],
      },
      {
        path: 'brz-home-page',
        loadChildren: () =>
          import('../../modules/home/home.module').then((m) => m.HomeModule),
        canActivate: [LoginGuard],
      },
      {
        path: 'sms',
        loadChildren: () =>
          import('../../modules/sms/sms.module').then((m) => m.SmsModule),
        canActivate: [LoginGuard],
      },
      {
        path: 'daily-cash',
        loadChildren: () =>
          import('../../modules/daily-cash/daily-cash.module').then((m) => m.DailyCashModule),
        canActivate: [LoginGuard],
      },
      {
        path: 'admin',
        loadChildren: () =>
          import('../../modules/admin/admin.module').then((m) => m.AdminModule),
        canActivate: [LoginGuard],
      },
      {
        path: 'collaborators',
        loadChildren: () =>
          import('../../modules/collaborators/collaborators.module').then((m) => m.CollaboratorsModule),
        canActivate: [LoginGuard],
      },{
        path: 'communication',
        loadChildren: () =>
          import('../../modules/communication/communication.module').then((m) => m.CommunicationModule),
        canActivate: [LoginGuard],
      },
      {
        path: 'my-profile',
        loadChildren: () =>
          import('../../modules/user-profile/user-profile.module').then((m) => m.UserProfileModule),
        canActivate: [LoginGuard],
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NavbarRoutingModule { }
