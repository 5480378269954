import { simultaneous } from './../../model/interfaces';
import { Component } from '@angular/core';
import { RemoveCollaboratorComponent } from '../remove-collaborator/remove-collaborator.component';
import { NewCollaboratorsBlipComponent } from '../new-collaborators-blip/new-collaborators-blip.component';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AgentsBlip } from '../../model/interfaces';
import { BlipComponent } from './../blip.component';
import { BlipServiceService } from './../blip-service.service';
import { Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-cx',
  templateUrl: './cx.component.html',
  styleUrls: ['./cx.component.scss'],
})
export class CxComponent {
  // dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  availableIdiomas = ['EN', 'ES', 'PT'];
  dataSource: AgentsBlip[];
  displayedColumns: string[] = [
    'sequence',
    'distribuir',
    'nome',
    'email',
    'maxTickets',
    'openedTickets',
    'waitingTickets',
    'totalTickets',
    'closedTickets',
    'idiomas',
    'id',
  ];
  waitingTickets: number = 0;
  emptyData: boolean = false;
  simultaneousTicket: number = 10;
  form: FormGroup;
  idSimultaneousTicket:string;
  private ticketChangeSubjects: { [key: string]: Subject<any> } = {};
  constructor(
    public dialog: MatDialog,
    private BlipServiceService: BlipServiceService,
    private formBuilder: FormBuilder,
    private BlipComponent: BlipComponent
  ) {}
  ngOnInit(): void {
    const storedValue = window.localStorage.getItem('simultaneousTicketCx');
    this.simultaneousTicket = storedValue ? +storedValue : 10;

    this.getQueue();
    setInterval(()=>{

      this.getQueue();
      this.returnWaitingTickets();
    },60000 )
    this.form = this.formBuilder.group({
      simultaneous: [10, Validators.required],
    });
    this.returnWaitingTickets();
    this.simultaneous();
  }

  simultaneous(){
    this.BlipServiceService.returnSimultaneousTicket().subscribe((data)=>{
      let dropdownNumber = data.filter(item => item.nome == "cx");
     this.form.get('simultaneous').setValue(dropdownNumber[0].maxTickets)
    })
  }

  returnWaitingTickets() {
    this.BlipServiceService.waitingTickets({ queue: 'cx' }).subscribe(
      (data) => {

        this.waitingTickets = data.count

        // console.log(data.count, this.waitingTickets)

        // this.waitingTickets = data.count;
        // let waiting = data.filter((item)=>item.nome == "cx")
        // console.log(data.count, this.waitingTickets)
      }
    );
  }

  onCheckboxChange(event: any, idioma: string, comparacao): void {
    const idiomasArray: FormArray = this.form.get('idiomas') as FormArray;

    if (event.checked) {
      idiomasArray.push(this.formBuilder.control(idioma));
    } else {
      const index = idiomasArray.controls.findIndex((x) => x.value === idioma);
      idiomasArray.removeAt(index);
    }
  }

  getQueue() {
    this.BlipComponent.loadSpinner = true;
    this.BlipServiceService.getCollaborators({
      departament: 'cx',
    }).subscribe((collaborators) => {
      this.dataSource = collaborators || [];
      collaborators.length > 0
        ? (this.emptyData = false)
        : (this.emptyData = true);
        collaborators.length>0? this.emptyData = false: this.emptyData =true;
      this.BlipComponent.loadSpinner = false;
    });
  }

  openDialog(data) {
    this.dialog
      .open(NewCollaboratorsBlipComponent, {
        height: '430px',
        data: {
          addCollaborator: data.save,
          agentData: data.agentData,
          departament: 'cx',
          dataSource: this.dataSource,
          simultaneousTicket:this.form.get('simultaneous').value
        },
      })
      .afterClosed()
      .subscribe((data) => {
        this.getQueue();
      });
  }

  removeCollaborator(data) {
    this.dialog
      .open(RemoveCollaboratorComponent, {
        data: {
          agentData: data.agentData,
          departament: 'cx',
        },
      })
      .afterClosed()
      .subscribe((data) => {
        this.getQueue();
      });
  }

  changeLimitTickets(operator: number, row) {
    row.maxTickets += operator;
  
    if (!this.ticketChangeSubjects[row.id]) {
      this.ticketChangeSubjects[row.id] = new Subject<any>();
      this.ticketChangeSubjects[row.id].pipe(
        debounceTime(1000), // 1 segundo de inatividade
        switchMap(({ row }) =>
          this.BlipServiceService.editCollaborator({ maxTickets: row.maxTickets, id: row.id })
        )
      ).subscribe(() => {
        this.getQueue();
      });
    }
  
    if (operator !== 0) {
      this.ticketChangeSubjects[row.id].next({ row });
    }}

  changeSimultaneousTicket(e) {
    this.form.get('simultaneous').setValue(e.target.value);
    window.localStorage.setItem('simultaneousTicketCx', e.target.value);
    this.BlipServiceService.editSimultaneousTicket(this.idSimultaneousTicket, parseInt(e.target.value)).subscribe();
    this.dataSource.forEach((item) => {
      let itemTickets = parseInt(e.target.value);
      let maxTickets = item.maxTickets;

      if (maxTickets > itemTickets) {
        this.BlipServiceService.editCollaborator({
          maxTickets: itemTickets,
          id: item.id,
        }).subscribe((data) => {
          this.getQueue();
        });
      }
    });
    this.getQueue();
  }

  onMenuClosed() {
    this.getQueue();
  }
}
