import { Component, OnInit } from '@angular/core';
import { AuthService } from './core/auth/auth.service';
import { Cap } from './core/user/model/caps';
import { UserService } from './core/user/user.service';
import { isDevMode } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'brz-app';

  authToken:any = window.localStorage.getItem('authToken')

  constructor(private userService: UserService, private authService: AuthService) { }

  ngOnInit() {

    let name = window.localStorage.getItem('displayName');
    let mail = window.localStorage.getItem('email');
    let pwd = window.localStorage.getItem('pwd');
    let caps = JSON.parse(window.localStorage.getItem('cap')) as Cap;

    if (caps) this.userService.setPermissions(caps as Cap);

    if (!isDevMode() && caps.hasOwnProperty('brzinsurance_blip_view_chat')) {
      if (![name || mail || pwd].includes(null)) {
        let temp_mail = 'webinternal@' + mail;
        //this.authService.initChat(temp_mail, pwd, name);
      }
    }

  }
}
