import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataMDealMap, MDealMap } from 'src/app/modules/dashboard-lead/model/mDealMap';

@Component({
  selector: 'app-table-marketing-deals',
  templateUrl: './table-marketing-deals.component.html',
  styleUrls: ['./table-marketing-deals.component.scss']
})
export class TableMarketingDealsComponent implements OnInit {

  @Input() deals: MDealMap;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  title: string;
  subtitle: string;

  dataSource: MatTableDataSource<DataMDealMap>;

  @Input() range: string;
  @Input() from: string;
  @Input() to: string;
  @Input() dcType: string[];

  displayedColumns: string[] = [
    'city',
    'zip',
    'total',
    'latitude',
    'longitude'
  ];

  constructor() { }

  ngOnInit(): void {
    this.addDataToDataSource(this.deals.data);
  }

  addDataToDataSource(data: DataMDealMap[]) {
    setTimeout(() => this.dataSource.paginator = this.paginator);
    setTimeout(() => this.dataSource.sort = this.sort);
    this.dataSource = new MatTableDataSource<DataMDealMap>(data);
  }

}
