import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DropzoneComponent, DropzoneConfigInterface} from 'ngx-dropzone-wrapper';
import {File} from 'src/app/modules/quote/model/quote';
import {QuotesService} from 'src/app/modules/quote/service/quote.service';
import {TokenService} from 'src/app/core/token/token.service';
import {DecPageComponent} from '../../../view-quote/quote-detail/dec-page/dec-page.component';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

  token: string = this.tokenService.getToken();
  isDecPage: boolean;
  idQuote: number;
  @ViewChild('dz') drpzone: DropzoneComponent;
  @ViewChild(DecPageComponent) decPageComponent: DecPageComponent;
  file: File;

  DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
    url: `${environment.apiUrl}/brz/v1/files/uploadfiles?relationship_type=1`,
    addRemoveLinks: true,
    method: 'POST',
    withCredentials: false,
    headers: {
      'Cache-Control': '',
      'X-Requested-With': '',
      Authorization: `Bearer ${this.token}`
    },
    maxFilesize: 50,
    acceptedFiles: 'image/*,application/pdf'
  };

  files: number[] = [];
  constructor(private quoteService: QuotesService, private tokenService: TokenService, private activatedRoute: ActivatedRoute) {

  }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe(params => {

      if (params.decpage) {
        this.isDecPage = true;
        this.idQuote = params.id;
        this.DEFAULT_DROPZONE_CONFIG = {
          url: `${environment.apiUrl}/brz/v1/files/uploadfiles?relationship_type=3&foreign_table_id=${params.id}`,
          addRemoveLinks: true,
          method: 'POST',
          withCredentials: false,
          headers: {
            'Cache-Control': '',
            'X-Requested-With': '',
            Authorization: `Bearer ${this.token}`
          },
          maxFilesize: 50,
          acceptedFiles: 'image/*,application/pdf'
        };
      } else {
        this.isDecPage = false;
      }

    });

  }

  onUploadSuccess($event) {

    if (!this.isDecPage) {

      this.file = {
        id: $event[1].data[0].attachment_id,
        uuId: $event[0].upload.uuid
      };

      this.quoteService.setUploadedFile(this.file);
    } else {
      window.location.reload();
      //this.decPageComponent.listenDecPageObservable(this.idQuote);
    }

  }

  onUploadError($event) {
  }

  onRemoveFile($event) {

    this.file = {
      uuId: $event.upload.uuid,
      remove: true
    };

    this.quoteService.deleteFile($event.upload.uuid);

    this.quoteService.setUploadedFile(this.file);

  }

  clearWrapper() {
    this.drpzone.directiveRef.reset();
    this.drpzone.directiveRef.dropzone();
  }

}
