<mat-card *ngIf="rank$ | async as rank; else elseBlock">

  <mat-card-header class="header-quote">

      <i class="fas fa-glass-cheers" style="margin-left: -20px; margin-top: 14px;font-size: 24px;"></i>

      <div class="ml-28">
        <mat-card-title class="title-count">{{title}}</mat-card-title>
        <mat-card-subtitle>{{subtitle}}</mat-card-subtitle>
      </div>

  </mat-card-header>

  <div *ngIf="ranks.length; else hasNoData">
    <mat-card-content>
      <div class="leaders">
        <ul>
          <li *ngFor="let user of ranks">
            <div class="lead-cats">
              <div class="troph-{{user.my_rank}}"></div>
              <div class="podium pod-{{user.my_rank}}">
                <div class="ranking-lead medal-{{user.my_rank}}">{{user.my_rank}}</div>
                <h4 class="name-{{user.my_rank}}">{{ user.name }}</h4>
                <p>{{ user.total_premium | currency }}</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
  </mat-card-content>
  </div>

  <ng-template #hasNoData>
    <mat-card class="card-no-data" style="height: 30vh; display: flex; justify-content: center; align-items: center;">
    </mat-card>
  </ng-template>

</mat-card>

<ng-template #elseBlock>
  <mat-card style="height: 50vh; display: flex; justify-content: center; align-items: center;">
    <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
  </mat-card>
</ng-template>
