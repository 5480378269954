import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import * as Highcharts from 'highcharts';
import { Subscription } from 'rxjs';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { DashboardLeadService } from '../../service/dashboard-lead.service';

export interface LineData extends Highcharts.PointOptionsObject {
  // custom: Record<string, (boolean|number|string)>;
  //name: string,
  y: number,
  label?: string
}

@Component({
  selector: 'app-quotes-sent',
  templateUrl: './quotes-sent.component.html',
  styleUrls: ['./quotes-sent.component.scss']
})
export class QuotesSentComponent implements OnInit {

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};
  updateFlag = false;
  width: number;

  // title: string;
  // description: string;
  teste: boolean = false;
  watcher: Subscription;

  @Input() hasCapPermission: boolean;

  constructor(private dashService: DashboardLeadService, private notification: NotificationService,
    media: MediaObserver) {

    this.watcher = media.asObservable().subscribe(change => {

      if (change[0].mqAlias === 'sm' || change[0].mqAlias === 'xs') {
        this.width = window.screen.width - 90;
      } else {
        this.width = window.screen.width - 350;
      }

    });}

  ngOnInit(): void {
    if (this.hasCapPermission) {
      this.chartIt();
    }
  }

  chartIt() {

    this.dashService.getQuotesSent().subscribe(data => {

      let line: LineData[] = [];
      let n: number[] = [];
      let st: string[] = [];

      data.forEach((e) => {

        n.push(e.value);

        line.push({
          y: e.value
        });

        st.push(e.label);

      });

      this.chartOptions.chart = {
        width: this.width,
        backgroundColor: '#fafafa',
        style: {
          fontFamily: 'Lato',
          fontWeight: '300'
        }
      }

      this.chartOptions.plotOptions = {
        areaspline: {
          fillOpacity: 0
        }
      };

      this.chartOptions.credits = {
        enabled: false
      }

      this.chartOptions.title = {
        text: null
      }

      this.chartOptions.legend = {
        enabled: true
      }

      this.chartOptions.xAxis = [{
        categories: st
      }]

      this.chartOptions.yAxis = {
        min: 0,
        title: {
          text: null
        },
        gridLineColor: '#f1f1f1'
      }

      this.chartOptions.series = [{
        name: 'Quotes',
        type: 'areaspline',
        data: line,
        color: '#fc636b'
      }];

      this.teste = true;


    }, (error: HttpErrorResponse) => {

      this.notification.error(error.message);

    });

  }

  private updateChart(value) {

    //this.chartValue = value;

    // first try - working without transition

    this.chartOptions.chart = {
      width: document.getElementById('quote-sent-card').offsetWidth - 50
    }
    this.updateFlag = true;

    // --------------------------------------------------------------

    // second try - working without transition
    // this.options.title.text = value;
    // this.chart.chart.series[0].points[0].update(value);

    // The transition will work if you don't use this line (but the title won't be updated as well)
    // this.update = true;

    // --------------------------------------------------------------

    // third try
    // working with the transition but leads to unexpected results (the displayed value is different from the given argument, and the transition get stuck on mobile)
    // const displayedValue = this.options.series["0"].data[0];

    // this.options.title.text = value;

    // if (this.gaugeUpdateInterval) {
    //   clearInterval(this.gaugeUpdateInterval);
    //   this.gaugeUpdateInterval = null;
    // }

    // this.gaugeUpdateInterval = setInterval(() => {
    //   this.options.series["0"].data[0] += displayedValue < value ? 1 : -1;
    //   this.update = true;

    //   if (this.options.series["0"].data[0] === this.chartValue) {
    //     clearInterval(this.gaugeUpdateInterval);
    //     this.gaugeUpdateInterval = null;
    //   }
    // }, 10);
  }

  @HostListener('window:resize', ['$event'])
        onResize(event){
          this.updateChart('a');

        }

}
