export const environment = {
  production: true,
  // apiUrl: 'https://intranet2.brzinsurance.com/wp-json',
  // channelSlug: 'private-dev-intranet-',
  // pusherId: '4dd329861a1da30f0371',
  // productionMode: false,

  apiUrl: 'https://intranet.brzinsurance.com/wp-json',
   channelSlug: 'private-live-intranet-',
    pusherId: '50d6feea59755bbd0e53',
    productionMode: true
};
