import { FormGroup } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { NewQuoteComponent } from '../../../new-quote/new-quote.component';

@Component({
  selector: 'app-input-svg',
  templateUrl: './input-svg.component.html',
  styleUrls: ['./input-svg.component.scss']
})
export class InputSvgComponent implements OnInit {

  @Input() svg:string;
  @Input() Driver:boolean = false;
  @Input() label:string;
  @Input() controlSvg:string;
  form: FormGroup;
  formDrivers: FormGroup;
  formVehicle:FormGroup;


  constructor(private formContainer: NewQuoteComponent) {}


  ngOnInit() {
    this.form = this.formContainer.getStepForm();
    this.formDrivers = this.formContainer.fdr();
    this.formVehicle = this.formContainer.fvh();
  }

  get validaErro(){

    if (this.formContainer.formStep <9){
      let ctrl = this.controlSvg
      if(this.form.controls[ctrl].errors && this.form.controls[ctrl].touched ){
  
        return true
      }else{
        return false
      }
    }else if(this.formContainer.formStep >=9){
      let ctrl = this.controlSvg
      if(this.formDrivers.controls[ctrl].errors && this.formDrivers.controls[ctrl].touched ){
  
        return true
      }else{
        return false
      }
    }else if(this.formContainer.formStep ==11){
      let ctrl = this.controlSvg
      if(this.formVehicle.controls[ctrl].errors && this.formVehicle.controls[ctrl].touched ){
  
        return true
      }else{
        return false
      }
    }

    
  }

}
