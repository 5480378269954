import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { merge, of as observableOf } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import Pusher from 'pusher-js';
import { AlertDecPage } from 'src/app/core/nav-bar/model/notification';
import { UserService } from 'src/app/core/user/user.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { DataQuote, IViewQuoteDTO } from '../../../model/quote';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { QuotesService } from '../../../service/quote.service';
import { QuoteDetailDialogComponent } from '../quote-detail/quote-detail-dialog/quote-detail-dialog.component';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-table-view-quote',
  templateUrl: './table-view-quote.component.html',
  styleUrls: ['./table-view-quote.component.scss']
})
export class TableViewQuoteComponent implements AfterViewInit {

  displayedColumns: string[] = [
    'lead_id',
    'lead_date',
    'first_name',
    'status',
    'premium',
    'dec_page_file_id'
  ];

  data: DataQuote[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  pageLimit: number[] = [10, 25, 50, 100];
  dto: IViewQuoteDTO = {};

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  private pusherClient: Pusher;

  constructor(
    private quoteService: QuotesService,
    private userService: UserService,
    public dialog: MatDialog,
    private notification: NotificationService,
    private cookieService: CookieService
  ) { }

  ngAfterViewInit() {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.quoteService!.getQuotes(
            this.dto.search_word,
            this.dto.date_to || this.cookieService.get('dtToViewQuotes') || null,
            this.dto.date_from || this.cookieService.get('dtFromViewQuotes') || null,
            this.paginator.pageSize,
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.dto.status || this.cookieService.get('statusViewQuote') || null
            );
        }),
        map(data => {

          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.length;

          return data.data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => {

        this.data = data
      });
  }

  pusher() {
    this.pusherClient = new Pusher('4dd329861a1da30f0371', { cluster: 'us2' });

    const channel = this.pusherClient.subscribe('lead');

    channel.bind(
      'status-change',
      (data: AlertDecPage) => {

        switch (data.notifications[0].type) {
          case 'dec_page':
            this.data.find(x => x.lead_id == data.notifications[0].lead_id).dec_page_file_id = data.notifications[0].fields.file_id.toString();
          default:
            break;
        }

      }
    );
  }

  getDetailQuote(idQuote: number) {
    const dialogRef = this.dialog.open(QuoteDetailDialogComponent, {
      data: idQuote
    });
  }


  downloadDecPage(id: number, idQuote: number) {

    if (this.userService.cap.hasOwnProperty('brzinsurance_lead_download_file')) {
      this.quoteService.downloadDecPage(id, idQuote).subscribe();
    }
    else {
      this.notification.alert('You have no permission to download dec page.')
    }

  }

}
