import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenService } from '../../token/token.service';
import { NotificationData } from '../model/notificationData';

@Injectable({
  providedIn: 'root'
})
export class NotificationDataService {

  token = this.tokenService.getToken();

  constructor(private http: HttpClient, private tokenService: TokenService) { }

  getNotifications(): Observable<NotificationData> {

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`
      }),
    };

    return this.http.get<NotificationData>(`${environment.apiUrl}/brz/v1/notifications/get`, httpOptions);

  }

  markAsRead(notifications_id: number[]): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`
      }),
    };

    return this.http.post<any>(`${environment.apiUrl}/brz/v1/notifications/read`, { notifications_id }, httpOptions);

  }

  markAsUnread(notifications_id: number[]): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`
      }),
    };

    return this.http.post<any>(`${environment.apiUrl}/brz/v1/notifications/unread`, { notifications_id }, httpOptions);

  }

  archiveNotification(notifications_id: number[]): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`
      }),
    };

    return this.http.post<any>(`${environment.apiUrl}/brz/v1/notifications/archive`, { notifications_id }, httpOptions);

  }
  
}
