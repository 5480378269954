import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MConversionRate } from 'src/app/modules/dashboard-lead/model/mConversionRate';
import { DashboardLeadService } from 'src/app/modules/dashboard-lead/service/dashboard-lead.service';

interface DataPie {
  y: number,
  name: string,
  color?: string
}
@Component({
  selector: 'app-chart-marketing-conversion-rate',
  templateUrl: './chart-marketing-conversion-rate.component.html',
  styleUrls: ['./chart-marketing-conversion-rate.component.scss']
})
export class ChartMarketingConversionRateComponent implements OnInit {

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};
  conversionRate$: Observable<MConversionRate>;
  title: string;
  subtitle: string;

  @Input() range: string;
  @Input() from: string;
  @Input() to: string;

  constructor(private dashboardLeadService: DashboardLeadService) { }

  ngOnInit(): void {
    this.getMarketingConversionRate(this.range, this.from, this.to);
  }

  getMarketingConversionRate(period: string, from: string, to: string) {
    this.conversionRate$ = this.dashboardLeadService.getMarketingConversionRate(period, from, to)
      .pipe(tap((conversionRate) => {
        this.title = conversionRate.title;
        this.subtitle = conversionRate.description;
        this.chartIt(conversionRate);
      }));
  }

  chartIt(conversionRate: MConversionRate) {

    var dataPie: DataPie[] = [];

    this.chartOptions.series = [];

    this.chartOptions.chart = {
      type: 'pie',
      backgroundColor: '#fafafa',
      height: '300px'
    }

    this.chartOptions.credits = {
      enabled: false
    }

    this.chartOptions.title = {
      text: null
    }

    this.chartOptions.legend = {
      itemStyle: {
        fontFamily: 'Lato',
        fontWeight: '300'
      }
    }

    this.chartOptions.yAxis = {
      title: {
        text: null
      },
    }

    this.chartOptions.tooltip = {
      shared: true
    }

    this.chartOptions.plotOptions = {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        shadow: false
      }
    }

    this.chartOptions.tooltip = {
      formatter: function () {
        return '<b>' + this.point.name + '</b>: ' + this.y + ' | ' + this.point.percentage.toFixed(2) + '%';
      },
      style: {
        fontFamily: 'Lato'
      }
    }

    conversionRate.data.forEach((e) => {

      dataPie.push({
        y: parseInt(e.total.toString()),
        name: e.label,
        //color: '#ff8800'
      });

    });

    this.chartOptions.series.push({
      colorByPoint: true,
      type: 'pie',
      data: dataPie,
      size: '50%',
      borderWidth: 0,
      innerSize: '75%',
      showInLegend: true,
      dataLabels: {
        enabled: false
      }
    });
  }

}
