<mat-expansion-panel class="expansion-panel" *ngIf="checkSms()">
  <mat-expansion-panel-header>
    <mat-panel-title style="color: #ffffff;">
      <div style="margin-top: 5px;">
        <i class="far fa-envelope-open"></i>
        <span style="margin-left: 10px;">SMS</span>
      </div>
    </mat-panel-title>

  </mat-expansion-panel-header>

  <div>
    <a mat-list-item routerLink="/home/sms/view-sms" class="item-desc">
      SMS
    </a>

  </div>

</mat-expansion-panel>
