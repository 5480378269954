import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { DashboardName } from 'src/app/modules/dashboard-lead/model/dashboard';
import { PusherService } from 'src/app/shared/services/pusher.service';
import { environment } from 'src/environments/environment';
import { ChartBusinessTypeComponent } from '../../charts/chart-business-type/chart-business-type.component';
import { ChartLobComponent } from '../../charts/chart-lob/chart-lob.component';
import { ChartPifComponent } from '../../charts/chart-pif/chart-pif.component';
import { ChartPifPremiumComponent } from '../../charts/chart-pif-premium/chart-pif-premium.component';
import { ChartPifLobPremiumComponent } from '../../charts/chart-pif-lob-premium/chart-pif-lob-premium.component';
import { ChartPifBusinessTypePremiumComponent } from '../../charts/chart-pif-business-type-premium/chart-pif-business-type-premium.component';
import { TableTotalPremiumComponent } from '../../tables/table-total-premium/table-total-premium.component';
import { TablePoliciesComponent } from '../../tables/table-policies/table-policies.component';
import { DcSharedService } from 'src/app/modules/daily-cash/shared/services/dc-shared.service';
import { Carrier } from '../../../../model/carrier';
import { Observable } from 'rxjs';

interface DataPie {
  y: number,
  name: string,
  color?: string
}

@Component({
  selector: 'app-brz-pie-filter',
  templateUrl: './brz-pie-filter.component.html',
  styleUrls: ['./brz-pie-filter.component.scss']
})
export class BrzPieFilterComponent implements OnInit {

  fgFilter: FormGroup;
  carriers$: Observable<Carrier[]>;

  @ViewChild(ChartPifComponent) chartPifComponent: ChartPifComponent;
  @ViewChild(ChartPifPremiumComponent) chartPifPremiumComponent: ChartPifPremiumComponent;
  @ViewChild(ChartPifLobPremiumComponent) chartPifLobPremiumComponent: ChartPifLobPremiumComponent;
  @ViewChild(ChartLobComponent) chartLobComponent: ChartLobComponent;
  @ViewChild(ChartBusinessTypeComponent) chartBusinessTypeComponent: ChartBusinessTypeComponent;
  @ViewChild(ChartPifBusinessTypePremiumComponent) chartPifBusinessTypePremiumComponent: ChartPifBusinessTypePremiumComponent;
  @ViewChild(TableTotalPremiumComponent) tableTotalPremiumComponent: TableTotalPremiumComponent;
  @ViewChild(TablePoliciesComponent) tablePoliciesComponent: TablePoliciesComponent;

  range: string;
  from: string;
  to: string;
  carrier_name: string;
  carrier_ids: string;

  currentTab: string = 'totalAmountPremium';

  @Input() results: DashboardName[];

  constructor(private _formBuilder: FormBuilder, private cookieService: CookieService, private pusherService: PusherService, private dcSharedService: DcSharedService) { }

  ngOnInit(): void {
    this.initForm();
    this.initVariables();
    this.pusher();

    this.getCarriers();
  }

  onSubmit() {

    var datePipe = new DatePipe('en-US');

    let from = null;
    let to = null;
    let period = this.fgFilter.get('fcPeriod').value;

    if (this.fgFilter.get('fcDtFrom').value)
      from = datePipe.transform(this.fgFilter.get('fcDtFrom').value, 'MM/dd/yyyy');

    if (this.fgFilter.get('fcDtTo').value)
      to = datePipe.transform(this.fgFilter.get('fcDtTo').value, 'MM/dd/yyyy');

    this.cookieService.set('periodAgency', period || null);

    if (this.cookieService.get('periodAgency') === 'customdate') {
      this.cookieService.set('dtFromAgency', from || null);
      this.cookieService.set('dtToAgency', to || null);
    }

    let search = this.fgFilter.get('fcSearch').value || null;

    let carriers = [this.fgFilter.get('fcCarriers').value];
    if (carriers) {
      this.cookieService.set('carrierAgency', this.fgFilter.get('fcCarriers').value);
    } else {
      this.cookieService.set('carrierAgency', '');
    }

    if (this.currentTab == 'totalAmountPremium') {

      if (this.heWantsPif()) this.chartPifComponent.getPIF(period, from, to);
      if (this.heWantsPifPremium()) this.chartPifPremiumComponent.getPIFPremium(period, from, to);
      if (this.heWantsPifLobPremium()) this.chartPifLobPremiumComponent.getPIFLobPremium(period, from, to);
      if (this.heWantsPifBusinessTypePremium()) this.chartPifBusinessTypePremiumComponent.getPIFBusinessTypePremium(period, from, to);
      if (this.heWantsPifBusinessType()) this.chartBusinessTypeComponent.getBusinessType(period, from, to);
      if (this.heWantsPifLob()) this.chartLobComponent.getLob(period, from, to);

    } else if (this.currentTab == 'totalPremium') {

      if (this.heWantsTotalPremium()) this.tableTotalPremiumComponent.getTotalPremiumStats(period, from, to, search, carriers);

    } else if (this.currentTab == 'policies') {

      if (this.heWantsPolicies()) this.tablePoliciesComponent.getPoliciesStats(period, from, to, search, carriers);

    }

  }

  initForm() {

    let from: Date;
    let to: Date;

    if (this.cookieService.get('dtFromAgency')) {
      from = new Date(this.htmlDecode(this.cookieService.get('dtFromAgency')));
    }

    if (this.cookieService.get('dtToAgency')) {
      to = new Date(this.htmlDecode(this.cookieService.get('dtToAgency')));
    }

    this.fgFilter = this._formBuilder.group({
      fcPeriod: [this.cookieService.get('periodAgency') || 'thismonth'],
      fcDtFrom: [from || ''],
      fcDtTo: [to || ''],
      fcOrderBy: [''],
      fcCarriers: [''],
      fcSearch: [''],
      fcFieldSearch: [''],
    });

  }

  htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  }

  pusher() {

    let pusherClient = this.pusherService.getPusher();

    const channel = pusherClient.subscribe(`${environment.channelSlug}dashboard-stats`);

    channel.bind(
      'pusher:subscription_succeeded', () => {
        console.log(`channel: ${environment.channelSlug}dashboard-stats`);
      });

    channel.bind('new-qq-policy', (qq) => {


      var datePipe = new DatePipe('en-US');
      let formatDateCreated = datePipe.transform(qq.effective_date, 'MM/dd/yyyy') || null;
      let dateCreated = new Date(formatDateCreated);
      let today = new Date();

      const from = this.fgFilter.get('fcDtFrom').value;
      const to = this.fgFilter.get('fcDtTo').value;

      switch (this.fgFilter.get('fcPeriod').value) {
        case 'all':
          this.generatePif(qq);
          this.generatePifPremium(qq);
          this.generatePifLobPremium(qq);
          this.generatePifBusinessTypePremium(qq);
          this.generateLob(qq);
          this.generateBusinessType(qq);
          break;
        case 'today':
          if ((dateCreated.getDate() === today.getDate())
            && (dateCreated.getMonth() === today.getMonth())
            && (dateCreated.getFullYear() === today.getFullYear())) {
            this.generatePif(qq);
            this.generatePifPremium(qq);
            this.generatePifLobPremium(qq);
            this.generatePifBusinessTypePremium(qq);
            this.generateLob(qq);
            this.generateBusinessType(qq);
          }
          break;
        case 'thisyear':
          if (dateCreated.getFullYear() === today.getFullYear()) {
            this.generatePif(qq);
            this.generatePifPremium(qq);
            this.generatePifLobPremium(qq);
            this.generatePifBusinessTypePremium(qq);
            this.generateLob(qq);
            this.generateBusinessType(qq);
          }
          break;
        case 'thisweek':
          if (this.isDateInThisWeek(dateCreated)) {
            this.generatePif(qq);
            this.generatePifPremium(qq);
            this.generatePifLobPremium(qq);
            this.generatePifBusinessTypePremium(qq);
            this.generateLob(qq);
            this.generateBusinessType(qq);
          }
          break;
        case 'thismonth':
          if (dateCreated.getMonth() === today.getMonth()) {
            this.generatePif(qq);
            this.generatePifPremium(qq);
            this.generatePifLobPremium(qq);
            this.generatePifBusinessTypePremium(qq);
            this.generateLob(qq);
            this.generateBusinessType(qq);
          }
          break;
        case 'customdate':
          if ((dateCreated <= to) && (dateCreated >= from)) {
            this.generatePif(qq);
            this.generatePifPremium(qq);
            this.generatePifLobPremium(qq);
            this.generatePifBusinessTypePremium(qq);
            this.generateLob(qq);
            this.generateBusinessType(qq);
          }
          break;

        default:
          break;
      }

    });

  }

  isDateInThisWeek(date) {
    const todayObj = new Date();
    const todayDate = todayObj.getDate();
    const todayDay = todayObj.getDay();
    const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);
    return date >= firstDayOfWeek && date <= lastDayOfWeek;
  }


  generatePif(qq: any) {
    if (this.chartPifComponent.chartOptions.series[0]['data'].length > 0) {

      let dataFind = this.chartPifComponent.chartOptions.series[0]['data'].find(x => x.name === qq.carrier);

      if (dataFind) dataFind.y = dataFind.y + 1;
      //else this.chartPifComponent.chartOptions.series.push()

    } else {

      // let a = 1;

      // let dataPie: DataPie = {
      //   y: parseInt(a.toString()),
      //   name: qq.carrier,
      //   //color: '#ff8800'
      // }

      // this.chartPifComponent.chartOptions.series[0]['data'].push(dataPie);
      this.chartPifComponent.getPIF(this.fgFilter.get('fcPeriod').value, this.fgFilter.get('fcDtFrom').value, this.fgFilter.get('fcDtTo').value);

    }

    this.chartPifComponent.updateFlag = true;
  }


  generatePifPremium(qq: any) {
    if (this.chartPifPremiumComponent.chartOptions.series[0]['data'].length > 0) {

      let dataFind = this.chartPifPremiumComponent.chartOptions.series[0]['data'].find(x => x.name === qq.carrier);

      if (dataFind) dataFind.y = dataFind.y + 1;
      //else this.chartPifPremiumComponent.chartOptions.series.push()

    } else {

      // let a = 1;

      // let dataPie: DataPie = {
      //   y: parseInt(a.toString()),
      //   name: qq.carrier,
      //   //color: '#ff8800'
      // }

      // this.chartPifPremiumComponent.chartOptions.series[0]['data'].push(dataPie);
      this.chartPifPremiumComponent.getPIFPremium(this.fgFilter.get('fcPeriod').value, this.fgFilter.get('fcDtFrom').value, this.fgFilter.get('fcDtTo').value);

    }

    this.chartPifPremiumComponent.updateFlag = true;
  }


  generatePifLobPremium(qq: any) {
    if (this.chartPifLobPremiumComponent.chartOptions.series[0]['data'].length > 0) {

      let dataFind = this.chartPifLobPremiumComponent.chartOptions.series[0]['data'].find(x => x.name === qq.carrier);

      if (dataFind) dataFind.y = dataFind.y + 1;
      //else this.chartPifLobPremiumComponent.chartOptions.series.push()

    } else {

      // let a = 1;

      // let dataPie: DataPie = {
      //   y: parseInt(a.toString()),
      //   name: qq.carrier,
      //   //color: '#ff8800'
      // }

      // this.chartPifLobPremiumComponent.chartOptions.series[0]['data'].push(dataPie);
      this.chartPifLobPremiumComponent.getPIFLobPremium(this.fgFilter.get('fcPeriod').value, this.fgFilter.get('fcDtFrom').value, this.fgFilter.get('fcDtTo').value);

    }

    this.chartPifLobPremiumComponent.updateFlag = true;
  }


  generatePifBusinessTypePremium(qq: any) {
    if (this.chartPifBusinessTypePremiumComponent.chartOptions.series[0]['data'].length > 0) {

      let dataFind = this.chartPifBusinessTypePremiumComponent.chartOptions.series[0]['data'].find(x => x.name === qq.carrier);

      if (dataFind) dataFind.y = dataFind.y + 1;
      //else this.chartPifBusinessTypePremiumComponent.chartOptions.series.push()

    } else {

      // let a = 1;

      // let dataPie: DataPie = {
      //   y: parseInt(a.toString()),
      //   name: qq.carrier,
      //   //color: '#ff8800'
      // }

      // this.chartPifBusinessTypePremiumComponent.chartOptions.series[0]['data'].push(dataPie);
      this.chartPifBusinessTypePremiumComponent.getPIFBusinessTypePremium(this.fgFilter.get('fcPeriod').value, this.fgFilter.get('fcDtFrom').value, this.fgFilter.get('fcDtTo').value);

    }

    this.chartPifBusinessTypePremiumComponent.updateFlag = true;
  }

  generateLob(qq: any) {
    if (this.chartLobComponent.chartOptions.series[0]['data'].length > 0) {

      let dataFind = this.chartLobComponent.chartOptions.series[0]['data'].find(x => x.name === qq.lob);

      if (dataFind) dataFind.y = dataFind.y + 1;
      //else this.chartPifComponent.chartOptions.series.push()

    } else {
      this.chartLobComponent.getLob(this.fgFilter.get('fcPeriod').value, this.fgFilter.get('fcDtFrom').value, this.fgFilter.get('fcDtTo').value);
    }

    this.chartLobComponent.updateFlag = true;
  }

  generateBusinessType(qq: any) {
    if (this.chartBusinessTypeComponent.chartOptions.series[0]['data'].length > 0) {

      let dataFind = this.chartBusinessTypeComponent.chartOptions.series[0]['data'].find(x => x.name === qq.business_type);

      if (dataFind) dataFind.y = dataFind.y + 1;
      //else this.chartPifComponent.chartOptions.series.push()

    } else {
      this.chartBusinessTypeComponent.getBusinessType(this.fgFilter.get('fcPeriod').value, this.fgFilter.get('fcDtFrom').value, this.fgFilter.get('fcDtTo').value);
    }

    this.chartBusinessTypeComponent.updateFlag = true;
  }

  initVariables() {
    var datePipe = new DatePipe('en-US');
    this.range = this.fgFilter.get('fcPeriod').value;
    this.from = datePipe.transform(this.fgFilter.get('fcDtFrom').value, 'MM/dd/yyyy');
    this.to = datePipe.transform(this.fgFilter.get('fcDtTo').value, 'MM/dd/yyyy');
  }

  heWantsPif(): boolean {
    return this.didItFoundCap('brzinsurance_reports_agencystats_pif');
  }
  heWantsPifBusinessType(): boolean {
    return this.didItFoundCap('brzinsurance_reports_agencystats_pif_business_type');
  }
  heWantsPifLob(): boolean {
    return this.didItFoundCap('brzinsurance_reports_agencystats_pif_lob');
  }

  heWantsPifPremium(): boolean {
    return this.didItFoundCap('brzinsurance_reports_agencystats_pif_premium');
  }
  heWantsPifBusinessTypePremium(): boolean {
    return this.didItFoundCap('brzinsurance_reports_agencystats_pif_business_type_premium');
  }
  heWantsPifLobPremium(): boolean {
    return this.didItFoundCap('brzinsurance_reports_agencystats_pif_lob_premium');
  }

  heWantsTotalPremium(): boolean {
    return this.didItFoundCap('brzinsurance_reports_agencystats_pif_lob_premium');
  }

  heWantsPolicies(): boolean {
    return this.didItFoundCap('brzinsurance_reports_agencystats_pif_lob_premium');
  }

  didItFoundCap(cap: string): boolean {
    if (this.results) if (this.results.find(x => x.cap === cap)) return true;
    return false;
  }

  setDashboardTab(tab: string) {
    this.currentTab = tab;
  }

  getCarriers() {
    this.carriers$ = this.dcSharedService.getProducers();
  }

  exportExcelPolicies() {
    this.tablePoliciesComponent.exportExcel();
  }

  exportExcelTotalPremium() {
    this.tableTotalPremiumComponent.exportExcel();
  }
}
