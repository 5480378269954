<div class="container">
  <div class="header-modal">
    <h1 mat-dialog-title>Remove Collaborator</h1>
    <mat-icon
      aria-hidden="false"
      aria-label="Example home icon"
      class="white-icon"
      (click)="closeModal()"
      >close</mat-icon
    >
  </div>

  <div class="content">
    <div class="info-box">
      <span class="describe-content"
        >Are you sure you want to remove the collaborator from the list:</span
      >
    </div>
    <div class="info-box">
      <span class="name-agent"> {{ this.data.agentData.nome }} </span>
    </div>
    <div class="info-box">
      <div class="warn-box">
        <span style="display: flex; align-items: center">
          <mat-icon style="margin-right: 10px">info</mat-icon>
          {{ this.data.agentData.nome }} will be removed only from this
          list.</span
        >
      </div>
    </div>
  </div>
  <div class="footer-modal" mat-dialog-actions>
    <button mat-button mat-dialog-close class="btn">Back</button>
    <button mat-button mat-dialog-close class="btn red-btn" (click)="removeCollaborator()" >Remove Collaborator</button>
  </div>
</div>
