
import { NewQuoteComponent } from './../../../new-quote/new-quote.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-next-button',
  templateUrl: './next-button.component.html',
  styleUrls: ['./next-button.component.scss'],
})
export class NextButtonComponent implements OnInit {
  form: FormGroup;
  formVehicle: FormGroup;
  formDrivers: FormGroup;
  @Input() nextStep: string;
  @Input() Next: string = 'Next';
  localData: any;



  constructor(
    private formContainer: NewQuoteComponent,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.form = this.formContainer.getStepForm();
    this.formDrivers = this.formContainer.fdr();
    this.formVehicle = this.formContainer.fvh();

    this.localData = {
      address: {
        address: this.form.controls.address.value,
        address_line_2: this.form.controls.aptunid.value,
        city: '',
        state: '',
        zip_code: '',
      },
      birthdate: this.form.controls.dateOfBirth.value,
      currently_insured: this.form.controls.currentlyInsured.value,
      drivers: [],
      email: this.form.controls.email.value,
      first_name: this.form.controls.firstName.value,
      gender: this.form.controls.gender.value,
      last_name: this.form.controls.lastName.value,
      marital_status: this.form.controls.marital.value,
      phone: this.form.controls.phone.value,
      policy_start_date: this.form.controls.policyStartDate.value,
      preferred_language: this.form.controls.preferredLanguage.value,
      quo_plan_insurance: this.form.controls.planInsurance.value,
      quo_type_insurance: this.form.controls.typeOfInsurance.value,
      spouse: {
        birthdate: this.form.controls.spouse_dateOfBirth.value,
        first_name: this.form.controls.spouse_firstName.value,
        gender: this.form.controls.gender.value,
        last_name: this.form.controls.spouse_lastName.value,
      },
      vehicles: [],
    };
  }

  nextStepp(data) {

    data.stopPropagation();
    window.localStorage.setItem('form', JSON.stringify(this.form.value));
    
    let formStep = this.formContainer.formStep;
    let controlsDrivers = this.formDrivers.controls;
    let controls = this.form.controls;
    if (
      formStep == 3 &&
      controls.firstName.errors &&
      controls.lastName.errors &&
      controls.email.errors
    ) {
      this.notificationService.error(
        'Please, check the indicated fields to continue'
      );


    } else if (
      formStep == 4 &&
      controls.dateOfBirth.errors &&
      controls.phone.errors
    ) {
      this.notificationService.error(
        'Please, check the indicated fields to continue'
      );
    } else if (
      formStep == 5 &&
      controls.preferredLanguage.errors &&
      controls.gender.errors
    ) {
      this.notificationService.error(
        'Please, check the indicated fields to continue'
      );
    } else if (formStep == 6 && controls.marital.errors) {
      this.notificationService.error(
        'Please, check the indicated fields to continue'
      );
    } else if (
      (formStep == 6 &&
        controls.marital.value == 'Married' &&
        controls.spouse_firstName.errors &&
        controls.spouse_lastName.errors &&
        controls.spouse_dateOfBirth.errors &&
        controls.spouse_gender.errors) ||
      (controls.marital.value == 'Domestic Partner' &&
        controls.spouse_firstName.errors &&
        controls.spouse_lastName.errors &&
        controls.spouse_dateOfBirth.errors &&
        controls.spouse_gender.errors)
    ) {
      this.notificationService.error(
        'Please, check the indicated fields to continue'
      );
    } else if (formStep == 7 && controls.address.errors) {
      this.notificationService.error(
        'Please, check the indicated fields to continue'
      )

    } else if (
      formStep == 8 &&
      controls.policyStartDate.errors &&
      controls.currentlyInsured.errors &&
      controls.primaryResidence.errors
    ) {
      this.notificationService.error(
        'Please, check the indicated fields to continue'
      );
    } else if (
      formStep == 9 &&
      controlsDrivers.driverStatus.errors &&
      controlsDrivers.driverFirstname.errors &&
      controlsDrivers.driverLastname.errors &&
      controlsDrivers.driverBirthdate.errors &&
      controlsDrivers.driverGender.errors &&
      controlsDrivers.driverLicenseState.errors &&
      controlsDrivers.driverLicenseNumber.errors
    ) {
      this.notificationService.error(
        'Please, check the indicated fields to continue'
      );
    } else {
      window.localStorage.setItem('formData', JSON.stringify(this.localData));
      this.formContainer.formStep += 1;
    }
  }

  saveAndNextStep() {
    let formStep = this.formContainer.formStep;
    let controlsDrivers = this.formDrivers.controls;

    if (
      formStep == 9 &&
      controlsDrivers.driverStatus.errors &&
      controlsDrivers.driverFirstname.errors &&
      controlsDrivers.driverLastname.errors &&
      controlsDrivers.driverBirthdate.errors &&
      controlsDrivers.driverGender.errors &&
      controlsDrivers.driverLicenseState.errors &&
      controlsDrivers.driverLicenseNumber.errors
    ) {
    } else {
      var driversData = {
        status: this.formDrivers.value.driverStatus,
        firstName: this.formDrivers.value.driverFirstname,
        lastName: this.formDrivers.value.driverLastname,
        dateOfBirth: this.formDrivers.value.driverBirthdate,
        gender: this.formDrivers.value.driverGender,
        licenseState: this.formDrivers.value.driverLicenseState,
        licenseNumber: this.formDrivers.value.driverLicenseNumber,
      };

      this.formContainer.driverList.push(driversData);
      console.log(this.formContainer.driverList)
      this.localData.drivers = this.formContainer.driverList;
      window.localStorage.setItem('formData', JSON.stringify(this.localData));
      window.localStorage.setItem(
        'drivers',
        JSON.stringify(this.formContainer.driverList)
      );

      if (JSON.parse(window.localStorage.getItem('drivers')).length > 0) {
        this.formContainer.hasDriver = true;
      } else {
        this.formContainer.hasDriver = false;
      }

      let driversLocalStorage = JSON.parse(
        window.localStorage.getItem('drivers')
      );
      let arrDrivers = [];
      driversLocalStorage.forEach((element) => {
        arrDrivers.push(element.firstName);
      });

      this.formContainer.drivers = arrDrivers;

      this.formContainer.formStep += 1;
    }
  }

  saveAndNextStepVehicle() {
    let formStep = this.formContainer.formStep;
    let controlsVehicles = this.formVehicle.controls;

    if (
      formStep == 11 &&
      controlsVehicles.vin.errors &&
      controlsVehicles.mainUse.errors &&
      controlsVehicles.driver.errors &&
      controlsVehicles.annualMiles.errors &&
      controlsVehicles.collisionDeductible.errors &&
      controlsVehicles.comprehensiveDeductible.errors
    ) {
    } else {
      var vehiclesData = {
        vin: this.formVehicle.value.vin,
        mainUse: this.formVehicle.value.mainUse,
        driver: this.formVehicle.value.driver,
        annualMiles: this.formVehicle.value.annualMiles,
        collisionDeductible: this.formVehicle.value.collisionDeductible,
        comprehensiveDeductible: this.formVehicle.value.comprehensiveDeductible,
        substituteTransportation:
          this.formVehicle.value.substituteTransportation,
        towingAndLabor: this.formVehicle.value.towingAndLabor,
      };

      this.formContainer.vehiclesList.push(vehiclesData);

      this.localData.vehicles = this.formContainer.vehiclesList;
      window.localStorage.setItem('formData', JSON.stringify(this.localData));

      window.localStorage.setItem(
        'vehicles',
        JSON.stringify(this.formContainer.vehiclesList)
      );

      if (JSON.parse(window.localStorage.getItem('vehicles')).length > 0) {
        this.formContainer.hasVehicle = true;
      } else {
        this.formContainer.hasVehicle = false;
      }

      let driversLocalStorage = JSON.parse(
        window.localStorage.getItem('vehicles')
      );
      let arrvehicles = [];
      driversLocalStorage.forEach((element) => {
        arrvehicles.push(element.firstName);
      });

      this.formContainer.vehicles = arrvehicles;

      this.formContainer.formStep += 1;
    }
  }


  
}
