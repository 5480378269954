
<mat-card *ngIf="detailed$ | async as detailed; else elseBlock">

    <mat-card-header class="header-quote">
        <i class="far fa-money-bill-alt" style="margin-left: -20px; margin-top: 14px;font-size: 24px;"></i>

        <div class="ml-28">
            <mat-card-title class="title-count">{{title}}</mat-card-title>
            <mat-card-subtitle>{{subtitle}}</mat-card-subtitle>
        </div>

    </mat-card-header>

    <div *ngIf="detailed.data.length > 0; else hasNoData">

        <div class="example-container" *ngIf="!!dataSource">
            <div>
                <table mat-table [dataSource]="dataSource" class="table" matSort>

                    <ng-container matColumnDef="user_id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 100px !important;"> Id </th>
                        <td mat-cell *matCellDef="let row" style="width: 100px !important;">
                            <span class="badge-outline-primary badge">
                                {{row.user_id }}
                            </span>
                        </td>
                        <td mat-footer-cell *matFooterCellDef>Total</td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 100px !important;"> Name </th>
                        <td mat-cell *matCellDef="let row" style="width: 100px !important;">{{row.name }}</td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <ng-container matColumnDef="daily_cash_type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header  style="width: 150px !important;"> DC Type </th>
                        <td mat-cell *matCellDef="let row"  style="width: 150px !important;">{{row.daily_cash_type }}</td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <ng-container matColumnDef="count">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header  style="width: 100px !important;"> Count </th>
                        <td mat-cell *matCellDef="let row"  style="width: 100px !important;">{{row.count }}</td>
                        <td mat-footer-cell *matFooterCellDef> {{getTotal('count') }} </td>
                    </ng-container>

                    <ng-container matColumnDef="premium">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header  style="width: 150px !important;"> Premium</th>
                        <td mat-cell *matCellDef="let row"  style="width: 150px !important;">{{row.premium | currency }}</td>
                        <td mat-footer-cell *matFooterCellDef> {{getTotal('premium') | currency}} </td>
                    </ng-container>

                    <ng-container matColumnDef="fee">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header  style="width: 150px !important;"> Fee </th>
                        <td mat-cell *matCellDef="let row"  style="width: 150px !important;">{{row.fee | currency }}</td>
                        <td mat-footer-cell *matFooterCellDef> {{getTotal('fee')| currency}} </td>
                    </ng-container>

                    <ng-container matColumnDef="translation">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header  style="width: 150px !important;"> Translation </th>
                        <td mat-cell *matCellDef="let row"  style="width: 150px !important;">{{row.translation | currency }}</td>
                        <td mat-footer-cell *matFooterCellDef> {{getTotal('translation') | currency}} </td>
                    </ng-container>

                    <ng-container matColumnDef="runner">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header  style="width: 150px !important;"> Runner </th>
                        <td mat-cell *matCellDef="let row"  style="width: 150px !important;">{{row.runner | currency }}</td>
                        <td mat-footer-cell *matFooterCellDef> {{getTotal('runner') | currency}} </td>
                    </ng-container>

                    <ng-container matColumnDef="commission">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header  style="width: 150px !important;"> Commission </th>
                        <td mat-cell *matCellDef="let row"  style="width: 150px !important;">{{row.commission | currency }}</td>
                        <td mat-footer-cell *matFooterCellDef> {{getTotal('commission') | currency}} </td>
                    </ng-container>

                    <ng-container matColumnDef="total">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header  > Total </th>
                        <td mat-cell *matCellDef="let row"  >{{row.total | currency }}</td>
                        <td mat-footer-cell *matFooterCellDef> {{getTotal('total') | currency}} </td>
                    </ng-container>

                    <ng-container matColumnDef="sales_target">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header  style="width: 150px !important;"> Sales Target </th>
                        <td mat-cell *matCellDef="let row"  style="width: 150px !important;">{{row.sales_target | currency }}</td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <ng-container matColumnDef="sales_success">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header  style="width: 180px !important;"> Sales Success </th>
                        <td mat-cell *matCellDef="let row"  style="width: 180px !important;">
                            <span *ngIf="row.sales_success">
                                <span [ngClass]="{'badge-outline-warn': row.sales_success < 50,
                        'badge-outline-info': row.sales_success > 50 && row.sales_success < 99,
                        'badge-outline-primary': row.sales_success > 99 }" class="badge">
                                    {{row.sales_success }}%
                                </span>
                            </span>
                        </td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
                </table>
            </div>
            <mat-paginator [pageSize]="50"></mat-paginator>
        </div>
    </div>

    <ng-template #hasNoData>
        <mat-card class="card-no-data"
            style="height: 30vh; display: flex; justify-content: center; align-items: center;">
        </mat-card>
    </ng-template>
</mat-card>

<ng-template #elseBlock>
    <mat-card style="height: 50vh; display: flex; justify-content: center; align-items: center;">
        <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
    </mat-card>
</ng-template>