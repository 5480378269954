<mat-expansion-panel class="expansion-panel" *ngIf="!checkAdminMenu()">
  <mat-expansion-panel-header>
    <mat-panel-title style="color: #ffffff;">
      <div style="margin-top: 5px;">
        <i class="fas fa-users-cog"></i>
        <span style="margin-left: 10px;">Admin</span>
      </div>
    </mat-panel-title>

  </mat-expansion-panel-header>

  <div>

    <a *ngIf="checkSignatureCap()" mat-list-item routerLink="/home/admin/email-signature" class="item-desc">
      Email Signature
    </a>

    <a *ngIf="checkSheetsCap()" mat-list-item routerLink="/home/admin/google-sheet" class="item-desc">
      Google Sheet
    </a>

    <a *ngIf="checkPandaCap()" mat-list-item routerLink="/home/admin/hubspot" class="item-desc">
      Hubspot
    </a>

    <a *ngIf="checkHubspotCap()" mat-list-item routerLink="/home/admin/pandadoc" class="item-desc">
      Pandadoc
    </a>

    <a *ngIf="checkSalesTarget()" mat-list-item routerLink="/home/admin/sales-target" class="item-desc">
      Sales Target
    </a>

    <a *ngIf="checkCarrierTarget()" mat-list-item routerLink="/home/admin/carrier-target" class="item-desc">
      Carrier Target
    </a>

  </div>

</mat-expansion-panel>
