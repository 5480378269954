<mat-card *ngIf="salesPolicy$ | async as sales; else elseBlock">

    <mat-card-header class="header-quote">

        <i class="far fa-handshake" style="margin-left: -20px; margin-top: 14px;font-size: 24px;"></i>

        <div class="ml-28">
          <mat-card-title class="title-count">{{title}}</mat-card-title>
          <mat-card-subtitle>{{subtitle}}</mat-card-subtitle>
        </div>

    </mat-card-header>

    <div *ngIf="sales.data.label.length > 0; else hasNoData">
      <mat-card-content>
        <highcharts-chart
        style="height: 30em; display: flex; justify-content: center; align-items: center;"
        [Highcharts]="Highcharts" [options]="chartOptions" class="chart" [(update)]="updateFlag">
        </highcharts-chart>
    </mat-card-content>
    </div>

    <ng-template #hasNoData>
      <mat-card class="card-no-data" style="height: 10em;; display: flex; justify-content: center; align-items: center;">
      </mat-card>
    </ng-template>

</mat-card>

<ng-template #elseBlock>
    <mat-card style="height: 10em;; display: flex; justify-content: center; align-items: center;">
      <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
    </mat-card>
  </ng-template>
