import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { DashboardName } from 'src/app/modules/dashboard-lead/model/dashboard';
import { PusherService } from 'src/app/shared/services/pusher.service';
import { environment } from 'src/environments/environment';
import { ChartPodiumComponent } from '../chart-podium/chart-podium.component';
import { ChartSalesPolicyCountComponent } from '../chart-sales-policy-count/chart-sales-policy-count.component';
import { ChartSalesPremiumCountComponent } from '../chart-sales-premium-count/chart-sales-premium-count.component';
import { TableDetailedListSalesComponent } from '../table-detailed-list/table-detailed-list-sales.component';

@Component({
  selector: 'app-brz-bar-sales-filter',
  templateUrl: './brz-bar-sales-filter.component.html',
  styleUrls: ['./brz-bar-sales-filter.component.scss']
})
export class BrzBarSalesFilterComponent implements OnInit {

  fgFilter: FormGroup;

  range: string;
  from: string;
  to: string;
  dcType: string[];

  totalFee: number;
  totalPremium: number;
  totalRevenue: number;
  totalPolicy: number;

  @Input() results: DashboardName[];
  @ViewChild(ChartSalesPolicyCountComponent) chartSalesPolicyCountComponent: ChartSalesPolicyCountComponent;
  @ViewChild(ChartSalesPremiumCountComponent) chartSalesPremiumCountComponent: ChartSalesPremiumCountComponent;
  @ViewChild(TableDetailedListSalesComponent) tableDetailedListSalesComponent: TableDetailedListSalesComponent;
  @ViewChild(ChartPodiumComponent) chartPodiumComponent: ChartPodiumComponent;

  constructor(private _formBuilder: FormBuilder, private cookieService: CookieService, private pusherService: PusherService) { }

  ngOnInit(): void {
    this.initForm();
    this.initVariables();
    this.pusher();
  }

  onSubmit() {

    var datePipe = new DatePipe('en-US');

    let from = null;
    let to = null;
    let period = this.fgFilter.get('fcPeriod').value;

    if (this.fgFilter.get('fcDtFrom').value)
      from = datePipe.transform(this.fgFilter.get('fcDtFrom').value, 'MM/dd/yyyy');

    if (this.fgFilter.get('fcDtTo').value)
      to = datePipe.transform(this.fgFilter.get('fcDtTo').value, 'MM/dd/yyyy');

    this.cookieService.set('periodSales', period || null);

    if (this.cookieService.get('periodSales') === 'customdate') {
      this.cookieService.set('dtFromSales', from);
      this.cookieService.set('dtToSales', to);
    }

    this.cookieService.set('dcTypeSales', this.fgFilter.get('fcDcType').value);

    if (this.heWantsPolicyCount()) this.chartSalesPolicyCountComponent.getSalesPolicy(period, from, to, this.fgFilter.get('fcDcType').value);
    if (this.heWantsPremiumAmount()) this.chartSalesPremiumCountComponent.getSalesPremium(period, from, to, this.fgFilter.get('fcDcType').value);
    if (this.heWantsDetailedList()) this.tableDetailedListSalesComponent.getDetailedListSales(period, from, to, this.fgFilter.get('fcDcType').value);
    if (this.heWantsDetailedList()) this.chartPodiumComponent.getPodiumRank(period, from, to, this.fgFilter.get('fcDcType').value);

  }

  initForm() {

    let from: Date;
    let to: Date;
    let dcType: string[];

    if (this.cookieService.get('dtFromSales')) {
      from = new Date(this.htmlDecode(this.cookieService.get('dtFromSales')));
    }

    if (this.cookieService.get('dtToSales')) {
      to = new Date(this.htmlDecode(this.cookieService.get('dtToSales')));
    }

    if (this.cookieService.get('dcTypeSales')) {
      dcType = this.htmlDecode(this.cookieService.get('dcTypeSales')).split(',');
    }

    this.fgFilter = this._formBuilder.group({
      fcPeriod: [this.cookieService.get('periodSales') || 'thismonth'],
      fcDtFrom: [from || ''],
      fcDtTo: [to || ''],
      fcDcType: [dcType || ['new', 'endorsement', 'brl'], Validators.required]
    });

  }

  htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  }

  initVariables() {
    var datePipe = new DatePipe('en-US');
    this.range = this.fgFilter.get('fcPeriod').value;
    this.from = datePipe.transform(this.fgFilter.get('fcDtFrom').value, 'MM/dd/yyyy');
    this.to = datePipe.transform(this.fgFilter.get('fcDtTo').value, 'MM/dd/yyyy');
    this.dcType = this.fgFilter.get('fcDcType').value;
  }

  heWantsPolicyCount(): boolean {
    return this.didItFoundCap('brzinsurance_reports_brzsales_policy_count');
  }

  heWantsPodium(): boolean {
    return this.didItFoundCap('brzinsurance_reports_brzsales_rank');
  }

  heWantsPremiumAmount(): boolean {
    return this.didItFoundCap('brzinsurance_reports_brzsales_premium_amount');
  }

  heWantsDetailedList(): boolean {
    return this.didItFoundCap('brzinsurance_reports_brzsales_detailed_list');
  }

  didItFoundCap(cap: string): boolean {
    if (this.results) if (this.results.find(x => x.cap === cap)) return true;

    return false;
  }

  getTotalFee($event: number) {
    this.totalFee = $event;
  }

  getTotalRevenue($event: number) {
    this.totalRevenue = $event;
  }

  getTotalPremium($event: number) {
    this.totalPremium = $event;
  }

  getTotalPolicy($event: number) {
    this.totalPolicy = $event;
  }

  pusher() {

    let pusherClient = this.pusherService.getPusher();

    const channel = pusherClient.subscribe(`${environment.channelSlug}dashboard-sales`);

    channel.bind(
      'pusher:subscription_succeeded', () => {
        console.log(`channel: ${environment.channelSlug}dashboard-sales`);
      });

    channel.bind('new-daily-cash', (sales) => {

      var datePipe = new DatePipe('en-US');
      let formatDateCreated = datePipe.transform(sales.daily_cash_timecreated, 'MM/dd/yyyy') || null;
      let dateCreated = new Date(formatDateCreated);
      let today = new Date();

      const from = this.fgFilter.get('fcDtFrom').value;
      const to = this.fgFilter.get('fcDtTo').value;

      switch (this.fgFilter.get('fcPeriod').value) {
        case 'all':
          this.chartItAll(sales);
          break;
        case 'today':
          if ((dateCreated.getDate() === today.getDate())
            && (dateCreated.getMonth() === today.getMonth())
            && (dateCreated.getFullYear() === today.getFullYear())) {
            this.chartItAll(sales);
          }
          break;
        case 'thisyear':
          if (dateCreated.getFullYear() === today.getFullYear()) {
            this.chartItAll(sales);
          }
          break;
        case 'thisweek':
          if (this.isDateInThisWeek(dateCreated)) {
            this.chartItAll(sales);
          }
          break;
        case 'thismonth':
          if (dateCreated.getMonth() === today.getMonth()) {
            this.chartItAll(sales);
          }
          break;
        case 'customdate':
          if ((dateCreated <= to) && (dateCreated >= from)) {
            this.chartItAll(sales);
          }
          break;

        default:
          break;
      }

    });

  }

  isDateInThisWeek(date) {
    const todayObj = new Date();
    const todayDate = todayObj.getDate();
    const todayDay = todayObj.getDay();
    const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);
    return date >= firstDayOfWeek && date <= lastDayOfWeek;
  }

  chartItAll(sales: any) {
    if (this.fgFilter.get('fcDcType').value.includes(sales.daily_cash_type)) {
      this.generateDetailedList(sales);
      this.generatePodium(sales);
      this.generatePolicyCount(sales);
      this.generatePremium(sales);
    }
  }

  generatePolicyCount(sales: any) {
    if (this.chartSalesPolicyCountComponent.chartOptions.series.length > 0) {

      if (this.chartSalesPolicyCountComponent.chartOptions.xAxis['categories'][0] === sales.name) {
        let linePolicyCount = this.chartSalesPolicyCountComponent.chartOptions.series.find(x => x.name === sales.daily_cash_type);

        if (linePolicyCount) {

          let oldValue = linePolicyCount['data'][0];

          linePolicyCount['data'][0] = oldValue + sales.count;

          this.totalPolicy = this.totalPolicy + sales.count;

        }
        else {
          this.chartSalesPolicyCountComponent.chartOptions.series.push({
            type: 'bar',
            name: sales.daily_cash_type,
            data: [sales.count],
            pointWidth: 40,
          });

        }
      }

    } else {
      this.chartSalesPolicyCountComponent.getSalesPolicy(this.fgFilter.get('fcPeriod').value,
        this.fgFilter.get('fcDtFrom').value,
        this.fgFilter.get('fcDtTo').value,
        this.fgFilter.get('fcDcType').value);
    }

    this.chartSalesPolicyCountComponent.updateFlag = true;

  }

  generateDetailedList(sales: any) {
    let lineTable = this.tableDetailedListSalesComponent.dataSource.data.find(x => x.name === sales.name);

    if (lineTable) {

      lineTable.premium = lineTable.premium + sales.premium;
      if (!lineTable.daily_cash_type.includes(sales.daily_cash_type)) {
        lineTable.daily_cash_type += `|${sales.daily_cash_type}`
      }
      lineTable.count = lineTable.count + sales.count;
      lineTable.fee = (lineTable.fee || 0) + sales.fee;
      lineTable.translation = (lineTable.translation || 0) + sales.translation;
      lineTable.runner = (lineTable.runner || 0) + sales.runner;
      lineTable.commission = (lineTable.commission || 0) + sales.commission;
      lineTable.total = (lineTable.total || 0) + sales.total;
      lineTable.sales_success = parseFloat(((lineTable.premium / lineTable.sales_target) * 100).toFixed(2));


    }
    else {

      // this.tableDetailedListSalesComponent.dataSource.data.unshift({
      //   commission: sales.commission,
      //   count: sales.count,
      //   daily_cash_type: sales.daily_cash_type,
      //   fee: sales.fee,
      //   name: sales.name,
      //   premium: sales.premium,
      //   runner: sales.runner,
      //   sales_success: sales.sales_success,
      //   sales_target: sales.sales_target,
      //   total: sales.total,
      //   translation: sales.translation,
      //   user_email: sales.user_email,
      //   user_id: sales.user_id
      // });


      this.tableDetailedListSalesComponent.getDetailedListSales(this.fgFilter.get('fcPeriod').value,
        this.fgFilter.get('fcDtFrom').value,
        this.fgFilter.get('fcDtTo').value,
        this.fgFilter.get('fcDcType').value);

    }

  }

  generatePodium(sales: any) {
    let lineRank = this.chartPodiumComponent.ranks.find(x => x.name === sales.name);

    if (lineRank) {
      lineRank.total_premium = lineRank.total_premium + sales.premium;
    }

    this.chartPodiumComponent.getPodiumRank(this.fgFilter.get('fcPeriod').value, this.fgFilter.get('fcDtFrom').value, this.fgFilter.get('fcDtTo').value, this.fgFilter.get('fcDcType').value);
  }

  generatePremium(sales: any) {
    if (this.chartSalesPremiumCountComponent.chartOptions.series.length > 0) {

      if (this.chartSalesPremiumCountComponent.chartOptions.xAxis['categories'][0] === sales.name) {
        let linePremium = this.chartSalesPremiumCountComponent.chartOptions.series.find(x => x.name === sales.daily_cash_type);

        if (linePremium) {

          let oldValue = linePremium['data'][0];

          linePremium['data'][0] = oldValue + sales.premium;

          this.totalPremium = this.totalPremium + sales.premium;

        }
        else {
          this.chartSalesPremiumCountComponent.chartOptions.series.push({
            type: 'bar',
            name: sales.daily_cash_type,
            data: [sales.premium],
            pointWidth: 40,

          });
        }
      }

    } else {
      this.chartSalesPremiumCountComponent.getSalesPremium(this.fgFilter.get('fcPeriod').value,
        this.fgFilter.get('fcDtFrom').value,
        this.fgFilter.get('fcDtTo').value,
        this.fgFilter.get('fcDcType').value);
    }

    this.chartSalesPremiumCountComponent.updateFlag = true;
  }
}
