<div class="container-box">
    <div class="title-box">
      <span class="subtitle">BRZ Collaborators / Tickets</span>
    </div>
  
    <div>
      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
        <mat-tab label="Auditoria">
          <app-audit-ticket></app-audit-ticket>
        </mat-tab>
  
        <mat-tab label="COI">
          <app-coi></app-coi>
        </mat-tab>

        <mat-tab label="Claim">
           <app-claim></app-claim>
          <!-- <app-claim></app-claim> -->
        </mat-tab>
  
        <mat-tab label="Avoid">
            <app-avoid></app-avoid>
          <!-- <app-avoid></app-avoid> -->
        </mat-tab> 
      </mat-tab-group>
    </div>
  </div>
  