import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { DataDecPageLead, DecPageLead } from 'src/app/modules/quote/model/decpage/dec-page-lead';
import { DataQuote } from 'src/app/modules/quote/model/quote';
import { QuotesService } from 'src/app/modules/quote/service/quote.service';
import { UserService } from 'src/app/core/user/user.service';
import { ConfirmationDialogComponent } from 'src/app/shared/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-dec-page',
  templateUrl: './dec-page.component.html',
  styleUrls: ['./dec-page.component.scss']
})
export class DecPageComponent implements OnInit {

  decpage$: Observable<DecPageLead>;
  idQuote: number;
  dialogRef: MatDialogRef<ConfirmationDialogComponent>;
  boxShadow: boolean = true;

  constructor(private quoteService: QuotesService,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private userService: UserService,
    public dialog: MatDialog) { }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe(params => {
      this.idQuote = params.id;

      if (params.header) {
        this.boxShadow = false;
      }

      this.listenDecPageObservable(this.idQuote);
    });
  }

  DeleteFile(fileId: number) {

    if (this.userService.cap.hasOwnProperty('brzinsurance_lead_delete_dec_page')) {

      this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });
      this.dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?"

      this.dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.quoteService.deleteFile(fileId).subscribe(status => {

            if (status === 'OK') {
              this.listenDecPageObservable(this.idQuote);
            }

          }, (err: HttpErrorResponse) => {
            this.notificationService.error(err.error.error);
          });
        }
        this.dialogRef = null;
      });
    } else {
      this.notificationService.alert('You have no permission to delete dec page.');
    }


  }

  listenDecPageObservable(quoteId: number) {
    this.decpage$ = this.quoteService.getDecPageByLead(quoteId);
  }

  DownloadDecPage(fileId: number) {
    
    if (this.userService.cap.hasOwnProperty('brzinsurance_lead_download_file')) {
      this.quoteService.downloadDecPage(fileId, this.idQuote).subscribe();
    }
    else {
      this.notificationService.alert('You have no permission to download dec page.')
    }

  }

}
