import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenService } from 'src/app/core/token/token.service';
import { UserService } from 'src/app/core/user/user.service';
import { environment } from 'src/environments/environment';
import { AgentsBlip, DataCollaborators } from './model/interfaces';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CollaboratorsService {
  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private userService: UserService
  ) {}

  getCollaborators(): Observable<AgentsBlip[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        token: `ex65jRFYjepRy6omf3G3#kLK3nGmxXa&k$5@`,
      }),
    };

    if (environment.productionMode) {
      return this.http
        .post<AgentsBlip[]>(
          `https://brzcomb-integration.herokuapp.com/blip/queue`,
          {
            lang: 'pt-br',
            departament: 'CX',
          },
          httpOptions
        )
        .pipe(pluck('response'));
    } else {
      return this.http
        .post<AgentsBlip[]>(
          `https://brzcomb-integration.herokuapp.com/blip/queue?homolog=true`,
          {
            lang: 'pt-br',
            departament: 'CX',
            homolog:true
          },
          httpOptions
        )
        .pipe(pluck('response'));
    }
  }

  addColaborattors(body) {
    body.equipe = 'CX';
    body.distribuir = body.distribuir === null ? false : body.distribuir;

    const httpOptions = {
      headers: new HttpHeaders({
        token: `ex65jRFYjepRy6omf3G3#kLK3nGmxXa&k$5@`,
      }),
    };

    if (environment.productionMode) {
      return this.http
        .post<{ response: string; success: boolean }>(
          `https://brzcomb-integration.herokuapp.com/firebase/dados/ATENDENTES`,
          body,
          httpOptions
        )
        .pipe();
    } else {
      return this.http
        .post<{ response: string; success: boolean }>(
          `https://brzcomb-integration.herokuapp.com/firebase/dados/ATENDENTES_HOMOLOG`,
          body,
          httpOptions
        )
        .pipe();
    }
  }

  editCollaborator(data) {
    const body = {
      id: data.id,
      data: {
        camada: data.camada,
        email: data.email,
        nome: data.nome,
        idiomas: data.idiomas,
        distribuir: data.distribuir,
      },
    };
    const httpOptions = {
      headers: new HttpHeaders({
        token: `ex65jRFYjepRy6omf3G3#kLK3nGmxXa&k$5@`,
      }),
    };

    if (environment.productionMode) {
      return this.http
        .patch<{ response: string; success: boolean }>(
          `https://brzcomb-integration.herokuapp.com/firebase/dados/ATENDENTES`,
          body,
          httpOptions
        )
        .pipe();
    } else {
      return this.http
        .patch<{ response: string; success: boolean }>(
          `https://brzcomb-integration.herokuapp.com/firebase/dados/ATENDENTES_HOMOLOG`,
          body,
          httpOptions
        )
        .pipe();
    }
  }

  deleteColaborattors(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        token: `ex65jRFYjepRy6omf3G3#kLK3nGmxXa&k$5@`,
      }),
      // body: {id: id}, // Aqui você inclui o corpo da solicitação DELETE
    };

    if (environment.productionMode) {
      return this.http
        .delete<{ response: string; success: boolean }>(
          `https://brzcomb-integration.herokuapp.com/firebase/dados/ATENDENTES?id=` +
            id,

          httpOptions
        )
        .pipe();
    } else {
      return this.http
        .delete<{ response: string; success: boolean }>(
          `https://brzcomb-integration.herokuapp.com/firebase/dados/ATENDENTES_HOMOLOG?id=` +
            id,

          httpOptions
        )
        .pipe();
    }
  }

  patchCollaborators(id: any, distribuir: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        token: `ex65jRFYjepRy6omf3G3#kLK3nGmxXa&k$5@`,
      }),
    };
    const body = {
      id: id,
      data: {
        distribuir: distribuir,
      },
    };
    if (environment.productionMode) {
      return this.http
        .patch<DataCollaborators[]>(
          `https://brzcomb-integration.herokuapp.com/firebase/dados/ATENDENTES`,

          body,
          httpOptions
        )
        .pipe(pluck('response'));
    } else {
      return this.http
        .patch<DataCollaborators[]>(
          `https://brzcomb-integration.herokuapp.com/firebase/dados/ATENDENTES_HOMOLOG`,
          body,
          {
            headers: new HttpHeaders({
              token: `ex65jRFYjepRy6omf3G3#kLK3nGmxXa&k$5@`,
            }),
          }
        )
        .pipe(pluck('response'));
    }
  }
}
