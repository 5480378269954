<mat-expansion-panel class="expansion-panel">
  <mat-expansion-panel-header>
    <mat-panel-title style="color: #ffffff;">
      <div style="margin-top: 5px;">
        <i class="far fa-chart-bar"></i>
        <span style="margin-left: 10px;">DashBoard</span>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <div>
    <a *ngIf="checkQuoteCap()" mat-list-item routerLink="/home/dashboard-lead/" class="item-desc">
      Brz Quote
    </a>

    <a *ngIf="checkBrzStatsCap()" mat-list-item routerLink="/home/dashboard-lead/stats" class="item-desc">
      Brz Stats
    </a>

    <a *ngIf="checkBrzAgencyStatsCap()" mat-list-item routerLink="/home/dashboard-lead/agency-stats" class="item-desc">
      Brz Agency Stats
    </a>

    <a *ngIf="checkSalesCap()" mat-list-item routerLink="/home/dashboard-lead/sales" class="item-desc">
      Brz Sales
    </a>

    <a *ngIf="checkMarketingCap()" mat-list-item routerLink="/home/dashboard-lead/marketing" class="item-desc">
      Brz Marketing
    </a>

    <a *ngIf="checkCarrierTarget()" mat-list-item routerLink="/home/dashboard-lead/carrier-target" class="item-desc">
      Brz Carrier Target
    </a>

  </div>
</mat-expansion-panel>
