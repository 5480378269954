import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { DataTotalPremium, TotalPremium } from 'src/app/modules/dashboard-lead/model/totalPremium';
import { DashboardLeadService } from 'src/app/modules/dashboard-lead/service/dashboard-lead.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-table-total-premium',
  templateUrl: './table-total-premium.component.html',
  styleUrls: ['./table-total-premium.component.scss']
})
export class TableTotalPremiumComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('TABLEEXPORT') tableExport: ElementRef;
  loaderExport: boolean = false;

  totalPremium$: Observable<TotalPremium>;
  detailed$: Observable<TotalPremium>;
  title: string;
  subtitle: string;
  totalPremiumValue: number = 0;
  totalRecords: number = 0;

  reqCompleted: boolean = false;

  dataSource: MatTableDataSource<DataTotalPremium>;
  dataExport: MatTableDataSource<DataTotalPremium>;

  @Input() range: string;
  @Input() from: string;
  @Input() to: string;
  @Input() carrier_name: string;
  @Input() carrier_ids: string[];

  displayedColumns: string[] = [
    'carrier',
    'premium',
    'amount',
  ];

  constructor(private dashboardLeadService: DashboardLeadService, private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.getTotalPremiumStats(this.range, this.from, this.to, this.carrier_name, this.carrier_ids);
  }

  getTotalPremiumStats(range: string, from: string, to: string, carrier_name: string, _carrier_ids: string[]) {

    this.reqCompleted = false;
    this.detailed$ = this.dashboardLeadService.getDetailedListAgencyStats(range, from, to, carrier_name, _carrier_ids)
      .pipe(tap((totalPremium) => {
        this.title = totalPremium.title;
        this.subtitle = totalPremium.description;
        this.reqCompleted = true;
        this.totalPremiumValue = 0;

        let dataAll = {};

        if (totalPremium.data) {
          let newData = totalPremium.data.map((item) => {

            if (dataAll[item.carrier_id] === null || dataAll[item.carrier_id] === undefined) {
              dataAll[item.carrier_id] = {
                carrier_name: '',
                premium: 0,
                count: 0,
              };
            }

            dataAll[item.carrier_id].carrier_name = item.carrier_name;
            dataAll[item.carrier_id].premium += item.premium;
            dataAll[item.carrier_id].count++;

            this.totalPremiumValue += item.premium;

            return item;
          });

          var myData = Object.keys(dataAll).map(key => {
            return dataAll[key];
          })

          totalPremium.data = myData;

          this.dataSource = new MatTableDataSource<DataTotalPremium>(myData);
          return totalPremium;
        }

        this.totalRecords = totalPremium.total !== null && totalPremium.total !== undefined ? totalPremium.total : 0;

        return null;
      }))
      .pipe(catchError(err => {
        this.reqCompleted = true;
        throw err;
      }));
  }

  addDataToDataSource(data: DataTotalPremium[]) {
    setTimeout(() => this.dataSource.paginator = this.paginator);
    setTimeout(() => this.dataSource.sort = this.sort);
    this.dataSource = new MatTableDataSource<DataTotalPremium>(data);
  }


  getTotal(column: string) {
    return this.dataSource.data.map(t => t[column]).reduce((acc, value) => acc + value, 0);
  }

  exportExcel() {
    this.loaderExport = true;

    this.dashboardLeadService.getDetailedListAgencyStats(this.range, this.from, this.to, this.carrier_name, this.carrier_ids)
    .subscribe((data: TotalPremium) => {

      if (data !== null) {
        this.dataExport = this.dataSource;

        if (data.data !== null && data.data !== undefined) {
          this.dataSource.data = data.data;

          setTimeout(() => {
            const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.tableExport.nativeElement); //converts a DOM TABLE element to a worksheet
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

            const fileName = 'agency-stats-total-premium.xlsx';

            /* save to file */
            XLSX.writeFile(wb, fileName);

            this.dataSource = this.dataExport;
            this.dataExport = new MatTableDataSource<DataTotalPremium>(null);

            this.loaderExport = false;
          });
        }

      } else {
        this.notificationService.error('There was an error generating the CSV file.');
        this.loaderExport = false;
      }
    });
  }

}
