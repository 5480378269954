<ng-template [ngTemplateOutlet]="FormStep1" *ngIf="formStep == 1">
</ng-template>

<!--- Personal auto --->

<ng-template [ngTemplateOutlet]="FormStep2" *ngIf="formStep == 2"></ng-template>

<ng-template [ngTemplateOutlet]="FormStep3" *ngIf="formStep == 3"></ng-template>

<ng-template [ngTemplateOutlet]="FormStep4" *ngIf="formStep == 4"></ng-template>

<ng-template [ngTemplateOutlet]="FormStep5" *ngIf="formStep == 5"></ng-template>

<ng-template [ngTemplateOutlet]="FormStep6" *ngIf="formStep == 6"></ng-template>

<ng-template [ngTemplateOutlet]="FormStep7" *ngIf="formStep == 7"></ng-template>

<ng-template [ngTemplateOutlet]="FormStep8" *ngIf="formStep == 8"></ng-template>

<ng-template [ngTemplateOutlet]="FormStep9" *ngIf="formStep == 9"></ng-template>

<ng-template
  [ngTemplateOutlet]="FormStep10"
  *ngIf="formStep == 10"
></ng-template>

<ng-template
  [ngTemplateOutlet]="FormStep11"
  *ngIf="formStep == 11"
></ng-template>

<ng-template
  [ngTemplateOutlet]="FormStep12"
  *ngIf="formStep == 12"
></ng-template>

<ng-template
  [ngTemplateOutlet]="FormStep13"
  *ngIf="formStep == 13"
></ng-template>

<ng-template
  [ngTemplateOutlet]="FormStep14"
  *ngIf="formStep == 14"
></ng-template>

<!--- Home --->

<ng-template
  [ngTemplateOutlet]="FormHomeStep2"
  *ngIf="formHomeStep == 2"
></ng-template>

<ng-template
  [ngTemplateOutlet]="FormHomeStep3"
  *ngIf="formHomeStep == 3"
></ng-template>

<ng-template
  [ngTemplateOutlet]="FormHomeStep4"
  *ngIf="formHomeStep == 4"
></ng-template>

<ng-template
  [ngTemplateOutlet]="FormHomeStep5"
  *ngIf="formHomeStep == 5"
></ng-template>

<ng-template
  [ngTemplateOutlet]="FormHomeStep6"
  *ngIf="formHomeStep == 6"
></ng-template>

<ng-template
  [ngTemplateOutlet]="FormHomeStep7"
  *ngIf="formHomeStep == 7"
></ng-template>

<ng-template
  [ngTemplateOutlet]="FormHomeStep8"
  *ngIf="formHomeStep == 8"
></ng-template>

<ng-template
  [ngTemplateOutlet]="FormHomeStep9"
  *ngIf="formHomeStep == 9"
></ng-template>

<ng-template
  [ngTemplateOutlet]="FormHomeStep10"
  *ngIf="formHomeStep == 10"
></ng-template>

<ng-template
  [ngTemplateOutlet]="FormHomeStep11"
  *ngIf="formHomeStep == 11"
></ng-template>

<ng-template
  [ngTemplateOutlet]="FormHomeStep12"
  *ngIf="formHomeStep == 12"
></ng-template>

<ng-template
  [ngTemplateOutlet]="FormHomeStep13"
  *ngIf="formHomeStep == 13"
></ng-template>

<ng-template
  [ngTemplateOutlet]="FormHomeStep14"
  *ngIf="formHomeStep == 14"
></ng-template>

<ng-template
  [ngTemplateOutlet]="FormHomeStep15"
  *ngIf="formHomeStep == 15"
></ng-template>

<ng-template
  [ngTemplateOutlet]="FormHomeStep16"
  *ngIf="formHomeStep == 16"
></ng-template>

<ng-template class="step1" #FormStep1>
  <div class="step1">
    <div class="container" [formGroup]="form">
      <app-title [title]="'Choose type of your insurance'"></app-title>

      <div class="typeOfInsurance-Container">
        <div class="cardInsurance">
          <div class="imageCard">
            <img style="width: 80px; height: 95px;"

              src="../../../../../assets/images/icon-car-wash.png"
              alt=""
            />
          </div>
          <div class="descriptionCard">
            <p>Personal Auto</p>
          </div>
          <div class="inputCard" [formGroup]="form">
            <label class="styleLabel" for="personalAtoPolicy"></label>
            <input
              type="radio"
              name="typeOfInsurance"
              value="personalAutoPolicy"
              class="inputStyle"
              id="personalAtoPolicy"
              formControlName="typeOfInsurance"
            />
          </div>
        </div>

        <div class="cardInsurance">
          <div class="imageCard">
            <img style="width: 80px; height: 95px;" src="../../../../../assets/images/icon-home.png" alt="" />
          </div>
          <div class="descriptionCard">
            <p>Home</p>
          </div>
          <div class="inputCard" [formGroup]="form">
            <label class="styleLabel" for="home"></label>
            <input
              type="radio"
              name="typeOfInsurance"
              class="inputStyle"
              value="home"
              id="home"
              formControlName="typeOfInsurance"
            />
          </div>
        </div>

        <div class="cardInsurance">
          <div class="imageCard">
            <label class="styleLabel" for=""></label>
            <img style="width: 80px; height: 95px;"
            src="../../../../../assets/images/icon-car-wash.png"
              alt=""
            />
          </div>
          <div class="descriptionCard">
            <p>General Liability</p>
          </div>
          <div class="inputCard" [formGroup]="form">
            <label class="styleLabel" for="generalLiability"></label>
            <input
              type="radio"
              name="typeOfInsurance"
              class="inputStyle"
              value="generalLiability"
              id="generalLiability"
              formControlName="typeOfInsurance"
            />
          </div>
        </div>

        <div class="cardInsurance">
          <div class="imageCard">
            <label class="styleLabel" for=""></label>
            <img style="width: 80px; height: 95px;"
            src="../../../../../assets/images/icon-suitcase.png"
              alt=""
            />
          </div>
          <div class="descriptionCard">
            <p>Workers<br />Compensation</p>
          </div>
          <div class="inputCard" [formGroup]="form">
            <label class="styleLabel" for="workersCompensation"></label>
            <input
              type="radio"
              name="typeOfInsurance"
              class="inputStyle"
              value="workersCompensation"
              id="workersCompensation"
              formControlName="typeOfInsurance"
            />
          </div>
        </div>

        <div class="cardInsurance">
          <div class="imageCard">
            <img style="width: 80px; height: 95px;" src="../../../../../assets/images/icon-office-building.png" alt="" />
          </div>
          <div class="descriptionCard">
            <p>Renders</p>
          </div>
          <div class="inputCard" [formGroup]="form">
            <label class="styleLabel" for="Renders"></label>
            <input
              type="radio"
              name="typeOfInsurance"
              class="inputStyle"
              value="Renders"
              id="Renders"
              formControlName="typeOfInsurance"
            />
          </div>
        </div>

        <div class="cardInsurance">
          <div class="imageCard">
            <img style="width: 80px; height: 95px;"
            src="../../../../../assets/images/icon-car-wash.png"
              alt=""
            />
          </div>
          <div class="descriptionCard">
            <p>Commercial<br />auto</p>
          </div>
          <div class="inputCard" [formGroup]="form">
            <label class="styleLabel" for="commercialAuto">
              <input
                type="radio"
                name="typeOfInsurance"
                class="inputStyle"
                checked
                value="commercialAuto"
                id="commercialAuto"
                formControlName="typeOfInsurance"
              />
            </label>
          </div>
        </div>
      </div>

      <app-next-button [Next]="'Next'"></app-next-button>
    </div>
  </div>
</ng-template>

<ng-template class="step2" #FormStep2>
  <div class="step2">
    <div class="containerPlan">
      <div class="title">
        <h1>Choose the plan of insurance you prefer</h1>
      </div>

      <div class="boxCards">
        <div class="boxContainer">
          <div class="labelPlan" id="labelBasic">
            <span>Basic</span>
          </div>
          <div class="cardPlan" id="cardBasic">
            <div class="box_bodyInjury">
              <p>Bodily injury</p>
              <span>$50,000/$100,000</span>
            </div>
            <hr />
            <p>Property damage</p>
            <span>$100,00</span>

            <div class="choose_plan">
              <button class="btn_choose" id="basic" (click)="chooseP('basic')">
                Choose
              </button>
            </div>
          </div>
        </div>

        <div class="boxContainer">
          <div class="labelPlan" id="labelStandard">
            <span>Standard</span>
          </div>
          <div class="cardPlan" id="cardStandard">
            <div class="box_bodyInjury">
              <p>Bodily injury</p>
              <span>$100,000/$300,000</span>
            </div>
            <hr />
            <p>Property damage</p>
            <span>$100,00</span>

            <div class="choose_plan">
              <button
                class="btn_choose"
                (click)="chooseP('standard')"
                id="standard"
              >
                Choose
              </button>
            </div>
          </div>
        </div>

        <div class="boxContainer">
          <div class="labelPlan" id="labelPremium">
            <span>Premium</span>
          </div>

          <div class="cardPlan" id="cardPremium">
            <div class="box_bodyInjury">
              <p>Bodily injury</p>
              <span>$250,000/$500,000</span>
            </div>
            <hr />
            <p>Property damage</p>
            <span>$100,00</span>

            <div class="choose_plan">
              <button
                class="btn_choose"
                (click)="chooseP('premium')"
                id="premium"
              >
                Choose
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template class="step3" #FormStep3>
  <div class="step3">
    <div class="containerPersonalData" [formGroup]="form">
      <app-title [title]="'Please enter your personal data'"></app-title>

      <div class="formData">
        <div class="name">
          <div class="boxName">
            <app-input
              [label]="'First Name'"
              [typeInput]="'text'"
              [control]="'firstName'"
              #dados
            ></app-input>
          </div>

          <div class="boxName">
            <app-input
              [label]="'Last Name'"
              [typeInput]="'text'"
              [control]="'lastName'"
            ></app-input>
          </div>
        </div>
        <div class="emailBox">
          <app-input
            [label]="'E-mail'"
            [typeInput]="'email'"
            [control]="'email'"
          ></app-input>
        </div>
      </div>

      <app-next-button></app-next-button>
    </div>
    <!-- routerLink="../step-1" [disabled]="!form.valid" -->
  </div>
</ng-template>

<ng-template class="step4" #FormStep4>
  <div class="step4">
    <div class="container" [formGroup]="form">
      <app-title [title]="'Please enter your personal data'"></app-title>

      <div class="containerInputs">
        <div class="inputBox">
          <app-input-svg
            [svg]="'date'"
            [label]="'Date of birth'"
            [controlSvg]="'dateOfBirth'"
          ></app-input-svg>
        </div>

        <div class="inputBox">
          <app-input-svg
            [svg]="'phone'"
            [label]="'Phone'"
            [controlSvg]="'phone'"
          ></app-input-svg>
        </div>
      </div>

      <app-next-button [routerLink]="'../step-5'"></app-next-button>
    </div>
  </div>
</ng-template>

<ng-template class="step5" #FormStep5>
  <div class="step5">
    <div class="container" [formGroup]="form">
      <app-title [title]="'Please enter your personal data'"></app-title>

      <div class="dataInputs">
        <div class="dataBox" [formGroup]="form">
          <app-select
            [label]="'preferred Language'"
            [values]="[
              'English',
              'Portuguese - Português',
              'Spanish - Espanhol'
            ]"
            [controlSelect]="'preferredLanguage'"
          ></app-select>
        </div>

        <div class="dataBox" [formGroup]="form">
          <app-select
            [label]="'Gender'"
            [values]="['Male', 'Female', 'I prefer not to inform']"
            [controlSelect]="'gender'"
          ></app-select>
        </div>
      </div>

      <app-next-button [routerLink]="'../step-6'"></app-next-button>
    </div>
  </div>
</ng-template>

<ng-template class="step6" #FormStep6>
  <div class="step6">
    <div class="container" [formGroup]="form">
      <app-title [title]="'Please enter your personal data'"></app-title>

      <div class="containerForm" [formGroup]="form">
        <div class="boxSelect">
          <app-select
            [label]="'Marital status'"
            [values]="[
              'Single',
              'Married',
              'Divorced',
              'Domestic Partner',
              'Separated',
              'Widowed'
            ]"
            [controlSelect]="'marital'"
          ></app-select>
        </div>

        <div
          class="maritalStatusBox"
          *ngIf="
            this.form.value.marital == 'Married' ||
            this.form.value.marital == 'Domestic Partner'
          "
        >
          <div class="titleSpouse">
            <h4>Spouse Data</h4>
          </div>

          <div class="boxInputs">
            <div class="firstBox boxChild">
              <div class="inpt">
                <app-input
                  [label]="'First name'"
                  [typeInput]="'text'"
                  [control]="'spouse_firstName'"
                ></app-input>
              </div>
              <div class="inpt">
                <app-input
                  [label]="'Last name'"
                  [typeInput]="'text'"
                  [control]="'spouse_lastName'"
                ></app-input>
              </div>
            </div>
            <div class="secondBox boxChild">
              <div class="inpt">
                <app-input-svg
                  [svg]="'date'"
                  [label]="'Date of birth'"
                  [controlSvg]="'spouse_dateOfBirth'"
                ></app-input-svg>
              </div>
              <div class="inpt">
                <app-select
                  [label]="'Gender'"
                  [values]="['Male', 'Female', 'I prefer not to inform']"
                  [controlSelect]="'spouse_gender'"
                ></app-select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <app-next-button [routerLink]="'../step-7'"></app-next-button>
    </div>
  </div>
</ng-template>

<ng-template class="step7" #FormStep7>
  <div class="step7">
    <div class="containerPersonalData" [formGroup]="form">
      <app-title [title]="'Whats your home address?'"></app-title>

      <div class="formData">
        <div class="inpt">
          <div class="inptChild">
            <app-input
              [label]="'Address'"
              [typeInput]="'text'"
              [address]="true"
              [control]="'address'"
            ></app-input>
          </div>
          <div class="inptChildApt">
            <app-input
              [label]="'APT/UNIT'"
              [typeInput]="'text'"
              [control]="'aptunid'"
              [plchldr]="'APT/UNIT'"
            ></app-input>
          </div>
        </div>

        <app-next-button [routerLink]="'../step-8'"></app-next-button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template class="step8" #FormStep8>
  <div class="step8">
    <div class="containerPersonalData" [formGroup]="form">
      <app-title [title]="'Insurance Details'"></app-title>

      <div class="formData">
        <div class="inptBox">
          <div class="inptChild">
            <app-input-svg
              [svg]="'date'"
              [label]="'Policy start date'"
              [controlSvg]="'policyStartDate'"
            ></app-input-svg>
          </div>
          <div class="inptChild">
            <app-select
              [label]="'Currently insured?'"
              [values]="['Yes', 'No', '']"
              [controlSelect]="'currentlyInsured'"
            ></app-select>
          </div>
        </div>

        <div class="inptBox">
          <div class="inptChild">
            <app-select
              [label]="'Primary residence'"
              [values]="['Own', 'Rented', 'Living with parents']"
              [controlSelect]="'primaryResidence'"
            ></app-select>
          </div>
        </div>
      </div>

      <app-next-button [routerLink]="'../step-9'"></app-next-button>
    </div>
  </div>
</ng-template>

<ng-template class="step9" #FormStep9>
  <div class="step9">
    <div class="containerPersonalData" [formGroup]="formDrivers">
      <div>
        <app-title [title]="'Enter your driver data'"></app-title>

        <div class="formData">
          <div>
            <div class="btnAddNewDriver">
              <button class="newDriver">
                <div class="plus">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    width="24"
                  >
                    <path
                      d="M11.25 18.75v-6h-6v-1.5h6v-6h1.5v6h6v1.5h-6v6Z"
                      fill="#7686bf"
                    />
                  </svg>
                </div>
                New driver
              </button>

              <button class="listDriver" *ngIf="hasDriver" (click)="openList()">
                <div class="plus">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    width="24"
                  >
                    <path d="M3 18v-2h18v2Zm0-5v-2h18v2Zm0-5V6h18v2Z" />
                  </svg>
                </div>
                List driver
              </button>
            </div>

            <div class="boxInputs">
              <div class="boxInputChild">
                <div class="inptDriver">
                  <app-select
                    [label]="'Driver status'"
                    [values]="['Rated']"
                    [controlSelect]="'driverStatus'"
                    [Driver]="'driver'"
                  ></app-select>
                </div>
              </div>
              <div class="boxInputChild">
                <div class="inpt">
                  <app-input
                    [label]="'First name'"
                    [typeInput]="'text'"
                    [control]="'driverFirstname'"
                    [Driver]="'driver'"
                  ></app-input>
                </div>
                <div class="inpt">
                  <app-input
                    [label]="'Last name'"
                    [typeInput]="'text'"
                    [control]="'driverLastname'"
                    [Driver]="'driver'"
                  ></app-input>
                </div>
              </div>
              <div class="boxInputChild">
                <div class="inpt">
                  <app-input-svg
                    [label]="'Date of birth'"
                    [svg]="'date'"
                    [controlSvg]="'driverBirthdate'"
                    [Driver]="'driver'"
                  ></app-input-svg>
                </div>
                <div class="inpt">
                  <app-select
                    [label]="'Gender'"
                    [values]="['Male', 'Female', 'I prefer not to him']"
                    [controlSelect]="'driverGender'"
                    [Driver]="'driver'"
                  ></app-select>
                </div>
              </div>
              <div class="boxInputChild">
                <div class="inpt">
                  <app-select
                    [label]="'License state'"
                    [values]="[
                      'Foreign/International',
                      'Alabama',
                      'Alaska',
                      'Arizona',
                      'Arkansas',
                      'California',
                      'Colorado',
                      'Connecticut',
                      'Delaware',
                      'Florida',
                      'Georgia',
                      'Hawaii',
                      'Idaho',
                      'Illinois',
                      'Indiana',
                      'Iowa',
                      'Kansas',
                      'Kentucky',
                      'Louisiana',
                      'Maine',
                      'Maryland',
                      'Massachusetts',
                      'Michigan',
                      'Minnesota',
                      'Mississippi',
                      'Missouri',
                      'Montana',
                      'Nebraska',
                      'Nevada',
                      'New Hampshire',
                      'New Jersey',
                      'New Mexico',
                      'New York',
                      'North California',
                      'Nrth Carolina',
                      'North Dakota',
                      'Ohio',
                      'Oklahoma',
                      'Oregon',
                      'Pennsylvania',
                      'Rhode Island',
                      'South Carolina',
                      'South Dakota',
                      'Tenessee',
                      'Texas',
                      'Utah',
                      'Vermont',
                      'Virginia',
                      'Washington',
                      'West Virginia',
                      'Wisconsin',
                      'Wyoming'
                    ]"
                    [controlSelect]="'driverLicenseState'"
                    [Driver]="'driver'"
                  ></app-select>
                </div>
                <div class="inpt">


                  <div class="boxInput">
                    <label> License number</label>
                    <input
                      type="text"
                      [ngClass]="{ 'is-invalid': validaErro }"
                      id="driverLicenseNumberInput"
                      formControlName="driverLicenseNumber"
                      maxlength="25"
                      #licenseNumber
                    />
                  </div>

                  <div class="contador">
                    <p>{{ licenseNumber.value.length }}/25</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <app-next-button [Next]="'Save'"></app-next-button>
      </div>
    </div>
    <!-- routerLink="../step-1" [disabled]="!form.valid" -->
  </div>
</ng-template>

<ng-template class="step10" #FormStep10>
  <div class="step10">
    <div class="containerPersonalData">
      <app-title [title]="'Enter your driver data'"></app-title>

      <div class="formData">
        <div class="btnAddNewDriver">
          <button class="newDriver" (click)="openList()">
            <div class="plus">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
                <path
                  d="M11.25 18.75v-6h-6v-1.5h6v-6h1.5v6h6v1.5h-6v6Z"
                  fill="#7686bf"
                />
              </svg>
            </div>
            New driver
          </button>

          <button class="ListDriver">
            <div class="plus">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
                <path d="M3 18v-2h18v2Zm0-5v-2h18v2Zm0-5V6h18v2Z" />
              </svg>
            </div>
            List driver
          </button>
        </div>

        <div class="table">
          <div
            class="alert alert-primary mt-3"
            *ngIf="
              driverList !== null &&
              driverList !== undefined &&
              driverList.length == 0
            "
          >
            <p class="mb-0 font-size-16">No drivers found.</p>
          </div>

          <div class="table-responsive">
            <table
              class="table quotes-list-table table-nowrap align-middle table-wborder font-size-18"
            >
              <thead>
                <tr>
                  <!-- <th class="text-center" scope="col">ID</th> -->
                  <th class="text-left" scope="col">Name</th>
                  <th class="text-center" scope="col">Date of birth</th>
                  <th class="text-center" scope="col">Gender</th>
                  <th class="text-center" scope="col">License number</th>
                  <th class="text-center" scope="col">License state</th>
                  <th class="text-center" scope="col">Driver status</th>
                  <th class="text-right" scope="col">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let driver of driverList; let indexDriver = index">
                  <!-- <td class="text-center">#{{ driver.dri_id }}</td> -->
                  <td class="text-left">
                    {{ driver.firstName }} {{ driver.lastName }}
                  </td>
                  <td class="text-center">{{ driver.dateOfBirth }}</td>
                  <td class="text-center">{{ driver.gender }}</td>
                  <td class="text-center">
                    {{
                      driver.licenseNumber.length > 0
                        ? driver.licenseNumber
                        : "--"
                    }}
                  </td>
                  <td class="text-center">
                    {{
                      driver.licenseState.length > 0
                        ? driver.licenseState
                        : "--"
                    }}
                  </td>
                  <td class="text-center">{{ driver.status }}</td>
                  <td class="text-right">
                    <img
                      class="icon-list-img"
                      src="../../../../../assets/images/icon-edit-purple.png"
                      alt=""
                      title="Edit"
                      (click)="editDriver(indexDriver)"
                      width="20px"
                    />
                    &nbsp;
                    <img
                      class="icon-list-img"
                      src="../../../../../assets/images/icon-trash-red.png"
                      alt=""
                      title="Remove"
                      (click)="removeDriver(indexDriver)"
                      width="20px"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <app-next-button></app-next-button>
    </div>
  </div>
</ng-template>

<ng-template #FormStep11>
  <div class="step11">
    <div class="containerPersonalData" [formGroup]="formVehicle">
      <div>
        <app-title [title]="'Enter vehicle data'"></app-title>

        <div class="formData">
          <div>
            <div class="btnAddNewDriver">
              <button class="newDriver" (click)="openList()">
                <div class="plus">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    width="24"
                  >
                    <path
                      d="M11.25 18.75v-6h-6v-1.5h6v-6h1.5v6h6v1.5h-6v6Z"
                      fill="#7686bf"
                    />
                  </svg>
                </div>
                New vehicle
              </button>

              <button
                class="listDriver"
                *ngIf="hasVehicle"
                (click)="openList()"
              >
                <div class="plus">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    width="24"
                  >
                    <path d="M3 18v-2h18v2Zm0-5v-2h18v2Zm0-5V6h18v2Z" />
                  </svg>
                </div>
                List vehicles
              </button>
            </div>

            <div class="boxInputs">
              <div class="boxInputChild">
                <div class="inpt">
                  <!-- <app-input
                                [label]="'VIN'"
                                [typeInput]="'text'"
                                [control]="'vin'"
                                [Driver]="'vehicle'"
                                #input
                                ></app-input> -->

                  <div class="boxInput">
                    <label> VIN</label>
                    <input
                      type="text"
                      [ngClass]="{ 'is-invalid': validaErro }"
                      id="vinInput"
                      formControlName="vin"
                      maxlength="17"
                      #input
                    />
                  </div>

                  <div class="contador">
                    <p>{{ input.value.length }}/17</p>
                  </div>
                </div>
                <div class="inpt">
                  <app-select
                    [label]="'Main use'"
                    [values]="['Pleasure', 'Business', 'Unknow']"
                    [controlSelect]="'mainUse'"
                    [Driver]="'vehicle'"
                  ></app-select>
                </div>
              </div>
              <div class="boxInputChild">
                <div class="inpt">
                  <app-select
                    [label]="'Driver'"
                    [values]="drivers"
                    [controlSelect]="'driver'"
                    [Driver]="'vehicle'"
                  ></app-select>
                </div>
                <div class="inpt">
                  <app-input
                    [label]="'Annual miles'"
                    [typeInput]="'text'"
                    [control]="'annualMiles'"
                    [Driver]="'vehicle'"
                  ></app-input>
                </div>
              </div>
              <div class="boxInputChild">
                <div class="inpt">
                  <app-select
                    [label]="'Collision deductible'"
                    [values]="['NONE', '300', '500', '1000', '2500']"
                    [controlSelect]="'collisionDeductible'"
                    [Driver]="'vehicle'"
                  ></app-select>
                </div>
                <div class="inpt">
                  <app-select
                    [label]="'Comprehensive deductible'"
                    [values]="['NONE', '300', '500', '1000', '2500']"
                    [controlSelect]="'comprehensiveDeductible'"
                    [Driver]="'vehicle'"
                  ></app-select>
                </div>
              </div>
              <div class="boxForm">
                <div class="formCheck">
                  <input
                    type="checkbox"
                    name="formCheck"
                    id="substituteTransportation"
                    value="Substitute transportation"
                    formControlName="substituteTransportation"
                  />
                  <label for="substituteTransportation"
                    >Substitute transportation</label
                  >
                </div>

                <div class="formCheck">
                  <input
                    type="checkbox"
                    name="formCheck"
                    id="towingAndLabor"
                    value="Substitute Towing and labor"
                    formControlName="towingAndLabor"
                  />
                  <label for="towingAndLabor"
                    >Substitute Towing and labor</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <app-next-button [Next]="'SaveVehicle'"></app-next-button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #FormStep12>
  <div class="step12">
    <div class="containerPersonalData">
      <app-title [title]="'Enter vehicle data'"></app-title>

      <div class="formData">
        <div class="btnAddNewDriver">
          <button class="newDriver" (click)="openList()" >
            <div class="plus">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
                <path
                  d="M11.25 18.75v-6h-6v-1.5h6v-6h1.5v6h6v1.5h-6v6Z"
                  fill="#7686bf"
                />
              </svg>
            </div>
            New vehicle
          </button>

          <button class="ListDriver">
            <div class="plus">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
                <path d="M3 18v-2h18v2Zm0-5v-2h18v2Zm0-5V6h18v2Z" />
              </svg>
            </div>
            List vehicles
          </button>
        </div>

        <div class="table">
          <div
            class="alert alert-primary mt-3"
            *ngIf="
              driverList !== null &&
              driverList !== undefined &&
              driverList.length == 0
            "
          >
            <p class="mb-0 font-size-16">No vehicles found.</p>
          </div>

          <div class="table-responsive">
            <table
              class="table quotes-list-table table-nowrap align-middle table-wborder font-size-18"
            >
              <thead>
                <tr>
                  <!-- <th class="text-center" scope="col">ID</th> -->
                  <th class="text-left" scope="col">VIN</th>
                  <th class="text-center" scope="col">Annual miles</th>
                  <th class="text-center" scope="col">Driver</th>
                  <th class="text-center" scope="col">Primary driver</th>
                  <!-- <th class="text-center" scope="col">License state</th>
                                            <th class="text-center" scope="col">Driver status</th>
                                            <th class="text-right" scope="col">&nbsp;</th> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let vehicle of vehiclesList; let indexVehicle = index"
                >
                  <!-- <td class="text-center">#{{ driver.dri_id }}</td> -->
                  <td class="text-left">{{ vehicle.vin }}</td>
                  <td class="text-center">{{ vehicle.annualMiles }}</td>
                  <td class="text-center">{{ vehicle.driver }}</td>
                  <td class="text-center">{{ vehicle.driver }}</td>
                  <td class="text-right">
                    <img
                      class="icon-list-img"
                      src="../../../../../assets/images/icon-edit-purple.png"
                      alt=""
                      title="Edit"
                      (click)="editDriver(indexVehicle)"
                      width="20px"
                    />
                    &nbsp;
                    <img
                      class="icon-list-img"
                      src="../../../../../assets/images/icon-trash-red.png"
                      alt=""
                      title="Remove"
                      (click)="removeDriver(indexVehicle)"
                      width="20px"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <app-next-button></app-next-button>
    </div>
  </div>
</ng-template>

<ng-template #FormStep13>
  <div class="step13">
    <div class="container">
      <app-title [title]="'Uploads'"></app-title>

      <div class="btn_upload">
        <input
          type="file"
          #upload
          style="display: none"
          (change)="onFileSelected($event)"
          class="input-hidden"
        />
        <button (click)="OpenUpload(upload)">
          <img src="../../../../../assets/images/icon-upload-yellow.png"  style="width: 24px;height: 24px;" alt="">
          <span>Upload</span></button>
      </div>

      <div class="description">
        <span>Allowed formats are PNG, JPEG, JPG, GIF and PDF.</span>
      </div>

      <div class="table">
        <div
          class="alert alert-primary mt-3"
          *ngIf="files !== null && files !== undefined && files.length == 0"
        >
          <p class="mb-0 font-size-16">No files uploaded.</p>
        </div>

        <div class="table-responsive">
          <table
            class="table quotes-list-table table-nowrap align-middle table-wborder font-size-18"
          >
            <thead>
              <tr>
                <!-- <th class="text-center" scope="col">ID</th> -->
                <th class="text-left" scope="col" style="border-left: 1px solid #f0f0f0;">Name</th>
                <th class="text-center" scope="col" >Type</th>
                <th class="text-center" scope="col"
               >Size</th>
               <th class="text-center" scope="col"
               style="border-right: 1px solid #f0f0f0;"></th>
               
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let file of files; let indexFiles = index">
                <!-- <td class="text-center">#{{ driver.dri_id }}</td> -->
                <td style="border-left: 1px solid #f0f0f0" class="text-left">{{ file.name }}</td>
                <td class="text-center">{{ file.type }}</td>
                <td class="text-center">{{ file.size }}</td>
                <td class="text-right" style="border-right: 1px solid #f0f0f0">
                  <img
                    class="icon-list-img"
                    src="../../../../../assets/images/icon-trash-red.png"
                    alt=""
                    title="Remove"
                    (click)="removeDriver(indexVehicle)"
                    width="20px"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <app-next-button [Next]="'Next'"></app-next-button>
    </div>
  </div>
</ng-template>

<ng-template #FormStep14>
  <div class="step14">
    <div class="title">
      <app-title [title]="'Submit Quote'"></app-title>
    </div>

    
    <div class="containerQuote">
      <div class="boxCard">
        <div class="cardPap">
          <div class="imgCard">
            <div class="circleDiv">
              <img
                src="../../../../../assets/images/Personal Auto policy.png"
                alt="Personal Auto policy"
              />
            </div>
          </div>

          <div class="descriptionCard">
            <div class="col-lg-9">
              <div class="submit-quote-card-subtitle">Personal auto</div>
              <div class="submit-quote-card-title">{{ form.controls.planInsurance.value}}</div>
              <div class="row mb-2">
                <div class="col-sm-6">
                  <div class="submit-quote-card-th">Bodily injury</div>
                </div>
                <div class="col-sm-6">
                  <div class="text-right text-left-xs submit-quote-card-td">
                    $100,000/$300,000
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="submit-quote-card-th">Property damage</div>
                </div>
                <div class="col-sm-6">
                  <div class="text-right text-left-xs submit-quote-card-td">
                    $100,00
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card-quote-table">
      <h3 class="card-quote-table-title">Personal information</h3>

      <div
        class="row row-cols-1 row-cols-sm-2 row-cols-md-4 row-cols-lg-5 row-cols-xl-5"
      >
        <div class="col">
          <div class="quote-table-head">First name</div>
          <div class="quote-table-body">
            {{ form.controls.firstName.value }}
          </div>
        </div>
        <div class="col">
          <div class="quote-table-head">Last name</div>
          <div class="quote-table-body">{{ form.controls.lastName.value }}</div>
        </div>
        <div class="col">
          <div class="quote-table-head">Date of birth</div>
          <div class="quote-table-body">
            {{ form.controls.dateOfBirth.value }}
          </div>
        </div>
        <div class="col">
          <div class="quote-table-head">Phone</div>
          <div class="quote-table-body">{{ form.controls.phone.value }}</div>
        </div>
        <div class="col">
          <div class="quote-table-head">Gender</div>
          <div class="quote-table-body">{{ form.controls.gender.value }}</div>
        </div>
        <div class="col">
          <div class="quote-table-head">Email</div>
          <div class="quote-table-body text-truncate">
            {{ form.controls.email.value }}
          </div>
        </div>
        <div class="col">
          <div class="quote-table-head">Marital status</div>
          <div class="quote-table-body">{{ form.controls.marital.value }}</div>
        </div>
      </div>
    </div>

    <div class="card-quote-table">
      <h3 class="card-quote-table-title">Spouse Data</h3>

      <div
        class="row row-cols-1 row-cols-sm-2 row-cols-md-4 row-cols-lg-5 row-cols-xl-5"
      >
        <div class="col">
          <div class="quote-table-head">First name</div>
          <div class="quote-table-body">
            {{ form.controls.spouse_firstName.value }}
          </div>
        </div>
        <div class="col">
          <div class="quote-table-head">Last name</div>
          <div class="quote-table-body">
            {{ form.controls.spouse_lastName.value }}
          </div>
        </div>
        <div class="col">
          <div class="quote-table-head">Date of birth</div>
          <div class="quote-table-body">
            {{ form.controls.spouse_dateOfBirth.value }}
          </div>
        </div>
        <div class="col">
          <div class="quote-table-head">Gender</div>
          <div class="quote-table-body">
            {{ form.controls.spouse_gender.value }}
          </div>
        </div>
      </div>
    </div>

    <div class="card-quote-table">
      <h3 class="card-quote-table-title">Address</h3>

      <div
        class="row row-cols-1 row-cols-sm-2 row-cols-md-4 row-cols-lg-5 row-cols-xl-5"
      >
        <div class="col">
          <div class="quote-table-head">Address</div>
          <div class="quote-table-body">{{ form.controls.address.value }}</div>
        </div>
        <div class="col">
          <div class="quote-table-head">Address line 2</div>
          <div class="quote-table-body">
            {{ form.controls.aptunid.value }}
          </div>
        </div>
        <div class="col">
          <div class="quote-table-head">City</div>
          <div class="quote-table-body">
            {{ localData.address.city }}
          </div>
        </div>
        <div class="col">
          <div class="quote-table-head">State</div>
          <div class="quote-table-body">
            {{ localData.address.state }}
          </div>
        </div>
        <div class="col">
          <div class="quote-table-head">Zip code</div>
          <div class="quote-table-body">
            {{ localData.address.zip_code }}
          </div>
        </div>
      </div>
    </div>

    <div class="card-quote-table">
      <h3 class="card-quote-table-title">Policy details</h3>

      <div
        class="row row-cols-1 row-cols-sm-2 row-cols-md-4 row-cols-lg-5 row-cols-xl-5"
      >
        <div class="col">
          <div class="quote-table-head">Policy start date</div>
          <div class="quote-table-body">
            {{ form.controls.policyStartDate.value }}
          </div>
        </div>
        <div class="col">
          <div class="quote-table-head">Currently insured</div>
          <div class="quote-table-body">
            {{ form.controls.currentlyInsured.value }}
          </div>
        </div>
        <div class="col">
          <div class="quote-table-head">Primary residence</div>
          <div class="quote-table-body">
            {{ localData.primaryResidence }}
          </div>
        </div>
        <div class="col">
          <div class="quote-table-head">Coverage selection</div>
          <div class="quote-table-body">
            {{ form.controls.planInsurance.value }}
          </div>
        </div>
        <div class="col">
          <div class="quote-table-head">Preferred language</div>
          <div class="quote-table-body">
            {{ form.controls.preferredLanguage.value }}
          </div>
        </div>
      </div>
    </div>

    <div class="card-quote-table">
      <h3 class="card-quote-table-title">Drivers</h3>

      <ng-container
        *ngFor="let driver of driverList; let indexDriver = index"
      >
        <hr *ngIf="indexDriver > 0" />
        <div
          class="row row-cols-1 row-cols-sm-2 row-cols-md-4 row-cols-lg-5 row-cols-xl-5"
        >
          <div class="col">
            <div class="quote-table-head">First name</div>
            <div class="quote-table-body">{{ driver.firstName }}</div>
          </div>
          <div class="col">
            <div class="quote-table-head">Last name</div>
            <div class="quote-table-body">{{ driver.lastName }}</div>
          </div>
          <div class="col">
            <div class="quote-table-head">Gender</div>
            <div class="quote-table-body">{{ driver.gender }}</div>
          </div>
          <div class="col">
            <div class="quote-table-head">Date of birth</div>
            <div class="quote-table-body">{{ driver.dateOfBirth }}</div>
          </div>
          <div class="col">
            <div class="quote-table-head">License state</div>
            <div class="quote-table-body">
              {{
                driver.licenseState.length > 0
                  ? driver.licenseState
                  : "--"
              }}
            </div>
          </div>
          <div class="col">
            <div class="quote-table-head">License number</div>
            <div class="quote-table-body">
              <span class="text-break-all">{{
                driver.licenseNumber.length > 0
                  ? driver.licenseNumber
                  : "--"
              }}</span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="card-quote-table">
      <h3 class="card-quote-table-title">Vehicles</h3>

      <ng-container
        *ngFor="
          let vehicle of vehiclesList;
          let indexVehicle = index
        "
      >
        <hr *ngIf="indexVehicle > 0" />
        <div
          class="row row-cols-1 row-cols-sm-2 row-cols-md-4 row-cols-lg-5 row-cols-xl-5"
        >
          <div class="col">
            <div class="quote-table-head">VIN</div>
            <div class="quote-table-body">{{ vehicle.vin }}</div>
          </div>
          <div class="col">
            <div class="quote-table-head">Annual miles</div>
            <div class="quote-table-body">{{ vehicle.annualMiles }}</div>
          </div>
          <div class="col">
            <div class="quote-table-head">Driver</div>
            <div class="quote-table-body">
              {{ vehicle.driver }}
            </div>
          </div>
          <div class="col">
            <div class="quote-table-head">Primary driver</div>
            <div class="quote-table-body">{{ vehicle.mainUse }}</div>
          </div>
          <div class="col">
            <div class="quote-table-head">Collision deductible</div>
            <div class="quote-table-body">
              {{ vehicle.collisionDeductible }}
            </div>
          </div>
          <div class="col">
            <div class="quote-table-head">Comprehensive deductible</div>
            <div class="quote-table-body">
              {{ vehicle.comprehensiveDeductible }}
            </div>
          </div>
          <div class="col">
            <div class="quote-table-head">Is transportation</div>
            <div class="quote-table-body">
              {{ vehicle.substituteTransportation }}
            </div>
          </div>
          <div class="col">
            <div class="quote-table-head">Is towing</div>
            <div class="quote-table-body">{{ vehicle.towingAndLabor }}</div>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="card-quote-table">
      <h3 class="card-quote-table-title">Uploads</h3>

      <div class="font-size-16" *ngIf="files.length == 0">
        No files uploaded.
      </div>

      <div *ngIf="files.length > 0">
        <ng-container
        *ngFor="let upload of files; let indexFiles = index"
        >
          <hr *ngIf="indexUploads > 0" />
          <div
            class="row row-cols-1 row-cols-sm-2 row-cols-md-4 row-cols-lg-3 row-cols-xl-3"
          >
            <div class="col" title="{{ upload.name}}">
              <div class="quote-table-head">Name</div>
              <div class="quote-table-body">
                <div class="text-truncate" style="width: 210px">
                  {{ upload.name }}
                </div>
              </div>
            </div>
            <div class="col">
              <div class="quote-table-head">Type</div>
              <div class="quote-table-body">{{ upload.type }}</div>
            </div>
            <div class="col">
              <div class="quote-table-head">Size</div>
              <div class="quote-table-body">{{ upload.size }}</div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    
    <div class="btn_container" >
      <button class="btnNext" (click)="submit()" >Submit Quote</button>
  </div>
  
  </div
></ng-template>
