import { BlipServiceService } from './../../blip/blip-service.service';
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
// import {
//   Individual,
//   LogsQueue,
// } from '../../../page/quotes-sent/model/sent-quotes';
import { FormControl, FormGroup } from '@angular/forms';
const date = new Date(); // Ou use a variável oneDayAgo do exemplo anterior

const day = date.getDate();
const month = date.getMonth() + 1; // Lembre-se de que os meses em JavaScript são baseados em zero, então somamos 1.
const year = date.getFullYear();

// Agora, formate a data no estilo dd/mm/yyyy.
const formattedDate = `${day.toString().padStart(2, '0')}/${month
  .toString()
  .padStart(2, '0')}/${year}`;

@Component({
  selector: 'app-modal-logs',
  templateUrl: './modal-logs.component.html',
  styleUrls: ['./modal-logs.component.scss'],
})
export class ModalLogsComponent {
  currentPagitanion: number = 0;

  constructor(
    public dialogRef: MatDialogRef<ModalLogsComponent>,
    public BlipServiceService: BlipServiceService
  ) {}

  displayedColumns: string[] = [
    'hora',
    'idioma',
    'nome_cliente',
    'sequencial',
    'status',
  ];

  displayColumnsLogs = [
    'email',
    'openedTickets',
    'totalTickets',
    'waitingTickets',
    'camada',
    'status',
  ];

  logs: {
    camada: any;
    email: any;
    estado: any;
    fila: string;
    hora: string;
    id: string;
    idioma: string;
    idiomas: any;
    nome: any;
    nome_cliente: string,
    sequencial: number,
    status: string,
    ticket_id: string
  }[] = [];
  individualLog: {
    email: string,
    openedTickets: number,
    totalTickets: number,
    waitingTickets:number,
    camada: number,
    status: string
  }[] = [];
  painelAberto = false;
  selectedRow: any;
  lines: any = [];

  today = new Date();
  oneDayAgo = new Date(this.today);

  initialDate = `${this.today.getFullYear()}-${(this.today.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${(this.today.getDate() - 1)
    .toString()
    .padStart(2, '0')}`;

  finalDate = `${this.today.getFullYear()}-${(this.today.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${this.today.getDate().toString().padStart(2, '0')}`;

  ngOnInit(): void {
    this.pagination(0, {
      startValue: this.initialDate,
      endValue: this.finalDate,
    });
    this.oneDayAgo.setDate(this.today.getDate() - 1);
  }

  dateChanged(e) {
    const startValue = this.period.get('start').value;
    const endValue = this.period.get('end').value;

    const startValueFormated = `${startValue.getFullYear()}-${(
      startValue.getMonth() + 1
    )
      .toString()
      .padStart(2, '0')}-${startValue.getDate().toString().padStart(2, '0')}`;

    const endValueFormated = `${endValue.getFullYear()}-${(
      endValue.getMonth() + 1
    )
      .toString()
      .padStart(2, '0')}-${endValue.getDate().toString().padStart(2, '0')}`;

      this.initialDate = startValueFormated;
      this.finalDate = endValueFormated;

    this.pagination(0, {
      startValue:  this.initialDate,
      endValue: this.finalDate ,
    });
  }

  period = new FormGroup({
    start: new FormControl(this.oneDayAgo),
    end: new FormControl(this.today),
  });

  pagination(pag, date?) {
    document.querySelector('app-modal-logs').scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    this.currentPagitanion = this.currentPagitanion + pag;
    let pagString = this.currentPagitanion.toString();

    this.BlipServiceService.getLogs(pagString, date||{
      startValue:  this.initialDate,
      endValue: this.finalDate ,
    }).subscribe(
      (data: any) => {
        this.logs = data.dados;
      }
    );
  }


  toggleExpansion(element: any): void {
    element.expanded = !element.expanded;
  }

  toggleInfo() {
    this.painelAberto = !this.painelAberto;
  }

  parseJSON(jsonString: string): any {
    try {
      let dados = JSON.parse(jsonString);
      var email = Object.keys(dados.email).map((e) => ({
        id: e,
        email: dados.email[e],
      }));

      var openedTickets = Object.keys(dados.openedTickets).map((e) => ({
        id: e,
        openedTickets: dados.openedTickets[e],
      }));

      var waitingTickets = Object.keys(dados.waitingTickets).map((e) => ({
        id: e,
        waitingTickets: dados.waitingTickets[e],
      }));

      var totalTickets = Object.keys(dados.totalTickets).map((e) => ({
        id: e,
        totalTickets: dados.totalTickets[e],
      }));

      var camada = Object.keys(dados.camada).map((e) => ({
        id: e,
        camada: dados.camada[e],
      }));

      var status = Object.keys(dados.status).map((e) => ({
        id: e,
        status: dados.status[e],
      }));

      const emailArray = email;
      const openedTicketsArray = openedTickets;
      const waitingTicketsArray = waitingTickets;
      const totalTicketsArray = totalTickets;
      const camadaArray = camada;
      const statusArray = status;

      var formatData = {
        email: emailArray,
        openedTickets: openedTicketsArray,
        waitingTickets: waitingTicketsArray,
        totalTickets: totalTicketsArray,
        camada: camadaArray,
        status: statusArray,
      };

      return formatData;
    } catch (error) {
      return null;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
