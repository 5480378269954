<mat-card class="card-admin">

  <mat-card-header class="header-quote">

    <i class="far fa-chart-bar fa-lg" style="margin-left: -20px; margin-top: 14px;"></i>

    <div class="ml-28">
      <mat-card-title class="title-dashboard">Carrier Target</mat-card-title>
     <mat-card-subtitle>Target for a carrier</mat-card-subtitle>
    </div>

  </mat-card-header>

  <mat-card-content style="margin-top: 24px;">

  <app-brz-carrier-filter ></app-brz-carrier-filter>

 <div class="boxChart" style="margin: 150px 0px;">
  <app-chart-carrier ></app-chart-carrier>
 </div>
</mat-card-content>

</mat-card>
