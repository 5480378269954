import { Component, Input, OnInit } from '@angular/core';
import { Cap } from 'src/app/core/user/model/caps';

@Component({
  selector: 'app-nav-item-admin',
  templateUrl: './nav-item-admin.component.html',
  styleUrls: ['./nav-item-admin.component.scss']
})
export class NavItemAdminComponent implements OnInit {

  @Input() cap: Cap;

  constructor() { }

  ngOnInit(): void {
  }

  checkSignatureCap(): boolean {
    return this.cap.hasOwnProperty('brz_menu_email_signature');
  }

  checkSheetsCap(): boolean {
    return this.cap.hasOwnProperty('brz_menu_googlesheets');
  }

  checkPandaCap(): boolean {
    return this.cap.hasOwnProperty('brz_menu_pandadoc');
  }

  checkHubspotCap(): boolean {
    return this.cap.hasOwnProperty('brz_menu_hubspot');
  }

  checkSalesTarget(): boolean {
    return this.cap.hasOwnProperty('brz_menu_sales_target');
  }

  checkCarrierTarget():boolean{
    // console.log(this.cap.hasOwnProperty('brz_menu_carrier_target'))
    return this.cap.hasOwnProperty('brz_menu_carrier_target')
  }

  checkAdminMenu(): boolean {
    return !this.checkSignatureCap() &&
      !this.checkSheetsCap() &&
      !this.checkPandaCap() &&
      !this.checkHubspotCap() &&
      !this.checkSalesTarget()
  }

}
