<mat-toolbar class="toolbar">

  <div class="flex">
    <button mat-icon-button (click)="snav.toggle()">
      <mat-icon class="menu">menu</mat-icon>
    </button>
    <img (click)="goHome()" class="logo" src="../../../../assets/images/breezy-horizontal-svg.svg">
  </div>
  <div class="flex"> 

    

    <a class="button buttonWhite buttonSmall" routerLink="/home/my-profile/user-profile" style="margin-right: 2em;">
      <div class="flex-center">
        <div class="avatar"></div>
        <div class="username">{{getUserName().toUpperCase()}}</div>
      </div>
    </a>

    <div>
      <div>
        <i class="far fa-bell" (click)="openNotifications()" matBadge="{{notify}}" matBadgeColor="warn"></i>
      </div>


    </div>

    <button (click)="logout()" mat-icon-button color="warn" matTooltip="Logout" matTooltipPosition="right">
      <mat-icon>first_page</mat-icon>
    </button>

  </div>

</mat-toolbar>
<mat-sidenav-container class="sidenav-container" [hasBackdrop]="hasBackdrop" (backdropClick)="backDropEvent()">
  <mat-sidenav [disableClose]="disableClose" [mode]="mode" #snav style="background-color:#2f3337;margin-top:60px !important;position:fixed;" opened>
    <mat-nav-list >

      <app-nav-item-dashboard [cap]="getCaps()"></app-nav-item-dashboard>
      <app-nav-item-quote [cap]="getCaps()"></app-nav-item-quote>
      <app-nav-item-sms [cap]="getCaps()"></app-nav-item-sms>
      <app-nav-item-daily-cash [cap]="getCaps()"></app-nav-item-daily-cash>
      <app-nav-item-admin [cap]="getCaps()"></app-nav-item-admin>
      <app-nav-item-collaborators [cap]="getCaps()"></app-nav-item-collaborators>
      <app-nav-item-communication [cap]="getCaps()"></app-nav-item-communication>

    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content style="min-height: 94vh;">
    <router-outlet></router-outlet>
  </mat-sidenav-content>

  <mat-sidenav #snavNotification closed mode="over" position="end">
    <app-nav-notifications (get-unread)="getUnreadNotifications($event)"></app-nav-notifications>
  </mat-sidenav>
</mat-sidenav-container>
