<div class="acordeon">
  <div class="head-table">
    <h1 class="title-dashboard">Logs</h1>

    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill="red" style="cursor: pointer;" (click)="onNoClick()"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
  </div>
  
  <div class="head-table">
    <form >
    <mat-form-field class="example-form-field">
      <mat-label>Period</mat-label>
      <mat-date-range-input
        [formGroup]="period"
        [rangePicker]="RangeDate"
        [comparisonStart]="period.value.start"
        [comparisonEnd]="period.value.end">
        <input matStartDate placeholder="Start date" formControlName="start" >
        <input matEndDate placeholder="End date" formControlName="end" >
      </mat-date-range-input>
      
      <mat-datepicker-toggle matIconSuffix [for]="RangeDate"></mat-datepicker-toggle>
      <mat-date-range-picker #RangeDate></mat-date-range-picker>
    </mat-form-field>
    <button mat-mini-fab color="primary" aria-label="Search Period" style="margin-left: 20px;" (click)="dateChanged($event)">
      <mat-icon>search</mat-icon>
    </button>
  </form>
  </div>
    <mat-accordion displayMode="flat" multi class="mat-table">
      <section matSort class="mat-elevation-z2 mat-header-row">
        <span class="mat-header-cell" mat-sort-header="vesselName">Hora</span>
        <span class="mat-header-cell" mat-sort-header="vesselId">Idioma</span>
        <span class="mat-header-cell" mat-sort-header="vesselStatus"
          >Nome do Cliente</span
        >
        <span class="mat-header-cell" mat-sort-header="ircs">Sequencial</span>
        <span class="mat-header-cell" mat-sort-header="ircs">Status</span>
      </section>
  

      <mat-expansion-panel *ngFor="let Log of logs">
        <mat-expansion-panel-header class="mat-row">
          <span class="mat-cell">{{ Log.hora | date: 'M/d/yy, h:mm a' }}</span>
          <span class="mat-cell">{{ Log.idioma }}</span>
          <span class="mat-cell">{{ Log.nome_cliente }}</span>
          <span class="mat-cell">{{ Log.sequencial }}</span>
          <span class="mat-cell">{{ Log.status }}</span>
        </mat-expansion-panel-header>

        
        <table mat-table [dataSource]="Log.estado" class="table-logs">

          <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->
        
          <!-- Position Column -->
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> Email </th>
            <td mat-cell *matCellDef="let element"> {{element.email}}</td>
          </ng-container>
        
          <!-- Name Column -->
          <ng-container matColumnDef="openedTickets">
            <th mat-header-cell *matHeaderCellDef> Opened Tickets </th>
            <td mat-cell *matCellDef="let element"> {{element.openedTickets}} </td>
          </ng-container>
        
          <!-- Weight Column -->
          <ng-container matColumnDef="totalTickets">
            <th mat-header-cell *matHeaderCellDef> Total Tickets </th>
            <td mat-cell *matCellDef="let element"> {{element.totalTickets}} </td>
          </ng-container>
        
          <!-- Symbol Column -->
          <ng-container matColumnDef="waitingTickets">
            <th mat-header-cell *matHeaderCellDef> Waiting Tickets </th>
            <td mat-cell *matCellDef="let element"> {{element.waitingTickets}} </td>
          </ng-container>
        
          <ng-container matColumnDef="camada">
            <th mat-header-cell *matHeaderCellDef> Camada </th>
            <td mat-cell *matCellDef="let element"> {{element.camada}} </td>
          </ng-container>
        
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element"> {{element.status}} </td>
          </ng-container>
        
          <tr mat-header-row *matHeaderRowDef="displayColumnsLogs"></tr>
          <tr mat-row *matRowDef="let row; columns: displayColumnsLogs;"></tr>
          
        </table>
        
        

      </mat-expansion-panel>
    </mat-accordion>

  </div>
  <div class="footer-modal">
     
    <div class="btns-box">
      <div class="btn" *ngIf="currentPagitanion !== 0" (click)="pagination(-1)"><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M560-240 320-480l240-240 56 56-184 184 184 184-56 56Z"/></svg></div>

      <div class="current-pagination">
        <span>{{currentPagitanion}}</span>
      </div>
      <div class="btn" (click)="pagination(1)"><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z"/></svg></div>
    </div>
  </div>
