<button
  mat-menu-item
  (click)="$event.stopPropagation()"
  *ngFor="let idioma of availableIdiomas"
  [formGroup]="form"
>
  <mat-checkbox
    formArrayName="idiomas"
    [ngClass]="idioma"
    [checked]="this.langList.includes(idioma)"
    (change)="onCheckboxChange($event.checked, idioma)"
  >
    {{ idioma }}
  </mat-checkbox>
</button>
