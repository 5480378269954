import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BlipServiceService } from './../blip-service.service';

@Component({
  selector: 'app-remove-collaborator',
  templateUrl: './remove-collaborator.component.html',
  styleUrls: ['./remove-collaborator.component.scss']
})
export class RemoveCollaboratorComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<RemoveCollaboratorComponent>,@Inject(MAT_DIALOG_DATA) public data,
  private BlipServiceService: BlipServiceService){

  }

  ngOnInit(): void {
  }

  removeCollaborator(){
    this.BlipServiceService.deleteCollaborators(this.data.agentData.id).subscribe((data)=>{
    })
  };

  closeModal(){
    this.dialogRef.close();
  }

}
