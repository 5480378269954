<div class="container">
  <div class="header-modal">
    <h1 mat-dialog-title *ngIf="data.addCollaborator; else editTitle">
      Add new collaborator ({{
        this.data.departament.charAt(0).toUpperCase() +
          this.data.departament.slice(1).toLowerCase()
      }})
    </h1>

    <ng-template #editTitle>
      <h1 mat-dialog-title>
        Edit new collaborator ({{
          this.data.departament.charAt(0).toUpperCase() +
            this.data.departament.slice(1).toLowerCase()
        }})
      </h1>
    </ng-template>
    <mat-icon
      aria-hidden="false"
      aria-label="Example home icon"
      class="white-icon"
      (click)="closeModal()"
      >close</mat-icon
    >
  </div>
  <div mat-dialog-content class="box-content">
    <form [formGroup]="dialogForm" class="form-group" [style.fontSize.px]="14">
      <div class="inpts-box">
        <div class="form-input-box">
          <label for="name-collaborator" class="inpt-label"
            >Collaborator name</label
          >
          <input
            type="text"
            placeholder="João da Silva Júnior"
            id="name-collaborator"
            class="inpt"
            formControlName="nome"
            [ngClass]="{
              'is-invalid':
                this.dialogForm.controls.nome.invalid &&
                this.dialogForm.controls.nome.touched
            }"
          />
          <span
            style="color: #ff1d1d; font-size: 12px"
            *ngIf="
              this.dialogForm.controls.nome.invalid &&
              this.dialogForm.controls.nome.touched
            "
            >Invalid Name</span
          >
        </div>

        <div class="form-input-box">
          <label for="email-collaborator" class="inpt-label"
            >Collaborator email</label
          >

          <input
            type="email"
            placeholder="nome@brzinsurance.com"
            id="email-collaborator"
            class="inpt"
            (blur)="formatarEmail($event)"
            [ngClass]="{
              'is-invalid':
                (!this.dialogForm.get('email').valid &&
                  this.dialogForm.get('email').touched) ||
                this.emailCadastrado && data.addCollaborator
            }"
            formControlName="email"
          />
          <div style="min-height: 25px">
            <span
              style="color: #ff1d1d; font-size: 12px"
              *ngIf="
                this.dialogForm.controls.email.value !==
                  this.data?.agentData?.email &&
                this.dialogForm.controls.email.touched &&
                !this.data.addCollaborator
              "
              >The email cannot be changed.</span
            >
            <span
              style="color: #ff1d1d; font-size: 12px"
              *ngIf="
                this.dialogForm.controls.email.value.length < 1 &&
                this.dialogForm.controls.email.touched
              "
              >Invalid Email.</span
            >
            <span
              style="color: #ff1d1d; font-size: 12px"
              *ngIf="this.emailCadastrado && data.addCollaborator"
              >Email already registered on the list</span
            >
          </div>
        </div>
      </div>
    </form>
  </div>

  <div mat-dialog-content class="box-content">
    <form [formGroup]="dialogForm" class="form-group" [style.fontSize.px]="14">
      <div class="inpts-box">
        <div class="form-input-box">
          <label for="queue-collaborator" class="inpt-label">Sequence</label>
          <select
            id="queue-collaborator"
            class="inpt select"
            formControlName="camada"
            disabled
            [ngClass]="{
              'is-invalid':
                this.dialogForm.controls.camada.invalid &&
                this.dialogForm.controls.camada.touched
            }"
          >
            <option clqss="option-select" selected value="1">1</option>
            <option clqss="option-select" value="2">2</option>
            <option clqss="option-select" value="3">3</option>
            <option clqss="option-select" value="4">4</option>
          </select>
        </div>

        <div class="form-input-box">
          <label for="language-collaborator" class="inpt-label">Language</label>

          <mat-form-field appearance="fill" style="width: 256px">
            <mat-label> </mat-label>
            <mat-select multiple formControlName="idiomas">
              <mat-option (onSelectionChange)="selectALL($event)"
                >All</mat-option
              >
              <mat-option value="EN">English</mat-option>
              <mat-option value="ES">Español</mat-option>
              <mat-option value="PT">Português</mat-option>
            </mat-select>
          </mat-form-field>
          <span
            style="color: #ff1d1d; font-size: 12px"
            *ngIf="
              this.dialogForm.controls.idiomas.invalid &&
              this.dialogForm.controls.idiomas.touched
            "
            >Please select at least one language
          </span>
        </div>
      </div>
    </form>
  </div>

  <footer class="footer-box">
    <div mat-dialog-actions>
      <button
        mat-button
        class="action-btn"
        (click)="addCollaborators()"
        *ngIf="data.addCollaborator; else editButton"
      >
        Add Collaborator
      </button>

      <ng-template #editButton>
        <button
          mat-button
          mat-dialog-close
          class="action-btn"
          (click)="editCollaborators()"
        >
          Edit Collaborator
        </button>
      </ng-template>
    </div>
  </footer>
</div>
