<mat-expansion-panel class="expansion-panel">
  <mat-expansion-panel-header>
    <mat-panel-title style="color: #ffffff">
      <div style="margin-top: 5px">
        <i class="far fa-chart-bar"></i>
        <span style="margin-left: 10px">Collaborators</span>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <div>
    <!-- <a
      *ngIf="checkQuoteCap()"
      mat-list-item
      routerLink="/home/collaborators/tickets"
      class="item-desc"
    >
      Tickets
    </a>
    <a
      *ngIf="checkQuoteCap()"
      mat-list-item
      routerLink="/home/collaborators/deals"
      class="item-desc"
    >
      Deals
    </a> -->

    <a
      *ngIf="checkCollaborators()"
      mat-list-item
      routerLink="/home/collaborators/blip"
      class="item-desc"
    >
      Blip
    </a>
  </div>
</mat-expansion-panel>
