import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import * as Highcharts from 'highcharts/highcharts.src';
import { CarrierServiceService } from '../../services/carrier-service.service';
import { GetCarrier } from '../../model/getCarriers';
import { Observable } from 'rxjs';
import { CarrierChart } from '../../model/carrierChart';

@Component({
  selector: 'app-chart-carrier',
  templateUrl: './chart-carrier.component.html',
  styleUrls: ['./chart-carrier.component.scss'],
})
export class ChartCarrierComponent implements OnInit, OnChanges {
  constructor(private carrierService: CarrierServiceService) {}
  init$: Observable<CarrierChart[]>;
  service$: Observable<CarrierChart[]>;
  periodDate: any;
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnInit(): void {
    this.service$ = this.carrierService.getPeriod();
    this.service$.subscribe((data) => {
      let latestDate = data.map((d) => d.date);
      let parts = this.getLatestDate(latestDate).split('-');
      let formattedDate = `${parts[1]}-${parts[0]}`;
      this.handleUpdate(data, formattedDate);
    });
  }

  handleUpdate(data, period) {
    this.periodDate = this.formatDate(period);
    function transformObject(originalObject, date) {
      let newArray = [];
      let carriers = {};
      originalObject.forEach((obj) => {
        if (obj.date === date) {
          if (!carriers[obj.carrier_name]) {
            carriers[obj.carrier_name] = {
              target: obj.target,
              total_premium: obj.total_premium || 0,
            };
          } else {
            carriers[obj.carrier_name].total_premium += obj.total_premium || 0;
          }
        }
      });
      for (let carrier in carriers) {
        newArray.push({
          name: carrier,
          target: carriers[carrier].target,
          total_premium: carriers[carrier].total_premium,
          date: date,
        });
      }
      return newArray;
    }

    var xAsiscateg = transformObject(data, period).map((res) => res.name);
    var target = transformObject(data, period).map((res) =>
      parseInt(res.target)
    );
    var totalPremium = transformObject(data, period).map((res) =>
      parseInt(res.total_premium)
    );

    this.chartOptions.series = [];

    this.chartOptions.chart = {
      type: 'column',
    };

    this.chartOptions.title = {
      text: this.periodDate,
    };

    this.chartOptions.legend = {
      itemStyle: {
        fontFamily: 'Lato',
        fontWeight: '300',
      },
    };

    this.chartOptions.xAxis = {
      categories: xAsiscateg,
      crosshair: true,
    };

    this.chartOptions.yAxis = {
      title: {
        text: 'Valor em dólares ($)',
        style: {
          fontFamily: 'Lato',
        },
      },
      labels: {
        formatter: function () {
          return '$' + this.value;
        },
      },
    };

    this.chartOptions.tooltip = {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true,
    };

    this.chartOptions.plotOptions = {
      column: {
        pointPadding: 0,
        borderWidth: 0,
        tooltip: {
          distance: 70,
        },
      },
    };

    this.chartOptions.series = [
      {
        name: 'Target',
        type: 'column',
        color: '#b7d1ea',
        data: target,
      },
      {
        name: 'Total Premium',
        type: 'column',
        color: '#38761d',
        data: totalPremium,
      },
    ];

    // this.chartOptions = {
    //   chart: {
    //     renderTo: 'carrierChart',
    //     type: 'column',
    //   },
    //   title: {
    //     text: this.periodDate,
    //   },
    //   subtitle: {
    //     text: null,
    //   },
    //   xAxis: {
    //     categories: xAsiscateg,
    //     crosshair: true,
    //   },
    //   yAxis: {
    //     min: 0,
    //     title: {
    //       text: 'Valor em dólares ($)',
    //     },
    //     labels: {
    //       formatter: function () {
    //         return '$' + this.value;
    //       },
    //     },
    //   },
    //   tooltip: {
    //     headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    //     pointFormat:
    //       '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
    //       '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
    //     footerFormat: '</table>',
    //     shared: true,
    //     useHTML: true,
    //   },
    //   plotOptions: {
    //     column: {
    //       pointPadding: 0.2,
    //       borderWidth: 0,
    //     },
    //   },
    //   series: [
    //     {
    //       name: 'Target',
    //       type: 'column',
    //       data: target,
    //     },
    //     {
    //       name: 'Total Premium',
    //       type: 'column',
    //       data: totalPremium,
    //     },
    //   ],
    // };

    transformObject(data, this.periodDate);

    Highcharts.chart('carrierChart', this.chartOptions);
  }

  formatDate(date) {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const [month, year] = date.split('-');
    return `${months[month - 1]}/${year}`;
  }

  getLatestDate(dates: string[]): string {
    return dates
      .map((date) => {
        const [month, year] = date.split('-');
        return `${year}-${month}-01`;
      })
      .sort((a, b) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateB.getTime() - dateA.getTime();
      })[0];
  }

}
