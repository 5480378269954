import { Injectable } from '@angular/core';
import { TokenService } from '../token/token.service';
import { BehaviorSubject } from 'rxjs';
import { User } from './model/user';
import { Cap } from './model/caps';
import { CookieService } from 'ngx-cookie-service';

const KEY_ROLE = 'userRole';
const KEY_CAP = 'cap';
const KEY_DISPLAYNAME = 'displayName';
const KEY_USER_ID = 'userId';
const KEY_EMAIL = 'email';
const KEY_PWD = 'pwd';
const KEY_LOCATION = 'location';
const KEY_LEADSOURCE = 'leadsource';

@Injectable({ providedIn: 'root' })
export class UserService {

  blip: any;

  public userSubject = new BehaviorSubject<User>(null);
  private userName: string;
  public cap: any;

  constructor(private tokenService: TokenService, private cookieService: CookieService) {
    this.tokenService.hasToken() &&
      this.decodeAndNotify();
  }

  setToken(token: string) {
    this.tokenService.setToken(token);
  }

  setUser(user: User) {
    this.userName = user.user_nicename;
    window.localStorage.setItem(KEY_USER_ID, user.ID);

    this.userSubject.next(user);
  }

  setLeadsource(source: string) {
    window.localStorage.setItem(KEY_LEADSOURCE, source);
  }

  setPermissions(cap: any) {
    this.cap = cap;
    window.localStorage.setItem(KEY_CAP, JSON.stringify(this.cap));
  }

  setUserMail(mail: string) {
    window.localStorage.setItem(KEY_EMAIL, mail);
  }

  setUserName(name: string) {
    window.localStorage.setItem(KEY_DISPLAYNAME, name);
  }

  setUserPwd(pwd: string) {
    window.localStorage.setItem(KEY_PWD, pwd);
  }

  setDefaultLocation(location: string) {
    window.localStorage.setItem(KEY_LOCATION, location);
  }

  setUserRole(role: string[]) {
    window.localStorage.setItem(KEY_ROLE, JSON.stringify(role));
  }

  getUserRole() {
    return window.localStorage.getItem(KEY_ROLE);
  }

  private decodeAndNotify() {
    const token = this.tokenService.getToken();
  }

  logout() {
    window.localStorage.removeItem('expiresIn');
    window.localStorage.removeItem(KEY_ROLE);
    window.localStorage.removeItem('pusherTransportTLS');

    window.localStorage.removeItem(KEY_DISPLAYNAME);
    window.localStorage.removeItem(KEY_EMAIL);
    window.localStorage.removeItem(KEY_PWD);
    window.localStorage.removeItem(KEY_CAP);
    window.localStorage.removeItem(KEY_LOCATION);
    window.localStorage.removeItem(KEY_LEADSOURCE);

    if (this.blip) {
      this.blip.destroy();
    }

    this.cookieService.deleteAll();

    this.tokenService.removeToken();
    this.userSubject.next(null);
  }

  isLogged() {
    return this.tokenService.hasToken();
  }

  getUserName() {
    return this.userName;
  }

}
