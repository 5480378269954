import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginModule } from './modules/login/login.module';
import { CoreModule } from './core/core.module';
import { QuoteModule } from './modules/quote/quote.module';
import { DashboardLeadModule } from './modules/dashboard-lead/dashboard-lead.module';
//import { PandadocComponent } from './components/admin/pandapoc/page/pandadoc.component';
import { GeocoderModule } from 'angular-geocoder';
import {MatTabsModule} from '@angular/material/tabs';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AgmCoreModule } from '@agm/core';
import { TesteComponent } from './modules/teste/teste.component';
import { CollaboratorsModule } from './modules/collaborators/collaborators.module';
import { ChartComponent } from './chart/chart.component';
@NgModule({
  declarations: [
    AppComponent,
    TesteComponent,
    ChartComponent,
    //PandadocComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    MatTabsModule,
    BrowserAnimationsModule,
    CoreModule,
    LoginModule,
    QuoteModule,
    DashboardLeadModule,
    CollaboratorsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBMR2GgiLOp2JPppQo2K7eGZfKgi4fuFOY',
      libraries: ['visualization'],
    }),
    GeocoderModule
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  //providers: [{ provide: LocationStrategy, useClass: PathLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule {  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
  this.registerIcons();
}

private registerIcons() {
  const icons: { name: string; url: string }[] = [
    { name: 'comment_plus', url: '../assets/icons/chat_add_on.svg' },
    { name: 'profile_circle', url: '../assets/icons/profile_circle.svg' },
    { name: 'sms-tracking', url: '../assets/icons/sms-tracking.svg' },
    {name: 'import-file',url:'../assets/icons/import-file.svg'},
    {name:'cloud_upload', url:'../assets/icons/cloud_upload.svg'},
    {name: 'sms-edit', url:'../assets/icons/sms-edit.svg'},
    {name: 'filter_alt', url:'../assets/icons/filter_alt.svg'},
    {name: 'monitoring', url:'../assets/icons/monitoring.svg'},
    {name: 'help', url:'../assets/icons/help.svg'},
    {name: 'message-remove', url: '../assets/icons/message-remove.svg'},
    {name:'trash', url: '../assets/icons/trash.svg'},
    {name:'close-circle', url:'../assets/icons/close-circle.svg'},
    {name:'red-close-circle', url:'../assets/icons/red-close-circle.svg'},
    {name:'sms-sent',url:'../assets/icons/sms-sent.svg'},
    {name: 'filter_alt_active', url: '../assets/icons/filter_alt_active.svg'}
    // Adicione mais ícones aqui
  ];

  icons.forEach(icon => {
    this.matIconRegistry.addSvgIcon(
      icon.name,
      this.domSanitizer.bypassSecurityTrustResourceUrl(icon.url)
    );
  });
}}

// this.matIconRegistry.addSvgIcon(
//   'comment_plus',
//   this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/chat_add_on.svg')
// );