<mat-card class="charts-card"
  *ngIf="heWantsFee() || heWantsRevenue() || heWantsPolicyCount() || heWantsPremiumAmount() || heWantsDetailedList()">

  <mat-card-header class="header-quote">

    <i class="far fa-chart-bar" style="margin-left: -20px; margin-top: 14px;"></i>

    <div class="ml-28">
      <mat-card-title class="title-dashboard">Staff Stats</mat-card-title>
      <mat-card-subtitle>General data for employees</mat-card-subtitle>
    </div>

  </mat-card-header>

  
  <mat-card-content>

    <form [formGroup]="fgFilter" class="form-group" [style.fontSize.px]="14" (ngSubmit)="onSubmit()">

      <mat-form-field appearance="fill" class="custom-form-field">
        <mat-label>Period</mat-label>
        <mat-select formControlName="fcPeriod" required>
          <mat-option value="all">All</mat-option>
          <mat-option value="today">Today</mat-option>
          <mat-option value="thisyear">This Year</mat-option>
          <mat-option value="thisweek">This Week</mat-option>
          <mat-option value="thismonth">This Month</mat-option>
          <mat-option value="customdate">Custom</mat-option>
        </mat-select>
      </mat-form-field>

      <div *ngIf="fgFilter.get('fcPeriod').value === 'customdate'" style="display: flex;">

        <mat-form-field color="accent" appearance="fill" class="custom-form-field" ass="custom-form-field">
          <mat-label>From</mat-label>
          <input matInput formControlName="fcDtFrom" [matDatepicker]="dtFrom" appMaskDate>
          <mat-datepicker-toggle matSuffix [for]="dtFrom"></mat-datepicker-toggle>
          <mat-datepicker #dtFrom></mat-datepicker>
        </mat-form-field>

        <mat-form-field color="accent" class="custom-form-field" appearance="fill">
          <mat-label>To</mat-label>
          <input matInput formControlName="fcDtTo" [matDatepicker]="dtTo" appMaskDate>
          <mat-datepicker-toggle matSuffix [for]="dtTo"></mat-datepicker-toggle>
          <mat-datepicker #dtTo></mat-datepicker>
        </mat-form-field>

      </div>
      <mat-form-field appearance="fill" class="custom-form-field">
        <mat-label>Dc Type</mat-label>
        <mat-select formControlName="fcDcType" multiple required>
          <mat-option value="new">New</mat-option>
          <mat-option value="endorsement">Endorsement</mat-option>
          <mat-option value="renew">Renew</mat-option>
          <mat-option value="payment">Payment</mat-option>
          <mat-option value="rewrite">Rewrite</mat-option>
          <mat-option value="brl">Broker of Record Letter</mat-option>
          <mat-option value="rmv">Rmv</mat-option>
          <mat-option value="remaining_balance">Remaining Balance</mat-option>
          <mat-option value="audit">Audit</mat-option>
          <mat-option value="policy_cancellation">Policy Cancellation</mat-option>
          <mat-option value="flat_cancel">Flat Cancel</mat-option>
          <mat-option value="refund">Refund</mat-option>
        </mat-select>
      </mat-form-field>

      <button class="btn-search" mat-mini-fab color="primary" [disabled]="!fgFilter.valid">
        <mat-icon>search</mat-icon>
      </button>

      <button class="btn-search-mobile" mat-stroked-button color="primary" [disabled]="!fgFilter.valid">
        search
      </button>

    </form>
  </mat-card-content>

  <div class="flex-around wrap">
    <mat-card class="box-shadow-gray" *ngIf="heWantsFee()">
      <mat-card-header class="direction-reverse">
        <i class="fas fa-dollar-sign" class="title-count"></i>
        <mat-card-title class="card-title">Total Fee</mat-card-title>
      </mat-card-header>
      <mat-card-content class="total-amount">{{totalFee | currency}}</mat-card-content>
    </mat-card>
    <mat-card class="box-shadow-gray" *ngIf="heWantsPremiumAmount()">
      <mat-card-header class="direction-reverse">
        <i class="fas fa-dollar-sign" class="title-count"></i>
        <mat-card-title class="card-title">Total Premium</mat-card-title>
      </mat-card-header>
      <mat-card-content class="total-amount">{{totalPremium | currency}}</mat-card-content>
    </mat-card>
    <mat-card class="box-shadow-gray" *ngIf="heWantsRevenue()">
      <mat-card-header class="direction-reverse">
        <i class="fas fa-dollar-sign" class="title-count"></i>
        <mat-card-title class="card-title">Total Revenue</mat-card-title>
      </mat-card-header>
      <mat-card-content class="total-amount">{{totalRevenue | currency}}</mat-card-content>
    </mat-card>
    <mat-card class="box-shadow-gray" *ngIf="heWantsPolicyCount()">
      <mat-card-header class="direction-reverse">
        <i class="fas fa-clipboard-list" class="title-count"></i>
        <mat-card-title class="card-title">Total Policy</mat-card-title>
      </mat-card-header>
      <mat-card-content class="total-amount">{{totalPolicy}}</mat-card-content>
    </mat-card>


  </div>

  <app-table-detailed-list *ngIf="heWantsDetailedList()" [range]="range" [from]="from" [to]="to" [dcType]="dcType">
  </app-table-detailed-list>

  <app-chart-stats-policy-count class="first-chart" *ngIf="heWantsPolicyCount()" (getTotalPolicy)="getTotalPolicy($event)" [range]="range"
    [from]="from" [to]="to" [dcType]="dcType">
  </app-chart-stats-policy-count>
  <app-chart-stats-premium-count *ngIf="heWantsPremiumAmount()" [range]="range" [from]="from" [to]="to"
    [dcType]="dcType" (getTotalPremium)="getTotalPremium($event)">
  </app-chart-stats-premium-count>
  <div class="double-charts">
    <app-chart-stats-revenue *ngIf="heWantsRevenue()" style="width: 100%;" [range]="range" [from]="from" [to]="to"
      [dcType]="dcType" (getTotalRevenue)="getTotalRevenue($event)"></app-chart-stats-revenue>
    <app-chart-stats-fee *ngIf="heWantsFee()" style="width: 100%;" [range]="range" [from]="from" [to]="to"
      [dcType]="dcType" (getTotalFee)="getTotalFee($event)"></app-chart-stats-fee>

  </div>



</mat-card>
