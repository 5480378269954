import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { DashboardName } from 'src/app/modules/dashboard-lead/model/dashboard';
import { DataSalesPolicy, ObjValuesSalesPolicy, SalesPolicy, ValuesSalesPolicy } from 'src/app/modules/dashboard-lead/model/salesPolicy';
import { PusherService } from 'src/app/shared/services/pusher.service';
import { environment } from 'src/environments/environment';
import { ChartPodiumComponent } from '../../../dashboard-brz-sales/chart-podium/chart-podium.component';
import { ChartStatsFeeComponent } from '../../charts/chart-stats-fee/chart-stats-fee.component';
import { ChartStatsPolicyCountComponent } from '../../charts/chart-stats-policy-count/chart-stats-policy-count.component';
import { ChartStatsPremiumCountComponent } from '../../charts/chart-stats-premium-count/chart-stats-premium-count.component';
import { ChartStatsRevenueComponent } from '../../charts/chart-stats-revenue/chart-stats-revenue.component';
import { TableDetailedListComponent } from '../../tables/table-detailed-list/table-detailed-list.component';

@Component({
  selector: 'app-brz-bar-filter',
  templateUrl: './brz-bar-filter.component.html',
  styleUrls: ['./brz-bar-filter.component.scss']
})
export class BrzBarFilterComponent implements OnInit {

  fgFilter: FormGroup;

  range: string;
  from: string;
  to: string;
  dcType: string[];

  totalFee: number;
  totalPremium: number;
  totalRevenue: number;
  totalPolicy: number;

  @Input() results: DashboardName[];
  @ViewChild(ChartStatsPolicyCountComponent) chartStatsPolicyCountComponent: ChartStatsPolicyCountComponent;
  @ViewChild(ChartStatsPremiumCountComponent) chartStatsPremiumCountComponent: ChartStatsPremiumCountComponent;
  @ViewChild(ChartStatsRevenueComponent) chartStatsRevenueComponent: ChartStatsRevenueComponent;
  @ViewChild(ChartStatsFeeComponent) chartStatsFeeComponent: ChartStatsFeeComponent;
  @ViewChild(ChartPodiumComponent) chartPodiumComponent: ChartPodiumComponent;
  @ViewChild(TableDetailedListComponent) tableDetailedListComponent: TableDetailedListComponent;

  constructor(private _formBuilder: FormBuilder, private cookieService: CookieService, private pusherService: PusherService) { }

  ngOnInit(): void {
    this.initForm();
    this.initVariables();
  }

  onSubmit() {

    var datePipe = new DatePipe('en-US');

    let from = null;
    let to = null;
    let period = this.fgFilter.get('fcPeriod').value;

    if (this.fgFilter.get('fcDtFrom').value)
      from = datePipe.transform(this.fgFilter.get('fcDtFrom').value, 'MM/dd/yyyy');

    if (this.fgFilter.get('fcDtTo').value)
      to = datePipe.transform(this.fgFilter.get('fcDtTo').value, 'MM/dd/yyyy');

    this.cookieService.set('periodStaff', period || null);

    if (this.cookieService.get('periodStaff') === 'customdate') {
      this.cookieService.set('dtFromStaff', from);
      this.cookieService.set('dtToStaff', to);
    }

    this.cookieService.set('dcType', this.fgFilter.get('fcDcType').value);

    if (this.heWantsPolicyCount()) this.chartStatsPolicyCountComponent.getStatsPolicy(period, from, to, this.fgFilter.get('fcDcType').value);
    if (this.heWantsPremiumAmount()) this.chartStatsPremiumCountComponent.getStatsPremium(period, from, to, this.fgFilter.get('fcDcType').value);
    if (this.heWantsRevenue()) this.chartStatsRevenueComponent.getStatsRevenue(period, from, to, this.fgFilter.get('fcDcType').value);
    if (this.heWantsFee()) this.chartStatsFeeComponent.getStatsFee(period, from, to, this.fgFilter.get('fcDcType').value);
    //if (this.heWantsFee()) this.chartPodiumComponent.getPodiumRank(period, from, to, this.fgFilter.get('fcDcType').value);
    if (this.heWantsDetailedList()) this.tableDetailedListComponent.getDetailedListStats(period, from, to, this.fgFilter.get('fcDcType').value);

  }

  initForm() {

    this.pusher();

    let from: Date;
    let to: Date;
    let dcType: string[];

    if (this.cookieService.get('dtFromStaff')) {
      from = new Date(this.htmlDecode(this.cookieService.get('dtFromStaff')));
    }

    if (this.cookieService.get('dtToStaff')) {
      to = new Date(this.htmlDecode(this.cookieService.get('dtToStaff')));
    }

    if (this.cookieService.get('dcType')) {
      dcType = this.htmlDecode(this.cookieService.get('dcType')).split(',');
    }

    this.fgFilter = this._formBuilder.group({
      fcPeriod: [this.cookieService.get('periodStaff') || 'thismonth'],
      fcDtFrom: [from || ''],
      fcDtTo: [to || ''],
      fcDcType: [dcType || ['new', 'endorsement', 'brl'], Validators.required]
    });

  }

  htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  }

  initVariables() {
    var datePipe = new DatePipe('en-US');
    this.range = this.fgFilter.get('fcPeriod').value;
    this.from = datePipe.transform(this.fgFilter.get('fcDtFrom').value, 'MM/dd/yyyy');
    this.to = datePipe.transform(this.fgFilter.get('fcDtTo').value, 'MM/dd/yyyy');
    this.dcType = this.fgFilter.get('fcDcType').value;
  }

  pusher() {

    let pusherClient = this.pusherService.getPusher();

    const channel = pusherClient.subscribe(`${environment.channelSlug}dashboard-stats`);

    channel.bind(
      'pusher:subscription_succeeded', () => {
        console.log(`channel: ${environment.channelSlug}dashboard-stats`);
      });

    channel.bind('new-daily-cash', (stats) => {

      var datePipe = new DatePipe('en-US');
      let formatDateCreated = datePipe.transform(stats.daily_cash_timecreated, 'MM/dd/yyyy') || null;
      let dateCreated = new Date(formatDateCreated);
      let today = new Date();

      const from = this.fgFilter.get('fcDtFrom').value;
      const to = this.fgFilter.get('fcDtTo').value;

      switch (this.fgFilter.get('fcPeriod').value) {
        case 'all':
          this.generateChartsIfDcTypeIncluded(stats);
          break;
        case 'today':
          if ((dateCreated.getDate() === today.getDate())
            && (dateCreated.getMonth() === today.getMonth())
            && (dateCreated.getFullYear() === today.getFullYear())) {
            this.generateChartsIfDcTypeIncluded(stats);
          }
          break;
        case 'thisyear':
          if (dateCreated.getFullYear() === today.getFullYear()) {
            this.generateChartsIfDcTypeIncluded(stats);
          }
          break;
        case 'thisweek':
          if (this.isDateInThisWeek(dateCreated)) {
            this.generateChartsIfDcTypeIncluded(stats);
          }
          break;
        case 'thismonth':
          if (dateCreated.getMonth() === today.getMonth()) {
            this.generateChartsIfDcTypeIncluded(stats);
          }
          break;
        case 'customdate':
          if((dateCreated <= to) && (dateCreated >= from)) {
            this.generateChartsIfDcTypeIncluded(stats);
          }
          break;

        default:
          break;
      }

    });

  }

  isDateInThisWeek(date) {
    const todayObj = new Date();
    const todayDate = todayObj.getDate();
    const todayDay = todayObj.getDay();
    const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);
    return date >= firstDayOfWeek && date <= lastDayOfWeek;
  }

  generateChartsIfDcTypeIncluded(stats: any) {

    if (this.fgFilter.get('fcDcType').value.includes(stats.daily_cash_type)) {
      this.generatePolicyCount(stats);
      this.generatePremium(stats);
      this.generateFee(stats);
      this.generateRevenue(stats);
      this.generateDetailedTable(stats);
    }
  }

  generatePolicyCount(stats: any) {
    if (this.chartStatsPolicyCountComponent.chartOptions.series.length > 0) {
      if (this.chartStatsPolicyCountComponent.chartOptions.series.find(x => x.name === stats.daily_cash_type)) {

        let indexCategories = this.chartStatsPolicyCountComponent.chartOptions.xAxis['categories'].findIndex(x => x === stats.name);

        let indexSeries = this.chartStatsPolicyCountComponent.chartOptions.series.findIndex(x => {
          return x.name === stats.daily_cash_type
        });

        if (indexCategories !== -1) {

          let oldValue = this.chartStatsPolicyCountComponent.chartOptions.series[indexSeries]['data'][indexCategories] || 0;
          this.chartStatsPolicyCountComponent.chartOptions.series[indexSeries]['data'][indexCategories] = oldValue + stats.count;

        } else {

          this.chartStatsPolicyCountComponent.chartOptions.xAxis['categories'].push(stats.name);

          if (this.chartStatsPolicyCountComponent.chartOptions.series[indexSeries].type === 'bar') {
            this.chartStatsPolicyCountComponent.chartOptions.series[indexSeries]['data'].push(stats.count);
          }
        }

      } else {
        this.chartStatsPolicyCountComponent.chartOptions.series.push({
          type: 'bar',
          name: stats.daily_cash_type,
          data: [stats.count],
        });
      }

      this.totalPolicy = this.totalPolicy + stats.count;

    } else {
      this.chartStatsPolicyCountComponent.getStatsPolicy(this.fgFilter.get('fcPeriod').value,
      this.fgFilter.get('fcDtFrom').value,
      this.fgFilter.get('fcDtTo').value,
      this.fgFilter.get('fcDcType').value);
        // this.chartStatsPolicyCountComponent.chartOptions.xAxis['categories'].push(stats.name);
        // this.chartStatsPolicyCountComponent.chartOptions.series.push({
        //   type: 'bar',
        //   name: stats.daily_cash_type,
        //   data: [stats.count],
        // });
        // this.totalPolicy = this.totalPolicy + stats.count;

    }

    this.chartStatsPolicyCountComponent.updateFlag = true;
  }

  generateDetailedTable(stats: any) {

    let lineTable = this.tableDetailedListComponent.dataSource.data.find(x => x.name === stats.name);

    if (lineTable) {

      lineTable.premium = lineTable.premium + stats.premium;
      if (!lineTable.daily_cash_type.includes(stats.daily_cash_type)) {
        lineTable.daily_cash_type += `|${stats.daily_cash_type}`
      }
      lineTable.count = lineTable.count + stats.count;
      lineTable.fee = lineTable.fee + stats.fee;
      lineTable.translation = lineTable.translation + stats.translation;
      lineTable.runner = lineTable.runner + stats.runner;
      lineTable.commission = lineTable.commission + stats.commission;
      lineTable.total = lineTable.total + stats.total;
    }
    else {

      // this.tableDetailedListComponent.getDetailedListStats(this.fgFilter.get('fcPeriod').value,
      // this.fgFilter.get('fcDtFrom').value,
      // this.fgFilter.get('fcDtTo').value,
      // this.fgFilter.get('fcDcType').value);

      const data = this.tableDetailedListComponent.dataSource.data;

      data.push({
        commission: stats.commission,
        count: stats.count,
        premium: stats.premium,
        runner: stats.runner,
        name: stats.name,
        total: stats.total,
        user_email: stats.user_email,
        user_id: stats.foreign_user_id,
        daily_cash_type: stats.daily_cash_type,
        fee: stats.fee,
        translation: stats.translation,
        sales_target: stats.sales_target,
        sales_success: stats.sales_success
      });
      
      this.tableDetailedListComponent.dataSource.data = data;

      // this.tableDetailedListComponent.dataSource.data.unshift({
      //   commission: stats.commission,
      //   count: stats.count,
      //   daily_cash_type: stats.daily_cash_type,
      //   fee: stats.fee,
      //   name: stats.name,
      //   premium: stats.premium,
      //   runner: stats.runner,
      //   sales_success: stats.sales_success,
      //   sales_target: stats.sales_target,
      //   total: stats.total,
      //   translation: stats.translation,
      //   user_email: stats.user_email,
      //   user_id: stats.user_id
      // });
    }
  }

  generatePremium(stats: any) {
    if (this.chartStatsPremiumCountComponent.chartOptions.series.length > 0) {
      if (this.chartStatsPremiumCountComponent.chartOptions.series.find(x => x.name === stats.daily_cash_type)) {

        let indexCategories = this.chartStatsPremiumCountComponent.chartOptions.xAxis['categories'].findIndex(x => x === stats.name);

        let indexSeries = this.chartStatsPremiumCountComponent.chartOptions.series.findIndex(x => {
          return x.name === stats.daily_cash_type
        });

        if (indexCategories !== -1) {

          let oldValue = this.chartStatsPremiumCountComponent.chartOptions.series[indexSeries]['data'][indexCategories] || 0;
          this.chartStatsPremiumCountComponent.chartOptions.series[indexSeries]['data'][indexCategories] = oldValue + stats.premium;

        } else {

          this.chartStatsPremiumCountComponent.chartOptions.xAxis['categories'].push(stats.name);
          
          if (this.chartStatsPremiumCountComponent.chartOptions.series[indexSeries].type === 'bar') {
            this.chartStatsPremiumCountComponent.chartOptions.series[indexSeries]['data'].push(stats.premium);
          }
        }

      } else {
        this.chartStatsPremiumCountComponent.chartOptions.series.push({
          type: 'bar',
          name: stats.daily_cash_type,
          data: [stats.premium],
        });
      }

      this.totalPremium = this.totalPremium + stats.premium;

    } else {
        // this.chartStatsPremiumCountComponent.chartOptions.xAxis['categories'].push(stats.name);
        // this.chartStatsPremiumCountComponent.chartOptions.series.push({
        //   type: 'bar',
        //   name: stats.daily_cash_type,
        //   data: [stats.premium],
        // });
        // this.totalPremium = this.totalPremium + stats.premium;
        this.chartStatsPremiumCountComponent.getStatsPremium(this.fgFilter.get('fcPeriod').value,
        this.fgFilter.get('fcDtFrom').value,
        this.fgFilter.get('fcDtTo').value,
        this.fgFilter.get('fcDcType').value);
    }

    this.chartStatsPremiumCountComponent.updateFlag = true;
  }

  generateFee(stats: any) {
    if (this.chartStatsFeeComponent.chartOptions.series[0]['data'].length > 0) {

      if (this.chartStatsFeeComponent.chartOptions.xAxis['categories'].find(x => x === stats.name)) {
        let index = this.chartStatsFeeComponent.chartOptions.xAxis['categories'].findIndex(x => x === stats.name);
        let oldValue = this.chartStatsFeeComponent.chartOptions.series[0]['data'][index];

        this.chartStatsFeeComponent.chartOptions.series[0]['data'][index] = oldValue + stats.fee;

      }
      else {
        this.chartStatsFeeComponent.chartOptions.xAxis['categories'].push(stats.name);
        this.chartStatsFeeComponent.chartOptions.series[0]['data'].push(stats.fee);
      }

      this.totalFee = this.totalFee + stats.fee;

    } else {
        // this.chartStatsFeeComponent.chartOptions.xAxis['categories'].push(stats.name);
        // this.chartStatsFeeComponent.chartOptions.series[0]['data'].push(stats.fee);

        // this.totalFee = this.totalFee + stats.fee;

        this.chartStatsFeeComponent.getStatsFee(this.fgFilter.get('fcPeriod').value,
        this.fgFilter.get('fcDtFrom').value,
        this.fgFilter.get('fcDtTo').value,
        this.fgFilter.get('fcDcType').value);

    }

    this.chartStatsFeeComponent.updateFlag = true;
  }

  generateRevenue(stats: any) {
    if (this.chartStatsRevenueComponent.chartOptions.series[0]['data'].length > 0) {

      if (this.chartStatsRevenueComponent.chartOptions.xAxis['categories'].find(x => x === stats.name)) {
        let index = this.chartStatsRevenueComponent.chartOptions.xAxis['categories'].findIndex(x => x === stats.name);
        let oldValue = this.chartStatsRevenueComponent.chartOptions.series[0]['data'][index];

        this.chartStatsRevenueComponent.chartOptions.series[0]['data'][index] = oldValue + stats.total;

      }
      else {
        this.chartStatsRevenueComponent.chartOptions.xAxis['categories'].push(stats.name);
        this.chartStatsRevenueComponent.chartOptions.series[0]['data'].push(stats.total);
      }

      this.totalRevenue = this.totalRevenue + stats.total;

    } else {

      // this.chartStatsRevenueComponent.chartOptions.xAxis['categories'].push(stats.name);
      // this.chartStatsRevenueComponent.chartOptions.series[0]['data'].push(stats.total);

      // this.totalRevenue = this.totalRevenue + stats.total;

      this.chartStatsRevenueComponent.getStatsRevenue(this.fgFilter.get('fcPeriod').value,
        this.fgFilter.get('fcDtFrom').value,
        this.fgFilter.get('fcDtTo').value,
        this.fgFilter.get('fcDcType').value);
    }

    this.chartStatsRevenueComponent.updateFlag = true;
  }

  heWantsFee(): boolean {
    return this.didItFoundCap('brzinsurance_reports_brzstats_fee');
  }

  heWantsRevenue(): boolean {
    return this.didItFoundCap('brzinsurance_reports_brzstats_revenue');
  }

  heWantsPolicyCount(): boolean {
    return this.didItFoundCap('brzinsurance_reports_brzstats_policy_count');
  }

  heWantsPremiumAmount(): boolean {
    return this.didItFoundCap('brzinsurance_reports_brzstats_premium_amount');
  }

  heWantsDetailedList(): boolean {
    return this.didItFoundCap('brzinsurance_reports_brzstats_detailed_list');
  }

  didItFoundCap(cap: string): boolean {
    if (this.results) if (this.results.find(x => x.cap === cap)) return true;

    return false;
  }

  getTotalFee($event: number) {
    this.totalFee = $event;
  }

  getTotalRevenue($event: number) {
    this.totalRevenue = $event;
  }

  getTotalPremium($event: number) {
    this.totalPremium = $event;
  }

  getTotalPolicy($event: number) {
    this.totalPolicy = $event;
  }
}
