<mat-card class="card-admin">

  <i *ngIf="soundOn; else soundOff" class="fas fa-volume-up sound" (click)="setSoundOff()"></i>
  <ng-template #soundOff>
    <i class="fas fa-volume-mute sound-off" (click)="setSoundOn()"></i>
  </ng-template>

  <div class="sticky-header">
    <mat-card-header class="card-header">

      <div class="gmail-logo"></div>

      <div class="ml-28">
        <mat-card-title>Notifications</mat-card-title>
        <mat-card-subtitle>Let's check if you have notifications</mat-card-subtitle>
      </div>

    </mat-card-header>

    <mat-card-content class="flex-justify-center">
      <div style="width: 100%;display: flex;justify-content: space-around;align-items: center;">
        <button mat-stroked-button (click)="markAllAsRead()"
          style="margin: 24px 0px; border: 1px solid rgb(231, 231, 231);">Mark all as read</button>
        <button mat-stroked-button (click)="archiveAll()" color="warn"
          style="margin: 24px 0px; border: 1px solid rgb(231, 231, 231);">Archive All</button>
      </div>
    </mat-card-content>

    <mat-card-content class="flex-justify-center" style="margin-top: 42px" *ngIf="loading">
      <mat-progress-spinner mode="indeterminate" color="primary" diameter="40"></mat-progress-spinner>
    </mat-card-content>
  </div>

  <mat-card-content *ngIf="notifications.length > 0 && !loading; else noMessageData">
    <div *ngFor="let notification of notifications" class="card-notification">

      <div class="flex-top">
        <div style="width: 2em;">
          <div *ngIf="notification.is_read === '0'" class="unread-notification"></div>
        </div>

        <div class="flex-column mw-18">
          <div class="header-notification">
            <mat-card-title>{{notification.title}}</mat-card-title>
          </div>

          <mat-card-subtitle class="subtitle-content">{{notification.content}}</mat-card-subtitle>
          <mat-chip-list class="chip-list">
            <mat-chip *ngIf="notification.is_read === '0'" (click)="markAsRead(notification.id)">MARK AS READ</mat-chip>
            <mat-chip *ngIf="notification.is_read === '1'" (click)="markAsUnread(notification.id)">MARK AS UNREAD
            </mat-chip>
            <mat-chip class="accent" (click)="archiveNotification(notification.id)">Archive</mat-chip>
          </mat-chip-list>
        </div>
        <mat-card-subtitle class="time">{{notification.created_at | date: 'MM/dd, h:mm a'}}</mat-card-subtitle>
      </div>

    </div>
  </mat-card-content>

  <ng-template #noMessageData>
    <mat-card-content class="flex-column">
      <div class="empty-notification"></div>
      <div class="flex-column">
        <span style="text-align: center; font: bold 1em Lato;">You don't have any notifications</span>
        <span style="text-align: center; font: 100 0.9em Lato; margin-top: 8px;">But as soon as something happens,
          you'll find it right here.</span>
      </div>
    </mat-card-content>
  </ng-template>


</mat-card>
