import { GetCarrier } from './../../dashboard-lead/components/dashboard-brz-carrier-target/model/getCarriers';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenService } from 'src/app/core/token/token.service';
import { UserService } from 'src/app/core/user/user.service';
import { environment } from 'src/environments/environment';
import {
  AgentsBlip,
  ApiResponse,
  simultaneous,
  waiting,
} from '../model/interfaces';
import { Observable, of } from 'rxjs';
import { catchError, map, pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BlipServiceService {
  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private userService: UserService
  ) {}

  addColaborattors(body) {
    body.distribuir = body.distribuir === null ? false : body.distribuir;
    body.camada = 1;
    const httpOptions = {
      headers: new HttpHeaders({
        token: `ex65jRFYjepRy6omf3G3#kLK3nGmxXa&k$5@`,
      }),
    };

    if (environment.productionMode) {
      return this.http
        .post<{ response: string; success: boolean }>(
          `https://brzcomb-integration.herokuapp.com/firebase/dados/ATENDENTES`,
          body,
          httpOptions
        )
        .pipe();
    } else {
      return this.http
        .post<{ response: string; success: boolean }>(
          `https://brzcomb-integration.herokuapp.com/firebase/dados/ATENDENTES_HOMOLOG`,
          body,
          httpOptions
        )
        .pipe();
    }
  }

  redistributeTickets(email) {
    const httpOptions = {
      headers: new HttpHeaders({
        token: `ex65jRFYjepRy6omf3G3#kLK3nGmxXa&k$5@`,
      }),
    };

    if (environment.productionMode) {
      return this.http
        .get<{ response: string; success: boolean }>(
          `http://brzcomb-integration.herokuapp.com/blip/distribuicao_automatica?fila=${email}`,

          httpOptions
        )
        .pipe();
    } else {
      return this.http
        .get<{ response: string; success: boolean }>(
          `http://brzcomb-integration.herokuapp.com/blip/distribuicao_automatica?fila=${email}&homolog=true`,
          httpOptions
        )
        .pipe();
    }
  }

  editCollaborator(data) {
    const body = {
      id: data.id,
      data: {
        camada: data.camada,
        email: data.email,
        nome: data.nome,
        idiomas: data.idiomas,
        distribuir: data.distribuir,
        maxTickets: data.maxTickets,
      },
    };
    const httpOptions = {
      headers: new HttpHeaders({
        token: `ex65jRFYjepRy6omf3G3#kLK3nGmxXa&k$5@`,
      }),
    };

    if (environment.productionMode) {
      return this.http
        .patch<{ response: string; success: boolean }>(
          `https://brzcomb-integration.herokuapp.com/firebase/dados/ATENDENTES`,
          body,
          httpOptions
        )
        .pipe();
    } else {
      return this.http
        .patch<{ response: string; success: boolean }>(
          `https://brzcomb-integration.herokuapp.com/firebase/dados/ATENDENTES_HOMOLOG`,
          body,
          httpOptions
        )
        .pipe();
    }
  }

  getCollaborators(data): Observable<AgentsBlip[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        token: `ex65jRFYjepRy6omf3G3#kLK3nGmxXa&k$5@`,
      }),
    };

    if (environment.productionMode) {
      return this.http
        .post<ApiResponse>(
          `https://brzcomb-integration.herokuapp.com/blip/queue`,
          {
            lang: 'pt-br',
            departament: data.departament,
          },
          httpOptions
        )
        .pipe(
          map((response) => {
            if (response === null) {
              return [] as AgentsBlip[];
            }
            if ('response' in response) {
              return (
                (response.response as AgentsBlip[]) || ([] as AgentsBlip[])
              );
            }
            return [] as AgentsBlip[];
          }),
          catchError((error) => {
            console.error('Erro na requisição:', error);
            return of([] as AgentsBlip[]);
          })
        );
    } else {
      return this.http
        .post<ApiResponse>(
          `https://brzcomb-integration.herokuapp.com/blip/queue?homolog=true`,
          {
            lang: 'pt-br',
            departament: data.departament,
            homolog: true,
          },
          httpOptions
        )
        .pipe(
          map((response) => {
            // Verifica se a resposta é nula
            if (response === null) {
              return [] as AgentsBlip[];
            }
            // Verifica se a chave 'response' está presente
            if ('response' in response) {
              return (
                (response.response as AgentsBlip[]) || ([] as AgentsBlip[])
              );
            }
            // Se a chave 'response' não estiver presente, retorna um array vazio
            return [] as AgentsBlip[];
          }),
          catchError((error) => {
            console.error('Erro na requisição:', error);
            // Em caso de erro, retorna um array vazio
            return of([] as AgentsBlip[]);
          })
        );
    }
  }

  deleteCollaborators(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        token: `ex65jRFYjepRy6omf3G3#kLK3nGmxXa&k$5@`,
      }),
      body: {
        id: data,
      },
    };

    if (environment.productionMode) {
      return this.http
        .delete<{ response: string; success: boolean }>(
          `https://brzcomb-integration.herokuapp.com/firebase/dados/ATENDENTES`,
          httpOptions
        )
        .pipe();
    } else {
      return this.http
        .delete(
          `https://brzcomb-integration.herokuapp.com/firebase/dados/ATENDENTES_HOMOLOG`,
          httpOptions
        )
        .pipe();
    }
  }

  waitingTickets(data): Observable<waiting> {
    let queue = data.queue;
    const httpOptions = {
      headers: new HttpHeaders({
        token: `q&#eGojb?KYMKAgKei7JkXd4CN!5g8$sBHc`,
      }),
    };

    if (environment.productionMode) {
      return this.http
        .get<waiting>(
          `https://brzcomb-integration.herokuapp.com/blip/queue/tickets/waiting?homolog=false&queue=${queue}`,
          httpOptions
        )
        .pipe(pluck('response'));
    } else {
      return this.http
        .get<waiting>(
          `https://brzcomb-integration.herokuapp.com/blip/queue/tickets/waiting?homolog=true&queue=${queue}`,
          httpOptions
        )
        .pipe(pluck('response'));
    }
  }

  returnSimultaneousTicket(): Observable<simultaneous[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        token: `ex65jRFYjepRy6omf3G3#kLK3nGmxXa&k$5@`,
      }),
    };

    if (environment.productionMode) {
      return this.http
        .get<simultaneous[]>(
          `https://brzcomb-integration.herokuapp.com/firebase/dados/LAYERS_INTRANET`,
          httpOptions
        )
        .pipe(pluck('response'));
    } else {
      return this.http
        .get<simultaneous[]>(
          `https://brzcomb-integration.herokuapp.com/firebase/dados/LAYERS_INTRANET`,
          httpOptions
        )
        .pipe(pluck('response'));
    }
  }

  editSimultaneousTicket(id:String, maxTickets:number): Observable<{ reponse: number; success: boolean }> {
    const httpOptions = {
      headers: new HttpHeaders({
        token: `ex65jRFYjepRy6omf3G3#kLK3nGmxXa&k$5@`,
      }),
    };

    const body = {
      id: id,
      data: {
        maxTickets: maxTickets,
      },
    };

    if (environment.productionMode) {
      return this.http
        .patch<{ reponse: number; success: boolean }>(
          `https://brzcomb-integration.herokuapp.com/firebase/dados/LAYERS_INTRANET`,
          body,
          httpOptions
        )
        .pipe(pluck('response'));
    } else {
      return this.http
        .patch<{ reponse: number; success: boolean }>(
          `https://brzcomb-integration.herokuapp.com/firebase/dados/LAYERS_INTRANET`,
          body,
          httpOptions
        )
        .pipe(pluck('response'));
    }
  }

  getLogs(pag = '0', date?): Observable<{
    camada: any;
    email: any;
    estado: any;
    fila: string;
    hora: string;
    id: string;
    idioma: string;
    idiomas: any;
    nome: any;
    nome_cliente: string,
    sequencial: number,
    status: string,
    ticket_id: string
  }[]> {
    const initial = date.startValue;
    const end = date.endValue;
    const httpOptions = {
      headers: new HttpHeaders({
        token: `ex65jRFYjepRy6omf3G3#kLK3nGmxXa&k$5@`,
      }),
    };

    return this.http
      .get<{ retorno: {
        camada: any;
        email: any;
        estado: any;
        fila: string;
        hora: string;
        id: string;
        idioma: string;
        idiomas: any;
        nome: any;
        nome_cliente: string,
        sequencial: number,
        status: string,
        ticket_id: string
      }[] }>( // Especifique o tipo esperado aqui
        'https://brzcomb-integration.herokuapp.com/blip/logs?take=50&skip=' +
          pag +
          '&data_inicio=' +
          initial +
          '&data_fim=' +
          end,
        httpOptions
      )
      .pipe(pluck('retorno'));
  }
}
