import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../user/user.service';
import { BlipChat } from 'blip-chat-widget';
import { Cap } from '../user/model/caps';
//  import { BlipClient } from "blip-client"

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  cap: Cap;
  show = true;
  build: boolean = false;
  chatvar: number;

  chat = new BlipChat()
    .withAppKey(
      'Ym90b3V2aWRvcmlhMToxZjNmNDIzNS04NWIwLTRiYjItODJlOC01MGEzNTcwZDZkMzY='
    )
    .withButton({ color: '#D2B232' });

  chatCEO = new BlipChat()
    .withAppKey(
      'cHJkYm90dGFsa3RvY2VvOmVjMWM0OWJiLTUzOGItNGIxOC04MDMxLTc0Mjg5Y2I5NjQyNQ=='
    )
    .withButton({ color: '#2CC3D5', icon: '' });


    chatIt = new BlipChat()
    .withAppKey(
      'YnJ6Y2hhbWFkb3N0aTpkOWE2MDVmYS1iMjU3LTQzMjQtYmU4YS0yYzQ1YmYyNDgxYzc='
    )
    .withCustomCommonUrl('https://brzinsurance.chat.blip.ai/')
    .withAccount({
      email:window.localStorage.getItem('data')
      // email:'teste@gmail.com',
    })
    .withButton({ color: '#2CC3D5', icon: '' });


    chatCollaborators = new BlipChat()
    .withCustomCommonUrl('https://brzinsurance.chat.blip.ai/')
    .withAppKey(
      'YXV4aWxpYXJjeDphZGEwODI2Zi0xYjY0LTRhOTctYTgyMi1mYzVmNmE4NzQyNDI='
    ).withAccount({
      email:window.localStorage.getItem('data')
      // email:'teste@gmail.com',
    })
    .withButton({ color: '#2CC3D5', icon: '' });



  constructor(private activatedRoute: ActivatedRoute,private userService: UserService) {}

  ngOnInit() {
    if (this.activatedRoute['_routerState'].snapshot.url.includes('hidden')) {
      this.show = false;
    }

    this.cap =this.userService.cap;

    if(!this.checkCollaborators()){
      var labelCollaborators = document.querySelector('#labelCollaborators') as HTMLDivElement;
      labelCollaborators.style.display = 'none !important';
      (document.querySelector('#baloonFour') as HTMLDivElement).style.display = 'none';
  
    }
    
    var divChat = document.querySelector('#chat') as HTMLDivElement;
    divChat.style.display = 'none';
  }

  checkCollaborators():boolean{
    return this.cap.hasOwnProperty('brz_menu_blip_queue');
  }

  easeOut() {
    var blipBaloon = document.querySelector('#baloon') as HTMLDivElement;
    blipBaloon.style.bottom = '70px';

    var blipBaloonTwo = document.querySelector('#baloonTwo') as HTMLDivElement;
    blipBaloonTwo.style.bottom = '130px';

    var blipBaloonThree = document.querySelector('#baloonThree') as HTMLDivElement;
    blipBaloonThree.style.bottom = '200px';

    var blipBaloonFour = document.querySelector('#baloonFour') as HTMLDivElement;
    blipBaloonFour.style.bottom = '270px';

    var main = document.querySelector('.mainBtn') as HTMLDivElement;
    main.dataset.content = 'x';

    var label = document.querySelector('#label') as HTMLDivElement;

    label.style.display = 'block';
    label.style.bottom = '90px';
    label.style.right = '70px';

    var labelTwo = document.querySelector('#labelCEO') as HTMLDivElement;

    labelTwo.style.display = 'block';
    labelTwo.style.bottom = '160px';
    labelTwo.style.right = '70px';

    var labelThree = document.querySelector('#labelThree') as HTMLDivElement;

    labelThree.style.display = 'block';
    labelThree.style.bottom = '230px';
    labelThree.style.right = '70px';

    var labelFour = document.querySelector('#labelCollaborators') as HTMLDivElement;
    if(this.checkCollaborators()){
      labelFour.style.display = 'block';
    }

    labelFour.style.bottom = '300px';
    labelFour.style.right = '70px';
  }

  easeIn() {
    var label = document.querySelector('#label') as HTMLDivElement;

    label.style.display = 'none';

    var labelCEO = document.querySelector('#labelCEO') as HTMLDivElement;

    labelCEO.style.display = 'none';

    var labelThree = document.querySelector('#labelThree') as HTMLDivElement;

    labelThree.style.display = 'none';

    var labelCollaborators = document.querySelector('#labelCollaborators') as HTMLDivElement;

    labelCollaborators.style.display = 'none';


    var blipBaloon = document.querySelector('#baloon') as HTMLDivElement;
    blipBaloon.style.bottom = '0px';

    var blipBaloonTwo = document.querySelector('#baloonTwo') as HTMLDivElement;
    blipBaloonTwo.style.bottom = '0px';

    var blipBaloonThree = document.querySelector('#baloonThree') as HTMLDivElement;
    blipBaloonThree.style.bottom = '0px';
    
    var blipBaloonFour = document.querySelector('#baloonFour') as HTMLDivElement;
    blipBaloonFour.style.bottom = '0px';

    var divChat = document.querySelector('#chat') as HTMLDivElement;
    if (divChat.style.display == 'block') {
    } else {
      var main = document.querySelector('.mainBtn') as HTMLDivElement;
      main.dataset.content = '?';
    }
  }

  abrir(chat) {

    if (chat == 1) {
      this.chatvar = 1;

      this.chat.withTarget('chat');
      this.chat.build();
      var divChat = document.querySelector('#chat') as HTMLDivElement;
      divChat.style.display = 'block';
  


    } else if (chat == 2) {
      this.chatvar = 2;
      this.chatCEO.withTarget('chatTwo');
      this.chatCEO.build();
      var divChatTwo = document.querySelector('#chatTwo') as HTMLDivElement;
      divChatTwo.style.display = 'block';


    } else if (chat == 3) {
      this.chatvar = 3;
      this.chatIt.withTarget('chatThree');
      this.chatIt.build();
      var divChatThree = document.querySelector('#chatThree') as HTMLDivElement;
      divChatThree.style.display = 'block';


    }else if (chat == 4) {
      this.chatvar = 4;
      this.chatCollaborators.withTarget('chatFour');
      this.chatCollaborators.build();
      var divChatFour = document.querySelector('#chatFour') as HTMLDivElement;
      divChatFour.style.display = 'block';


    }


    var main = document.querySelector('.mainBtn') as HTMLDivElement;
    var lblChat = document.querySelector('#lblChat') as HTMLDivElement;

    main.dataset.content = 'x';

    lblChat.style.display = 'block';
  }




  abrirCEO() {
    // build chat
    if (this.build == false) {
      this.chatCEO.withTarget('chat');
      this.chatCEO.withoutHistory();
      this.chatCEO.build();
      this.build = true;
    }

    var divChat = document.querySelector('#chat') as HTMLDivElement;
    var main = document.querySelector('.mainBtn') as HTMLDivElement;
    var lblChat = document.querySelector('#lblChat') as HTMLDivElement;

    divChat.style.display = 'block';
    main.dataset.content = 'x';

    lblChat.style.display = 'block';
  }

  close() {
    // this.chat.destroy()

    var lblChat = document.querySelector('#lblChat') as HTMLDivElement;
    lblChat.style.display = 'none';

    var main = document.querySelector('.mainBtn') as HTMLDivElement;
    main.dataset.content = '?';


    if(this.chatvar == 1){
      this.chat.destroy();
    var divChat = document.querySelector('#chat') as HTMLDivElement;
    divChat.style.display = 'none';
    
    }else if(this.chatvar == 2){
      this.chatCEO.destroy();
      var divChat = document.querySelector('#chatTwo') as HTMLDivElement;
      divChat.style.display = 'none';
    }else if(this.chatvar == 3){
      this.chatIt.destroy();
      var divChatThree = document.querySelector('#chatThree') as HTMLDivElement;
      divChatThree.style.display = 'none';
    }
    else if(this.chatvar == 4){
      this.chatCollaborators.destroy();
      var divChatFour = document.querySelector('#chatFour') as HTMLDivElement;
      divChatFour.style.display = 'none';
    }
    this.build == false;
  }

    hoverOutThree(){
        var label = document.querySelector('#labelCEO') as HTMLDivElement;

    // label.style.display = "none";
  }

  hoverOutTwo() {
    var label = document.querySelector('#labelCEO') as HTMLDivElement;

    // label.style.display = "none";
  }

  hoverOut() {
    var label = document.querySelector('#label') as HTMLDivElement;

    // label.style.display = "none";
  }
}
