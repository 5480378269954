import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as Highcharts from 'highcharts';
import { sum } from 'lodash';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ObjValuesSalesPolicy, SalesPolicy } from 'src/app/modules/dashboard-lead/model/salesPolicy';
import { DashboardLeadService } from 'src/app/modules/dashboard-lead/service/dashboard-lead.service';

interface BarChart {
  name: string;
  data?: number[];
}

@Component({
  selector: 'app-chart-sales-premium-count',
  templateUrl: './chart-sales-premium-count.component.html',
  styleUrls: ['./chart-sales-premium-count.component.scss']
})
export class ChartSalesPremiumCountComponent implements OnInit {

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};
  salesPolicy$: Observable<SalesPolicy>;

  users: BarChart[] = [];
  title: string;
  subtitle: string;
  totalPremium: number = 0;
  updateFlag = false;

  @Input() range: string;
  @Input() from: string;
  @Input() to: string;
  @Input() dcType: string[];
  @Output() getTotalPremium: EventEmitter<number> = new EventEmitter<number>();

  constructor(private dashboardLeadService: DashboardLeadService) { }

  ngOnInit(): void {
    this.getSalesPremium(this.range, this.from, this.to, this.dcType);
  }

  getSalesPremium(range: string, from: string, to: string, dcType: string[]) {
    this.salesPolicy$ = this.dashboardLeadService.getSalesPremiumCount(range, from, to, dcType)
      .pipe(tap((salesPolicy) => {
        this.title = salesPolicy.title;
        this.subtitle = salesPolicy.description;
        this.chartIt(salesPolicy);
      }))
  }

  chartIt(sales: SalesPolicy) {

    this.users = [];
    this.chartOptions.series = [];

    this.chartOptions.chart = {
      type: 'bar',
      backgroundColor: '#fafafa',
    }

    this.chartOptions.credits = {
      enabled: false
    }

    this.chartOptions.title = {
      text: null
    }

    this.chartOptions.tooltip = {
      valuePrefix: '$',
      style: {
        fontFamily: 'Lato',
      }
    }

    this.chartOptions.legend = {
      itemStyle: {
        fontFamily: 'Lato',
        fontWeight: '300'
      }
    }

    this.chartOptions.xAxis = {
      categories: sales.data.label
    }

    this.chartOptions.yAxis = {
      title: {
        text: 'Premium',
        style: {
          fontFamily: 'Lato',
        }
      },
      stackLabels: {
        enabled: true,
        align: 'right',
        style: {
          fontFamily: 'Lato',
        },
        formatter: function() {
          return '$' + Highcharts.numberFormat(this.total,0)
        }
      }
    }

    this.chartOptions.plotOptions = {
      series: {
        stacking: 'normal'
      },
      bar: {
        //groupPadding: 0,
        dataLabels: {
          inside: false,
          enabled: false,
          color: '#000',

        }
      }
    }

    sales.data.label.forEach((e => {
      sales.data.values[e].forEach((e: any) => {

        this.buildChartData(e.new, 'new');
        this.buildChartData(e.endorsement, 'endorsement');
        this.buildChartData(e.renew, 'renew');
        this.buildChartData(e.payment, 'payment');
        this.buildChartData(e.rewrite, 'rewrite');
        this.buildChartData(e.brl, 'brl');
        this.buildChartData(e.rmv, 'rmv');
        this.buildChartData(e.remaining_balance, 'remaining_balance');
        this.buildChartData(e.audit, 'audit');
        this.buildChartData(e.policy_cancellation, 'policy_cancellation');
        this.buildChartData(e.flat_cancel, 'flat_cancel');
        this.buildChartData(e.refund, 'refund');

      });
    }));

    this.totalPremium = 0;

    let colors = ['#ffb900', '#ff6319', '#88aca1', '#6a67ce','#1aafd0', '#fc636b', , '#00b2a9', '#8cb811', , '#3be8b0', ];

    this.users.forEach((e, i) => {

      this.totalPremium += sum(e.data);

      this.chartOptions.series.push({
        type: 'bar',
        name: e.name,
        data: e.data,
        pointWidth: 40,
        color: colors[i]
      });
    });

    this.getTotalPremium.emit(this.totalPremium);

  }

  buildChartData(dcType: number, name: string) {
    if (dcType) {
      if (!this.users.find(x => x.name === name)) {

        this.users.push({
          name: name,
          data: []
        });

      }
      this.users.find(x => x.name == name).data.push(dcType);
    }
  }

}
