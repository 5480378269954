import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginGuard } from 'src/app/core/auth/login.guard';
import { CreateQuoteComponent } from './components/create-quote/create-quote.component';
import { NewQuoteComponent } from './components/new-quote/new-quote.component';
import { DecPageComponent } from './components/view-quote/quote-detail/dec-page/dec-page.component';
import { QuoteDetailComponent } from './components/view-quote/quote-detail/quote-detail.component';
import { ViewQuoteComponent } from './components/view-quote/view-quote.component';

export const routes: Routes = [
  {
    path: 'create-quote', component: NewQuoteComponent, canActivate: [LoginGuard],
  },
  {
    path: 'view-quote', component: ViewQuoteComponent, canActivate: [LoginGuard],
  },
  // {
  //   path: 'view-quote/quote-detail/:id/:header', component: QuoteDetailComponent, canActivate: [LoginGuard],
  // },
  {
    path: 'view-quote/quote-detail/:id/:decpage/:header', component: DecPageComponent, canActivate: [LoginGuard],
  },
  {
    path: 'view-quote/quote-detail/:id/:decpage', component: DecPageComponent, canActivate: [LoginGuard],
  },
  {
    path: 'view-quote/quote-detail/:id', component: QuoteDetailComponent, canActivate: [LoginGuard],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class QuoteRoutingModule { }
