import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NewCollaboratorComponent } from '../new-collaborator/new-collaborator.component';
import { MatDialog } from '@angular/material/dialog';


export interface PeriodicElement {
  select: boolean;
  queue: number;
  status: boolean;
  name: string;
  email: string;
  tickets: string;
  idiomas: Array<string>;
  ticketsToday: number;
  openedTickets: number;
  closedTickets: number;
  totalTickets: number; 
  menu:object;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    select: false,
    queue: 2,
    status: true,
    name: 'Gustavo Lopes',
    email: 'lopesalmeidagustavo@gmail.com',
    tickets: 'audit',
    idiomas: ['EN', 'ES', 'PT'],
    ticketsToday: 23514,
    openedTickets: 321,
    closedTickets: 3,
    totalTickets: 0,menu:{}
  },
  {
    select: true,
    queue: 1,
    status: false,
    name: 'Ana Silva',
    email: 'anasilva@example.com',
    tickets: 'support',
    idiomas: ['EN', 'PT'],
    ticketsToday: 124,
    openedTickets: 15,
    closedTickets: 10,
    totalTickets: 5,menu:{}
  },
  {
    select: false,
    queue: 3,
    status: true,
    name: 'Lucas Oliveira',
    email: 'lucas.oliveira@example.com',
    tickets: 'bug',
    idiomas: ['PT'],
    ticketsToday: 87,
    openedTickets: 20,
    closedTickets: 15,
    totalTickets: 5,menu:{}
  },
  {
    select: true,
    queue: 2,
    status: true,
    name: 'Mariana Santos',
    email: 'mariana.santos@example.com',
    tickets: 'feature',
    idiomas: ['EN', 'PT'],
    ticketsToday: 150,
    openedTickets: 30,
    closedTickets: 20,
    totalTickets: 10,menu:{}
  },
  {
    select: false,
    queue: 1,
    status: true,
    name: 'Juliana Lima',
    email: 'juliana.lima@example.com',
    tickets: 'support',
    idiomas: ['EN', 'ES'],
    ticketsToday: 75,
    openedTickets: 10,
    closedTickets: 5,
    totalTickets: 5,menu:{}
  },
  {
    select: true,
    queue: 2,
    status: false,
    name: 'Pedro Santos',
    email: 'pedro.santos@example.com',
    tickets: 'bug',
    idiomas: ['PT'],
    ticketsToday: 120,
    openedTickets: 25,
    closedTickets: 20,
    totalTickets: 5,menu:{}
  },
  {
    select: true,
    queue: 3,
    status: true,
    name: 'Carolina Oliveira',
    email: 'carolina.oliveira@example.com',
    tickets: 'feature',
    idiomas: ['EN'],
    ticketsToday: 90,
    openedTickets: 15,
    closedTickets: 10,
    totalTickets: 5,menu:{}
  },
  {
    select: false,
    queue: 1,
    status: false,
    name: 'Rafael Costa',
    email: 'rafael.costa@example.com',
    tickets: 'audit',
    idiomas: ['PT'],
    ticketsToday: 110,
    openedTickets: 20,
    closedTickets: 15,
    totalTickets: 5,menu:{}
  },
  {
    select: true,
    queue: 2,
    status: true,
    name: 'Fernanda Almeida',
    email: 'fernanda.almeida@example.com',
    tickets: 'bug',
    idiomas: ['EN', 'PT'],
    ticketsToday: 150,
    openedTickets: 30,
    closedTickets: 25,
    totalTickets: 5,menu:{}
  },
];

@Component({
  selector: 'app-claim',
  templateUrl: './claim.component.html',
  styleUrls: ['./claim.component.scss']
})
export class ClaimComponent implements OnInit {

  
  displayedColumns: string[] = [
    'select',
    'queue',
    'status',
    'name',
    'email',
    'tickets',
    'idiomas',
    'ticketsToday',
    'openedTickets',
    'closedTickets',
    'totalTickets',
    'menu'
  ];

  constructor(public dialog: MatDialog) {}
  ngOnInit(): void {
    // this.openDialog();
  }

  openDialog() {
    this.dialog.open(NewCollaboratorComponent);
  }

  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  
}
