import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

import { DashboardLeadRoutingModule } from './dashboard-lead-routing.module';
import { DashboardLeadComponent } from './page/dashboard-lead.component';
import { QuotesSentComponent } from './page/quotes-sent/quotes-sent.component';
import { ConversionRateComponent } from './page/conversion-rate/conversion-rate.component';
import { QuotesComponent } from './page/quotes/quotes.component';

import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { HighchartsChartModule } from 'highcharts-angular';
import { DashboardStatsComponent } from './components/dashboard-brz-stats/dashboard-stats.component';
import { DashboardAgencyStatsComponent } from './components/dashboard-brz-agency-stats/dashboard-agency-stats.component';
import { DashboardMarketingComponent } from './components/dashboard-marketing/dashboard-marketing.component';
import { ChartBusinessTypeComponent } from './components/dashboard-brz-agency-stats/charts/chart-business-type/chart-business-type.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ChartLobComponent } from './components/dashboard-brz-agency-stats/charts/chart-lob/chart-lob.component';
import { ChartPifComponent } from './components/dashboard-brz-agency-stats/charts/chart-pif/chart-pif.component';
import { ChartPifPremiumComponent } from './components/dashboard-brz-agency-stats/charts/chart-pif-premium/chart-pif-premium.component';
import { ChartPifLobPremiumComponent } from './components/dashboard-brz-agency-stats/charts/chart-pif-lob-premium/chart-pif-lob-premium.component';
import { ChartPifBusinessTypePremiumComponent } from './components/dashboard-brz-agency-stats/charts/chart-pif-business-type-premium/chart-pif-business-type-premium.component';
import { TableTotalPremiumComponent } from './components/dashboard-brz-agency-stats/tables/table-total-premium/table-total-premium.component';
import { TablePoliciesComponent } from './components/dashboard-brz-agency-stats/tables/table-policies/table-policies.component';
import { ChartStatsPolicyCountComponent } from './components/dashboard-brz-stats/charts/chart-stats-policy-count/chart-stats-policy-count.component';
import { ChartStatsPremiumCountComponent } from './components/dashboard-brz-stats/charts/chart-stats-premium-count/chart-stats-premium-count.component';
import { BrzBarFilterComponent } from './components/dashboard-brz-stats/components/brz-bar-filter/brz-bar-filter.component';
import { BrzPieFilterComponent } from './components/dashboard-brz-agency-stats/components/brz-pie-filter/brz-pie-filter.component';
import { MatDividerModule } from '@angular/material/divider';
import { NgxMaskModule } from 'ngx-mask';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TableDetailedListComponent } from './components/dashboard-brz-stats/tables/table-detailed-list/table-detailed-list.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { ChartStatsRevenueComponent } from './components/dashboard-brz-stats/charts/chart-stats-revenue/chart-stats-revenue.component';
import { ChartStatsFeeComponent } from './components/dashboard-brz-stats/charts/chart-stats-fee/chart-stats-fee.component';
import { DashboardBrzSalesComponent } from './components/dashboard-brz-sales/dashboard-brz-sales.component';
import { BrzBarSalesFilterComponent } from './components/dashboard-brz-sales/brz-bar-sales-filter/brz-bar-sales-filter.component';
import { ChartSalesPolicyCountComponent } from './components/dashboard-brz-sales/chart-sales-policy-count/chart-sales-policy-count.component';
import { ChartSalesPremiumCountComponent } from './components/dashboard-brz-sales/chart-sales-premium-count/chart-sales-premium-count.component';
import { TableDetailedListSalesComponent } from './components/dashboard-brz-sales/table-detailed-list/table-detailed-list-sales.component';
import { MarketingFilterComponent } from './components/dashboard-marketing/components/marketing-filter/marketing-filter.component';
import { ChartMarketingDealsComponent } from './components/dashboard-marketing/charts/chart-marketing-deals/chart-marketing-deals.component';
import { ChartMarketingConversionRateComponent } from './components/dashboard-marketing/charts/chart-marketing-conversion-rate/chart-marketing-conversion-rate.component';

import { AgmCoreModule } from '@agm/core';
import { ChartMarketingGoogleMapComponent } from './components/dashboard-marketing/charts/chart-marketing-google-map/chart-marketing-google-map.component';
import { TableMarketingDealsComponent } from './components/dashboard-marketing/tables/table-marketing-deals/table-marketing-deals.component';
import { ChartPodiumComponent } from './components/dashboard-brz-sales/chart-podium/chart-podium.component';
import { DashboardBrzCarrierTargetComponent } from './components/dashboard-brz-carrier-target/dashboard-brz-carrier-target.component';
import { BrzCarrierFilterComponent } from './components/dashboard-brz-carrier-target/components/brz-carrier-filter/brz-carrier-filter.component';
import { ChartCarrierComponent } from './components/dashboard-brz-carrier-target/charts/chart-carrier/chart-carrier.component';
import { CarrierServiceService } from './components/dashboard-brz-carrier-target/services/carrier-service.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgSelectModule } from '@ng-select/ng-select';import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatMenuModule } from '@angular/material/menu';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogAnimationsExampleDialogComponent } from './components/dashboard-collaborators/dialog-animations-example-dialog/dialog-animations-example-dialog.component';
import { ModalLogsComponent } from './components/dashboard-collaborators/modal-logs/modal-logs.component';
import { CdkContextMenuTrigger, CdkMenuItem, CdkMenu } from '@angular/cdk/menu';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatExpansionModule } from '@angular/material/expansion';
import { DashboardCollaboratorsModule } from './components/dashboard-collaborators/dashboard-collaborators.module';

@NgModule({
  declarations: [
    DashboardLeadComponent,
    QuotesSentComponent,
    ConversionRateComponent,
    QuotesComponent,
    DashboardStatsComponent,
    DashboardAgencyStatsComponent,
    DashboardMarketingComponent,
    ChartBusinessTypeComponent,
    ChartPifComponent,
    ChartPifPremiumComponent,
    ChartPifLobPremiumComponent,
    ChartPifBusinessTypePremiumComponent,
    ChartLobComponent,
    TableTotalPremiumComponent,
    TablePoliciesComponent,
    ChartStatsPolicyCountComponent,
    ChartStatsPremiumCountComponent,
    BrzPieFilterComponent,
    BrzBarFilterComponent,
    TableDetailedListComponent,
    ChartStatsRevenueComponent,
    ChartStatsFeeComponent,
    DashboardBrzSalesComponent,
    BrzBarSalesFilterComponent,
    ChartSalesPolicyCountComponent,
    ChartSalesPremiumCountComponent,
    TableDetailedListSalesComponent,
    MarketingFilterComponent,
    ChartMarketingDealsComponent,
    ChartMarketingConversionRateComponent,
    ChartMarketingGoogleMapComponent,
    TableMarketingDealsComponent,
    ChartPodiumComponent,
    DashboardBrzCarrierTargetComponent,
    BrzCarrierFilterComponent,
    ChartCarrierComponent,
    DialogAnimationsExampleDialogComponent,
    ModalLogsComponent,
  ],

  imports: [
    CommonModule,
    MatStepperModule,
    DragDropModule,
    MatListModule,
    MatDialogModule,

    MatGridListModule,
    MatExpansionModule,
    DashboardLeadRoutingModule,
    MatCardModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    OverlayModule,
    ScrollingModule,
    MatCheckboxModule,
    HighchartsChartModule,
    FormsModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDatepickerModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatMenuModule,
    NgSelectModule,
    NgxMaskModule.forRoot(),
    MatTableModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCAsTVbM9kXa3fD17PWdjfOF9SMmEYudvs',
      libraries: ['visualization'],
    }),
  ],
  providers: [CarrierServiceService, ChartCarrierComponent],
})
export class DashboardLeadModule {}
