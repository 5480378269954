import { SdrBlipComponent } from './../sdr-blip/sdr-blip.component';
import { ClaimBlipComponent } from './../claim-blip/claim-blip.component';
import { Component, Inject, inject } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BlipServiceService } from '../blip-service.service';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-new-collaborators-blip',
  templateUrl: './new-collaborators-blip.component.html',
  styleUrls: ['./new-collaborators-blip.component.scss'],
})
export class NewCollaboratorsBlipComponent {
  dialogForm: FormGroup;
  emailCadastrado: Boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<NewCollaboratorsBlipComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private blipService: BlipServiceService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.dialogForm = this._formBuilder.group({
      email: [this.data?.agentData?.email || '', [this.validaEmail.bind(this)]],
      nome: [this.data?.agentData?.nome || '', [Validators.required]],
      idiomas: new FormControl(this.data?.agentData?.idiomas || [], [
        Validators.required,
      ]),
      camada: [1, [Validators.required]],
      equipe: [this.data?.departament || '', [Validators.required]],
      distribuir: [false, [Validators.required]],
      maxTickets: [this.data.simultaneousTicket, [Validators.required]],
    });
    this.dialogForm.controls['camada'].disable();
    if (!this.data.addCollaborator) {
      this.dialogForm.controls['email'].disable();
    }

    this.dialogForm.get('idiomas').valueChanges.subscribe((data) => {
      const filteredArray = data.filter(
        (item) => item !== null && item !== undefined && item !== ''
      );
      // this.dialogForm.controls['idiomas'].setValue(filteredArray);
    });
  }

  validaEmail(control: AbstractControl): ValidationErrors | null {
    let isEqual = this.data?.dataSource?.filter(
      (item) => item.email == control.value
    );
    this.emailCadastrado = isEqual.length > 0;

    return isEqual.length > 0 ? { invalidEmail: true } : null;
  }
  
  openSnackBar(message) {
    this._snackBar.open(message, 'Got it!');
  }

  selectALL(element) {
    const idiomas = this.dialogForm.controls['idiomas'];

    idiomas.setValue(element.source._selected ? ['EN', 'ES', 'PT'] : ['']);
  }

  closeModal() {
    this.dialogRef.close();
  }

  addCollaborators() {
    const body = this.dialogForm.value;
    body.idiomas = body.idiomas.filter(
      (item) => item !== null && item !== undefined && item !== ''
    );
    if (this.dialogForm.status == 'VALID') {
      this.blipService.addColaborattors(body).subscribe((e) => {
        if (e.success) {
          this.dialogRef.close();
        } else {
          this.openSnackBar(e.response);
        }
      });
    } else {
      Object.keys(this.dialogForm.controls).forEach((field) => {
        const control = this.dialogForm.get(field);
        control?.markAsTouched({ onlySelf: true });
      });
    }
  }

  // emailValidator(control) {
  //   const email = control.value;
  //   if (email && email.indexOf('@brzinsurance.com') === -1) {
  //     return { invalidEmail: true };
  //   }
  //   return null;
  // }

  formatarEmail(event) {
    const emailControl = this.dialogForm.get('email');
    let valor = emailControl?.value;

    if (valor && !valor.endsWith('@brzinsurance.com')) {
      valor = valor.split('@')[0] + '@brzinsurance.com';
      emailControl?.setValue(valor);
    }
  }

  editCollaborators() {
    const body = this.dialogForm.value;

    body.id = this.data?.agentData?.id;
    body.maxTickets = this.data?.agentData?.maxTickets;

    body.idiomas
      ? (body.idiomas = body.idiomas.filter(
          (item) => item !== null && item !== undefined && item !== ''
        ))
      : null;
    if (this.dialogForm.status == 'VALID') {
      this.blipService
        .editCollaborator(this.dialogForm.value)
        .subscribe((e) => {
          if (e.success) {
            this.dialogRef.close();
          } else {
            this.openSnackBar(e.response);
          }
        });
    } else {
      Object.keys(this.dialogForm.controls).forEach((field) => {
        const control = this.dialogForm.get(field);
        control?.markAsTouched({ onlySelf: true });
      });
    }
  }
}
