import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, pluck, switchMap, take, tap } from 'rxjs/operators';
import { TokenService } from 'src/app/core/token/token.service';
import { environment } from 'src/environments/environment';
import { BasicInfo } from '../model/basicInfo';
import { Driver, DriverInfo } from '../model/driver';
import { ApiFile, DataQuote, File, IViewQuoteDTO, Quote } from '../model/quote';
import { CreateQuote } from '../model/submit';
import { ICreateQuoteDTO } from '../model/submit/dto/ICreateQuoteDTO';
import { Vehicle, VehicleInfo } from '../model/vehicles';
import { DecPageLead } from '../model/decpage/dec-page-lead';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { UserService } from 'src/app/core/user/user.service';
import { SortQuote } from '../model/sort/sort-quote';

@Injectable({
  providedIn: 'root'
})
export class QuotesService {
  nav = (window.navigator as any)
  token = this.tokenService.getToken();
  private _basicInfo: BehaviorSubject<BasicInfo> = new BehaviorSubject(null);
  private _driverInfo: BehaviorSubject<DriverInfo> = new BehaviorSubject(null);
  private _vehicleInfo: BehaviorSubject<VehicleInfo> = new BehaviorSubject(null);
  private _uploadedFiles: BehaviorSubject<File> = new BehaviorSubject(null);
  private _quotes: BehaviorSubject<number> = new BehaviorSubject(1);
  private _limit: BehaviorSubject<number> = new BehaviorSubject(null);
  private _page: BehaviorSubject<number> = new BehaviorSubject(null);
  private _sort: BehaviorSubject<SortQuote> = new BehaviorSubject(null);

  public get Page(): Observable<number> {
    return this._page.asObservable();
  }

  public get Limit(): Observable<number> {
    return this._limit.asObservable();
  }

  public get Sort(): Observable<SortQuote> {
    return this._sort.asObservable();
  }

  public get UploadedFiles(): Observable<File> {
    return this._uploadedFiles.asObservable();
  }

  public get BasicInfo(): Observable<BasicInfo> {
    return this._basicInfo.asObservable();
  }

  public get DriverInfo(): Observable<DriverInfo> {
    return this._driverInfo.asObservable();
  }

  public get VehicleInfo(): Observable<VehicleInfo> {
    return this._vehicleInfo.asObservable();
  }

  public get Quote(): Observable<number> {
    return this._quotes.asObservable();
  }

  setLimit(limit: number) {
    this._limit.next(limit);
  }

  setPage(page: number) {
    this._page.next(page);
  }

  setSort(sort: SortQuote) {
    this._sort.next(sort);
  }

  setQuote(decPage: number) {
    this._quotes.next(decPage);
  }

  setUploadedFile(id: File) {
    this._uploadedFiles.next(id);
  }

  setBasicInfo(greeting: BasicInfo) {
    this._basicInfo.next(greeting);
  }

  resetBasicInfo() {
    this._basicInfo.next(null);
  }

  setDriverInfo(driver: DriverInfo) {
    this._driverInfo.next(driver);
  }

  resetDriverInfo() {
    this._driverInfo.next(null);
  }

  setVehicleInfo(vehicle: VehicleInfo) {
    this._vehicleInfo.next(vehicle);
  }

  resetVehicleInfo() {
    this._vehicleInfo.next(null);
  }

  constructor(private http: HttpClient, private tokenService: TokenService, private notification: NotificationService, private userService: UserService) { }

  saveQuote(dto: ICreateQuoteDTO): Observable<CreateQuote> {

    if (this.userService.cap.hasOwnProperty('brzinsurance_lead_submit_quote')) {
      const httpOptions = {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.token}`
        })
      };

      return this.http.post<CreateQuote>(`${environment.apiUrl}/brz/v1/quotes/createquote`, dto, httpOptions);
    } else {
      this.notification.alert('You have no permission to submit quote.');
    }


  }

  saveFile(formData: FormData): Observable<CreateQuote> {

    if (this.userService.cap.hasOwnProperty('brzinsurance_lead_upload_dec_page')) {

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.token}`
        }),
      };

      return this.http.post<CreateQuote>(`${environment.apiUrl}/brz/v1/files/uploadfiles`, formData, httpOptions);
    } else {
      this.notification.alert('You have no permission to upload dec page.')
    }

  }

  deleteFile(fileId: number): Observable<string> {

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`
      }),
    };

    return this.http.delete<string>(`${environment.apiUrl}/brz/v1/files/?file_id=${fileId}`, httpOptions)
      .pipe(
        pluck('status')
      )

  }

  getQuotes(word?: string, to?: string, from?: string, limit?: number, fieldSort?: string, sortBy?: string, page?: number, status?: string): Observable<Quote> {

    if (this.userService.cap.hasOwnProperty('brzinsurance_lead_view_quotes')) {

      const httpOptions = {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.token}`
        }),
      };

      let dto: IViewQuoteDTO = {
        limit: limit || null,
        date_from: from || null,
        date_to: to || null,
        search_word: word || null,
        page: page,
        sort_by_field: fieldSort || null,
        sort_by: sortBy || null,
        status: status
      }

      return this.http.post<Quote>(`${environment.apiUrl}/brz/v1/quotes/view`, dto, httpOptions)
        .pipe(take(1),
          map(data => {

            data.data.map((e) => {

              e.preferred_language = e.preferred_language == 'en' ? 'ENGLISH' : e.preferred_language == 'pt' ? 'PORTUGUESE' : e.preferred_language == 'es' ? 'SPANISH' : null;
              e.primary_residence = e.primary_residence == 'R' ? 'RENT' : e.primary_residence == 'O' ? 'OWN' : e.primary_residence == 'L' ? 'LIVING WITH PARENTS' : null;
              e.coverage_selection = e.coverage_selection == 'B' ? 'BASIC' : e.coverage_selection == 'S' ? 'STANDARD' : e.coverage_selection == 'P' ? 'PREMIUM' : null;
              e.status = e.status == 'P' ? 'PENDING' : e.status == 'A' ? 'ACTIVE' : e.status == 'L' ? 'LOST' : e.status == 'W' ? 'WON' : null;

            });

            return data;

          }));
    }
    else {
      this.notification.alert('You have no permission to get quotes.');
    }
  }

  getFiles(id: number): Observable<ApiFile> {

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`
      }),
    };

    return this.http.get<ApiFile>(`${environment.apiUrl}/brz/v1/quotes/files?lead_id=${id}`, httpOptions);

  }

  getDecPageByLead(id: number): Observable<DecPageLead> {

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`
      }),
    };

    return this.http.get<DecPageLead>(`${environment.apiUrl}/brz/v1/quotes/files/dec_page?type=lead&id=${id}`, httpOptions)
      .pipe(
        //pluck('data')
      );

  }

  getVehicle(idQuote: number): Observable<Vehicle> {

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`
      }),
    };

    return this.http.get<Vehicle>(`${environment.apiUrl}/brz/v1/quotes/vehicles?lead_id=${idQuote}`, httpOptions);
  }

  getBasicsById(idQuote: number): Observable<DataQuote> {

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`
      }),
    };

    return this.http.get<DataQuote>(`${environment.apiUrl}/brz/v1/quotes/view?lead_id=${idQuote}`, httpOptions).pipe(
      pluck('data'),
      pluck('0'),
      map((data: DataQuote) => {

        data.preferred_language = data.preferred_language == 'en' ? 'ENGLISH' : data.preferred_language == 'pt' ? 'PORTUGUESE' : data.preferred_language == 'es' ? 'SPANISH' : null;
        data.primary_residence = data.primary_residence == 'R' ? 'RENT' : data.primary_residence == 'O' ? 'OWN' : data.primary_residence == 'L' ? 'LIVING WITH PARENTS' : null;
        data.coverage_selection = data.coverage_selection == 'B' ? 'BASIC' : data.coverage_selection == 'S' ? 'STANDARD' : data.coverage_selection == 'P' ? 'PREMIUM' : null;
        data.status = data.status == 'P' ? 'PENDING' : data.status == 'A' ? 'ACTIVE' : data.status == 'L' ? 'LOST' : data.status == 'W' ? 'WON' : null;

        return data;

      })
    );
  }

  getDriver(idQuote: number): Observable<Driver> {

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`
      }),
    };

    return this.http.get<Driver>(`${environment.apiUrl}/brz/v1/quotes/drivers?lead_id=${idQuote}`, httpOptions);
  }

  downloadDecPage(fileId: number, quoteId: number): Observable<Blob> {

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
        'Content-Type': 'application/pdf',
        'Accept': 'application/pdf'
      }),
      responseType: 'blob' as 'json',

    };

    return this.http.get<Blob>(`${environment.apiUrl}/brz/v1/files/download/?file_id=${fileId}`, httpOptions).pipe(
      tap(file => {

        if (file['error']) {
          this.notification.error(file['error']);
        } else {
          var newBlob = new Blob([file], { type: "application/pdf" });

          if (window.navigator && this.nav.msSaveOrOpenBlob) {
            this.nav.msSaveOrOpenBlob(newBlob);
            return;
          }

          const data = window.URL.createObjectURL(newBlob);

          var link = document.createElement('a');
          link.href = data;
          link.download = `dec-page-lead-id-${quoteId}.pdf`;
          // this is necessary as link.click() does not work on the latest firefox
          link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

          setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            link.remove();
          }, 100);
        }
      })
    );
  }

}
