import { AutocompleteComponent } from './components/dashboard-brz-carrier-target/components/test/autocomplete/autocomplete.component';
import { DashboardBrzCarrierTargetComponent } from './components/dashboard-brz-carrier-target/dashboard-brz-carrier-target.component';
import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardMarketingComponent } from './components/dashboard-marketing/dashboard-marketing.component';
import { DashboardStatsComponent } from './components/dashboard-brz-stats/dashboard-stats.component';
import { DashboardAgencyStatsComponent } from './components/dashboard-brz-agency-stats/dashboard-agency-stats.component';
import { DashboardLeadComponent } from './page/dashboard-lead.component';
import { DashboardBrzSalesComponent } from './components/dashboard-brz-sales/dashboard-brz-sales.component';
import { DashboardCollaboratorsComponent } from './components/dashboard-collaborators/dashboard-collaborators.component';

export const routes: Routes = [
  {
    path: '',
    component: DashboardLeadComponent,
  },
  {
    path: 'stats',
    component: DashboardStatsComponent,
  },
  {
    path: 'agency-stats',
    component: DashboardAgencyStatsComponent,
  },
  {
    path: 'sales',
    component: DashboardBrzSalesComponent,
  },
  {
    path: 'marketing',
    component: DashboardMarketingComponent,
  },
  {
    path: 'carrier-target',
    component: DashboardBrzCarrierTargetComponent,
  },
  {
    path: 'autocomplete',
    component: AutocompleteComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardLeadRoutingModule {}
