import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as Highcharts from 'highcharts';
import { sum } from 'lodash';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SalesPolicy } from 'src/app/modules/dashboard-lead/model/salesPolicy';
import { DashboardLeadService } from 'src/app/modules/dashboard-lead/service/dashboard-lead.service';

interface BarChart {
  name: string;
  data?: number[];
}

@Component({
  selector: 'app-chart-stats-revenue',
  templateUrl: './chart-stats-revenue.component.html',
  styleUrls: ['./chart-stats-revenue.component.scss']
})
export class ChartStatsRevenueComponent implements OnInit {

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};
  teste: boolean = false;
  salesPolicy$: Observable<SalesPolicy>;

  title: string;
  subtitle: string;
  updateFlag = false;

  @Input() range: string;
  @Input() from: string;
  @Input() to: string;
  @Input() dcType: string[];

  @Output() getTotalRevenue: EventEmitter<number> = new EventEmitter<number>();

  constructor(private dashboardLeadService: DashboardLeadService) { }

  ngOnInit(): void {
    this.getStatsRevenue(this.range, this.from, this.to, this.dcType);
  }

  getStatsRevenue(range: string, from: string, to: string, dcType: string[]) {
    this.salesPolicy$ = this.dashboardLeadService.getStatsRevenue(range, from, to, dcType)
      .pipe(tap((salesPolicy) => {
        this.title = salesPolicy.title;
        this.subtitle = salesPolicy.description;
        this.chartIt(salesPolicy);
      }))
  }

  chartIt(sales: SalesPolicy) {

    this.chartOptions.series = [];

    this.chartOptions.chart = {
      type: 'column',
      backgroundColor: '#fafafa',
    }

    this.chartOptions.credits = {
      enabled: false
    }

    this.chartOptions.title = {
      text: null
    }

    this.chartOptions.legend = {
      itemStyle: {
        fontFamily: 'Lato',
        fontWeight: '300'
      }
    }

    this.chartOptions.tooltip = {
      valuePrefix: '$',
      style: {
        fontFamily: 'Lato',
      }
    }


    this.chartOptions.xAxis = {
      categories: sales.data.label
    }

    this.chartOptions.yAxis = {
      title: {
        text: 'Revenue',
        style: {
          fontFamily: 'Lato',
        }
      },
      labels: {
        enabled: true,
        align: 'right',
        style: {
          fontFamily: 'Lato',
        }
      }
    }

    this.chartOptions.plotOptions = {
      bar: {
        dataLabels: {
          inside: false,
          enabled: false,
          color: '#000',
        }
      }
    }

    let totals: number[] = [];

    sales.data.label.forEach((e => {
      sales.data.values[e].forEach((e: any) => {
        totals.push(e.total);
      });
    }));

    this.chartOptions.series.push({
      type: 'column',
      name: 'Revenue',
      data: totals
    });

    this.getTotalRevenue.emit(sum(totals));

  }

}
