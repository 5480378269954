<mat-card class="card-detail" *ngIf="basics$ | async as basicInfo">

  <mat-card-header>
    <mat-card-title>{{basicInfo.first_name.toUpperCase()}} {{basicInfo.last_name.toUpperCase()}}
    </mat-card-title>
    <mat-card-subtitle>Quote Id: {{basicInfo.lead_id}} | Date: {{basicInfo.lead_date}}
    </mat-card-subtitle>

    <mat-card-subtitle style="display: flex;">

      <p *ngIf="basicInfo.status">Status:
        <span [ngClass]="{'pending': basicInfo.status === 'PENDING', 'active': basicInfo.status === 'ACTIVE', 'won': basicInfo.status === 'WON'}">
          <span *ngIf="basicInfo.status === 'LOST'">
              
            <span class="lost">{{ basicInfo.status || '-'}}</span>
            <i class="fas fa-info-circle info" matTooltip="{{basicInfo.lost_reason}}" matTooltipPosition="above"></i>
          </span>
          <span *ngIf="basicInfo.status != 'LOST'">
            {{ basicInfo.status || '-'}}
          </span>
        </span>
      </p>

      <p class="go-away" *ngIf="basicInfo.premium">Premium: {{ basicInfo.premium | currency}}</p>

      <p class="go-away" *ngIf="basicInfo.dec_page_file_id">Dec Page <i class="far fa-file-pdf"
          (click)="downloadDecPage(basicInfo.dec_page_file_id)"></i></p>

    </mat-card-subtitle>
  </mat-card-header>

  <mat-accordion class="example-headers-align" multi>

    <mat-expansion-panel [expanded]="true" style="box-shadow: none; background-color: #fdfdfd;">

      <mat-expansion-panel-header>

        <mat-panel-title>
          Basic Info
        </mat-panel-title>
        <mat-panel-description>
          <mat-icon>account_circle</mat-icon>
        </mat-panel-description>

      </mat-expansion-panel-header>

      <div class="basic-infos">

        <div class="wrap-4">
          <mat-form-field>
            <mat-label>Phone</mat-label>
            <input matInput disabled value="{{basicInfo.phone}}">
          </mat-form-field>

          <mat-form-field>
            <mat-label>Email</mat-label>
            <input matInput disabled value="{{basicInfo.email.toUpperCase()}}">
          </mat-form-field>

          <mat-form-field>
            <mat-label>Adress Line 1</mat-label>
            <input matInput disabled value="{{basicInfo.line1.toUpperCase()}}">
          </mat-form-field>

          <mat-form-field>
            <mat-label>Adress Line 2</mat-label>
            <input matInput disabled value="{{basicInfo.line2.toUpperCase()}}">
          </mat-form-field>
        </div>
        <div class="wrap-4">
          <mat-form-field>
            <mat-label>City</mat-label>
            <input matInput disabled value="{{basicInfo.city.toUpperCase()}}">
          </mat-form-field>

          <mat-form-field>
            <mat-label>State</mat-label>
            <input matInput disabled value="{{basicInfo.state.toUpperCase()}}">
          </mat-form-field>

          <mat-form-field>
            <mat-label>Zip Code</mat-label>
            <input matInput disabled value="{{basicInfo.zipcode}}">
          </mat-form-field>

          <mat-form-field>
            <mat-label>Policy Start Date</mat-label>
            <input matInput disabled value="{{basicInfo.policy_effective_date}}">
          </mat-form-field>
        </div>
        <div class="wrap-4">
          <mat-form-field>
            <mat-label>Currently Insured</mat-label>
            <input matInput disabled value="{{basicInfo.currently_insured === 'Y' ? 'YES' : 'NO'}}">
          </mat-form-field>

          <mat-form-field>
            <mat-label>Primary Residence</mat-label>
            <input matInput disabled value="{{basicInfo.primary_residence}}">
          </mat-form-field>

          <mat-form-field>
            <mat-label>Coverage Selection</mat-label>
            <input matInput disabled value="{{basicInfo.coverage_selection}}">
          </mat-form-field>

          <mat-form-field>
            <mat-label>Lead Source</mat-label>
            <input matInput disabled value="{{basicInfo.lead_source ===  1 ? 'LENDBUZZ' : null}}">
          </mat-form-field>
        </div>
        <div class="wrap-4">
          <mat-form-field>
            <mat-label>Prefered Language</mat-label>
            <input matInput disabled value="{{basicInfo.preferred_language}}">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Notes</mat-label>
            <textarea matInput disabled value="{{basicInfo.notes}}"></textarea>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Loan Number</mat-label>
            <input matInput disabled value="{{basicInfo.loan_number}}">
          </mat-form-field>
        </div>

      </div>

    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="drivers$ | async as drivers" [expanded]="true"
      style="box-shadow: none; background-color: #fdfdfd;">

      <mat-expansion-panel-header>

        <mat-panel-title>
          Drivers Info
        </mat-panel-title>
        <mat-panel-description>
          <mat-icon>perm_contact_calendar</mat-icon>
        </mat-panel-description>

      </mat-expansion-panel-header>

      <div class="basic-infos" *ngFor="let driver of drivers" style="margin-top: 20px;">

        <div class="wrap-4">
          <mat-form-field>
            <mat-label>Driver Id</mat-label>
            <input matInput disabled value="{{driver.driver_id}}">
          </mat-form-field>

          <mat-form-field>
            <mat-label>First Name</mat-label>
            <input matInput disabled value="{{driver.first_name.toUpperCase()}}">
          </mat-form-field>

          <mat-form-field>
            <mat-label>Last Name</mat-label>
            <input matInput disabled value="{{driver.last_name.toUpperCase()}}">
          </mat-form-field>

          <mat-form-field>
            <mat-label>Gender</mat-label>
            <input matInput disabled value="{{driver.gender === 'M' ? 'MALE' : 'FEMALE'}}">
          </mat-form-field>
        </div>

        <div class="wrap-4">
          <mat-form-field>
            <mat-label>DOB</mat-label>
            <input matInput disabled value="{{driver.dob}}">
          </mat-form-field>

          <mat-form-field>
            <mat-label>License State</mat-label>
            <input matInput disabled value="{{driver.license_state.toUpperCase()}}">
          </mat-form-field>

          <mat-form-field>
            <mat-label>License Number</mat-label>
            <input matInput disabled value="{{driver.license_number}}">
          </mat-form-field>

          <mat-form-field>
            <mat-label>Foreign Lead Id</mat-label>
            <input matInput disabled value="{{driver.foreign_lead_id}}">
          </mat-form-field>
        </div>

      </div>

    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="vehicles$ | async as vehicles" [expanded]="true"
      style="box-shadow: none; background-color: #fdfdfd;">

      <mat-expansion-panel-header>

        <mat-panel-title>
          Vehicles Info
        </mat-panel-title>

        <mat-panel-description>
          <mat-icon>directions_car</mat-icon>
        </mat-panel-description>

      </mat-expansion-panel-header>

      <div class="basic-infos" *ngFor="let vehicle of vehicles" style="margin-top: 20px;">

        <div class="wrap-2">

          <mat-form-field>
            <mat-label>Vehicle Id</mat-label>
            <input matInput disabled value="{{vehicle.vehicle_id}}">
          </mat-form-field>

          <mat-form-field>
            <mat-label>VIN</mat-label>
            <input matInput disabled value="{{vehicle.vin.toUpperCase()}}">
          </mat-form-field>

        </div>

        <div class="wrap-4">

          <mat-form-field>
            <mat-label>Annual Miles</mat-label>
            <input matInput disabled value="{{vehicle.annual_miles}}">
          </mat-form-field>

          <mat-form-field>
            <mat-label>DED collision</mat-label>
            <input matInput disabled value="{{vehicle.ded_collision}}">
          </mat-form-field>

          <mat-form-field>
            <mat-label>Foreign Driver Id</mat-label>
            <input matInput disabled value="{{vehicle.foreign_driver_id}}">
          </mat-form-field>

          <mat-form-field>
            <mat-label>Foreign Lead Id</mat-label>
            <input matInput disabled value="{{vehicle.foreign_lead_id}}">
          </mat-form-field>
        </div>

        <div class="wrap-4">
          <mat-form-field>
            <mat-label>DED comprehensive</mat-label>
            <input matInput disabled value="{{vehicle.ded_comprehensive}}">
          </mat-form-field>

          <mat-form-field>
            <mat-label>Is Transportation</mat-label>
            <input matInput disabled value="{{vehicle.is_transportation === 'Y' ? 'YES' : 'NO'}}">
          </mat-form-field>

          <mat-form-field>
            <mat-label>Is Towing</mat-label>
            <input matInput disabled value="{{vehicle.is_towing === 'Y' ? 'YES' : 'NO'}}">
          </mat-form-field>

          <mat-form-field>
            <mat-label>Primary Use</mat-label>
            <input matInput disabled value="{{vehicle.primary_use === 'P' ? 'PLEASURE' : 'BUSINESS'}}">
          </mat-form-field>

        </div>

      </div>

    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="images.length > 0" [expanded]="true"
      style="box-shadow: none; background-color: #fdfdfd;">

      <mat-expansion-panel-header>

        <mat-panel-title>
          Attached Files
        </mat-panel-title>

        <mat-panel-description>
          Preview Attached Files
          <mat-icon>insert_photo</mat-icon>
        </mat-panel-description>

      </mat-expansion-panel-header>

      <div class="images">
        <div *ngFor="let image of images" style="margin-top: 20px;">

          <img *ngIf="image.data.includes('application/pdf')" (click)="teste(image.id)" class="pdf"
            src="../../../../../../assets/images/pdf.svg">

          <img *ngIf="!image.data.includes('application/pdf')" style="height: 200px;" [src]="image.data | safeHtml">

        </div>
      </div>

    </mat-expansion-panel>

  </mat-accordion>

</mat-card>