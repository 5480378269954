<mat-card class="card-admin">

  <mat-card-header class="card-header">

    <div class="dc-logo"></div>

    <div class="ml-28">
      <mat-card-title class="title-dashboard">View Quotes</mat-card-title>
      <mat-card-subtitle>General data for quotes</mat-card-subtitle>
    </div>

  </mat-card-header>

  <app-card-filter-view-quote (change-filter)="changeFilter($event)"></app-card-filter-view-quote>
  <app-table-view-quote (change-paginator)="changePaginator($event)"></app-table-view-quote>

</mat-card>
