<mat-card class="charts-card" *ngIf="heWantsDeals() || heWantsConversionRate() || heWantsDealsMap()">

  <mat-card-header class="header-quote">

    <i class="fas fa-search-location" style="margin-left: -20px; margin-top: 14px;"></i>

    <div class="ml-28">
      <mat-card-title class="title-dashboard">Brz Marketing</mat-card-title>
      <mat-card-subtitle>General data for marketing</mat-card-subtitle>
    </div>

  </mat-card-header>

  <mat-card-content>

    <form [formGroup]="fgFilter" class="form-group" [style.fontSize.px]="14" (ngSubmit)="onSubmit()">

      <mat-form-field appearance="fill" class="custom-form-field">
        <mat-label>Period</mat-label>
        <mat-select formControlName="fcPeriod" required>
          <mat-option value="all">All</mat-option>
          <mat-option value="today">Today</mat-option>
          <mat-option value="thisyear">This Year</mat-option>
          <mat-option value="thisweek">This Week</mat-option>
          <mat-option value="thismonth">This Month</mat-option>
          <mat-option value="customdate">Custom</mat-option>
        </mat-select>
      </mat-form-field>

      <div *ngIf="fgFilter.get('fcPeriod').value === 'customdate'" style="display: flex;">

        <mat-form-field color="primary" appearance="fill" class="custom-form-field">
          <mat-label>From</mat-label>
          <input matInput formControlName="fcDtFrom" [matDatepicker]="dtFrom" appMaskDate>
          <mat-datepicker-toggle matSuffix [for]="dtFrom"></mat-datepicker-toggle>
          <mat-datepicker #dtFrom></mat-datepicker>
        </mat-form-field>

        <mat-form-field color="primary" appearance="fill" class="custom-form-field">
          <mat-label>To</mat-label>
          <input matInput formControlName="fcDtTo" placeholder="MM/DD/YYYY" [matDatepicker]="policyPickerMobile"
            appMaskDate>
          <mat-datepicker-toggle matSuffix [for]="policyPickerMobile"></mat-datepicker-toggle>
          <mat-datepicker #policyPickerMobile></mat-datepicker>
        </mat-form-field>

      </div>

      <button class="btn-search" mat-mini-fab color="primary" [disabled]="!fgFilter.valid">
        <mat-icon>search</mat-icon>
      </button>

      <button class="btn-search-mobile" mat-stroked-button color="primary" [disabled]="!fgFilter.valid">
        search
      </button>


    </form>

    <div class="pie-charts wrap">
      <app-chart-marketing-deals *ngIf="heWantsDeals()" style="width: 100%;"  [range]="range" [from]="from" [to]="to"></app-chart-marketing-deals>
      <app-chart-marketing-conversion-rate *ngIf="heWantsConversionRate()"  style="width: 100%;" [range]="range" [from]="from" [to]="to"></app-chart-marketing-conversion-rate>
    </div>
    <app-chart-marketing-google-map *ngIf="heWantsDealsMap()" style="width: 100%;"  [range]="range" [from]="from" [to]="to"></app-chart-marketing-google-map>

  </mat-card-content>
</mat-card>
