<div class="box">
  <div class="box-title">
    <div style="align-items: center; display: flex">
      <span class="subtitle-minor"
        >Claim
        <mat-icon style="transform: scale(0.8); position: absolute"
          >schedule</mat-icon
        >
      </span>

      <span
        style="
          color: #e24132;
          margin-left: 40px;
          font-family: Lato;
          font-size: 18px;
          font-weight: 400;
          line-height: 21.6px;
          text-align: left;
        "
      >
        {{ waitingTickets }} tickets waiting</span
      >
    </div>

    <div class="end" style="display: flex">
      <div
        style="
          display: flex;
          justify-content: space-between;
          margin-right: 20px;
          align-items: center;
          width: 260px;
        "
      >
        <span class="simultaneous-flag">Overall Waiting Tickets Limit</span>
        <form [formGroup]="form">
          <select
            name=""
            id="simultaneousTicket"
            class="slct"
            formControlName="simultaneous"
            (change)="changeSimultaneousTicket($event)"
          >
            <option value=5 class="opt">5</option>
            <option value=10 class="opt">10</option>
          </select>
        </form>
      </div>
      <button class="add-btn" (click)="openDialog({ save: true })">
        New Collaborator
      </button>
    </div>
  </div>

  <div class="table-data">
    <div class="empty-box" *ngIf="emptyData">
      <span class="empty-text">Empty Data</span>
    </div>
    <table
      mat-table
      class="table-data-style"
      [dataSource]="dataSource"
      fixedLayout="false"
    >
    <ng-container matColumnDef="sequence" sticky>
      <th
        mat-header-cell
        *matHeaderCellDef
        style="
          height: 49px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 10% ;
          max-width: 150px;
        "
        id="myStatus"
      >
        SEQUENCE
      </th>
      <td
        mat-cell
        *matCellDef="let row; let i = index"
        style="
          height: 49px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 10% ;
          max-width: 150px;
        "
      >
       {{i+1}}
      </td>
    </ng-container>
      <ng-container matColumnDef="distribuir" sticky>
        <th
          mat-header-cell
          *matHeaderCellDef
          style="
            height: 49px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 10% ;
            max-width: 150px;
          "
          id="myStatus"
        >
          DISTRIBUTION
        </th>
        <td
          mat-cell
          *matCellDef="let row; let i = index"
          style="
            height: 49px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 10% ;
            max-width: 150px;
          "
        >
          <app-slide
            [initialState]="row.distribuir"
            [switchId]="'switch-' + i + '-claim'"
            [agentData]="row"
            (updateTable)="getQueue()"
          ></app-slide>
        </td>
      </ng-container>

      <ng-container matColumnDef="nome" sticky>
        <th
          mat-header-cell
          *matHeaderCellDef
          style="
            height: 49px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 10%;
            max-width: 150px;
          "
        >
          NAME
        </th>
        <td
          mat-cell
          *matCellDef="let row"
          style="
            height: 49px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 10% ;
            max-width: 150px;
          "
        >
          {{ row.nome }}
        </td>
      </ng-container>

      <ng-container matColumnDef="email" sticky>
        <th
          mat-header-cell
          *matHeaderCellDef
          style="
            height: 49px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 16%;
            max-width: 239px;
          "
        > 
          E-MAIL
        </th>
        <td
          mat-cell
          *matCellDef="let row"
          style="
            height: 49px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 16%;
            max-width: 239px;
          "
        >
          {{ row.email }}
        </td>
      </ng-container>

      <ng-container matColumnDef="maxTickets">
        <th
          mat-header-cell
          *matHeaderCellDef
          style="
            height: 49px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 10%;
            max-width: 150px;
          "
        >
        WAITING
        TICKETS LIMIT
        </th>
        <td
        mat-cell
        *matCellDef="let row"
        style="
          height: 49px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 10%;
          max-width: 150px;
        "
      >
        <mat-icon
          style="
            background-color: rgba(143, 143, 143, 0.25);
            border-radius: 20px;
          "
          [ngStyle]="{
            'background-color':
              row.maxTickets <= 1|| row.distribuir ==false
                ? 'rgba(215, 215, 215, 0.25)'
                : 'rgba(143, 143, 143, 0.25)',
            color: row.maxTickets <= 1 ? '#b3b3b3' : '#000'
          }"
          (click)="
          row.distribuir ? changeLimitTickets(-1, row) : changeLimitTickets(0, row)
          || (row.maxTickets > 1 && row.distribuir ? changeLimitTickets(-1, row) : changeLimitTickets(0, row))
        "
        
          >remove</mat-icon
        >
        {{ row.maxTickets }}
        <mat-icon
          style="border-radius: 20px"
          [ngStyle]="{
            'background-color':
              row.maxTickets >= this.form.get('simultaneous').value
                ? 'rgba(215, 215, 215, 0.25)'
                : 'rgba(143, 143, 143, 0.25)',
            color:
              row.maxTickets >= this.form.get('simultaneous').value
                ? '#b3b3b3'
                : '#000'
          }"
          (click)="
            row.maxTickets >= this.form.get('simultaneous').value|| row.distribuir == false
              ? changeLimitTickets(0, row)
              : changeLimitTickets(1, row)
          "
          >add</mat-icon
        >
      </td>
      </ng-container>

      <ng-container matColumnDef="openedTickets">
        <th
          mat-header-cell
          *matHeaderCellDef
          style="
            height: 49px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 10%;
            max-width: 120px;
          "
        >
          OPENED TICKETS
        </th>
        <td
          mat-cell
          *matCellDef="let row"
          style="
            height: 49px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 10%;
            max-width: 120px;
          "
        >
          {{ row.openedTickets }}
        </td>
      </ng-container>

      <ng-container matColumnDef="waitingTickets">
        <th
          mat-header-cell
          *matHeaderCellDef
          style="
            height: 49px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 10%;
            max-width: 120px;
          "
        >
          WAITING TICKETS
        </th>
        <td
          mat-cell
          *matCellDef="let row"
          style="
            height: 49px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 10%;
            max-width: 120px;
          "
        >
          {{ row.waitingTickets }}
        </td>
      </ng-container>

      <ng-container matColumnDef="totalTickets">
        <th
          mat-header-cell
          *matHeaderCellDef
          style="
            height: 49px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 10%;
            max-width: 110px;
          "
        >
          TOTAL TICKETS
        </th>
        <td
          mat-cell
          *matCellDef="let row"
          style="
            height: 49px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 10%;
            max-width: 110px;
          "
        >
          {{ row.totalTickets }}
        </td>
      </ng-container>

      <ng-container matColumnDef="closedTickets">
        <th
          mat-header-cell
          *matHeaderCellDef
          style="
            height: 49px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 10%;
            max-width: 110px;
          "
        >
          CLOSED TICKETS
        </th>
        <td
          mat-cell
          *matCellDef="let row"
          style="
            height: 49px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 10%;
            max-width: 110px;
          "
        >
          {{ row.closedTickets }}
        </td>
      </ng-container>

      <ng-container matColumnDef="idiomas">
        <th
          mat-header-cell
          *matHeaderCellDef
          style="
            height: 49px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 10%;
            max-width: 110px;
          "

        >
          LANGUAGES
        </th>
        <td
        [matMenuTriggerFor]="menu"
        [formGroup]="form"
        mat-cell
        *matCellDef="let row"
        style="
          height: 49px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 10%;
          max-width: 110px;
        "
      >
        {{ row.idiomas }}

        <mat-menu #menu="matMenu" (closed)="onMenuClosed()" (menuClosed)="onMenuClosed()">
          <app-menu-laguage [langList]="row.idiomas" [dataRow]="row"></app-menu-laguage>
        </mat-menu>
      </td>
      </ng-container>

      <ng-container stickyEnd matColumnDef="id">
        <th
          mat-header-cell
          *matHeaderCellDef
          style="
            height: 49px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 10%;
            max-width: 250px;
          "
        ></th>
        <td
          mat-cell
          *matCellDef="let row"
          [matMenuTriggerFor]="menu"
          style="
            height: 49px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 10%;
            max-width: 250px;
          "
        >
          <mat-icon>more_vert</mat-icon>

          <mat-menu #menu="matMenu">
            <button
              mat-menu-item
              (click)="openDialog({ save: false, agentData: row })"
            >
              Edit collaborator
            </button>
            <button
              mat-menu-item
              style="color: #ff1d1d"
              (click)="removeCollaborator({ agentData: row })"
            >
              Remove from list
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <mat-header-row
        class="row-header"
        *matHeaderRowDef="displayedColumns; sticky: true"
        sticky
      ></mat-header-row>
      <mat-row
      class="rows-table"
      *matRowDef="let row; columns: displayedColumns"

      [ngStyle]="{
        'background-color':
          row.distribuir ==false
            ? 'rgba(215, 215, 215, 0.25)'
            : '#fff',
        color: row.maxTickets <= 1 ? '#b3b3b3' : '#000'
      }"
    ></mat-row>
    </table>
  </div>
</div>
