import { Component, Input, OnInit } from '@angular/core';
import { Cap } from 'src/app/core/user/model/caps';

@Component({
  selector: 'app-nav-item-collaborators',
  templateUrl: './nav-item-collaborators.component.html',
  styleUrls: ['./nav-item-collaborators.component.scss']
})
export class NavItemCollaboratorsComponent implements OnInit {

  @Input() cap: Cap;

  constructor() { }

  ngOnInit(): void {
    // console.log(this.cap)
  }

  checkBrzStatsCap(): boolean {
    return this.cap.hasOwnProperty('brz_menu_dc_report_brz_stats');
  }

  checkBrzAgencyStatsCap(): boolean {
    return this.cap.hasOwnProperty('brz_menu_dc_report_brz_agency_stats');
  }

  checkSalesCap(): boolean {
    return this.cap.hasOwnProperty('brz_menu_dc_report_sales');
  }

  checkMarketingCap(): boolean {
    return this.cap.hasOwnProperty('brz_menu_dc_report_marketing');
  }

  checkQuoteCap(): boolean {
    return this.cap.hasOwnProperty('brz_menu_quote_dashboard');
  }

  checkCarrierTarget():boolean{
    return this.cap.hasOwnProperty('brz_carrier_target');
  }

  checkCollaborators():boolean{
    return this.cap.hasOwnProperty('brz_menu_blip_queue');
  }
  
}
