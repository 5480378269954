<mat-expansion-panel class="expansion-panel" *ngIf="checkCreateQuote() || checkViewQuote()">
  <mat-expansion-panel-header>
    <mat-panel-title style="color: #ffffff;">
      <div style="margin-top: 5px;">
        <!-- <i class="far fa-clipboard"></i> -->
        <i class="fas fa-dolly"></i>
        <span style="margin-left: 10px;">Quotes</span>
      </div>
    </mat-panel-title>

  </mat-expansion-panel-header>
  <!-- [ngClass]="{'display-off': !createQuote}" -->
  <div>
    <a mat-list-item routerLink="/home/quote/create-quote" class="item-desc" *ngIf="checkCreateQuote()">
      Create Quote
    </a>

    <a mat-list-item routerLink="/home/quote/view-quote" class="item-desc" *ngIf="checkViewQuote()">
      View Quotes
    </a>
  </div>

</mat-expansion-panel>
