import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, filter, map, pluck, switchMap, tap } from 'rxjs/operators';
import { UserService } from '../user/user.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../user/model/user';
import { IGetTokenDTO } from '../token/model/dto/IGetTokenDTO';
import { Token } from '../token/model/token';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { TokenService } from '../token/token.service';
import { BlipChat } from 'blip-chat-widget';
import { Cap } from '../user/model/caps';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private tokenService: TokenService,
    private notification: NotificationService) { }

  getToken(username: string, password: string): Observable<string> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    let dto: IGetTokenDTO = {
      client_id: '39015cb51ece0e3231cb3c7236336b',
      client_secret: '0f44a4c36d1a5809e3a954e4232ceaf5ed7f007d',
      password: password,
      username: username
    }

    return this.http.post<Token>(`${environment.apiUrl}/er-authenticate/v1/get_token/`, dto, httpOptions)
      .pipe(
        filter(res => {
          return res.status === 200
        }
        ),
        map(data => {

          this.tokenService.setExpirationDate(data.data.expires_in);
          this.userService.setUserRole(data.data.role);
          this.userService.setLeadsource(data.data['lead_source']);

          return data.data.access_token
        }),
        catchError(err => {
          this.notification.error(err['error'].message);
          return 'e';
        })
      );
  }

  login(username: string, password: string): Observable<User> {

    return this.getToken(username, password)
      .pipe(
        switchMap(token => {

          if (token != 'e') {


            this.userService.setToken(token);

            const httpOptions = {
              headers: new HttpHeaders({
                Authorization: `Bearer ${token}`
              })
            };

            return this.http.get<User>(`${environment.apiUrl}/brz/v1/user/get_user_info`, httpOptions)
              .pipe(
                pluck('data'),
                pluck('user'),
                map(data => {

                  let user: User;
                  user = data['data'] as User;
                  let caps = data['allcaps'] as Cap;
                  if (caps.hasOwnProperty('brzinsurance_blip_view_chat')) {
                    //const blip = this.initChat(user.user_email, user.user_pass, user.display_name);
                    //this.userService.blip = blip;
                  }

                  this.userService.setPermissions(caps);
                  this.userService.setUserName(user.display_name);
                  this.userService.setUserMail(user.user_email);
                  this.userService.setUserPwd(user.user_pass);
                  this.userService.setDefaultLocation(user.default_location);
                  this.userService.setUser(user);


                  return user;
                })
              );
          }

        })
      );
  }

  initChat(mail: string, pwd: string, name: string): any {

    const blip = new BlipChat();

    blip.withAppKey(
      'bGVuZGJ1eno6YmNhMDMwYzQtY2ZiMy00MzhkLWI4ZmQtZTA0OWU4Njg2NzFl'
    )
      .withButton({color: 'transparent',
        icon: 'https://brzinsurance.com/email_signature/brzbee-64.png',
      })
      .withAuth({
        authType: BlipChat.DEV_AUTH,
        userIdentity: mail,
        userPassword: pwd,
      })
      .withAccount({
        fullName: name,
        email: mail,
      })
      .build();

    return blip;
  }

}
