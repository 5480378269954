import { Driver } from './../../../../model/driver/Driver';
import { NewQuoteComponent } from './../../../new-quote/new-quote.component';
import { FormGroup } from '@angular/forms';
import { Component, Input, OnInit, Output, EventEmitter  } from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {

 
  form: FormGroup;
  formDrivers:FormGroup;
  formVehicle:FormGroup;
  @Input() label:string ;
  @Input() contador:boolean = true ;
  @Input() typeInput:any
  @Input() formControlName:any
  @Input() address:boolean
  @Input() control:string;
  @Input() plchldr:string;
  @Input() Driver:string = 'personal';
  verification:boolean = false;
  @Input() ExternalValidaErro:boolean = false;
  formattedAdress:string
  options ={
    componentRestrictions:{
      country: ['US']
    }
  }

  get fPe() { return this.form.controls; }
  constructor(private formContainer: NewQuoteComponent) {}


  ngOnInit() {
    this.form = this.formContainer.getStepForm();
    this.formDrivers = this.formContainer.fdr();
    this.formVehicle = this.formContainer.fvh();
    
  }

  

  get validaErro(){
    
    if (this.formContainer.formStep <9){
      let ctrl = this.control
      if(this.form.controls[ctrl].errors && this.form.controls[ctrl].touched ){
        return true
      }else{
        
        return false
      }
    }else if(this.formContainer.formStep ==9){
      let ctrl = this.control
      if(this.formDrivers.controls[ctrl].errors && this.formDrivers.controls[ctrl].touched ){
  
        return true
      }else{
        return false
      }
    }else if(this.formContainer.formStep ==11){
      let ctrl = this.control
      if(this.formVehicle.controls[ctrl].errors && this.formVehicle.controls[ctrl].touched ){
  
        return true
      }else{
        return false
      }
    }

    


    
  }

  public handleAddressChange(address: any) {
    this.formattedAdress = address.formatted_address;
    this.form.patchValue({
      address: this.formattedAdress,
    });
}
}
