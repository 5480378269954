import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as Highcharts from 'highcharts';
import { sum } from 'lodash';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ObjValuesSalesPolicy, SalesPolicy } from 'src/app/modules/dashboard-lead/model/salesPolicy';
import { DashboardLeadService } from 'src/app/modules/dashboard-lead/service/dashboard-lead.service';

interface BarChart {
  name: string;
  data?: number[];
}

@Component({
  selector: 'app-chart-stats-policy-count',
  templateUrl: './chart-stats-policy-count.component.html',
  styleUrls: ['./chart-stats-policy-count.component.scss']
})
export class ChartStatsPolicyCountComponent implements OnInit {

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};
  updateFlag = false;

  salesPolicy$: Observable<SalesPolicy>;

  users: BarChart[] = [];
  title: string;
  subtitle: string;
  hasValue: boolean = false;
  totalPolicy: number = 0;

  @Input() range: string;
  @Input() from: string;
  @Input() to: string;
  @Input() dcType: string[];
  @Output() getTotalPolicy: EventEmitter<number> = new EventEmitter<number>();

  constructor(private dashboardLeadService: DashboardLeadService) { }

  ngOnInit(): void {
    this.getStatsPolicy(this.range, this.from, this.to, this.dcType);
  }

  getStatsPolicy(range: string, from: string, to: string, dcType: string[]) {
    this.salesPolicy$ = this.dashboardLeadService.getStatsPolicyCount(range, from, to, dcType)
      .pipe(tap((salesPolicy) => {

        this.title = salesPolicy.title;
        this.subtitle = salesPolicy.description;

        this.chartIt(salesPolicy);
      }));
  }

  chartIt(sales: SalesPolicy) {

    this.chartOptions.series = [];
    this.users = [];

    this.chartOptions.chart = {
      type: 'bar',
      backgroundColor: '#fafafa',
    }

    this.chartOptions.credits = {
      enabled: false
    }

    this.chartOptions.title = {
      text: null
    }

    this.chartOptions.legend = {
      itemStyle: {
        fontFamily: 'Lato',
        fontWeight: '300'
      }
    }

    this.chartOptions.xAxis = {
      categories: sales.data.label,
    }

    this.chartOptions.yAxis = {
      title: {
        text: 'Count of Deals',
        style: {
          fontFamily: 'Lato',
        }
      },
      stackLabels: {
        enabled: true,
        align: 'right',
        style: {
          fontFamily: 'Lato',
        },

      }
    }

    this.chartOptions.tooltip = {
      style: {
        fontFamily: 'Lato',
      },
      shared: true,
      backgroundColor: '#fafafa'
    }


    this.chartOptions.plotOptions = {
      series: {
        stacking: 'normal'
      }
    }

    sales.data.label.forEach((e => {
      sales.data.values[e].forEach((e: any) => {

        this.buildChartData(e.new, 'new', e.name);
        this.buildChartData(e.endorsement, 'endorsement', e.name);
        this.buildChartData(e.renew, 'renew', e.name);
        this.buildChartData(e.payment, 'payment', e.name);
        this.buildChartData(e.rewrite, 'rewrite', e.name);
        this.buildChartData(e.brl, 'brl', e.name);
        this.buildChartData(e.rmv, 'rmv', e.name);
        this.buildChartData(e.remaining_balance, 'remaining_balance', e.name);
        this.buildChartData(e.audit, 'audit', e.name);
        this.buildChartData(e.flat_cancel, 'flat_cancel', e.name);
        this.buildChartData(e.refund, 'refund', e.name);

      });
    }));

    this.totalPolicy = 0;

    this.users.forEach((e) => {
      this.totalPolicy += sum(e.data);
      this.chartOptions.series.push({
        type: 'bar',
        name: e.name,
        data: e.data,
      });
    });

    this.getTotalPolicy.emit(this.totalPolicy);

  }

  buildChartData(dcType: number, name: string, user: string) {
    if( this.dcType.includes(name) ) {
      if (!this.users.find(x => x.name === name)) {

        this.users.push({
          name: name,
          data: []
        });

      }
      this.users.find(x => x.name == name).data.push(dcType);
    }
  }
}


