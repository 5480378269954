import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Dashboard } from '../../model/dashboard';
import { DashboardLeadService } from '../../service/dashboard-lead.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
@Component({
  selector: 'app-dashboard-agency-stats',
  templateUrl: './dashboard-agency-stats.component.html',
  styleUrls: ['./dashboard-agency-stats.component.scss']
})
export class DashboardAgencyStatsComponent implements OnInit {

  dashboards$: Observable<Dashboard>;

  constructor(private dashboardLeadService: DashboardLeadService, private notification: NotificationService) { }

  ngOnInit(): void {
    if (this.dashboardLeadService.getCapPermission('brz_menu_dc_report_brz_agency_stats')) {
      this.dashboards$ = this.dashboardLeadService.getDashboards();
    } else {
      this.notification.error("You don't have permission to access this page.");
    }
  }

}
