<div class="box">
    <div class="box-title">
      <span class="subtitle-minor">Endorsement Pessoal</span>
      <button class="add-btn" (click)="openDialog()">Adicionar</button>
    </div>
  
    <div class="table-data">
      <mat-table class="table-data-style" [dataSource]="dataSource" fixedLayout="false">
        <ng-container matColumnDef="select" sticky>
          <mat-header-cell *matHeaderCellDef style="width: 500px !important;">
            <mat-checkbox class="example-margin" [checked]="false"></mat-checkbox>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-checkbox
              [checked]="row.select"
              class="example-margin"
            ></mat-checkbox>
          </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="queue" sticky>
          <mat-header-cell *matHeaderCellDef> Queue </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.queue }} </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="status" sticky>
          <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-slide-toggle></mat-slide-toggle>
          </mat-cell> 
        </ng-container>
  
        <ng-container matColumnDef="name" sticky>
          <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.name }} </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef> E-mail </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.email }} </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="acting">
          <mat-header-cell *matHeaderCellDef> Acting </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.acting }} </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="idiomas">
          <mat-header-cell *matHeaderCellDef> Idiomas </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.idiomas }} </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="dealsToday">
          <mat-header-cell *matHeaderCellDef> DEALS TODAY </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.dealsToday }} </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="totalDeals">
          <mat-header-cell *matHeaderCellDef> TOTAL DEALS </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.totalDeals }} </mat-cell>
        </ng-container>
        
  
        <ng-container matColumnDef="menu" stickyEnd>
          <mat-header-cell *matHeaderCellDef>  </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-icon>more_vert</mat-icon>
          </mat-cell>
        </ng-container>
  
        <mat-header-row
          class="row-header"
          *matHeaderRowDef="displayedColumns;sticky:true "
          
        ></mat-header-row>
        <mat-row
          class="rows-table"
          *matRowDef="let row; columns: displayedColumns"
        ></mat-row>
      </mat-table>
    </div>
  </div>
  
  
  
  
  