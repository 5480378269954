import { Component, Input } from '@angular/core';
import { Cap } from 'src/app/core/user/model/caps';

@Component({
  selector: 'app-nav-item-communication',
  templateUrl: './nav-item-communication.component.html',
  styleUrls: ['./nav-item-communication.component.scss']
})
export class NavItemCommunicationComponent {

  @Input() cap: Cap;

  constructor() { }

  checkQuoteCap(): boolean {
    return this.cap.hasOwnProperty('brz_menu_quote_dashboard');
  }
  
}
