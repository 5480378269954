import { ChartCarrierComponent } from './../../charts/chart-carrier/chart-carrier.component';
import { DataSharedService } from './../../services/data-shared.service';
import { CarrierServiceService } from './../../services/carrier-service.service';
import { CarrierChart } from './../../model/carrierChart';
import { DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of, Subscription } from 'rxjs';
import { UserService } from 'src/app/core/user/user.service';
import { Producer } from 'src/app/modules/daily-cash/modules/view-daily-cash/model/producer';
import { map, pluck, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from 'src/app/core/token/token.service';
import { environment } from 'src/environments/environment';
import { MatSelect } from '@angular/material/select';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

export class ItemList {
  constructor(public item: string, public selected?: boolean) {
    if (selected === undefined) selected = false;
  }
}

@Component({
  selector: 'app-brz-carrier-filter',
  templateUrl: './brz-carrier-filter.component.html',
  styleUrls: ['./brz-carrier-filter.component.scss'],
})
export class BrzCarrierFilterComponent {
  fgFilter: FormGroup;
  producers$: Observable<Producer[]>;
  dateCarriers$: Observable<CarrierChart[]>;
  dateCarriers: CarrierChart[];
  carriers$: Observable<CarrierChart[]>;
  token = this.tokenService.getToken();
  selectedCarrierValue: any;
  loop = true;
  @Output('change-filter') changeFilter: EventEmitter<any> =
    new EventEmitter<any>();
  @ViewChild('selectElement') selectElement: MatSelect;

  selectAll = false;
  selectedUsers: [any];
  userControl: any;
  selectedCarriers: [];
  carr = [
    { id: 'anjmao', name: 'Anjmao' },
    { id: 'varnas', name: 'Tadeus Varnas' },
  ];

  addCustomUser = (term) => ({ carrier_id: term, carrier_name: term });
  selectedCarriersIds: number[];
  carriers: any;

  constructor(
    private chartCarrierComponent: ChartCarrierComponent,
    private tokenService: TokenService,
    private http: HttpClient,
    private userService: UserService,
    private carrierService: CarrierServiceService,
    private dataShared: DataSharedService,
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.getCarriers();

    this.carriers$.subscribe((carriers) => {
      this.selectedCarrierValue = carriers.map(
        (carrier) => carrier.carrier_id
      );
    });

  }

  selected(event: MatAutocompleteSelectedEvent, index: number): void {}

  changed(data) {
    if (this.loop) {
      this.carriers$.subscribe((carriers) => {
        this.selectedCarrierValue = carriers.map(
          (carrier) => carrier.carrier_id
        );
      });
      this.loop = false;
    } else {
      this.selectedCarrierValue = [];
      this.loop = true;
    }
  }

  updateData(data) {
    this.dataShared.updateData(data);
  }

  getCarriers() {
    this.dateCarriers$ = this.carrierService.getDates();

    this.dateCarriers$.subscribe((dateCarriers) => {
      this.dateCarriers = dateCarriers;
      this.selectedDate = dateCarriers[0].date;
    });

    this.carriers$ = this.carrierService.getPeriod().pipe(
      map((carriers) => {
        return carriers.filter(
          (carrier, index, self) =>
            index ===
            self.findIndex((t) => t.carrier_name === carrier.carrier_name)
        );
      })
    );
  }

  selectedDate: string;

  selectDate(event) {
    this.selectedDate = event.value;
  }

  onSubmit() {
    this.updateData(this.fgFilter.value);

    this.changeFilter.emit(this.fgFilter.value);

    let arr = this.fgFilter.value.fcCarrier.filter((item) => item != 'All');
    const obj = {
      carrier_id: arr,
      dates: [this.fgFilter.value.fcDate],
    };
    this.carrierService
      .updateGet(obj)
      .pipe(
        tap((data) => {
          this.chartCarrierComponent.handleUpdate(
            data,
            this.fgFilter.value.fcDate
          );
        })
      )
      .subscribe();
  }

  itIsManager(): boolean {
    return (
      this.userService.getUserRole().includes('manager') ||
      this.userService.getUserRole().includes('administrator')
    );
  }

  initForm() {
    this.fgFilter = new FormGroup({
      fcDate: new FormControl(''),
      fcCarrier: new FormControl([]),
    });
  }

  htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent;
  }

  onCarrierSelect(value: any) {
    if (this.loop && value.indexOf('all') !== -1) {
      // Se "Selecionar todos" estiver selecionado, seleciona todos os outros itens
      this.carriers$.subscribe((carriers) => {
        this.selectedCarrierValue = carriers.map(
          (carrier) => carrier.carrier_id
        );
        this.loop = false;
      });
    } else {
      // Caso contrário, adiciona ou remove o item selecionado
      const index = this.selectedCarrierValue.indexOf(value);
      if (index !== -1) {
        this.selectedCarrierValue.splice(index, 1);
      } else {
        this.selectedCarrierValue.push(value);
      }

      if( value.indexOf('all') !== -1){
        this.selectedCarrierValue =[];
      }
      this.loop = true;
    }
  }
}
