import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NavBarComponent } from './nav-bar.component';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { NavbarRoutingModule } from './nav-bar-routing.module';
import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';

import { LoadingModule } from 'src/app/shared/components/loading/loading.module';
import { NavItemDashboardComponent } from './components/nav-item-dashboard/nav-item-dashboard.component';
import { NavItemQuoteComponent } from './components/nav-item-quote/nav-item-quote.component';
import { NavItemSmsComponent } from './components/nav-item-sms/nav-item-sms.component';
import { NavItemDailyCashComponent } from './components/nav-item-daily-cash/nav-item-daily-cash.component';
import { MainToolbarComponent } from './components/main-toolbar/main-toolbar.component';
import { NavItemAdminComponent } from './components/nav-item-admin/nav-item-admin.component';
import { NavNotificationsComponent } from './components/nav-notifications/nav-notifications.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoginModule } from 'src/app/modules/login/login.module';
import { NavItemCollaboratorsComponent } from './components/nav-item-collaborators/nav-item-collaborators.component';
import { NavItemCommunicationComponent } from './components/nav-item-communication/nav-item-communication.component';
@NgModule({
  declarations: [
    NavBarComponent,
    NavItemDashboardComponent,
    NavItemQuoteComponent,
    NavItemSmsComponent,
    NavItemDailyCashComponent,
    MainToolbarComponent,
    NavItemAdminComponent,
    NavNotificationsComponent,
    NavItemCollaboratorsComponent,
    NavItemCommunicationComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NavbarRoutingModule,
    LoginModule,
    LoadingModule,

    MatSidenavModule,
    MatChipsModule,
    MatListModule,
    MatTooltipModule,
    MatIconModule,
    MatToolbarModule,
    MatExpansionModule,
    MatMenuModule,
    MatButtonModule,
    MatBadgeModule,
    MatCardModule,
    MatDividerModule,
    MatDividerModule,
    MatProgressSpinnerModule
  ]
})
export class NavbarModule { }
