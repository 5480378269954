import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, pluck } from 'rxjs/operators';
import { TokenService } from 'src/app/core/token/token.service';
import { UserService } from 'src/app/core/user/user.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { environment } from 'src/environments/environment';
import { DcType } from '../../modules/create-daily-cash/model/dcType';
import { Location } from '../../modules/create-daily-cash/model/location';
import { Policy } from '../../modules/create-daily-cash/model/policy';
import { QqUser } from '../../modules/create-daily-cash/model/qq';
import { Referral } from '../../modules/create-daily-cash/model/referral';
import { DailyCashData } from '../../modules/view-daily-cash/model/dailyCash';
import { Producer } from '../../modules/view-daily-cash/model/producer';
import { DataViewPolicy } from '../../modules/view-policies/model/dataViewPolicy';
import { BasicInfoDailyCashComponent } from '../../modules/create-daily-cash/components/basic-info-daily-cash/basic-info-daily-cash.component';
import { FormBuilder, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class DcSharedService {
  @ViewChild(BasicInfoDailyCashComponent)
  token = this.tokenService.getToken();
  BasicInfoDailyCashComponent: BasicInfoDailyCashComponent;

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private userService: UserService,
    private notification: NotificationService,
    private formBuilder: FormBuilder
    // private BasicInfoDailyCashComponent: BasicInfoDailyCashComponent
  ) {
  }

  getLocations(): Observable<Location[]> {
    if (this.userService.cap.hasOwnProperty('brzinsurance_dc_read')) {
      const httpOptions = {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.token}`,
        }),
      };

      return this.http
        .get<Location[]>(
          `${environment.apiUrl}/brz/v1/daily_cash/locations/`,
          httpOptions
        )
        .pipe(pluck('results'));
    } else {
      this.notification.alert('You have no permission to get locations.');
    }
  }

  getReferrals(): Observable<Referral[]> {
    if (this.userService.cap.hasOwnProperty('brzinsurance_dc_read')) {
      const httpOptions = {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.token}`,
        }),
      };

      return this.http
        .get<Referral[]>(
          `${environment.apiUrl}/brz/v1/daily_cash/referrals/`,
          httpOptions
        )
        .pipe(pluck('results'));
    } else {
      this.notification.alert('You have no permission to get referrals.');
    }
  }

  getDcTypes(): Observable<DcType> {
    if (this.userService.cap.hasOwnProperty('brzinsurance_dc_read')) {
      const httpOptions = {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.token}`,
        }),
      };

      return this.http
        .get<DcType>(
          `${environment.apiUrl}/brz/v1/daily_cash/dc_types/`,
          httpOptions
        )
        .pipe(pluck('results'));
    } else {
      this.notification.alert('You have no permission to get dc types.');
    }
  }

  searchQQ(word: string): Observable<QqUser[]> {
    if (this.userService.cap.hasOwnProperty('brzinsurance_dc_read')) {
      const httpOptions = {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.token}`,
        }),
      };

      return this.http
        .post<QqUser[]>(
          `${environment.apiUrl}/brz/v1/daily_cash/search/qq_user/`,
          { search_string: word },
          httpOptions
        )
        .pipe(pluck('results'));
    } else {
      this.notification.alert('You have no permission to search qq.');
    }
  }

  getPolicies(qqId: number, audit?: any): Observable<Policy[]> {
    if (this.userService.cap.hasOwnProperty('brzinsurance_dc_read')) {
      const httpOptions = {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.token}`,
        }),
      };
      if (audit=== 'audit') {
        return this.http
          .post<Policy[]>(
            `${environment.apiUrl}/brz/v1/daily_cash/search/policies/`,
            {
              qq_id: qqId,
              include_active: true,
              include_pending: false,
              include_expired: true,
              include_cancelled: true,
            },
            httpOptions
          )
          .pipe(distinctUntilChanged(), pluck('results'));
      } else {
        return this.http
          .post<Policy[]>(
            `${environment.apiUrl}/brz/v1/daily_cash/search/policies/`,
            {
              qq_id: qqId,
            },
            httpOptions
          )
          .pipe(distinctUntilChanged(), pluck('results'));
      }
    } else {
      this.notification.alert('You have no permission to get policies.');
    }
  }

  getDailyCash(dc_id?: number): Observable<DailyCashData> {
    if (this.userService.cap.hasOwnProperty('brzinsurance_dc_read')) {
      const httpOptions = {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.token}`,
        }),
      };

      return this.http
        .post<DailyCashData>(
          `${environment.apiUrl}/brz/v1/daily_cash/search/daily_cash/`,
          { dc_id },
          httpOptions
        )
        .pipe(pluck('data'));
    } else {
      this.notification.alert('You have no permission to get daily cash.');
    }
  }

  getProducts(product_id?: number): Observable<DataViewPolicy> {
    if (this.userService.cap.hasOwnProperty('brzinsurance_dc_read')) {
      const httpOptions = {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.token}`,
        }),
      };

      return this.http
        .post<DataViewPolicy>(
          `${environment.apiUrl}/brz/v1/daily_cash/search/dc_product/`,
          { product_id },
          httpOptions
        )
        .pipe(pluck('data'));
    } else {
      this.notification.alert('You have no permission to get products.');
    }
  }

  getProducers(): Observable<Producer[]> {
    if (this.userService.cap.hasOwnProperty('brzinsurance_dc_read')) {
      const httpOptions = {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.token}`,
        }),
      };

      return this.http
        .post<Producer[]>(
          `${environment.apiUrl}/brz/v1/daily_cash/search/producers/`,
          { active: 'true' },
          httpOptions
        )
        .pipe(pluck('results'));
    } else {
      this.notification.alert('You have no permission to get producers.');
    }
  }
}
