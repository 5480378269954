/// <reference types="@types/googlemaps" />

import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MDealMap } from 'src/app/modules/dashboard-lead/model/mDealMap';
import { DashboardLeadService } from 'src/app/modules/dashboard-lead/service/dashboard-lead.service';
declare module 'googlemaps';
declare var MarkerClusterer: any;


interface Location {
  lat: number, lng: number
}

@Component({
  selector: 'app-chart-marketing-google-map',
  templateUrl: './chart-marketing-google-map.component.html',
  styleUrls: ['./chart-marketing-google-map.component.scss']
})
export class ChartMarketingGoogleMapComponent implements OnInit {

  @Input() range: string;
  @Input() from: string;
  @Input() to: string;
  deals$: Observable<MDealMap>;
  private map: google.maps.Map = null;
  private heatmap: google.maps.visualization.HeatmapLayer = null;
  coords: google.maps.LatLng[] = [];
  locations: Location[] = [];
  heatdata: any;
  title: string;
  subtitle: string;

  constructor(private dashboardLeadService: DashboardLeadService) { }

  ngOnInit(): void {
    this.getMarketingDealsMap(this.range, this.from, this.to);
  }

  getMarketingDealsMap(period: string, from: string, to: string) {
    this.deals$ = this.dashboardLeadService.getMarketingDealsMap(period, from, to)
      .pipe(tap((deals) => {

        this.title = deals.title;
        this.subtitle = deals.description;
        this.heatdata = [];

        deals.data.forEach(e => {
          this.heatdata.push({ location: new google.maps.LatLng(e.latitude, e.longitude), weight: e.total });
          this.locations.push({
            lat: e.latitude,
            lng: e.longitude
          })
        });

      }));
  }

  onMapLoad(mapInstance: google.maps.Map) {
    this.map = mapInstance;

    var massachusetts = new google.maps.LatLng(42.407211, -71.382439);

    this.map.setCenter(massachusetts);
    this.map.setZoom(8);

    this.heatmap = new google.maps.visualization.HeatmapLayer({
      map: this.map,
      data: this.heatdata,
      dissipating: true,
      maxIntensity: 10,
      opacity: 1
    });

    this.changeGradient();

    // const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    // var infowindow = new google.maps.InfoWindow();

    // const markers = this.locations.map((location, i) => {

    //   var marker = new google.maps.Marker({
    //     position: location,
    //     label: labels[i % labels.length]
    //   });

    //   google.maps.event.addListener(marker, 'click', function (evt) {
    //     debugger
    //     //infowindow.setContent(marke);
    //     infowindow.open(this.map, marker);
    //     infowindow.setContent('teste' + marker.get)
    //   });

    //   return marker;

    // });

    // new MarkerClusterer(this.map, markers, {
    //   imagePath:
    //     "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
    // });

  }

  changeGradient() {
    const gradient = [
      "rgba(0, 255, 255, 0)",
      "rgba(0, 255, 255, 1)",
      "rgba(0, 191, 255, 1)",
      "rgba(0, 127, 255, 1)",
      "rgba(0, 63, 255, 1)",
      "rgba(0, 0, 255, 1)",
      "rgba(0, 0, 223, 1)",
      "rgba(0, 0, 191, 1)",
      "rgba(0, 0, 159, 1)",
      "rgba(0, 0, 127, 1)",
      "rgba(63, 0, 91, 1)",
      "rgba(127, 0, 63, 1)",
      "rgba(191, 0, 31, 1)",
      "rgba(255, 0, 0, 1)",
    ];
    this.heatmap.set("gradient", this.heatmap.get("gradient") ? null : gradient);
  }


}
