<div class="example-container" *ngIf="!!dataSource">
  <div>
    <table mat-table [dataSource]="dataSource" class="table" matSort>

      <ng-container matColumnDef="city">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> city </th>
        <td mat-cell *matCellDef="let row">{{row.city }}</td>
      </ng-container>

      <ng-container matColumnDef="zip">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> zip</th>
        <td mat-cell *matCellDef="let row">{{row.zip}}</td>
      </ng-container>

      <ng-container matColumnDef="total">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> total</th>
        <td mat-cell *matCellDef="let row">{{row.total}}</td>
      </ng-container>

      <ng-container matColumnDef="latitude">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> latitude</th>
        <td mat-cell *matCellDef="let row">{{row.latitude}}</td>
      </ng-container>

      <ng-container matColumnDef="longitude">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> longitude</th>
        <td mat-cell *matCellDef="let row">{{row.longitude}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <mat-paginator [pageSize]="10"></mat-paginator>
</div>
