<div class="container">
    <div class="header-modal">
      <h1 mat-dialog-title>Adicionar novo colaborador (Audit)</h1>
      <mat-icon
        aria-hidden="false"
        aria-label="Example home icon"
        class="white-icon"
        (click)="closeModal()"
        >close</mat-icon
      >
    </div>
    <div mat-dialog-content class="box-content">
      <form class="form-group" [style.fontSize.px]="14">
        <div class="inpts-box">
          <div class="form-input-box">
            <label for="name-collaborator" class="inpt-label"
              >Nome do colaborador</label
            >
            <input
              type="text"
              placeholder="João da Silva Júnior"
              id="name-collaborator"
              class="inpt"
            />
          </div>
  
          <div class="form-input-box">
            <label for="email-collaborator" class="inpt-label"
              >E-mail do coladorador</label
            >
            <input
              type="email"
              placeholder="nome@email.com"
              id="email-collaborator"
              class="inpt"
            />
          </div>
        </div>
      </form>
    </div>
  
    <div mat-dialog-content class="box-content">
      <form [formGroup]="dialogForm" class="form-group" [style.fontSize.px]="14">
        <div class="inpts-box">
          <div class="form-input-box queue-inpt">
            <label for="queue-collaborator" class="inpt-label">Queue</label>
            <select id="queue-collaborator" class="inpt select" style="width: 100% !important">
              <option clqss="option-select" value="1">1</option>
              <option clqss="option-select" value="2">2</option>
              <option clqss="option-select" value="3">3</option>
              <option clqss="option-select" value="4">4</option>
            </select>
          </div>


          <div class="form-input-box acting-inpt">
            <label for="language-collaborator" class="inpt-label">Acting</label>
  
            <mat-form-field appearance="fill" style="width: 100%">
              <mat-label> </mat-label>
              <mat-select multiple>
                <mat-option value="all">All</mat-option>
                <mat-option value="en">Renewal</mat-option>
                <mat-option value="es">Pessoal Policies</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
  
          <div class="form-input-box language-inpt">
            <label for="language-collaborator" class="inpt-label">Language</label>
  
            <mat-form-field appearance="fill" style="width: 100%">
              <mat-label> </mat-label>
              <mat-select multiple>
                <mat-option value="all">All</mat-option>
                <mat-option value="en">English</mat-option>
                <mat-option value="es">Español</mat-option>
                <mat-option value="pt">Português</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
  
    <footer class="footer-box">
      <div mat-dialog-actions>
        <button
          mat-button
          mat-dialog-close
          class="action-btn"
          (click)="closeModal()"
        >
          Add Collaborator
        </button>
      </div>
    </footer>
  </div>
  