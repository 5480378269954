import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UserService } from 'src/app/core/user/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private router: Router, private auth: AuthService, private userService: UserService) { }

  hide = true;

  formGroup = new FormGroup({
    fcUser: new FormControl('', Validators.required),
    fcPwd: new FormControl('', Validators.required),
  });

  isFieldInvalid(field: string) {
    return (
      (!this.formGroup.get(field).valid && this.formGroup.get(field).touched) ||
      (this.formGroup.get(field).untouched)
    );
  }
//teste
  onSubmit() {

    const username = this.formGroup.get('fcUser').value;
    const password = this.formGroup.get('fcPwd').value;
    window.localStorage.setItem('data', username)
    this.auth.login(username, password).subscribe(res => {
        this.router.navigate(['/home/brz-home-page/']);
      }),      (error: HttpErrorResponse) => {
        this.router.navigate(['/login']);
      }

  }

  ngOnInit(): void {

    if(this.userService.isLogged()){
      this.router.navigate(['/home/brz-home-page/']);
    }

  }

}
