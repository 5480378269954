import { AfterViewInit, Component, Input, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { UserService } from 'src/app/core/user/user.service';
import { DataViewPolicy } from '../../model/dataViewPolicy';
import { IViewProductDTO } from '../../model/dto/viewProductDTO';
import { ViewPolicy } from '../../model/viewPolicy';
import { ViewPoliciesService } from '../../services/view-policies.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-table-view-policies',
  templateUrl: './table-view-policies.component.html',
  styleUrls: ['./table-view-policies.component.scss']
})
export class TableViewPoliciesComponent implements AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('TABLEEXPORT') tableExport: ElementRef;

  @Input() dailies: ViewPolicy;
  //dailyCash: DailyCash;

  data: DataViewPolicy[] = [];
  dataExport: DataViewPolicy[] = [];
  exportLimit: number = 10000;
  loaderExport: boolean = false;

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  pageLimit: number[] = [10, 25, 50, 100];
  dto: IViewProductDTO = {};

  displayedColumns: string[] = [
    'daily_cash_product_id',
    'daily_cash_id',
    'daily_cash_type',
    'daily_cash_product_effective_date',
    'foreign_location_id',
    'daily_cash_display_name',
    'user_email',
    'foreign_lob_id',
    'foreign_carrier_id',
    'foreign_policy_id',
    'daily_cash_product_premium',
    'daily_cash_product_brz',
    'daily_cash_product_monthly',
    'daily_cash_product_carrier',
    'daily_cash_product_monthly_to_be_paid',
    'daily_cash_product_carrier_to_be_paid',
    'daily_cash_product_is_rmv',
    'daily_cash_product_runner',
    'daily_cash_product_runner_id',
    'daily_cash_product_translation',
    'daily_cash_product_cccharge',
    'daily_cash_file'
  ];

  constructor(private viewPoliciesService: ViewPoliciesService,
    private cookieService: CookieService,
    private router: Router,
    private userService: UserService,
    private notificationService: NotificationService) { }

  ngAfterViewInit(): void {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.viewPoliciesService!.getPolicies(
            this.paginator.pageIndex,
            this.paginator.pageSize,
            this.dto.search_user || this.cookieService.get('producerPolicy') || '',
            this.dto.search_word || null,
            this.dto.dc_type || this.cookieService.get('dcTypePolicy') || '',
          this.dto.date_range || this.cookieService.get('periodPolicy') || 'thismonth',
            this.dto.date_from || this.cookieService.get('dtFromPolicy') || null,
            this.dto.date_to || this.cookieService.get('dtToPolicy') || null,
            this.sort.direction,
            `table_product.${this.sort.active}`,
          );
        }),
        map(data => {

          this.data = data.data;

          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = parseInt(data.recordsTotal);

          return data.data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => {
        this.data = data;
      });
  }

  editDailyCash(dcId: number) {
    this.router.navigate([`/home/daily-cash/create-daily-cash/`, dcId]);
  }

  canEdit(): boolean {
    return this.userService.cap.hasOwnProperty('brzinsurance_dailycash_edit');
  }

  exportExcel() {
    this.loaderExport = true;

    this.viewPoliciesService!.getPolicies(
      this.paginator.pageIndex,
      this.exportLimit,
      this.dto.search_user || this.cookieService.get('producerPolicy') || '',
      this.dto.search_word || null,
      this.dto.dc_type || this.cookieService.get('dcTypePolicy') || '',
      this.dto.date_range || this.cookieService.get('periodPolicy') || 'thismonth',
      this.dto.date_from || this.cookieService.get('dtFromPolicy') || null,
      this.dto.date_to || this.cookieService.get('dtToPolicy') || null,
      this.sort.direction,
      `table_product.${this.sort.active}`,
    ).subscribe((data: ViewPolicy) => {

      if (data !== null) {
        this.dataExport = this.data;

        if (data.data !== null && data.data !== undefined) {
          this.data = data.data;

          setTimeout(() => {
            const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.tableExport.nativeElement); //converts a DOM TABLE element to a worksheet
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

            const fileName = 'daily-cash-policies.xlsx';

            /* save to file */
            XLSX.writeFile(wb, fileName);

            this.data = this.dataExport;
            this.dataExport = [];

            this.loaderExport = false;
          });
        }

      } else {
        this.notificationService.error('There was an error generating the CSV file.');
        this.loaderExport = false;
      }
    });
  }
}
