import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DataDetailedList, DetailedList } from 'src/app/modules/dashboard-lead/model/detailedList';
import { DashboardLeadService } from 'src/app/modules/dashboard-lead/service/dashboard-lead.service';

@Component({
  selector: 'app-table-detailed-list-sales',
  templateUrl: './table-detailed-list-sales.component.html',
  styleUrls: ['./table-detailed-list-sales.component.scss']
})
export class TableDetailedListSalesComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  detailed$: Observable<DetailedList>;
  title: string;
  subtitle: string;

  dataSource: MatTableDataSource<DataDetailedList>;

  @Input() range: string;
  @Input() from: string;
  @Input() to: string;
  @Input() dcType: string[];

  displayedColumns: string[] = [
    'user_id',
    'name',
    'daily_cash_type',
    'count',
    'premium',
    'fee',
    'translation',
    'runner',
    'commission',
    'total',
    'sales_target',
    'sales_success'
  ];

  constructor(private dashboardLeadService: DashboardLeadService) { }

  ngOnInit(): void {
    this.getDetailedListSales(this.range, this.from, this.to, this.dcType);
  }

  getDetailedListSales(range: string, from: string, to: string, dcType: string[]) {
    this.detailed$ = this.dashboardLeadService.getDetailedListSales(range, from, to, dcType)
    .pipe(tap(detailedList => {
      this.title = detailedList.title;
      this.subtitle = detailedList.description;
      this.addDataToDataSource(detailedList.data);

    }));
  }

  addDataToDataSource(data: DataDetailedList[]) {
    setTimeout(() => this.dataSource.paginator = this.paginator);
    setTimeout(() => this.dataSource.sort = this.sort);
    this.dataSource = new MatTableDataSource<DataDetailedList>(data);
  }


  getTotal(column: string) {
    return this.dataSource.data.map(t => t[column]).reduce((acc, value) => acc + value, 0);
  }

}
