import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { TicketsComponent } from './tickets/tickets.component';
import { DealsComponent } from './deals/deals.component';
import { BlipComponent } from './blip/blip.component';

export const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'tickets' },
  {
    path: 'tickets',
    component: TicketsComponent
  },{
    path: 'deals',
    component: DealsComponent
  },{
    path: 'blip',
    component: BlipComponent
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule],
})
export class CollaboratorsRoutingModule {}
