import { pluck } from 'rxjs/operators';
import { CarrierChart } from './../model/carrierChart';
import { TokenService } from './../../../../../core/token/token.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { GetCarrier } from '../model/getCarriers';

@Injectable({
  providedIn: 'root'
})
export class CarrierServiceService {
  unsubscribe() {
    throw new Error('Method not implemented.');
  }
  
  token = this.tokenService.getToken();

  constructor(private http: HttpClient,
    private tokenService: TokenService) { }
    
  getPeriod(): Observable<CarrierChart[]>{
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`
      }),
    };

    return this.http.post<CarrierChart>(`${environment.apiUrl}/brz/v1/carrier_target/get`,
      httpOptions)
      .pipe(pluck('results'));
  }


  getDates(): Observable<CarrierChart[]>{
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`
      }),
    };

    return this.http.post<CarrierChart>(`${environment.apiUrl}/brz/v1/carrier_target/get_dates`,{
      "sort_by_field":"timestamp",
    "sort_by":"DESC" 
        
    },
      httpOptions)
      .pipe(pluck('results'));
  }

  updateGet(data): Observable<CarrierChart[]>{
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`
      }),
    };

    
    const body ={
      "carrier_id":[3412,3413],
      "dates":[    
          "04-2021",
          "05-2021"
      ]
      
      }

    return this.http.post<CarrierChart>(`${environment.apiUrl}/brz/v1/carrier_target/get`, data,
      httpOptions)
      .pipe(pluck('results'));
  }

  getCarrier(): Observable<GetCarrier[]>{
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`
      }),
    };

    return this.http.post<GetCarrier>(`${environment.apiUrl}/brz/v1/carrier_target/get_carriers`,
      httpOptions)
      .pipe(pluck('results'));
  }
}
