import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { pluck, tap, filter } from 'rxjs/operators';
import { TokenService } from 'src/app/core/token/token.service';
import { environment } from 'src/environments/environment';
import { BusinessType } from '../model/businessType';
import { Dashboard, DashboardName } from '../model/dashboard';
import { DetailedList } from '../model/detailedList';
import { TotalPremium } from '../model/totalPremium';
import { LOB } from '../model/lob';
import { MConversionRate } from '../model/mConversionRate';
import { MDeal } from '../model/mDeal';
import { MDealMap } from '../model/mDealMap';
import { PIF } from '../model/pif';
import { PIFLobPremium } from '../model/pifLobPremium';
import { PIFPremium } from '../model/pifPremium';
import { PIFBusinessTypePremium } from '../model/pifBusinessTypePremium';
import { PodiumRank } from '../model/podiumRank';
import { SalesPolicy } from '../model/salesPolicy';
import { DataConversionRate } from '../page/conversion-rate/model/conversion-rate';
import {
  DataCollaborators,
  DataSentQuotes,
  LogsQueue,
} from '../page/quotes-sent/model/sent-quotes';
import { UserService } from 'src/app/core/user/user.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardLeadService {
  token = this.tokenService.getToken();

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private userService: UserService
  ) {}

  getQuotesSent(): Observable<DataSentQuotes[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
    };

    return this.http
      .get<DataSentQuotes[]>(
        `${environment.apiUrl}/brz/v1/quotes/report/sent_quotes`,
        httpOptions
      )
      .pipe(pluck('data'));
  }

  getCollaborators(filters: object): Observable<DataCollaborators[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        token: `ex65jRFYjepRy6omf3G3#kLK3nGmxXa&k$5@`,
      }),
    };

    if(environment.productionMode){
    return this.http
      .post<DataCollaborators[]>(
        `https://brzcomb-integration.herokuapp.com/blip/queue`,
        {
          lang: 'pt-br',
          departament: 'CX',
        },
        httpOptions
      )
      .pipe(pluck('response'));
      }else{
            return this.http
    .get<DataCollaborators[]>(
      `https://brzcomb-integration.herokuapp.com/firebase/dados/ATENDENTES_HOMOLOG`,
      {
        headers: new HttpHeaders({
          token: `ex65jRFYjepRy6omf3G3#kLK3nGmxXa&k$5@`,
        }),
      }
    )
    .pipe(pluck('response'));
      }

  }

  patchCollaborators(id: any, distribuir: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        token: `ex65jRFYjepRy6omf3G3#kLK3nGmxXa&k$5@`,
      }),
    };
    const body = {
      id: id,
      data: {
        distribuir: distribuir,
      },
    };
    if(environment.productionMode){
    return this.http
      .patch<DataCollaborators[]>(
        `https://brzcomb-integration.herokuapp.com/firebase/dados/ATENDENTES`,
        
        body,
        httpOptions
      )
      .pipe(pluck('response'));
      }else{

      
    return this.http
    .patch<DataCollaborators[]>(
      `https://brzcomb-integration.herokuapp.com/firebase/dados/ATENDENTES_HOMOLOG`,
      body,
      {
        headers: new HttpHeaders({
          token: `ex65jRFYjepRy6omf3G3#kLK3nGmxXa&k$5@`,
        }),
      }
    )
    .pipe(pluck('response'))};
  }

  changeLayer(id: string, layer: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        token: `ex65jRFYjepRy6omf3G3#kLK3nGmxXa&k$5@`,
      }),
    };
    const body = {
      id: id,
      data: {
        camada: layer,
      },
    };

    if(environment.productionMode){
    return this.http
      .patch<DataCollaborators[]>(
        `https://brzcomb-integration.herokuapp.com/firebase/dados/ATENDENTES`,
        body,
        httpOptions
      )
      .pipe(pluck('response'));
      }else{
    return this.http
    .patch<DataCollaborators[]>(
      `https://brzcomb-integration.herokuapp.com/firebase/dados/ATENDENTES_HOMOLOG`,
      body,
      {
        headers: new HttpHeaders({
          token: `ex65jRFYjepRy6omf3G3#kLK3nGmxXa&k$5@`,
        }),
      }
    )
    .pipe(pluck('response'));
      }
  }

  addColaborattors(body){
    body.equipe = 'CX'
    body.distribuir = body.distribuir === null ? false : body.distribuir;
    
    const httpOptions = {
      headers: new HttpHeaders({
         token: `ex65jRFYjepRy6omf3G3#kLK3nGmxXa&k$5@`,
      }),
    };

    if(environment.productionMode){
    return this.http
      .post<{response:string,success:boolean}>(
        `https://brzcomb-integration.herokuapp.com/firebase/dados/ATENDENTES`,
        body,
        httpOptions
      )
      .pipe();
    }else{
      return this.http
      .post<{response:string,success:boolean}>(
        `https://brzcomb-integration.herokuapp.com/firebase/dados/ATENDENTES_HOMOLOG`,
        body,
        httpOptions
      )
      .pipe();
      }


  }

  editCollaborator(data){

    const body ={
      id: data.id,
      data:{
        camada: data.camada,
        email: data.email,
        nome: data.nome,
        idiomas:data.idiomas,
        distribuir:data.distribuir,
      }
    };
    const httpOptions = {
      headers: new HttpHeaders({
        token: `ex65jRFYjepRy6omf3G3#kLK3nGmxXa&k$5@`,
      }),
    };

    if(environment.productionMode){
    return this.http
      .patch<{response:string,success:boolean}>(
        `https://brzcomb-integration.herokuapp.com/firebase/dados/ATENDENTES`,
        body,
        httpOptions
      )
      .pipe();
    }else{
      return this.http
      .patch<{response:string,success:boolean}>(
        `https://brzcomb-integration.herokuapp.com/firebase/dados/ATENDENTES_HOMOLOG`,
        body,
        httpOptions
      )
      .pipe();
    }
  }

  deleteColaborattors(id){

    const httpOptions = {
      headers: new HttpHeaders({
        token: `ex65jRFYjepRy6omf3G3#kLK3nGmxXa&k$5@`,
      }),
      // body: {id: id}, // Aqui você inclui o corpo da solicitação DELETE
    };

    if(environment.productionMode){
    return this.http
      .delete<{response:string,success:boolean}>(
        `https://brzcomb-integration.herokuapp.com/firebase/dados/ATENDENTES?id=`+id,
        
        httpOptions
      )
      .pipe();
    }else{
      return this.http
      .delete<{response:string,success:boolean}>(
        `https://brzcomb-integration.herokuapp.com/firebase/dados/ATENDENTES_HOMOLOG?id=`+id,
        
        httpOptions
      )
      .pipe();
    }


  }

  getConversionRate(): Observable<DataConversionRate> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
    };

    return this.http
      .get<DataConversionRate>(
        `${environment.apiUrl}/brz/v1/quotes/report/conversion_rate?interval=all`,
        httpOptions
      )
      .pipe(pluck('data'));
  }

  getQuotes(): Observable<DataConversionRate[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
    };

    return this.http
      .get<DataConversionRate[]>(
        `${environment.apiUrl}/brz/v1/quotes/report/quotes?interval=all`,
        httpOptions
      )
      .pipe(pluck('data'));
  }

  getPif(
    date_range: string,
    date_from: string,
    date_to: string
  ): Observable<PIF> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
    };

    return this.http
      .post<PIF>(
        `${environment.apiUrl}/brz/v1/reports/brzstats/pif`,
        {
          date_range,
          date_from,
          date_to,
        },
        httpOptions
      )
      .pipe();
  }

  getPifPremium(
    date_range: string,
    date_from: string,
    date_to: string
  ): Observable<PIFPremium> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
    };

    return this.http
      .post<PIFPremium>(
        `${environment.apiUrl}/brz/v1/reports/agencystats/pif_premium`,
        {
          date_range,
          date_from,
          date_to,
        },
        httpOptions
      )
      .pipe();
  }

  getPifLobPremium(
    date_range: string,
    date_from: string,
    date_to: string
  ): Observable<PIFLobPremium> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
    };

    return this.http
      .post<PIFLobPremium>(
        `${environment.apiUrl}/brz/v1/reports/agencystats/pif_lob_premium`,
        {
          date_range,
          date_from,
          date_to,
        },
        httpOptions
      )
      .pipe();
  }

  getPIFBusinessTypePremium(
    date_range: string,
    date_from: string,
    date_to: string
  ): Observable<PIFBusinessTypePremium> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
    };

    return this.http
      .post<PIFBusinessTypePremium>(
        `${environment.apiUrl}/brz/v1/reports/agencystats/pif_business_type_premium`,
        {
          date_range,
          date_from,
          date_to,
        },
        httpOptions
      )
      .pipe();
  }

  getPifBusinessType(
    date_range: string,
    date_from: string,
    date_to: string
  ): Observable<BusinessType> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
    };

    return this.http
      .post<BusinessType>(
        `${environment.apiUrl}/brz/v1/reports/brzstats/pif_business_type`,
        {
          date_range,
          date_from,
          date_to,
        },
        httpOptions
      )
      .pipe();
  }

  getLOB(
    date_range: string,
    date_from: string,
    date_to: string
  ): Observable<LOB> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
    };

    return this.http
      .post<LOB>(
        `${environment.apiUrl}/brz/v1/reports/brzstats/pif_lob`,
        {
          date_range,
          date_from,
          date_to,
        },
        httpOptions
      )
      .pipe();
  }

  getStatsPolicyCount(
    date_range: string,
    date_from: string,
    date_to: string,
    daily_cash_type: string[]
  ): Observable<SalesPolicy> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
    };

    return this.http
      .post<SalesPolicy>(
        `${environment.apiUrl}/brz/v1/reports/brzstats/policy_count`,
        {
          date_range,
          date_from,
          date_to,
          daily_cash_type,
        },
        httpOptions
      )
      .pipe();
  }

  getStatsRevenue(
    date_range: string,
    date_from: string,
    date_to: string,
    daily_cash_type: string[]
  ): Observable<SalesPolicy> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
    };

    return this.http
      .post<SalesPolicy>(
        `${environment.apiUrl}/brz/v1/reports/brzstats/revenue`,
        {
          date_range,
          date_from,
          date_to,
          daily_cash_type,
        },
        httpOptions
      )
      .pipe();
  }

  getStatsPremiumCount(
    date_range: string,
    date_from: string,
    date_to: string,
    daily_cash_type: string[]
  ): Observable<SalesPolicy> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
    };

    return this.http
      .post<SalesPolicy>(
        `${environment.apiUrl}/brz/v1/reports/brzstats/premium_count`,
        {
          date_range,
          date_from,
          date_to,
          daily_cash_type,
        },
        httpOptions
      )
      .pipe();
  }

  getDetailedListStats(
    date_range: string,
    date_from: string,
    date_to: string,
    daily_cash_type: string[]
  ): Observable<DetailedList> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
    };

    return this.http
      .post<DetailedList>(
        `${environment.apiUrl}/brz/v1/reports/brzstats/detailed_list`,
        {
          date_range,
          date_from,
          date_to,
          daily_cash_type,
        },
        httpOptions
      )
      .pipe();
  }

  getStatsFee(
    date_range: string,
    date_from: string,
    date_to: string,
    daily_cash_type: string[]
  ): Observable<SalesPolicy> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
    };

    return this.http
      .post<SalesPolicy>(
        `${environment.apiUrl}/brz/v1/reports/brzstats/fee`,
        {
          date_range,
          date_from,
          date_to,
          daily_cash_type,
        },
        httpOptions
      )
      .pipe();
  }

  getSalesPremiumCount(
    date_range: string,
    date_from: string,
    date_to: string,
    daily_cash_type: string[]
  ): Observable<SalesPolicy> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
    };

    return this.http
      .post<SalesPolicy>(
        `${environment.apiUrl}/brz/v1/reports/sales/premium_count`,
        {
          date_range,
          date_from,
          date_to,
          daily_cash_type,
        },
        httpOptions
      )
      .pipe();
  }

  getDetailedListAgencyStats(
    date_range: string,
    date_from: string,
    date_to: string,
    carrier_name: string,
    _carrier_ids: string[]
  ): Observable<TotalPremium> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
    };

    return this.http
      .post<TotalPremium>(
        `${environment.apiUrl}/brz/v1/reports/agencystats/detailed_list`,
        {
          date_range,
          date_from,
          date_to,
          carrier_name,
          _carrier_ids,
        },
        httpOptions
      )
      .pipe();
  }

  getDetailedListSales(
    date_range: string,
    date_from: string,
    date_to: string,
    daily_cash_type: string[]
  ): Observable<DetailedList> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
    };

    return this.http
      .post<DetailedList>(
        `${environment.apiUrl}/brz/v1/reports/sales/detailed_list`,
        {
          date_range,
          date_from,
          date_to,
          daily_cash_type,
        },
        httpOptions
      )
      .pipe();
  }

  getSalesPolicyCount(
    date_range: string,
    date_from: string,
    date_to: string,
    daily_cash_type: string[]
  ): Observable<SalesPolicy> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
    };

    return this.http
      .post<SalesPolicy>(
        `${environment.apiUrl}/brz/v1/reports/sales/policy_count`,
        {
          date_range,
          date_from,
          date_to,
          daily_cash_type,
        },
        httpOptions
      )
      .pipe();
  }

  getDashboards(): Observable<Dashboard> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
    };

    return this.http
      .get<Dashboard>(`${environment.apiUrl}/brz/v1/reports`, httpOptions)
      .pipe();
  }

  updatedDashboardThatUserWantToSee(
    default_dashboard: string,
    dashboards: DashboardName[]
  ): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
    };

    return this.http
      .post<any>(
        `${environment.apiUrl}/brz/v1/reports`,
        { default_dashboard, dashboards },
        httpOptions
      )
      .pipe();
  }

  getMarketingDeals(
    date_range: string,
    date_from: string,
    date_to: string
  ): Observable<MDeal> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
    };

    return this.http
      .post<MDeal>(
        `${environment.apiUrl}/brz/v1/reports/marketing/get_deals`,
        {
          date_range,
          date_from,
          date_to,
        },
        httpOptions
      )
      .pipe();
  }

  getMarketingDealsMap(
    date_range: string,
    date_from: string,
    date_to: string
  ): Observable<MDealMap> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
    };

    return this.http
      .post<MDealMap>(
        `${environment.apiUrl}/brz/v1/reports/marketing/get_deals_map`,
        {
          date_range,
          date_from,
          date_to,
        },
        httpOptions
      )
      .pipe();
  }

  getMarketingConversionRate(
    date_range: string,
    date_from: string,
    date_to: string
  ): Observable<MConversionRate> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
    };

    return this.http
      .post<MConversionRate>(
        `${environment.apiUrl}/brz/v1/reports/marketing/conversion_rate`,
        {
          date_range,
          date_from,
          date_to,
        },
        httpOptions
      )
      .pipe();
  }

  getPodiumRank(
    date_range: string,
    date_from: string,
    date_to: string,
    daily_cash_type: string[]
  ): Observable<PodiumRank> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
    };

    return this.http
      .post<PodiumRank>(
        `${environment.apiUrl}/brz/v1/reports/sales/rank`,
        {
          date_range,
          date_from,
          date_to,
          daily_cash_type,
        },
        httpOptions
      )
      .pipe();
  }

  getCapPermission(capProp: string) {
    return this.userService.cap.hasOwnProperty(capProp);
  }

  getLogs(pag = '0', date?): Observable<LogsQueue[]> {
    const initial = date.startValue;
    const end = date.endValue;
    const httpOptions = {
      headers: new HttpHeaders({
        token: `ex65jRFYjepRy6omf3G3#kLK3nGmxXa&k$5@`,
      }),
    };

    return this.http
      .get<{ retorno: LogsQueue[] }>( // Especifique o tipo esperado aqui
        'https://brzcomb-integration.herokuapp.com/blip/logs?take=50&skip=' +
          pag +
          '&data_inicio=' +
          initial +
          '&data_fim=' +
          end,
        httpOptions
      )
      .pipe(pluck('retorno'));
  }
}
