<mat-card *ngIf="detailed$ | async as detailed; else elseBlock">

    <div class="tab-title">Policies</div>
    <div class="tab-subtitle">&nbsp;</div>

    <div class="header-results">
        <div class="total-premium-value">&nbsp;</div>
        <div class="total-premium-records">Total records: {{totalRecords}}</div>
    </div>

    <div *ngIf="detailed.data.length > 0; else hasNoData">

        <div class="example-container" *ngIf="!!dataSource">
            <div class="example-table-container" #TABLEEXPORT>
                <table mat-table [dataSource]="dataSource" class="table" matSort>

                    <ng-container matColumnDef="qq_link">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: left !important;"> QQ_LINK </th>
                        <td mat-cell *matCellDef="let row" style="text-align: left;">{{row.name }}</td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <ng-container matColumnDef="display_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> DISPLAY_NAME</th>
                        <td mat-cell *matCellDef="let row">{{row.carrier_name }}</td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <ng-container matColumnDef="foreign_hubspot_id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> FOREIGN_HUBSPOT_ID </th>
                        <td mat-cell *matCellDef="let row">{{row.foreign_customer_id }}</td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <ng-container matColumnDef="policy_link">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> POLICY_LINK </th>
                        <td mat-cell *matCellDef="let row">{{row.name }}</td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <ng-container matColumnDef="policy_id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> POLICY_ID </th>
                        <td mat-cell *matCellDef="let row">{{row.policy_id }}</td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <ng-container matColumnDef="foreign_customer_id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> FOREIGN_CUSTOMER_ID </th>
                        <td mat-cell *matCellDef="let row">{{row.foreign_customer_id }}</td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <ng-container matColumnDef="effective_date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> EFFECTIVE_DATE </th>
                        <td mat-cell *matCellDef="let row">{{row.effective_date }}</td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <ng-container matColumnDef="expiration_date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> EXPIRATION_DATE </th>
                        <td mat-cell *matCellDef="let row">{{row.expiration_date }}</td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <ng-container matColumnDef="policy_status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> POLICY_STATUS </th>
                        <td mat-cell *matCellDef="let row">{{row.policy_status }}</td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <ng-container matColumnDef="premium">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> PREMIUM </th>
                        <td mat-cell *matCellDef="let row">{{row.premium | currency }}</td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> DESCRIPTION </th>
                        <td mat-cell *matCellDef="let row">{{row.description }}</td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <ng-container matColumnDef="foreign_carrier_id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> FOREIGN_CARRIER_ID </th>
                        <td mat-cell *matCellDef="let row">{{row.foreign_carrier_id }}</td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <ng-container matColumnDef="business_type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> BUSINESS_TYPE </th>
                        <td mat-cell *matCellDef="let row">{{row.business_type }}</td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <ng-container matColumnDef="carrier_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> CARRIER_NAME </th>
                        <td mat-cell *matCellDef="let row">{{row.carrier_name }}</td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>

        <button type="button" class="btn-dashboard-tab btn-dashboard-tab-active">Load more</button>
    </div>

    <ng-template #hasNoData>
        <mat-card class="card-no-data"
            style="height: 30vh; display: flex; justify-content: center; align-items: center;">
        </mat-card>
    </ng-template>

</mat-card>

<ng-template #elseBlock>
    <mat-card style="height: 50vh; display: flex; justify-content: center; align-items: center;">
        <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
    </mat-card>
</ng-template>

<div class="shadow-export" *ngIf="loaderExport">
  <div class="shadow-export-loader shadow-export-loader-message">
    <div class="spinner">
      <mat-spinner [diameter]="60"></mat-spinner>
    </div>
    <span class="shadow-export-loader-text">Wait, we are generating the CSV</span>
  </div>
</div>