import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-new-collaborator-deal',
  templateUrl: './new-collaborator-deal.component.html',
  styleUrls: ['./new-collaborator-deal.component.scss'],
})
export class NewCollaboratorDealComponent implements OnInit {
  dialogForm: FormGroup;

  constructor(
    private _formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<NewCollaboratorDealComponent>
  ) {}

  ngOnInit(): void {}

  closeModal() {
    this.dialogRef.close();
  }
}
