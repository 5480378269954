<form [formGroup]="fgFilter" class="form-group" (ngSubmit)="onSubmit()" [style.fontSize.px]="14">

    <!-- <mat-form-field appearance="fill">
      <mat-label>Show</mat-label>
      <mat-select formControlName="ctrlLimit" required>
        <mat-option value="10">10</mat-option>
        <mat-option value="20">20</mat-option>
        <mat-option value="50">50</mat-option>
        <mat-option value="100">100</mat-option>
      </mat-select>
    </mat-form-field> -->

    <mat-form-field color="accent" appearance="fill">
      <mat-label>From</mat-label>
      <input matInput formControlName="fcDtFrom" [matDatepicker]="dtFrom" appMaskDate>
      <mat-datepicker-toggle matSuffix [for]="dtFrom"></mat-datepicker-toggle>
      <mat-datepicker #dtFrom></mat-datepicker>
    </mat-form-field>

    <mat-form-field color="accent" appearance="fill">
      <mat-label>To</mat-label>
      <input matInput formControlName="fcDtTo" [matDatepicker]="dtTo" appMaskDate>
      <mat-datepicker-toggle matSuffix [for]="dtTo"></mat-datepicker-toggle>
      <mat-datepicker #dtTo></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Search Word</mat-label>
      <input matInput formControlName="fcSearch">
    </mat-form-field>

    <!-- <mat-form-field appearance="fill">
      <mat-label>Status</mat-label>
      <input matInput formControlName="fcStatus">
    </mat-form-field> -->

   <mat-form-field appearance="fill">
      <mat-label>Status</mat-label>
      <mat-select formControlName="fcStatus">
        <mat-option value="">All</mat-option>
        <mat-option value="w">Won</mat-option>
        <mat-option value="l">Lost</mat-option>
        <mat-option value="p">Pending</mat-option>
      </mat-select>
    </mat-form-field>


    <button class="btn-search" mat-mini-fab color="primary" [disabled]="!fgFilter.valid">
      <mat-icon>search</mat-icon>
    </button>

    <button class="btn-search-mobile" mat-stroked-button color="primary" [disabled]="!fgFilter.valid">Search</button>

  </form>
