<ap-loading></ap-loading>

<div id="page-home">
  <div class="content">

    <main>

      <div class="title">
        <h1 class="intranet-title">INTRANET</h1>
        <div class="div-logo">
          <svg xmlns="http://www.w3.org/2000/svg" width="85" height="70" viewBox="0 0 454 374" fill="none" style="margin-left: 25px;">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M152.66 52.5918L218.107 0L237.808 0.0773541L302.049 52.5343L342.732 18.4215L391.743 18.4322L251.587 135.722L308.457 183.51L428.282 82.9064H454V325.108H428.277L308.448 224.414L237.973 283.584L217.898 283.626L146.285 224.017L25.7105 325.119H0V82.8638H25.6672L146.212 183.204L202.87 135.696L63.7129 18.4322H112.009L152.66 52.5918ZM177.102 73.1313L227.239 115.262L277.617 73.0207L227.831 32.3672L177.102 73.1313ZM227.218 156.155L170.675 203.566L227.867 251.172L284.103 203.957L227.218 156.155ZM121.822 203.654L31.3103 128.313V279.549L121.822 203.654ZM332.801 203.967L422.69 279.502V128.498L332.801 203.967Z" fill="#FECD17"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M219.392 374L135.435 304.682H184.73L229.299 341.548L273.195 304.749L322.569 304.682L239.421 373.934L219.392 374Z" fill="#FECD17"/>
            </svg>
          <!-- <img src='../../../assets/images/breezy-logo.png' class="logo" /> -->
        </div>
      </div>

      <h2 class="subtitle">Insurance agency that cares about people </h2>

      <form [formGroup]="formGroup" (ngSubmit)="onSubmit()" [style.fontSize.px]="18">

        <mat-form-field class="field">
          <mat-label>username</mat-label>
          <input matInput formControlName="fcUser" autocomplete="off">

          <mat-error *ngIf="isFieldInvalid('fcUser')">
            Please enter your user
          </mat-error>
        </mat-form-field>

        <mat-form-field class="field">
          <mat-label>password</mat-label>
          <input matInput [type]="hide ? 'password' : 'text'" formControlName="fcPwd">

          <mat-error *ngIf="isFieldInvalid('fcPwd')">
            Please enter your password
          </mat-error>
        </mat-form-field>

        <button mat-raised-button color="primary" [disabled]="!formGroup.valid">Login</button>

        <div class="password-forgot">
          <a routerLink="/login/forgot-password/">Forgot your password?</a>
        </div>

      </form>

    </main>
  </div>
</div>
