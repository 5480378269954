import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { PusherService } from 'src/app/shared/services/pusher.service';
import { environment } from 'src/environments/environment';
import { ConversionRateComponent } from './conversion-rate/conversion-rate.component';
import { QuotesSentComponent } from './quotes-sent/quotes-sent.component';
import { QuotesComponent } from './quotes/quotes.component';
import { DashboardLeadService } from '../service/dashboard-lead.service';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-dashboard-lead',
  templateUrl: './dashboard-lead.component.html',
  styleUrls: ['./dashboard-lead.component.scss']
})
export class DashboardLeadComponent implements OnInit {

  constructor(private pusherService: PusherService, private dashboardLeadService: DashboardLeadService, private notification: NotificationService) { }

  @ViewChild(QuotesComponent) quotesComponent: QuotesComponent;
  @ViewChild(QuotesSentComponent) quotesSentComponent: QuotesSentComponent;
  @ViewChild(ConversionRateComponent) ConversionRateComponent: ConversionRateComponent;

  hasCapPermission: boolean = false;

  ngOnInit() {
    this.pusher();

    this.hasCapPermission = this.dashboardLeadService.getCapPermission('brz_menu_quote_dashboard');

    if (!this.hasCapPermission) {
      this.notification.error("You don't have permission to access this page.");
    }
  }

  pusher() {

    let pusherClient = this.pusherService.getPusher();

    let roles = JSON.parse(window.localStorage.getItem('userRole')) as any[];
    let firstIndex: number;

    for (var index in roles) {
      firstIndex = parseInt(index);
      break;
    }

    const channel = pusherClient.subscribe(`${environment.channelSlug}role-${roles[firstIndex]}`);


    //[slug]-leadsource-[leadsource]

    channel.bind(
      'pusher:subscription_succeeded', () => {
        console.log(`channel: ${environment.channelSlug}role-${roles[firstIndex]}`);
      });

    channel.bind('new-lead', (lead) => {

      if (this.quotesComponent.chartOptions.series[0]['data'].length > 0) {

        switch (lead.status.toLowerCase()) {
          case 'p':
            let pLine = this.quotesComponent.chartOptions.series[0]['data'].find(x => x.name.toLowerCase() === 'pending');

            if (pLine) pLine.y++
            else this.quotesComponent.chartOptions.series[0]['data'].push({ y: 1 })

            break;
          case 'w':
            let wLine = this.quotesComponent.chartOptions.series[0]['data'].find(x => x.name.toLowerCase() === 'won');

            if (wLine) wLine.y++
            else this.quotesComponent.chartOptions.series[0]['data'].push({ name: "Won", y: 1 })

            break;
          case 'l':
            let lLine = this.quotesComponent.chartOptions.series[0]['data'].find(x => x.name.toLowerCase() === 'lost');

            if (lLine) lLine.y++
            else this.quotesComponent.chartOptions.series[0]['data'].push({ name: "Lost", y: 1 })
            break;

          default:
            break;
        }

        this.quotesComponent.updateFlag = true;

      }

      if (this.ConversionRateComponent.chartOptions.series[0]['data'].length > 0) {
        this.ConversionRateComponent.updateFlag = true;
      }

      if (this.quotesSentComponent.chartOptions.series[0]['data'].length > 0) {

        var datePipe = new DatePipe('en-US');
        let dateCreated = datePipe.transform(lead.lead_date, 'yyyy-MM') || null;

        let index = this.quotesSentComponent.chartOptions.xAxis[0].categories.findIndex(x => x === dateCreated);
        //2021-05
        //         0: "2020-08"
        // 1: "2020-09"

        if (index === -1) {
          this.quotesSentComponent.chartOptions.xAxis[0].categories.push(dateCreated);
          //let newItemIndex = this.quotesSentComponent.chartOptions.xAxis[0].categories.findIndex(x => x === dateCreated);
          this.quotesSentComponent.chartOptions.series[0]['data'].push({ y: 1 });
        } else {
          this.quotesSentComponent.chartOptions.series[0]['data'][index].y++;
        }

        this.quotesSentComponent.updateFlag = true;

      }


    });

    let leadsource = window.localStorage.getItem('leadsource');

    if (leadsource) {
      const leadsourceChannel = pusherClient.subscribe(`${environment.channelSlug}leadsource-${leadsource}`);

      leadsourceChannel.bind(
        'pusher:subscription_succeeded', () => {
          console.log(`channel: ${environment.channelSlug}leadsource-${leadsource}`);
        });

      leadsourceChannel.bind('new-lead', (lead) => {

        if (this.quotesComponent.chartOptions.series[0]['data'].length > 0) {

          switch (lead.status.toLowerCase()) {
            case 'p':
              let pLine = this.quotesComponent.chartOptions.series[0]['data'].find(x => x.name.toLowerCase() === 'pending');

              if (pLine) pLine.y++
              else this.quotesComponent.chartOptions.series[0]['data'].push({ y: 1 })

              break;
            case 'w':
              let wLine = this.quotesComponent.chartOptions.series[0]['data'].find(x => x.name.toLowerCase() === 'won');

              if (wLine) wLine.y++
              else this.quotesComponent.chartOptions.series[0]['data'].push({ name: "Won", y: 1 })

              break;
            case 'l':
              let lLine = this.quotesComponent.chartOptions.series[0]['data'].find(x => x.name.toLowerCase() === 'lost');

              if (lLine) lLine.y++
              else this.quotesComponent.chartOptions.series[0]['data'].push({ name: "Lost", y: 1 })
              break;

            default:
              break;
          }

          this.quotesComponent.updateFlag = true;

        }

        let total: number = 0;
        let won: number = 0;

        this.quotesComponent.chartOptions.series[0]['data'].forEach(e => {
          total = total + e.y;

          if (e.name.toLowerCase() === 'won') won = e.y;
        });

        this.ConversionRateComponent.chartOptions.series[0]['data'][0].y = parseFloat((parseFloat((won / total).toFixed(2)) * 100).toFixed(2));

        this.ConversionRateComponent.updateFlag = true;

        if (this.quotesSentComponent.chartOptions.series[0]['data'].length > 0) {

          var datePipe = new DatePipe('en-US');
          let dateCreated = datePipe.transform(lead.lead_date, 'yyyy-MM') || null;

          let index = this.quotesSentComponent.chartOptions.xAxis[0].categories.findIndex(x => x === dateCreated);
          //2021-05
          //         0: "2020-08"
          // 1: "2020-09"

          if (index === -1) {
            this.quotesSentComponent.chartOptions.xAxis[0].categories.push(dateCreated);
            //let newItemIndex = this.quotesSentComponent.chartOptions.xAxis[0].categories.findIndex(x => x === dateCreated);
            this.quotesSentComponent.chartOptions.series[0]['data'].push({ y: 1 });
          } else {
            this.quotesSentComponent.chartOptions.series[0]['data'][index].y++;
          }

          this.quotesSentComponent.updateFlag = true;

        }

      });
    }

  }
}
