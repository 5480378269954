<div class="loader-box" *ngIf="loadSpinner">
  <mat-spinner [diameter]="24" [mode]="'indeterminate'" ></mat-spinner>
</div>

<div class="container-box">
  <div class="title-box">
    <span class="subtitle">BRZ Collaborators / Blip</span>
  </div>

  <div class="subtitle-box">
    <span class="subtitle" >Customer Experience</span>
    <button class="history-log-btn" (click)="openLogs('10000','10000')">
      <span>Ticket History</span>
      <mat-icon>schedule</mat-icon>
    </button>
  </div>

  <div>
    <app-cx></app-cx>
    <app-claim-blip></app-claim-blip>
    <app-sdr-blip></app-sdr-blip>
  </div>
</div>
