<mat-dialog-content>

  <mat-card class="card-planilha-auxiliar">

      <!-- <mat-card-header>
        <div mat-card-avatar class="header-image"></div>
        <mat-card-title>Confirmation</mat-card-title>
        <mat-card-subtitle class="card-subtitle">
          Let us know if you are right about that
        </mat-card-subtitle>
      </mat-card-header> -->
      <mat-card-content>
        {{confirmMessage}}
      </mat-card-content>

  </mat-card>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="warn" (click)="dialogRef.close(true)">Confirm</button>
  <button mat-button (click)="dialogRef.close(false)">Cancel</button>
</mat-dialog-actions>
