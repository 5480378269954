import { SdrBlipComponent } from './../../blip/sdr-blip/sdr-blip.component';
import { ClaimBlipComponent } from './../../blip/claim-blip/claim-blip.component';
import { BlipServiceService } from './../../blip/blip-service.service';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ConfirmComponent } from '../confirm/confirm.component';

@Component({
  selector: 'app-slide',
  templateUrl: './slide.component.html',
  styleUrls: ['./slide.component.scss'],
})
export class SlideComponent implements OnInit {
  @Input() initialState: any;
  @Input() switchId: any;
  @Input() agentData: any;
  @Output() updateTable = new EventEmitter<void>();

  constructor(
    private BlipServiceService: BlipServiceService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  changeStatus(event) {
    
    const isChecked = (event.target as HTMLInputElement).checked;
    if (!isChecked && this.agentData.waitingTickets >0) {
      //  abrir modal, se clicar em redistribuir chamar endpoint http://brzcomb-integration.herokuapp.com/blip/distribuicao_automatica?fila=email&homolog=true
      this.dialog
        .open(
          ConfirmComponent,

          {
            height: '250px',
            data: {
              agentData: this.agentData,
              departament: this.agentData,
            },
          }
        )
        .afterClosed()
        .subscribe((data) => {
          this.updateTable.emit();
        });
    }

    this.BlipServiceService.editCollaborator({
      distribuir: isChecked,
      id: this.agentData.id,
    }).subscribe((data) => {
      this.updateTable.emit();
    });
  }
}
