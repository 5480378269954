import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, pluck, tap } from 'rxjs/operators';
import { TokenService } from 'src/app/core/token/token.service';
import { UserService } from 'src/app/core/user/user.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { environment } from 'src/environments/environment';
import { DataViewPolicy } from '../model/dataViewPolicy';
import { ViewPolicy } from '../model/viewPolicy';

@Injectable({
  providedIn: 'root'
})
export class ViewPoliciesService {

  token = this.tokenService.getToken();

  constructor(private http: HttpClient, private tokenService: TokenService,
    private userService: UserService, private notification: NotificationService) { }

  getPolicies(page: number, limit: number, search_user: string,
    search_word: string,dc_type: string, date_range: string, date_from: string,
    date_to: string, sort_by: string, sort_by_field: string): Observable<ViewPolicy> {

    if (this.userService.cap.hasOwnProperty('brzinsurance_dc_read')) {

      const httpOptions = {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.token}`
        })
      };

      return this.http.post<ViewPolicy>
        (`${environment.apiUrl}/brz/v1/daily_cash/search/dc_product/`,
          {
            page,
            limit,
            search_user,
            search_word,
            dc_type,
            date_range,
            date_from,
            date_to,
            sort_by,
            sort_by_field,
          },
          httpOptions)
      // .pipe(
      //   pluck('data'),
      // );
    }
    else {
      this.notification.alert('You have no permission to get policies.');
    }

  }

  getProducts(product_id?: number): Observable<DataViewPolicy> {

    if (this.userService.cap.hasOwnProperty('brzinsurance_dc_read')) {

      const httpOptions = {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.token}`
        })
      };

      return this.http.post<DataViewPolicy>
        (`${environment.apiUrl}/brz/v1/daily_cash/search/dc_product/`, { product_id }, httpOptions)
        .pipe(
          pluck('data')
        );
    }
    else {
      this.notification.alert('You have no permission to get products.');
    }
  }


}
