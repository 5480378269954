import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginGuard } from './core/auth/login.guard';
import { TesteComponent } from './modules/teste/teste.component';

export const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./core/nav-bar/nav-bar.module').then((m) => m.NavbarModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'teste',
    component: TesteComponent,
    canActivate: [LoginGuard],
  },
  // {
  //   path: ':state/:code', loadChildren: () =>
  //     import('./core/nav-bar/nav-bar.module').then((m) => m.NavbarModule),
  //   canActivate: [LoginGuard],
  // },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  //imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', useHash: false })],
  imports: [RouterModule.forRoot(routes, { /*relativeLinkResolution: 'legacy',*/ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
