import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map, tap } from 'rxjs/operators';
import { PodiumRank, PodiumRankData } from 'src/app/modules/dashboard-lead/model/podiumRank';
import { DashboardLeadService } from 'src/app/modules/dashboard-lead/service/dashboard-lead.service';

@Component({
  selector: 'app-chart-podium',
  templateUrl: './chart-podium.component.html',
  styleUrls: ['./chart-podium.component.scss']
})
export class ChartPodiumComponent implements OnInit {

  rank$: Observable<PodiumRank>;

  ranks: PodiumRankData[] = [];
  title: string;
  subtitle: string;

  @Input() range: string;
  @Input() from: string;
  @Input() to: string;
  @Input() dcType: string[];

  constructor(private dashboardLeadService: DashboardLeadService) { }

  ngOnInit(): void {

    this.getPodiumRank(this.range, this.from, this.to, this.dcType);
  }

  getPodiumRank(range: string, from: string, to: string, dcType: string[]) {
    this.rank$ = this.dashboardLeadService.getPodiumRank(range, from, to, dcType)
      .pipe(
        map((podiumRank) => {



          let a;

          podiumRank.data.forEach((e, i) => {

            if (e.name.includes('@')) {
              a = e.name.split('@');
              podiumRank.data[i].name = a[0];
            }
          })

          return podiumRank;
        }),
        tap((podiumRank) => {

          this.title = podiumRank.title;
          this.subtitle = podiumRank.description;
          this.ranks = [];

          if (podiumRank.data.length > 0) {

            this.ranks = podiumRank.data;

            let champion = this.ranks.filter(x => x.my_rank === 1);
            let secondPlace = this.ranks.filter(x => x.my_rank === 2);
            let thirdPlace = this.ranks.filter(x => x.my_rank > 2);

            let second = this.ranks.find(x => x.my_rank === 2) || [{
              my_rank: 0,
              name: '',
              total_premium: 0,
              user_email: '',
              user_id: 0,
            }];

            if (thirdPlace.length > 1) {
              this.ranks = [secondPlace[0], champion[0], thirdPlace[0], thirdPlace[1]];
            }
            else if (thirdPlace.length === 1) {
              this.ranks = [secondPlace[0], champion[0], thirdPlace[0]];
            }
            else if(secondPlace.length > 0) {
              this.ranks = [secondPlace[0], champion[0]];
            }
            else {
              this.ranks = [champion[0]];
            }
          }

        }));
  }

}
