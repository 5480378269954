<mat-card *ngIf="detailed$ | async as detailed; else elseBlock">

    <div class="tab-title">Total Premium</div>
    <div class="tab-subtitle">Policies in force</div>

    <div class="header-results">
        <div class="total-premium-value">{{totalPremiumValue | currency }}</div>
        <div class="total-premium-records">Total records: {{totalRecords}}</div>
    </div>

    <div *ngIf="detailed.data.length > 0; else hasNoData">

        <div class="example-container" *ngIf="!!dataSource">
            <div class="example-table-container" #TABLEEXPORT>
                <table mat-table [dataSource]="dataSource" class="table" matSort>

                    <ng-container matColumnDef="carrier">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: left !important;"> Carrier </th>
                        <td mat-cell *matCellDef="let row" style="text-align: left;">{{row.carrier_name }}</td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <ng-container matColumnDef="premium">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Total premium</th>
                        <td mat-cell *matCellDef="let row">{{row.premium | currency }}</td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Amount </th>
                        <td mat-cell *matCellDef="let row">{{row.count }}</td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>

        <button type="button" class="btn-dashboard-tab btn-dashboard-tab-active">Load more</button>
    </div>

    <ng-template #hasNoData>
        <mat-card class="card-no-data"
            style="height: 30vh; display: flex; justify-content: center; align-items: center;">
        </mat-card>
    </ng-template>

</mat-card>

<ng-template #elseBlock>
    <mat-card style="height: 50vh; display: flex; justify-content: center; align-items: center;">
        <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
    </mat-card>
</ng-template>

<div class="shadow-export" *ngIf="loaderExport">
  <div class="shadow-export-loader shadow-export-loader-message">
    <div class="spinner">
      <mat-spinner [diameter]="60"></mat-spinner>
    </div>
    <span class="shadow-export-loader-text">Wait, we are generating the CSV</span>
  </div>
</div>