import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-new-quote',
  templateUrl: './new-quote.component.html',
  styleUrls: ['./new-quote.component.scss'],
})
export class NewQuoteComponent implements OnInit {
  formStep: number = 1;
  formHomeStep: number = 1;
  hasDriver: boolean = false;
  hasVehicle: boolean = false;
  form: FormGroup;
  formDrivers: FormGroup;
  formVehicle: FormGroup;
  driverList = [];
  drivers: any;
  vehicles: any;
  vehiclesList = [];
  files = [];
  localData: any;
  @Input() teste: any;
  constructor(private formBuilder: FormBuilder,
    private notificationService: NotificationService) {}

  ngOnInit(): void {


    // this.notificationService.success('Successfully Saved')
    //     else this.notificationService.error('Error on saving')


    this.formVehicle = this.formBuilder.group({
      vin: ['', [Validators.required, Validators.maxLength(17)]],
      mainUse: ['', [Validators.required]],
      driver: ['', [Validators.required]],
      annualMiles: ['', [Validators.required]],
      collisionDeductible: ['', [Validators.required]],
      comprehensiveDeductible: ['', [Validators.required]],
      substituteTransportation: '',
      towingAndLabor: '',
    });

    console.log(this.formVehicle.controls);
    this.formVehicle = this.fvh();
    this.formDrivers = this.formBuilder.group({
      driverFirstname: ['', [Validators.required]],
      driverLastname: ['', [Validators.required]],
      driverBirthdate: ['', [Validators.required]],
      driverGender: ['', [Validators.required]],
      driverLicenseNumber: ['', [Validators.required]],
      driverLicenseState: ['', [Validators.required]],
      driverStatus: ['', [Validators.required]],
    });

    this.createFormGroup();
    window.localStorage.setItem('drivers', JSON.stringify([]));

    this.localData = {
      address: {
        address: this.form.controls.address.value,
        address_line_2: this.form.controls.aptunid.value,
        city: '',
        state: '',
        zip_code: '',
      },
      birthdate: this.form.controls.dateOfBirth.value,
      currently_insured: this.form.controls.currentlyInsured.value,
      drivers: [],
      email: this.form.controls.email.value,
      first_name: this.form.controls.firstName.value,
      gender: this.form.controls.gender.value,
      last_name: this.form.controls.lastName.value,
      marital_status: this.form.controls.marital.value,
      phone: this.form.controls.phone.value,
      policy_start_date: this.form.controls.policyStartDate.value,
      preferred_language: this.form.controls.preferredLanguage.value,
      quo_plan_insurance: this.form.controls.planInsurance.value,
      quo_type_insurance: this.form.controls.typeOfInsurance.value,
      primaryResidence: this.form.controls.primaryResidence.value,
      spouse: {
        birthdate: this.form.controls.spouse_dateOfBirth.value,
        first_name: this.form.controls.spouse_firstName.value,
        gender: this.form.controls.gender.value,
        last_name: this.form.controls.spouse_lastName.value
      },
      vehicles: [],
    };
  }

  createFormGroup() {
    this.form = this.formBuilder.group({
      typeOfInsurance: ['', Validators.required],
      planInsurance: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      dateOfBirth: ['', Validators.required],
      phone: ['', Validators.required],
      gender: ['', Validators.required],
      preferredLanguage: ['', Validators.required],
      marital: ['', Validators.required],
      spouse_firstName: ['', Validators.required],
      spouse_lastName: ['', Validators.required],
      spouse_dateOfBirth: ['', Validators.required],
      spouse_gender: ['', Validators.required],
      address: ['', Validators.required],
      aptunid: '',
      policyStartDate: ['', Validators.required],
      currentlyInsured: ['', Validators.required],
      primaryResidence: ['', Validators.required],
    });
    // console.log(this.form.controls );
  }

  getStepForm(): FormGroup {
    return this.form as FormGroup;
  }

  fdr(): FormGroup {
    return this.formDrivers as FormGroup;
  }

  fvh(): FormGroup {
    return this.formVehicle as FormGroup;
  }

 validation(){

 return true
  
  }

  chooseP(plan) {
    this.form.patchValue({
      planInsurance: plan,
    });
    this.formStep += 1;
    window.localStorage.setItem('form', JSON.stringify(this.form.value));

    return this.form as FormGroup;
  }

  openList() {
    if (this.formStep == 9) {
      this.formStep = 10;
    } else if (this.formStep == 10) {
      this.formStep = 9;
    }

    if (this.formStep == 11) {
      this.formStep = 12;
    } else if (this.formStep == 12) {
      this.formStep = 11;
    }
  }

  OpenUpload(upload) {
    upload.click();
  }

  onFileSelected(data) {
    console.log(data.target.files);

    let arrFiles = data.target.files;

    arrFiles.forEach((element) => {
      this.files.push(element);
      console.log(element.size);
    });
    console.log(this.files);
  }


  submit(){
    this.notificationService.success('Successfully Saved')
  }
}
