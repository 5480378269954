<div class="boxInput" [formGroup]="form" *ngIf="Driver == 'personal'" >
    <label [attr.for]=formControlName> {{label}}</label>
    <input #inpt1 *ngIf="label !='Address'" [attr.type]="typeInput" [ngClass]="{'backgroundInput': address, 'is-invalid': validaErro || ExternalValidaErro}" [attr.id]=formControlName [formControlName]="control" [attr.placeholder]="plchldr"  />


    
    <input   *ngIf="label =='Address'" ngx-google-places-autocomplete [options]='options' [ngClass]="{'backgroundInput': address, 'is-invalid': validaErro || ExternalValidaErro}" [formControlName]="control"  #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)"/>


 

  
    
    
  </div>

  <div class="boxInput" *ngIf="Driver == 'driver'" [formGroup]="formDrivers">
    <label [attr.for]=formControlName> {{label}}</label>
    <input    *ngIf="label !='Address'" [attr.type]="typeInput" [ngClass]="{'backgroundInput': address, 'is-invalid': validaErro}" [attr.id]=formControlName [formControlName]="control" [attr.placeholder]="plchldr" #inptLicenseNumber />
    <input   *ngIf="label =='Address'" ngx-google-places-autocomplete [options]='options' [ngClass]="{'backgroundInput': address, 'is-invalid': validaErro}" [formControlName]="control"  #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)"/>

   
    
  </div>

  <div class="boxInput" *ngIf="Driver == 'vehicle'" [formGroup]="formVehicle">
    <label [attr.for]=formControlName> {{label}}</label>
    <input  *ngIf="label !='Address'" [attr.type]="typeInput" [ngClass]="{'backgroundInput': address, 'is-invalid': validaErro}" [attr.id]=formControlName [formControlName]="control" [attr.placeholder]="plchldr"  #input  />
    <input   *ngIf="label =='Address'" ngx-google-places-autocomplete [options]='options' [ngClass]="{'backgroundInput': address, 'is-invalid': validaErro}" [formControlName]="control"  #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)"/>








    
  </div>