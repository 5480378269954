<div class="box">
    <div class="box-title">
      <span class="subtitle-minor">Open Claim</span>
      <button class="add-btn" (click)="openDialog()">Adicionar</button>
    </div>
  
    <div class="table-data">
      <mat-table class="table-data-style" [dataSource]="dataSource" fixedLayout="false">
        <ng-container matColumnDef="select" sticky>
          <mat-header-cell *matHeaderCellDef style="width: 500px !important;">
            <mat-checkbox class="example-margin" [checked]="false"></mat-checkbox>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-checkbox
              [checked]="row.select"
              class="example-margin"
            ></mat-checkbox>
          </mat-cell>
        </ng-container>
   
        <ng-container matColumnDef="queue" sticky>
          <mat-header-cell *matHeaderCellDef> Queue </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.queue }} </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="status" sticky>
          <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-slide-toggle></mat-slide-toggle>
          </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="name" sticky>
          <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.name }} </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef> E-mail </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.email }} </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="tickets">
          <mat-header-cell *matHeaderCellDef> Tickets </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.tickets }} </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="idiomas">
          <mat-header-cell *matHeaderCellDef> Idiomas </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.idiomas }} </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="ticketsToday">
          <mat-header-cell *matHeaderCellDef> Tickets Today </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.ticketsToday }} </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="openedTickets">
          <mat-header-cell *matHeaderCellDef> Opened Tickets </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.openedTickets }} </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="closedTickets">
          <mat-header-cell *matHeaderCellDef> Closed Tickets </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.closedTickets }} </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="totalTickets">
          <mat-header-cell *matHeaderCellDef> Total Tickets </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.totalTickets }} </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="menu" stickyEnd>
          <mat-header-cell *matHeaderCellDef>  </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-icon>more_vert</mat-icon>
          </mat-cell>
        </ng-container>
  
        <mat-header-row
          class="row-header"
          *matHeaderRowDef="displayedColumns;sticky:true "
          
        ></mat-header-row>
        <mat-row
          class="rows-table"
          *matRowDef="let row; columns: displayedColumns"
        ></mat-row>
      </mat-table>
    </div>
  </div>
  
  
  
  
  