import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewCollaboratorDealComponent } from '../../deals/new-collaborator-deal/new-collaborator-deal.component';
import { MatTableDataSource } from '@angular/material/table';
import { NewCollaboratorsBlipComponent } from '../new-collaborators-blip/new-collaborators-blip.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AgentsBlip } from '../../model/interfaces';
import { BlipServiceService } from './../blip-service.service';
import { RemoveCollaboratorComponent } from '../remove-collaborator/remove-collaborator.component';
import { Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

export interface PeriodicElement {
  select: boolean;
  queue: number;
  status: boolean;
  name: string;
  email: string;
  acting: string;
  idiomas: Array<string>;
  dealsToday: number;
  totalDeals: number;
  menu: object;
}

@Component({
  selector: 'app-sdr-blip',
  templateUrl: './sdr-blip.component.html',
  styleUrls: ['./sdr-blip.component.scss'],
})
export class SdrBlipComponent implements OnInit {
  // dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  dataSource: AgentsBlip[];
  displayedColumns: string[] = [
    'sequence',
    'distribuir',
    'nome',
    'email',
    'maxTickets',
    'openedTickets',
    'waitingTickets',
    'totalTickets',
    'closedTickets',
    'idiomas',
    'id',
  ];
  emptyData: boolean = false;
  waitingTickets: number = 0;
  simultaneousTicket: number = 10;
  form: FormGroup;
  idSimultaneousTicket: string;
  private ticketChangeSubjects: { [key: string]: Subject<any> } = {};

  constructor(
    public dialog: MatDialog,
    private BlipServiceService: BlipServiceService,
    private formBuilder: FormBuilder
  ) {}
  ngOnInit(): void {
    const storedValue = window.localStorage.getItem('simultaneousTicketSDR');
    this.simultaneousTicket = storedValue ? +storedValue : 10;

    setInterval(() => {
      this.returnWaitingTickets();
      this.getQueue();
    }, 60000);
    this.returnWaitingTickets();
    this.simultaneous();
    this.getQueue();
    this.form = this.formBuilder.group({
      simultaneous: [10, Validators.required],
    });
  }

  simultaneous() {
    this.BlipServiceService.returnSimultaneousTicket().subscribe((data) => {
      let dropdownNumber = data.filter((item) => item.nome == 'sdr');
      this.idSimultaneousTicket = dropdownNumber[0].id;
      this.form.get('simultaneous').setValue(dropdownNumber[0].maxTickets);
    });
  }

  returnWaitingTickets() {
    this.BlipServiceService.waitingTickets({ queue: 'sdr' }).subscribe(
      (data) => {
        this.waitingTickets = data.count;
      }
    );
  }

  getQueue() {
    this.BlipServiceService.getCollaborators({ departament: 'sdr' }).subscribe(
      (collaborators) => {
        this.dataSource = collaborators;

        collaborators.length > 0
          ? (this.emptyData = false)
          : (this.emptyData = true);
      }
    );
  }

  openDialog(data) {
    this.dialog
      .open(NewCollaboratorsBlipComponent, {
        height: '430px',
        data: {
          addCollaborator: data.save,
          agentData: data.agentData,
          departament: 'sdr',
          dataSource: this.dataSource,
          simultaneousTicket: this.form.get('simultaneous').value,
        },
      })
      .afterClosed()
      .subscribe((data) => {
        this.getQueue();
      });
  }

  removeCollaborator(data) {
    this.dialog
      .open(RemoveCollaboratorComponent, {
        data: {
          agentData: data.agentData,
          departament: 'sdr',
        },
      })
      .afterClosed()
      .subscribe((data) => {
        this.getQueue();
      });
  }

  changeLimitTickets(operator: number, row) {
    row.maxTickets += operator;

    if (!this.ticketChangeSubjects[row.id]) {
      this.ticketChangeSubjects[row.id] = new Subject<any>();
      this.ticketChangeSubjects[row.id]
        .pipe(
          debounceTime(1000), // 1 segundo de inatividade
          switchMap(({ row }) =>
            this.BlipServiceService.editCollaborator({
              maxTickets: row.maxTickets,
              id: row.id,
            })
          )
        )
        .subscribe(() => {
          this.getQueue();
        });
    }

    if (operator !== 0) {
      this.ticketChangeSubjects[row.id].next({ row });
    }
  }

  changeSimultaneousTicket(e) {
    this.form.get('simultaneous').setValue(e.target.value);
    window.localStorage.setItem('simultaneousTicketSDR', e.target.value);
    this.BlipServiceService.editSimultaneousTicket(
      this.idSimultaneousTicket,
      parseInt(e.target.value)
    ).subscribe();

    this.dataSource.forEach((item) => {
      let itemTickets = parseInt(e.target.value);
      if (+item.maxTickets > itemTickets) {
        this.BlipServiceService.editCollaborator({
          maxTickets: itemTickets,
          id: item.id,
        }).subscribe((data) => {
          this.getQueue();
        });
      }
    });
  }
  onMenuClosed() {
    this.getQueue();
  }
}
