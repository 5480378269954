<!-- <mat-card *ngIf="teste" style="margin: 24px 24px;">

  <mat-card-header class="header-quote">

    <i class="far fa-clipboard" style="margin-left: -20px;"></i>

    <div class="ml-28">
      <mat-card-title>Quotes</mat-card-title>
      <mat-card-subtitle>&nbsp;</mat-card-subtitle>
    </div>

  </mat-card-header> -->



<mat-card *ngIf="teste">

  <mat-card-header class="header-quote">

    <i class="fas fa-chart-pie" style="margin-left: -20px; margin-top: 14px;"></i>

    <div class="ml-28">
      <mat-card-title class="title-dashboard">Quotes</mat-card-title>
     <mat-card-subtitle>General data for quotes</mat-card-subtitle>
    </div>

  </mat-card-header>
  <mat-card-content>
    <highcharts-chart style="height: 50vh; display: flex;
    justify-content: center;
    align-items: center;" [Highcharts]="Highcharts" [options]="chartOptions" class="chart" [(update)]="updateFlag"></highcharts-chart>
  </mat-card-content>

</mat-card>
