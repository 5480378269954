import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

import { BlipComponent } from '../../blip/blip.component';
import { BlipServiceService } from '../../blip/blip-service.service';
@Component({
  selector: 'app-menu-laguage',
  templateUrl: './menu-laguage.component.html',
  styleUrls: ['./menu-laguage.component.scss'],
})
export class MenuLaguageComponent implements OnInit {
  availableIdiomas = ['All', 'EN', 'ES', 'PT'];
  form: FormGroup;
  @Input() langList: Array<string>;
  @Input() dataRow;
  isChecked = true;

  constructor(
    private BlipServiceService: BlipServiceService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      idiomas: this.formBuilder.array(this.langList || []),
    });
  }

  getCheckboxControl(idioma: string) {
    return (this.form.get('idiomas') as FormArray).controls[
      this.availableIdiomas.indexOf(idioma)
    ];
  }

  onCheckboxChange(checked: boolean, idioma: string) {
    console.log(checked, idioma);
    if (checked) {
      this.langList = (idioma === 'All') ? ['EN', 'ES', 'PT'] : [...this.langList, idioma];
  } else {
      this.langList = (idioma === 'All') ? [] : this.langList.filter(item => item !== idioma);
  }

    console.log(this.langList);

    this.BlipServiceService.editCollaborator({
           idiomas: this.langList,
           id: this.dataRow.id,
        }).subscribe((data) => {});
  }
}
