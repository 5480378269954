<mat-card *ngIf="heWantsPif() || heWantsPifBusinessType() || heWantsPifLob() || heWantsPifPremium() || heWantsPifBusinessTypePremium() || heWantsPifLobPremium()" class="card-container">

  <mat-card-header class="header-quote">

    <i class="fas fa-chart-pie" style="margin-left: -20px; margin-top: 14px;"></i>

    <div class="ml-28">
      <mat-card-title class="title-dashboard">Agency Stats</mat-card-title>
      <mat-card-subtitle>General data for agency</mat-card-subtitle>
    </div>

  </mat-card-header>

  <mat-card-content>

    <div class="tabs-container">
      <button type="button" class="btn-dashboard-tab" [ngClass]="{ 'btn-dashboard-tab-active': currentTab == 'totalAmountPremium' }" (click)="setDashboardTab('totalAmountPremium')">Total Amount/Premium</button>
      <button type="button" class="btn-dashboard-tab" [ngClass]="{ 'btn-dashboard-tab-active': currentTab == 'totalPremium' }" (click)="setDashboardTab('totalPremium')">Total Premium</button>
      <button type="button" class="btn-dashboard-tab" [ngClass]="{ 'btn-dashboard-tab-active': currentTab == 'policies' }" (click)="setDashboardTab('policies')">Policies</button>
    </div>

    <div class="form-container">
      <form [formGroup]="fgFilter" class="form-group" [style.fontSize.px]="14" (ngSubmit)="onSubmit()">

        <mat-form-field appearance="fill" *ngIf="currentTab == 'totalPremium'">
          <mat-label>Order by</mat-label>
          <mat-select formControlName="fcOrderBy">
            <mat-option value="all">A - Z</mat-option>
            <mat-option value="z-a">Z - A</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" *ngIf="currentTab == 'totalAmountPremium' || currentTab == 'policies'" class="PeriodMargin">
          <mat-label>Period</mat-label>
          <mat-select formControlName="fcPeriod">
            <mat-option value="all">All</mat-option>
            <mat-option value="today">Today</mat-option>
            <mat-option value="thisyear">This Year</mat-option>
            <mat-option value="thisweek">This Week</mat-option>
            <mat-option value="thismonth">This Month</mat-option>
            <mat-option value="customdate">Custom</mat-option>
          </mat-select>
        </mat-form-field>

        <div *ngIf="fgFilter.get('fcPeriod').value === 'customdate' && (currentTab == 'totalAmountPremium' || currentTab == 'policies')" style="display: flex;">

          <mat-form-field color="primary" appearance="fill" class="PeriodMargin">
            <mat-label>From</mat-label>
            <input matInput formControlName="fcDtFrom" [matDatepicker]="dtFrom" appMaskDate>
            <mat-datepicker-toggle matSuffix [for]="dtFrom"></mat-datepicker-toggle>
            <mat-datepicker #dtFrom></mat-datepicker>
          </mat-form-field>

          <mat-form-field color="primary" appearance="fill" class="PeriodMargin">
            <mat-label>To</mat-label>
            <input matInput formControlName="fcDtTo" placeholder="MM/DD/YYYY" [matDatepicker]="policyPickerMobile"
              appMaskDate>
            <mat-datepicker-toggle matSuffix [for]="policyPickerMobile"></mat-datepicker-toggle>
            <mat-datepicker #policyPickerMobile></mat-datepicker>
          </mat-form-field>

        </div>

        <mat-form-field appearance="fill" *ngIf="currentTab == 'totalPremium' || currentTab == 'policies'">
          <mat-label>Carriers</mat-label>
          <mat-select formControlName="fcCarriers">
            <mat-option>-</mat-option>
            <mat-option *ngFor="let carrier of carriers$ | async" value="{{carrier.user_email}}">
              {{carrier.user_email}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" *ngIf="currentTab == 'totalPremium' || currentTab == 'policies'">
          <mat-label>Search</mat-label>
          <input matInput formControlName="fcSearch">
        </mat-form-field>

        <mat-form-field appearance="fill" *ngIf="currentTab == 'policies'">
          <mat-label>Field to be searched</mat-label>
          <mat-select formControlName="fcFieldSearch">
            <mat-option value="all">All</mat-option>
          </mat-select>
        </mat-form-field>

        <button class="btn-search" mat-mini-fab color="primary" [disabled]="!fgFilter.valid">
          <mat-icon>search</mat-icon>
        </button>

        <button class="btn-search-mobile" mat-stroked-button color="primary" [disabled]="!fgFilter.valid">
          search
        </button>

      </form>

      <button mat-stroked-button color="primary" class="btn-export" (click)="exportExcelTotalPremium()" *ngIf="currentTab == 'totalPremium'">
        Export
      </button>

      <button mat-stroked-button color="primary" class="btn-export" (click)="exportExcelPolicies()" *ngIf="currentTab == 'policies'">
        Export
      </button>
    </div>

    <div *ngIf="currentTab == 'totalAmountPremium'">
      <div class="total-label" *ngIf="heWantsPif() || heWantsPifBusinessType() || heWantsPifLob()">Total amount</div>
      <div class="charts">
        <app-chart-pif *ngIf="heWantsPif()" style="width: 100%;"  [range]="range" [from]="from" [to]="to"></app-chart-pif>
        <app-chart-lob *ngIf="heWantsPifLob()"  style="width: 100%;" [range]="range" [from]="from" [to]="to"></app-chart-lob>
        <app-chart-business-type *ngIf="heWantsPifBusinessType()"  style="width: 100%;"  [range]="range" [from]="from" [to]="to"></app-chart-business-type>
      </div>

      <div class="total-label" *ngIf="heWantsPifPremium() || heWantsPifBusinessTypePremium() || heWantsPifLobPremium()">Total Premium</div>
      <div class="charts">
        <app-chart-pif-premium *ngIf="heWantsPifPremium()" style="width: 100%;" [range]="range" [from]="from" [to]="to"></app-chart-pif-premium>
        <app-chart-pif-lob-premium *ngIf="heWantsPifLobPremium()" style="width: 100%;" [range]="range" [from]="from" [to]="to"></app-chart-pif-lob-premium>
        <app-chart-pif-business-type-premium *ngIf="heWantsPifBusinessTypePremium()"  style="width: 100%;" [range]="range" [from]="from" [to]="to"></app-chart-pif-business-type-premium>
      </div>
    </div>

    <div *ngIf="currentTab == 'totalPremium'">
      <app-table-total-premium [range]="range" [from]="from" [to]="to" [carrier_ids]="carrier_ids" [carrier_name]="carrier_name"></app-table-total-premium>
    </div>

    <div *ngIf="currentTab == 'policies'">
      <app-table-policies [range]="range" [from]="from" [to]="to" [carrier_ids]="carrier_ids" [carrier_name]="carrier_name"></app-table-policies>
    </div>

  </mat-card-content>
</mat-card>
