import { FormArray, FormGroup } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { NewQuoteComponent } from '../../../new-quote/new-quote.component';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {

  array:string = 'driver'
  @Input() values:string[];
  @Input() Driver:string = 'personal';
  @Input() label:string;
  @Input() controlSelect:string;
  form:FormGroup
  formDrivers:FormGroup
  formVehicle:FormGroup;
expression: any = window.location.hash.includes('step-9')
 
  constructor(public formContainer: NewQuoteComponent) { }

  ngOnInit(): void {
    this.form = this.formContainer.getStepForm();
    this.formDrivers = this.formContainer.fdr();
    this.formVehicle = this.formContainer.fvh();
  }

  valor(){
    this.form = this.formContainer.getStepForm();
  }

  get validaErro(){

    
    if (this.formContainer.formStep <9){
      let ctrl = this.controlSelect
      if(this.form.controls[ctrl].errors && this.form.controls[ctrl].touched ){
  
        return true
      }else{
        return false
      }
    }else if(this.formContainer.formStep ==9){
      let ctrl = this.controlSelect
      if(this.formDrivers.controls[ctrl].errors && this.formDrivers.controls[ctrl].touched ){
  
        return true
      }else{
        return false
      }
    }else if(this.formContainer.formStep ==11){
      let ctrl = this.controlSelect
      if(this.formVehicle.controls[ctrl].errors && this.formVehicle.controls[ctrl].touched ){
  
        return true
      }else{
        return false
      }
    }
  
  }
}
