import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RequestInterceptor } from './interceptors/request.interceptor';
import { RouterModule } from '@angular/router';
import { NavbarModule } from './nav-bar/nav-bar.module';
import { DialogModule } from '../shared/components/dialogs/dialog.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
    NavbarModule,
    DialogModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    }
  ]
})
export class CoreModule { }
