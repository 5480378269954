<div class="example-container" *ngIf="show; else elseBlock">
  <ap-loading></ap-loading>
  <app-main-toolbar></app-main-toolbar>
</div>

<ng-template #elseBlock>
  <router-outlet></router-outlet>
</ng-template>

<div class="chatBaloon" (mouseenter)="easeOut()" (mouseleave)="easeIn()">
  <button class="mainBtn" style="z-index: 3" data-content="?"></button>

  <div class="bg" id="label"><span>Chat with HR</span></div>
  <div class="bg" id="labelCEO"><span>Ask CEO</span></div>
  <div class="bg" id="labelThree"><span>IT Questions</span></div>
  <div class="bg" id="labelCollaborators"><span>Collaborators</span></div>
  <button
    class="blipBaloon"
    id="baloon"
    style="z-index: 2; position: relative"
    (mouseleave)="hoverOut()"
    (click)="abrir(1)"
  >
  <svg xmlns="http://www.w3.org/2000/svg" fill="rgb(252, 99, 107)
  " width="48px" height="48px" viewBox="0 0 24 24"><path d="M3,5A1,1,0,0,1,4,4H20a1,1,0,0,1,1,1V15a1,1,0,0,1-1,1H18v4l-5-4H4a1,1,0,0,1-1-1Z"/></svg>
  </button>

  <button
    class="blipBaloon"
    id="baloonTwo"
    style="z-index: 1; position: absolute"
    (mouseleave)="hoverOutTwo()"
    (click)="abrir(2)"
  >
  <svg xmlns="http://www.w3.org/2000/svg" fill="#00c6d7" width="48px" height="48px" viewBox="0 0 24 24"><path d="M3,5A1,1,0,0,1,4,4H20a1,1,0,0,1,1,1V15a1,1,0,0,1-1,1H18v4l-5-4H4a1,1,0,0,1-1-1Z"/></svg>
  </button>

  <button
  class="blipBaloon"
  id="baloonThree"
  style="z-index: 1; position: absolute"
  (mouseleave)="hoverOutThree()"
  (click)="abrir(3)"
>
<svg xmlns="http://www.w3.org/2000/svg" fill="#00c6d7" width="48px" height="48px" viewBox="0 0 24 24"><path d="M3,5A1,1,0,0,1,4,4H20a1,1,0,0,1,1,1V15a1,1,0,0,1-1,1H18v4l-5-4H4a1,1,0,0,1-1-1Z"/></svg>
</button>

<button
class="blipBaloon"
id="baloonFour"
style="z-index: 1; position: absolute"
(mouseleave)="hoverOutTwo()"
(click)="abrir(4)"
>
<svg xmlns="http://www.w3.org/2000/svg" fill="#00c6d7" width="48px" height="48px" viewBox="0 0 24 24"><path d="M3,5A1,1,0,0,1,4,4H20a1,1,0,0,1,1,1V15a1,1,0,0,1-1,1H18v4l-5-4H4a1,1,0,0,1-1-1Z"/></svg>
</button>
</div>


<div class="frameBox">
<div class="labelChat" id="lblChat">
  <button (click)="close()">
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path
        d="M6.35 20.025 4 17.65 9.625 12 4 6.35l2.35-2.4L12 9.6l5.65-5.65L20 6.35 14.375 12 20 17.65l-2.35 2.375-5.65-5.65Z"
      />
    </svg>
  </button>
</div>
<div id="chat" class="chatToggle"></div>
<div id="chatTwo" class="chatToggle"></div>
<div id="chatThree" class="chatToggle"></div>
<div id="chatFour" class="chatToggle"></div>
</div>