<!-- <mat-card id="quote-sent-card" *ngIf="teste" style="margin: 24px 24px;">

  <mat-card-header class="header-quote">

    <i class="fas fa-paper-plane" style="margin-left: -20px;"></i>

    <div class="ml-28">
      <mat-card-title>Quotes Sent</mat-card-title>
      <mat-card-subtitle>Total of quotes sent by Month</mat-card-subtitle>
    </div>

  </mat-card-header> -->



<mat-card *ngIf="teste">

  <mat-card-header class="header-quote">

    <i class="far fa-chart-bar" style="margin-left: -20px; margin-top: 14px;"></i>

    <div class="ml-28">
      <mat-card-title class="title-dashboard">Quotes Sent</mat-card-title>
     <mat-card-subtitle>Total of quotes sent by Month</mat-card-subtitle>
    </div>

  </mat-card-header>
  <mat-card-content>
    <highcharts-chart style="height: 50vh; width: 100%;" [Highcharts]="Highcharts" [options]="chartOptions" [(update)]="updateFlag">
    </highcharts-chart>
  </mat-card-content>

</mat-card>
