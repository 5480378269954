import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './../../app-routing.module';
import { NgModule, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';

import { QuoteRoutingModule } from './quote-routing.module';
import { CreateQuoteComponent } from './components/create-quote/create-quote.component';
import { ViewQuoteComponent } from './components/view-quote/view-quote.component';
import { BasicInfoComponent } from './components/create-quote/forms/basic-info/basic-info.component';
import { DriversComponent } from './components/create-quote/forms/drivers/drivers.component';
import { FileUploadComponent } from './components/create-quote/forms/file-upload/file-upload.component';
import { SubmitComponent } from './components/create-quote/forms/submit/submit.component';
import { VehiclesComponent } from './components/create-quote/forms/vehicles/vehicles.component';

import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { QuotesService } from './service/quote.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';

import { NgxMaskModule, IConfig } from 'ngx-mask';
import { CreateQuoteDirective } from './components/create-quote/create-quote.directive';
import { QuoteDetailComponent } from './components/view-quote/quote-detail/quote-detail.component';
import { PipeModule } from 'src/app/shared/pipes/pipe.module';
import { QuoteDetailDialogComponent } from './components/view-quote/quote-detail/quote-detail-dialog/quote-detail-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DecPageComponent } from './components/view-quote/quote-detail/dec-page/dec-page.component';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TableViewQuoteComponent } from './components/view-quote/table-view-quote/table-view-quote.component';
import { CardFilterViewQuoteComponent } from './components/view-quote/card-filter-view-quote/card-filter-view-quote.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxPaginationModule } from 'ngx-pagination';
import { NewQuoteComponent } from './components/new-quote/new-quote.component';
import { SharedModule } from './components/shared/shared.module';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete'; 

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    CreateQuoteComponent,
    ViewQuoteComponent,
    BasicInfoComponent,
    DriversComponent,
    FileUploadComponent,
    SubmitComponent,
    VehiclesComponent,
    CreateQuoteDirective,
    QuoteDetailComponent,
    QuoteDetailDialogComponent,
    DecPageComponent,
    TableViewQuoteComponent,
    CardFilterViewQuoteComponent,
    NewQuoteComponent
  ],
  imports: [
    CommonModule,
    QuoteRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    MatPaginatorModule,

    MatCardModule,
    MatStepperModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatExpansionModule,
    DropzoneModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatRadioModule,
    MatSnackBarModule,
    MatTableModule,
    MatDialogModule,
    MatSortModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    GooglePlaceModule,


    NgxPaginationModule,

    NgxMaskModule.forRoot(),
    PipeModule.forRoot()
  ],
  exports: [
    CreateQuoteDirective,
    MatStepperModule
  ],
  providers: [QuotesService]
})
export class QuoteModule { }
