import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { PIFLobPremium } from 'src/app/modules/dashboard-lead/model/pifLobPremium';
import { DashboardLeadService } from 'src/app/modules/dashboard-lead/service/dashboard-lead.service';

interface DataPie {
  y: number,
  name: string,
  color?: string
}
@Component({
  selector: 'app-chart-pif-lob-premium',
  templateUrl: './chart-pif-lob-premium.component.html',
  styleUrls: ['./chart-pif-lob-premium.component.scss']
})
export class ChartPifLobPremiumComponent implements OnInit {

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};
  teste: boolean = false;
  pifs$: Observable<PIFLobPremium>;
  title: string;
  subtitle: string;
  updateFlag = false;
  reqCompleted: boolean = false;

  @Input() range: string;
  @Input() from: string;
  @Input() to: string;

  constructor(private dashboardLeadService: DashboardLeadService, private cookieService: CookieService) { }

  ngOnInit(): void {
    this.getPIFLobPremium(this.range, this.from, this.to);
  }

  getPIFLobPremium(period: string, from: string, to: string) {
    this.reqCompleted = false;
    this.pifs$ = this.dashboardLeadService.getPifLobPremium(period, from, to)
      .pipe(tap((pif) => {
        this.title = pif.title;
        this.subtitle = pif.description;
        this.reqCompleted = true;
        this.chartIt(pif);
      }))
      .pipe(catchError(err => {
        this.reqCompleted = true;
        throw err;
      }));
  }

  chartIt(pif: PIFLobPremium) {

    this.teste = false;

    var dataPie: DataPie[] = [];

    this.chartOptions.series = [];

    this.chartOptions.chart = {
      type: 'pie',
      backgroundColor: '#fafafa',
      height: '300px'
    }

    this.chartOptions.credits = {
      enabled: false
    }

    this.chartOptions.title = {
      text: null
    }

    this.chartOptions.legend = {
      itemStyle: {
        fontFamily: 'Lato',
        fontWeight: '300'
      }
    }

    this.chartOptions.yAxis = {
      title: {
        text: null
      },
    }

    this.chartOptions.tooltip = {
      shared: true
    }

    this.chartOptions.plotOptions = {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        shadow: false
      }
    }

    this.chartOptions.tooltip = {
      formatter: function () {
        return '<b>' + this.point.name + '</b>: ' + this.y + ' | ' + this.point.percentage.toFixed(2) + '%';
      },
      style: {
        fontFamily: 'Lato'
      }
    }

    pif.data.forEach((e) => {

      dataPie.push({
        y: parseInt(e.value.toString()),
        name: e.label,
        //color: '#ff8800'
      });

    });

    this.chartOptions.series.push({
      colorByPoint: true,
      type: 'pie',
      data: dataPie,
      size: '50%',
      borderWidth: 0,
      innerSize: '75%',
      showInLegend: false,
      dataLabels: {
        enabled: false
      }
    });

    this.teste = true;
  }

}