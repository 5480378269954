<div class="container-box">
  <div class="title-box">
    <span class="subtitle">BRZ Collaborators / Deals</span>
  </div>

  <div>
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
      <mat-tab label="Audit">
        <app-audit-deals></app-audit-deals>
      </mat-tab>

      <mat-tab label="Retention">
        <app-retention></app-retention>
      </mat-tab>

      <mat-tab label="Endorsement">
        <app-endorsement></app-endorsement>
      </mat-tab>

      <mat-tab label="Sales">
        <app-sales></app-sales>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
