<mat-card *ngIf="deals$ | async as deals; else elseBlock">

    <mat-card-header class="header-quote" style="justify-content: left;">

        <i class="fas fa-globe-africa" style="margin-left: -20px; margin-top: 14px;font-size: 24px;"></i>

        <div class="ml-28">
            <mat-card-title class="title">{{title}}</mat-card-title>
            <mat-card-subtitle>{{subtitle}}</mat-card-subtitle>
        </div>

    </mat-card-header>

    <div *ngIf="deals.data.length > 0; else hasNoData">
        <mat-card-content>
            <agm-map id="map" [latitude]="lat" [longitude]="lng" (mapReady)="onMapLoad($event)">
            </agm-map>

            <app-table-marketing-deals [deals]="deals"></app-table-marketing-deals>
        </mat-card-content>

    </div>

    <ng-template #hasNoData>
        <mat-card class="card-no-data"
            style="height: 30vh; display: flex; justify-content: center; align-items: center;">
        </mat-card>
    </ng-template>

</mat-card>

<ng-template #elseBlock>
    <mat-card style="display: flex; justify-content: center; align-items: center; height: 20em;">
        <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
    </mat-card>
</ng-template>
