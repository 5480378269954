import { Component, Input, OnInit } from '@angular/core';
import { Cap } from 'src/app/core/user/model/caps';

@Component({
  selector: 'app-nav-item-quote',
  templateUrl: './nav-item-quote.component.html',
  styleUrls: ['./nav-item-quote.component.scss']
})
export class NavItemQuoteComponent implements OnInit {

  @Input() cap: Cap;

  constructor() { }

  ngOnInit(){}

  checkCreateQuote(): boolean {
    return this.cap.hasOwnProperty('brz_menu_create_quote');
  }

  checkViewQuote(): boolean {
    return this.cap.hasOwnProperty('brz_menu_view_quotes');
  }

}
