<div class="container">
  <div class="header-modal">
    <h1 mat-dialog-title>Pause ticket distribution</h1>
    <mat-icon
      aria-hidden="false"
      aria-label="Example home icon"
      class="white-icon"
      (click)="closeModal()"
      >close</mat-icon
    >
  </div>

  <div class="content">
    <div class="info-box">
      <span class="describe-content"
        >“{{ this.data.agentData.nome }}” still has <span class="onHold">{{this.data.agentData.waitingTickets}} tickets on hold</span>, would you like to
        redistribute the tickets to other employees?</span
      >
    </div>
  </div>
  <div class="footer-modal" mat-dialog-actions>
    <button mat-button mat-dialog-close class="btn">Maintain tickets</button>
    <button mat-button mat-dialog-close class="btn red-btn" (click)="redistribute()">
      Redistribute tickets
    </button>
  </div>
</div>
