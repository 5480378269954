import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Router } from '@angular/router';
import Pusher from 'pusher-js';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/core/user/user.service';
import { PusherService } from 'src/app/shared/services/pusher.service';
import { environment } from 'src/environments/environment';
import { NewNotification } from '../../model/newNotification';
import { NavNotificationsComponent } from '../nav-notifications/nav-notifications.component';
import * as playAlert from 'alert-sound-notify';
import { CookieService } from 'ngx-cookie-service';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-main-toolbar',
  templateUrl: './main-toolbar.component.html',
  styleUrls: ['./main-toolbar.component.scss']
})
export class MainToolbarComponent implements OnInit, AfterViewInit {

  showNotifications = false;
  showNotificationsZero = false;
  private pusherClient: Pusher;
  leads: number[] = [];
  notify: number;
  watcher: Subscription;
  messageNotify: string;
  timeNotification: Date;
  mode = 'over';
  hasBackdrop: boolean = false;
  disableClose: boolean = true;
  change: MediaChange[];

  @ViewChild(NavNotificationsComponent) navNotificationsComponent: NavNotificationsComponent;
  @ViewChild('snavNotification') snavNotification: MatSidenav;
  @ViewChild('snav') snav: MatSidenav;

  constructor(private userService: UserService, private router: Router,
    media: MediaObserver, private pusherService: PusherService,
    private cookieService: CookieService) {

    this.watcher = media.asObservable().subscribe(change => {

      this.change = change;

      if (change[0].mqAlias === 'sm' || change[0].mqAlias === 'xs') {
        this.mode = 'over';
        this.hasBackdrop = true;
      } else {
        this.mode = 'side';
      }

    });

  }

  openNotifications() {

    this.snavNotification.toggle();
    this.hasBackdrop = !this.hasBackdrop;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.listenNotification();
    });
  }

  backDropEvent() {
    if (!(this.change[0].mqAlias === 'sm' || this.change[0].mqAlias === 'xs')) {
      this.hasBackdrop = false;

    } else {
      this.snav.close();
    }

  }

  ngOnInit(): void {
    this.pusher();
    this.getUserName();

  }

  listenNotification() {
    this.navNotificationsComponent.Unread.subscribe(totalUnread => {
      this.notify = totalUnread;
    });
  }

  getUserName(): string {
    return window.localStorage.getItem('displayName');
  }

  clearNotifications() {

    // this.showNotifications = !this.showNotifications;
    // if (this.leads.length === 0) {
    //   this.showNotificationsZero = true;
    // } else {
    //   this.showNotificationsZero = false;
    //   this.notify = null;
    // }

  }

  getUnreadNotifications($event) {
    this.notify = $event;
  }

  go(leadId: number) {
    this.router.navigate([`/home/quote/view-quote/quote-detail/${leadId}/decpage`]);
  }

  goHome() {
    this.router.navigate(['/home/brz-home-page/']);
  }

  logout() {
    this.userService.logout();
    this.router.navigate(['']);
  }

  pusher() {

    this.pusherClient = this.pusherService.getPusher();

    let roles = JSON.parse(window.localStorage.getItem('userRole')) as any[];
    let userId = JSON.parse(window.localStorage.getItem('userId'));
    let firstIndex: number;

    for (var index in roles) {
      firstIndex = parseInt(index);
      break;
    }

    const channel = this.pusherClient.subscribe(`${environment.channelSlug}role-${roles[firstIndex]}`);
    const channelGlobal = this.pusherClient.subscribe(`${environment.channelSlug}global`);
    const channelUserId = this.pusherClient.subscribe(`${environment.channelSlug}${userId}`);

    channel.bind(
      'pusher:subscription_succeeded', () => {
        console.log(`channel: ${environment.channelSlug}role-${roles[firstIndex]}`);
      });

    channel.bind('new-notification', (newNotification: NewNotification) => {

      if (this.cookieService.get('sound') === 'sound-on') {
        playAlert('submarine');
        playAlert.volume(1);
      }

      if (this.notify) this.notify++
      else this.notify = 1

      this.navNotificationsComponent.notifications.unshift({
        content: newNotification.content,
        created_at: newNotification.created_at,
        foreign_template_id: newNotification.foreign_template_id,
        id: newNotification.id,
        is_read: newNotification.is_read,
        source_id: newNotification.source_id,
        title: newNotification.title,
        trash: newNotification.trash,
        user_group: newNotification.user_group,
        user_id: newNotification.user_id
      });

    });

    channelUserId.bind('new-notification', (newNotification: NewNotification) => {

      if (this.cookieService.get('sound') === 'sound-on') {
        playAlert('submarine');
        playAlert.volume(1);
      }

      if (this.notify) this.notify++
      else this.notify = 1

      this.navNotificationsComponent.notifications.unshift({
        content: newNotification.content,
        created_at: newNotification.created_at,
        foreign_template_id: newNotification.foreign_template_id,
        id: newNotification.id,
        is_read: newNotification.is_read,
        source_id: newNotification.source_id,
        title: newNotification.title,
        trash: newNotification.trash,
        user_group: newNotification.user_group,
        user_id: newNotification.user_id
      });

    });

    channelGlobal.bind('new-notification', (newNotification: NewNotification) => {

      if (this.cookieService.get('sound') === 'sound-on') {
        playAlert('submarine');
        playAlert.volume(1);
      }

      if (this.notify) this.notify++
      else this.notify = 1

      this.navNotificationsComponent.notifications.unshift({
        content: newNotification.content,
        created_at: newNotification.created_at,
        foreign_template_id: newNotification.foreign_template_id,
        id: newNotification.id,
        is_read: newNotification.is_read,
        source_id: newNotification.source_id,
        title: newNotification.title,
        trash: newNotification.trash,
        user_group: newNotification.user_group,
        user_id: newNotification.user_id
      });

    });

    channelGlobal.bind('log-out', (logout) => {
      if (logout.update) this.logout();

    });

    channelUserId.bind('log-out', (logout) => {
      if (logout.update) this.logout();
    });

    channel.bind('log-out', (logout) => {
      if (logout.update) this.logout();

    });

    let leadsource = window.localStorage.getItem('leadsource');

    if (leadsource) {
      const leadsourceChannel = this.pusherClient.subscribe(`${environment.channelSlug}leadsource-${leadsource}`);

      leadsourceChannel.bind(
        'pusher:subscription_succeeded', () => {
          console.log(`channel: ${environment.channelSlug}leadsource-${leadsource}`);
        });

      leadsourceChannel.bind('new-notification', (newNotification) => {

        if (this.cookieService.get('sound') === 'sound-on') {
          playAlert('submarine');
          playAlert.volume(1);
        }

        if (this.notify) this.notify++
        else this.notify = 1

        this.navNotificationsComponent.notifications.unshift({
          content: newNotification.content,
          created_at: newNotification.created_at,
          foreign_template_id: newNotification.foreign_template_id,
          id: newNotification.id,
          is_read: newNotification.is_read,
          source_id: newNotification.source_id,
          title: newNotification.title,
          trash: newNotification.trash,
          user_group: newNotification.user_group,
          user_id: newNotification.user_id
        });
      });

    }

  }

  close(reason: string) {
    this.snavNotification.close();
  }

  getCaps() {
    return this.userService.cap;
  }

}
