import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { DashboardName } from 'src/app/modules/dashboard-lead/model/dashboard';
import { PusherService } from 'src/app/shared/services/pusher.service';
import { environment } from 'src/environments/environment';
import { ChartMarketingConversionRateComponent } from '../../charts/chart-marketing-conversion-rate/chart-marketing-conversion-rate.component';
import { ChartMarketingDealsComponent } from '../../charts/chart-marketing-deals/chart-marketing-deals.component';
import { ChartMarketingGoogleMapComponent } from '../../charts/chart-marketing-google-map/chart-marketing-google-map.component';

@Component({
  selector: 'app-marketing-filter',
  templateUrl: './marketing-filter.component.html',
  styleUrls: ['./marketing-filter.component.scss']
})
export class MarketingFilterComponent implements OnInit {

  fgFilter: FormGroup;
  @ViewChild(ChartMarketingDealsComponent) chartMarketingDealsComponent: ChartMarketingDealsComponent;
  @ViewChild(ChartMarketingConversionRateComponent) chartMarketingConversionRateComponent: ChartMarketingConversionRateComponent;
  @ViewChild(ChartMarketingGoogleMapComponent) chartMarketingGoogleMapComponent: ChartMarketingGoogleMapComponent;

  range: string;
  from: string;
  to: string;

  @Input() results: DashboardName[];

  constructor(private _formBuilder: FormBuilder,
    private cookieService: CookieService,
    private pusherService: PusherService) { }

  ngOnInit(): void {
    this.initForm();
    this.initVariables();
    this.pusher();
  }

  onSubmit() {

    var datePipe = new DatePipe('en-US');

    let from = null;
    let to = null;
    let period = this.fgFilter.get('fcPeriod').value;

    if (this.fgFilter.get('fcDtFrom').value)
      from = datePipe.transform(this.fgFilter.get('fcDtFrom').value, 'MM/dd/yyyy');

    if (this.fgFilter.get('fcDtTo').value)
      to = datePipe.transform(this.fgFilter.get('fcDtTo').value, 'MM/dd/yyyy');

    this.cookieService.set('periodMarketing', period || null);

    if (this.cookieService.get('periodMarketing') === 'customdate') {
      this.cookieService.set('dtFromMarketing', from || null);
      this.cookieService.set('dtToMarketing', to || null);
    }

    if (this.heWantsDeals()) this.chartMarketingDealsComponent.getMarketingDeals(period, from, to);
    if (this.heWantsConversionRate()) this.chartMarketingConversionRateComponent.getMarketingConversionRate(period, from, to);
    if (this.heWantsDealsMap()) this.chartMarketingGoogleMapComponent.getMarketingDealsMap(period, from, to);

  }

  pusher() {

    let pusherClient = this.pusherService.getPusher();

    const newDailyCashChannel = pusherClient.subscribe(`${environment.channelSlug}dashboard-marketing`);

    newDailyCashChannel.bind(
      'pusher:subscription_succeeded', () => {
        console.log(`channel: ${environment.channelSlug}dashboard-marketing`);
      });

    newDailyCashChannel.bind('new-deal', (newDeal) => {

      if (newDeal.update) {

        var datePipe = new DatePipe('en-US');

        let period = this.fgFilter.get('fcPeriod').value;
        //alert(period);
        let from= datePipe.transform(this.fgFilter.get('fcDtFrom').value, 'MM/dd/yyyy');
        let to = datePipe.transform(this.fgFilter.get('fcDtTo').value, 'MM/dd/yyyy');

        this.chartMarketingDealsComponent.getMarketingDeals(period, from, to);
        this.chartMarketingConversionRateComponent.getMarketingConversionRate(period, from, to);
        this.chartMarketingGoogleMapComponent.getMarketingDealsMap(period, from, to);
      }

    });

  }

  initForm() {

    let from: Date;
    let to: Date;

    if (this.cookieService.get('dtFromMarketing')) {
      from = new Date(this.htmlDecode(this.cookieService.get('dtFromMarketing')));
    }

    if (this.cookieService.get('dtToMarketing')) {
      to = new Date(this.htmlDecode(this.cookieService.get('dtToMarketing')));
    }

    this.fgFilter = this._formBuilder.group({
      fcPeriod: [this.cookieService.get('periodMarketing') || 'thismonth'],
      fcDtFrom: [from || ''],
      fcDtTo: [to || ''],
    });

  }

  htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  }

  initVariables() {
    var datePipe = new DatePipe('en-US');
    this.range = this.fgFilter.get('fcPeriod').value;
    this.from = datePipe.transform(this.fgFilter.get('fcDtFrom').value, 'MM/dd/yyyy');
    this.to = datePipe.transform(this.fgFilter.get('fcDtTo').value, 'MM/dd/yyyy');
  }

  heWantsConversionRate(): boolean {
    return this.didItFoundCap('brzinsurance_reports_marketing_conversion_rate');
  }
  heWantsDeals(): boolean {
    return this.didItFoundCap('brzinsurance_reports_marketing_get_deals');
  }
  heWantsDealsMap(): boolean {
    return this.didItFoundCap('brzinsurance_reports_marketing_deals_map');
  }

  didItFoundCap(cap: string): boolean {
    if (this.results) if (this.results.find(x => x.cap === cap)) return true;
    return false;
  }

}
