import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { IViewQuoteDTO, Quote } from '../../model/quote';
import { QuotesService } from '../../service/quote.service';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, finalize, tap } from 'rxjs/operators';
import { CardFilterViewQuoteComponent } from './card-filter-view-quote/card-filter-view-quote.component';
import { TableViewQuoteComponent } from './table-view-quote/table-view-quote.component';
import { PusherService } from 'src/app/shared/services/pusher.service';
import { environment } from 'src/environments/environment';
import { QuoteDetailComponent } from './quote-detail/quote-detail.component';

@Component({
  selector: 'app-view-quote',
  templateUrl: './view-quote.component.html',
  styleUrls: ['./view-quote.component.scss']
})
export class ViewQuoteComponent implements OnInit {

  quote$: Observable<Quote>;
  loading: boolean = false;
  dto: IViewQuoteDTO = {};

  from: string;
  status: string;
  to: string;
  searchWord: string;
  page: number;
  limit: number;
  sortByField: string;
  sortBy: string;

  @ViewChild(CardFilterViewQuoteComponent) cardFilterViewQuoteComponent: CardFilterViewQuoteComponent;
  @ViewChild(TableViewQuoteComponent) tableViewQuoteComponent: TableViewQuoteComponent;
  @ViewChild(QuoteDetailComponent) quoteDetailComponent: QuoteDetailComponent;

  constructor(
    private quoteService: QuotesService,
    private notification: NotificationService,
    private pusherService: PusherService
  ) { }

  ngOnInit(): void {
    //this.listenObservables();
    //this.getQuotes(this.dto);
    this.pusher();
  }

  listenObservables() {
    this.quoteService.Page.subscribe(page => {

    });
    this.quoteService.Limit.subscribe(limit => {

    });
    this.quoteService.Sort.subscribe(sort => {

      if (sort) {
        this.sortBy = sort.direction
        this.sortByField = sort.active

        this.generateDto();
        this.getQuotes(this.dto);
      }

    });
  }

  getQuotes(dto: IViewQuoteDTO) {

    this.loading = true;
    this.quote$ = this.quoteService.getQuotes(dto.search_word, dto.date_to, dto.date_from, dto.limit, dto.sort_by_field, dto.sort_by, dto.page, dto.status)
      .pipe(finalize(() => { this.loading = false })
        , catchError(err => {
          this.loading = false;
          this.notification.error(err);
          throw new Error(err);
        }));
  }

  changeFilter($event) {

    var datePipe = new DatePipe('en-US');

    this.from = datePipe.transform($event.value.fcDtFrom, 'MM/dd/yyyy') || null;
    this.to = datePipe.transform($event.value.fcDtTo, 'MM/dd/yyyy') || null;
    this.status = $event.value.fcStatus || null;
    this.searchWord = $event.value.fcSearch || null;

    this.generateDto();

    this.tableViewQuoteComponent.ngAfterViewInit();

    //this.getQuotes(this.dto);
  }

  generateDto() {
    this.dto = {
      limit: this.limit,
      date_from: this.from,
      date_to: this.to,
      search_word: this.searchWord,
      page: this.page,
      sort_by_field: this.sortByField,
      sort_by: this.sortBy,
      status: this.status,
    }

    this.tableViewQuoteComponent.dto = this.dto;
  }

  changePaginator($event) {

    this.limit = $event.limit;
    this.page = $event.page;

    this.generateDto();

    this.getQuotes(this.dto);

  }

  pusher() {
    let pusherClient = this.pusherService.getPusher();

    let roles = JSON.parse(window.localStorage.getItem('userRole')) as any[];
    let firstIndex: number;

    for (var index in roles) {
      firstIndex = parseInt(index);
      break;
    }

    const channel = pusherClient.subscribe(`${environment.channelSlug}role-${roles[firstIndex]}`);

    channel.bind(
      'pusher:subscription_succeeded', () => {
        console.log(`channel: ${environment.channelSlug}dashboard-stats`);
      });

    channel.bind('new-lead', (lead) => {

      var datePipe = new DatePipe('en-US');
      let formatDateCreated = datePipe.transform(lead.lead_date, 'MM/dd/yyyy') || null;
      let dateCreated = new Date(formatDateCreated);

      const from = this.cardFilterViewQuoteComponent.fgFilter.get('fcDtFrom').value;
      const to = this.cardFilterViewQuoteComponent.fgFilter.get('fcDtTo').value;

      if ((dateCreated <= to) && (dateCreated >= from) ) {
        this.generateTable(lead);
      }

      // switch (this.cardFilterViewQuoteComponent.fgFilter.get('fcStatus').value) {
      //   case '':

      //     break;
      //   case 'w':
      //     if ((dateCreated <= to) && (dateCreated >= from) && lead.status === 'w') {
      //       this.generateTable(lead);
      //     }
      //     break;
      //   case 'l':
      //     if ((dateCreated <= to) && (dateCreated >= from) && lead.status === 'l') {
      //       this.generateTable(lead);
      //     }
      //     break;
      //     case 'p':
      //       if ((dateCreated <= to) && (dateCreated >= from) && lead.status === 'p') {
      //         this.generateTable(lead);
      //       }
      //       break;
      //   default:
      //     break;
      // }

    });

    let leadsource = window.localStorage.getItem('leadsource');

    if(leadsource) {
      const leadsourceChannel = pusherClient.subscribe(`${environment.channelSlug}leadsource-${leadsource}`);

      leadsourceChannel.bind(
        'pusher:subscription_succeeded', () => {
          console.log(`channel: ${environment.channelSlug}leadsource-${leadsource}`);
        });

      leadsourceChannel.bind('status-change', (status) => {

        let tableLine = this.tableViewQuoteComponent.data.find(x => x.lead_id === status.lead_id.toString());

        if(tableLine) tableLine.dec_page_file_id = status.fields.file_id;

      });

      leadsourceChannel.bind('new-lead', (lead) => {

        let line = this.tableViewQuoteComponent.data.find(x => x.lead_id === lead.lead_id);

        if (line) {

          line.loan_number = lead.loan_number;
          line.lead_id = lead.lead_id;
          line.lead_date = lead.lead_date;
          line.first_name = lead.first_name;
          line.last_name = lead.last_name;
          line.phone = lead.phone;
          line.email = lead.email;
          line.line1 = lead.line1;
          line.line2 = lead.line2;
          line.city = lead.city;
          line.state = lead.state;
          line.zipcode = lead.zipcode;
          line.policy_effective_date = lead.policy_effective_date;
          line.currently_insured = lead.currently_insured;
          line.primary_residence = lead.primary_residence;
          line.coverage_selection = lead.coverage_selection;
          line.preferred_language = lead.preferred_language;
          line.lead_source = lead.lead_source;
          line.status = lead.status;

          line.status = lead.status.toUpperCase() == 'P' ? 'PENDING' : lead.status.toUpperCase() == 'A' ? 'ACTIVE' : lead.status.toUpperCase() == 'L' ? 'LOST' : lead.status.toUpperCase() == 'W' ? 'WON' : null;

          line.premium = lead.premium;
          line.notes = lead.notes;
          line.lost_reason = lead.lost_reason;

        }

        else {
          this.tableViewQuoteComponent.data.push(lead);
        }

      });
    }

  }

  generateTable(lead: any) {
    let line = this.tableViewQuoteComponent.data.find(x => x.lead_id === lead.lead_id);

    if (line) {

      line.loan_number = lead.loan_number;
      line.lead_id = lead.lead_id;
      line.lead_date = lead.lead_date;
      line.first_name = lead.first_name;
      line.last_name = lead.last_name;
      line.phone = lead.phone;
      line.email = lead.email;
      line.line1 = lead.line1;
      line.line2 = lead.line2;
      line.city = lead.city;
      line.state = lead.state;
      line.zipcode = lead.zipcode;
      line.policy_effective_date = lead.policy_effective_date;
      line.currently_insured = lead.currently_insured;
      line.primary_residence = lead.primary_residence;
      line.coverage_selection = lead.coverage_selection;
      line.preferred_language = lead.preferred_language;
      line.lead_source = lead.lead_source;
      line.status = lead.status;

      line.status = lead.status.toUpperCase() == 'P' ? 'PENDING' : lead.status.toUpperCase() == 'A' ? 'ACTIVE' : lead.status.toUpperCase() == 'L' ? 'LOST' : lead.status.toUpperCase() == 'W' ? 'WON' : null;

      line.premium = lead.premium;
      line.notes = lead.notes;
      line.lost_reason = lead.lost_reason;

    }

    else {
      this.tableViewQuoteComponent.data.push(lead);
    }
  }

}
