<form
  [formGroup]="fgFilter"
  class="form-group"
  [style.fontSize.px]="14"
  (ngSubmit)="onSubmit()"
>
  <mat-form-field appearance="fill">
    <mat-label>Period</mat-label>
    <mat-select formControlName="fcDate"  [value]="selectedDate" (selectionChange)="selectDate($event)"  required>
      <mat-option
        *ngFor="let dateCarrier of dateCarriers$ | async "
        value="{{ dateCarrier.date }}"
        
      >
        {{ dateCarrier.date }}
      </mat-option>
    </mat-select>
  </mat-form-field>




<div class="carrier-filter">
  <ng-select 
  appearance="fill"
  [multiple]="true"
  formControlName="fcCarrier"
  placeholder="Carriers *"
  [(ngModel)]="selectedCarrierValue" class="select"
  (change)="onCarrierSelect(selectedCarrierValue)"
  >
  <ng-option value="all" id="selectAll"  class="selectOption">Select All</ng-option>
  <ng-option *ngFor="let carrier of carriers$ | async " [value]="carrier.carrier_id" class="selectOption">{{carrier.carrier_name}}</ng-option>
</ng-select>
</div>
  


  <!-- <mat-form-field appearance="fill">
    <mat-label>Carriers</mat-label>
    <mat-select formControlName="fcCarrier" #selected  multiple required id="selectCarrier"  [(ngModel)]="selectedCarrierValue">

      
      <input
      #inputSearch
      matInput
      multiple
      />
      <mat-option (click)="changed($event)" value="All">
        Select All
      </mat-option>
      <mat-option

        *ngFor="let carrier of carriers$  | async "
        value="{{ carrier.carrier_id}}"
        class="optionCarrier"
      >
        {{ carrier.carrier_name }} - {{ carrier.carrier_id }}
      </mat-option>
    </mat-select>
  </mat-form-field> -->

  <button class="btn-search" mat-mini-fab color="primary" [disabled]="false">
    <mat-icon>search</mat-icon>
  </button>

  <button
    class="btn-search-mobile"
    mat-stroked-button
    color="primary"
    [disabled]="!fgFilter.valid"
  >
    Search
  </button>
</form>
