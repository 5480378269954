import { SdrBlipComponent } from './sdr-blip/sdr-blip.component';
import { ClaimBlipComponent } from './claim-blip/claim-blip.component';
import { CxComponent } from './cx/cx.component';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewCollaboratorDealComponent } from '../deals/new-collaborator-deal/new-collaborator-deal.component';
import { MatTableDataSource } from '@angular/material/table';
import { ModalLogsComponent } from '../components/modal-logs/modal-logs.component';

export interface PeriodicElement {
  select: boolean;
  queue: number;
  status: boolean;
  name: string;
  email: string;
  acting: string;
  idiomas: Array<string>;
  dealsToday: number;
  totalDeals: number;
  menu: object;
}

@Component({
  selector: 'app-blip',
  templateUrl: './blip.component.html',
  styleUrls: ['./blip.component.scss'],
})
export class BlipComponent implements OnInit {
  displayedColumns: string[] = [
    'select',
    'queue',
    'status',
    'name',
    'email',
    'acting',
    'idiomas',
    'dealsToday',
    'totalDeals',
    'menu',
  ];
  loadSpinner: boolean = false;

  constructor(public dialog: MatDialog) {}
  ngOnInit(): void {

  }

  openDialog() {
    this.dialog.open(NewCollaboratorDealComponent);
  }

  openLogs(
    enterAnimationDuration: string,
    exitAnimationDuration: string
  ): void {
     this.dialog.open(ModalLogsComponent, {
      width: '95%',
      height: '770px',
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }
}
