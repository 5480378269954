import { Injectable } from '@angular/core';
import Pusher from 'pusher-js';
import { TokenService } from 'src/app/core/token/token.service';
import { environment } from 'src/environments/environment';
import * as PusherBatchAuthorizer from 'pusher-js-auth';

@Injectable({
  providedIn: 'root'
})
export class PusherService {

  constructor(private tokenService: TokenService) { }

  token = this.tokenService.getToken();

  getPusher(): Pusher {

    return new Pusher('50d6feea59755bbd0e53',
      {
        cluster: 'us2',
        authorizer: PusherBatchAuthorizer,
        authEndpoint: `${environment.apiUrl}/brz/v1/notifications/auth`,
        auth: { headers: { "Authorization": `Bearer ${this.token}` } },
        //authDelay: 200
      });
  }

  
}
