<div class="box-input" *ngIf="expression !=true && Driver == 'personal'" [formGroup]="form">
    <label for="selectForm"> <span>*</span> {{ label }} </label>
    
    <select
    [formControlName]="controlSelect"
    [ngClass]="{'is-invalid': validaErro}"
      name="selectForm"
      id="selectForm"
    >

    <option *ngFor="let data of values" value="{{ data }}">{{ data }}</option>

    </select>
    </div>


  <div class="box-input" *ngIf="expression && Driver == 'personal'" [formGroup]="form">
    <label for="selectForm"> <span>*</span> {{ label }} </label>
    <div formArrayName="driver" >
      <!-- <ng-container *ngFor=" let driver of form.get('drive')['controls'] "></ng-container> -->
    <select
    formControlName=2
    [ngClass]="{'is-invalid': validaErro}"
      name="selectForm"
      id="selectForm"
    >

    <option *ngFor="let data of values" value="{{ data }}">{{ data }}</option>

    </select>
    </div>
  </div>


  <div class="box-input" *ngIf="expression !=true && Driver=='driver'" [formGroup]="formDrivers">
    <label for="selectForm"> <span>*</span> {{ label }} </label>
    
    <select
    [formControlName]="controlSelect"
    [ngClass]="{'is-invalid': validaErro}"
      name="selectForm"
      id="selectForm"
    >

    <option *ngFor="let data of values" value="{{ data }}">{{ data }}</option>

    </select>
    </div>


  <div class="box-input" *ngIf="expression && Driver=='driver'" [formGroup]="formDrivers">
    <label for="selectForm"> <span>*</span> {{ label }} </label>
    <div formArrayName="driver" >
      <!-- <ng-container *ngFor=" let driver of form.get('drive')['controls'] "></ng-container> -->
    <select
    formControlName=2
    [ngClass]="{'is-invalid': validaErro}"
      name="selectForm"
      id="selectForm"
    >

    <option *ngFor="let data of values" value="{{ data }}">{{ data }}</option>

    </select>
    </div>
  </div>

  <div class="box-input" *ngIf="expression !=true && Driver == 'vehicle'" [formGroup]="formVehicle">
    <label for="selectForm"> <span>*</span> {{ label }} </label>
    
    <select
    [formControlName]="controlSelect"
    [ngClass]="{'is-invalid': validaErro}"
      name="selectForm"
      id="selectForm"
    >

    <option *ngFor="let data of values" value="{{ data }}">{{ data }}</option>

    </select>
    </div>


  <div class="box-input" *ngIf="expression && Driver == 'vehicle'" [formGroup]="formVehicle">
    <label for="selectForm"> <span>*</span> {{ label }} </label>
    <div formArrayName="driver" >
      <!-- <ng-container *ngFor=" let driver of form.get('drive')['controls'] "></ng-container> -->
    <select
    formControlName=2
    [ngClass]="{'is-invalid': validaErro}"
      name="selectForm"
      id="selectForm"
    >

    <option *ngFor="let data of values" value="{{ data }}">{{ data }}</option>

    </select>
    </div>
  </div>