import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BlipServiceService } from '../../blip/blip-service.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent {
  @Output() updateTable = new EventEmitter<void>();
  constructor(
    private dialogRef: MatDialogRef<ConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private BlipServiceService: BlipServiceService
  ) {}

  ngOnInit(): void {
  }

  turnOff() {
    this.BlipServiceService.editCollaborator({
      distribuir: false,
      id: this.data.agentData.id,
    }).subscribe((data) => {
      this.updateTable.emit();
      this.dialogRef.close();
    });
  }

  redistribute(){
    this.BlipServiceService.redistributeTickets(this.data.agentData.email).subscribe((data) => {
      
    });
  }

  closeModal() {
    this.dialogRef.close();
  }
}
