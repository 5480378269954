import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './page/login.component';

export const routes: Routes = [
  {
    path: '', component: LoginComponent,
  },
  {
    path: 'forgot-password', loadChildren: () => import('../forgot-password/forgot-password.module').then((m) => m.ForgotPasswordModule),
  },
  {
    path: 'check-key/:key/:mail', loadChildren: () => import('../check-key/check-key.module').then((m) => m.CheckKeyModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule { }
