import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NextButtonComponent } from './Components/next-button/next-button.component';
import { TitleComponent } from './Components/title/title.component';
import { InputComponent } from './Components/input/input.component';
import { InputSvgComponent } from './Components/input-svg/input-svg.component';
import { SelectComponent } from './Components/select/select.component';
import { RouterModule } from '@angular/router';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';



@NgModule({
  declarations: [
    NextButtonComponent,
    TitleComponent,
    InputComponent,
    InputSvgComponent,
    SelectComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    GooglePlaceModule
  ],
  exports: [
    NextButtonComponent,
    TitleComponent,
    InputComponent,
    InputSvgComponent,
    SelectComponent
  ]
})
export class SharedModule { }
