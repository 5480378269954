import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { DataPolicies, Policies } from 'src/app/modules/dashboard-lead/model/policies';
import { DataDetailedList, DetailedList } from 'src/app/modules/dashboard-lead/model/detailedList';
import { DashboardLeadService } from 'src/app/modules/dashboard-lead/service/dashboard-lead.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-table-policies',
  templateUrl: './table-policies.component.html',
  styleUrls: ['./table-policies.component.scss']
})
export class TablePoliciesComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('TABLEEXPORT') tableExport: ElementRef;
  loaderExport: boolean = false;

  policies$: Observable<Policies>;
  detailed$: Observable<Policies>;
  title: string;
  subtitle: string;
  totalRecords: number = 0;

  reqCompleted: boolean = false;

  dataSource: MatTableDataSource<DataPolicies>;
  dataExport: MatTableDataSource<DataPolicies>;

  @Input() range: string;
  @Input() from: string;
  @Input() to: string;
  @Input() carrier_name: string;
  @Input() carrier_ids: string[];

  displayedColumns: string[] = [
    'qq_link',
    'display_name',
    'foreign_hubspot_id',
    'policy_link',
    'policy_id',
    'foreign_customer_id',
    'effective_date',
    'expiration_date',
    'policy_status',
    'premium',
    'description',
    'foreign_carrier_id',
    'business_type',
    'carrier_name',
  ];

  constructor(private dashboardLeadService: DashboardLeadService, private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.getPoliciesStats(this.range, this.from, this.to, this.carrier_name, this.carrier_ids);
  }

  getPoliciesStats(range: string, from: string, to: string, carrier_name: string, _carrier_ids: string[]) {

    this.reqCompleted = false;
    this.detailed$ = this.dashboardLeadService.getDetailedListAgencyStats(range, from, to, carrier_name, _carrier_ids)
      .pipe(tap((resPolicies) => {
        this.title = resPolicies.title;
        this.subtitle = resPolicies.description;
        this.reqCompleted = true;

        if (resPolicies.data) {
          let newData = resPolicies.data.map((item) => {
            return item;
          });

          resPolicies.data = newData;

          this.dataSource = new MatTableDataSource<DataPolicies>(newData);
        }

        this.totalRecords = resPolicies.total !== null && resPolicies.total !== undefined ? resPolicies.total : 0;
        return resPolicies;
      }))
      .pipe(catchError(err => {
        this.reqCompleted = true;
        throw err;
      }));
  }

  addDataToDataSource(data: DataPolicies[]) {
    setTimeout(() => this.dataSource.paginator = this.paginator);
    setTimeout(() => this.dataSource.sort = this.sort);
    this.dataSource = new MatTableDataSource<DataPolicies>(data);
  }


  getTotal(column: string) {
    return this.dataSource.data.map(t => t[column]).reduce((acc, value) => acc + value, 0);
  }

  exportExcel() {
    this.loaderExport = true;

    this.dashboardLeadService.getDetailedListAgencyStats(this.range, this.from, this.to, this.carrier_name, this.carrier_ids)
    .subscribe((data: Policies) => {

      if (data !== null) {
        this.dataExport = this.dataSource;

        if (data.data !== null && data.data !== undefined) {
          this.dataSource.data = data.data;

          setTimeout(() => {
            const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.tableExport.nativeElement); //converts a DOM TABLE element to a worksheet
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

            const fileName = 'agency-stats-policies.xlsx';

            /* save to file */
            XLSX.writeFile(wb, fileName);

            this.dataSource = this.dataExport;
            this.dataExport = new MatTableDataSource<DataPolicies>(null);

            this.loaderExport = false;
          });
        }

      } else {
        this.notificationService.error('There was an error generating the CSV file.');
        this.loaderExport = false;
      }
    });
  }

}
